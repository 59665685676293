import { Input, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import "../../Assets/scss/Component/Movement/_deviceInforBulkMovement.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { addSaleProductApi } from "../../Redux/productReducer/saleProductReducer";
import { useDispatch, useSelector } from "react-redux";
import CreateSaleProduct from "../Product/CreateSaleProduct";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";

export default function DeviceInforBulkMovement(props) {
  const deviceList = [...props.deviceList];
  const dispatch = useDispatch();
  const [openCreateSale, setOpenCreateSale] = useState(false);
  const [purchaseProductItem, setPurchaseProductItem] = useState({});
  const { arrMovementDevice } = useSelector((state) => state.movementReducer);

  // Find purchase product to show
  useEffect(() => {
    const data = arrMovementDevice.find(
      (item) => item.id === props.deviceShow.deviceId
    );
    setPurchaseProductItem(data);
  }, []);

  // Handle form
  const formik = useFormik({
    initialValues: {
      salesProductId: props.deviceShow.salesProductId,
      replaceDeviceId: props.deviceShow.replaceDeviceId,
      comment: props.deviceShow.comment,
    },
    onSubmit: (values) => {
      convertEmptyToNull(values);
      const newData = { ...values };
      newData.deviceId = props.deviceShow.deviceId;
      const indexFind = deviceList.findIndex(
        (item) => item.deviceId === newData.deviceId
      );
      deviceList[indexFind] = newData;
      props.setDeviceList(deviceList);
      props.cancelDeviceInforModal();
    },
  });

  const saleProductInformation = useFormik({
    initialValues: {
      name: "",
      shortName: "",
      description: null,
      otherCost: "",
      haasPrice: "",
      baseSubsPrice: "",
      hwPrice: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      shortName: yup.string().required("Short name is required"),
    }),
    onSubmit: (values) => {
      let newValues = { ...values };
      newValues.otherCost !== ""
        ? (newValues.otherCost = Number(newValues.otherCost))
        : (newValues.otherCost = 0);
      newValues.haasPrice !== ""
        ? (newValues.haasPrice = Number(newValues.haasPrice))
        : (newValues.haasPrice = 0);
      newValues.baseSubsPrice !== ""
        ? (newValues.baseSubsPrice = Number(newValues.baseSubsPrice))
        : (newValues.baseSubsPrice = 0);
      newValues.hwPrice !== ""
        ? (newValues.hwPrice = Number(newValues.hwPrice))
        : (newValues.hwPrice = 0);
      const action = addSaleProductApi(newValues);
      dispatch(action);
      handleOkCreateSale();
      saleProductInformation.resetForm();
    },
  });

  // Handle modal
  const showModalCreateSale = () => {
    setOpenCreateSale(true);
  };

  const handleOkCreateSale = () => {
    message.loading("loading", 1);
    setOpenCreateSale(false);
  };
  const handleCancelCreateSale = () => {
    setOpenCreateSale(false);
    saleProductInformation.resetForm();
  };

  return (
    <form className="bulk_device-infor" onSubmit={formik.handleSubmit}>
      <div className="bulk_device-form">
        <div className="bulk_device-item">
          <p>Purchase product</p>
          <Input
            name="purchaseProduct"
            size="large"
            readOnly
            value={
              purchaseProductItem.purchaseProduct !== null
                ? purchaseProductItem.purchaseProduct?.name
                : "None"
            }
          />
        </div>
        <div className="bulk_device-item">
          <p>Sales product</p>
          <div className="bulk_device-rows">
            <div className="bulk_device-rows-select">
              <Select
                name="salesProductId"
                options={props.arraySaleProductShow}
                defaultValue={
                  formik.values.salesProductId !== null
                    ? {
                        value: props.saleProductShow?.value,
                        label: props.saleProductShow?.label,
                      }
                    : ""
                }
                onChange={(e) => {
                  if (e !== null) {
                    formik.setFieldValue("salesProductId", e.value);
                  } else {
                    formik.setFieldValue("salesProductId", e);
                  }
                }}
              ></Select>
            </div>
            <button type="button" onClick={showModalCreateSale}>
              Add
            </button>
          </div>
        </div>
        <div className="bulk_device-item">
          <p>Replace device</p>
          <Select
            name="replaceDeviceId"
            options={props.arrDevice}
            defaultValue={
              formik.values.replaceDeviceId !== null
                ? {
                    value: props.replaceDeviceShow?.value,
                    label: props.replaceDeviceShow?.label,
                  }
                : ""
            }
            onChange={(e) => {
              if (e !== null) {
                formik.setFieldValue("replaceDeviceId", e.value);
              } else {
                formik.setFieldValue("replaceDeviceId", e);
              }
            }}
          />
        </div>
        <div className="bulk_device-item">
          <p>Comment</p>
          <Input.TextArea
            name="comment"
            size="large"
            onChange={formik.handleChange}
            value={formik.values.comment}
          />
        </div>
      </div>
      <div className="bulk_device-action">
        <button
          type="button"
          className="cancel"
          onClick={props.cancelDeviceInforModal}
        >
          Cancel
        </button>
        <button type="submit" className="create">
          Create
        </button>
      </div>
      <Modal
        title="Create Sales Product"
        open={openCreateSale}
        onCancel={handleCancelCreateSale}
        footer={null}
        width={600}
        style={{ top: "50px" }}
      >
        <CreateSaleProduct
          handleCancelCreateSale={handleCancelCreateSale}
          handleOkCreateSale={handleOkCreateSale}
          saleProductInformation={saleProductInformation}
        />
      </Modal>
    </form>
  );
}
