import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import { useSelector } from "react-redux";
import { Skeleton, Tooltip } from "antd";
import { FileOutlined } from "@ant-design/icons";
import "../../Assets/scss/Component/Device/_deviceHistory.scss";
import { convertTime } from "../../Utils/convertTime";
dayjs.extend(relativeTime);

export default function EventHistory() {
  const { arrayChangeLog, isLoading } = useSelector(
    (state) => state.eventReducer
  );
  const { arrCompany } = useSelector((state) => state.companyReducer);
  const { arrMovementDevice } = useSelector((state) => state.movementReducer);
  const { arrayEventType } = useSelector((state) => state.eventTypeReducer);
  const { arrUser } = useSelector((state) => state.userReducer);

  // Show history function
  const showHistory = () => {
    if (isLoading === true) {
      return <Skeleton active avatar />;
    } else {
      if (arrayChangeLog.length !== 0) {
        return arrayChangeLog.map((item, index) => {
          if (item.action !== "Updated") {
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="Event had been deleted!"
                    color="#2962FF"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    {item.action.toLowerCase()}{" "}
                    {dayjs(new Date(item.createdAt)).fromNow()}
                  </p>
                </div>
              </div>
            );
          } else {
            const arrShow = [];
            let newItem = { ...item };
            newItem.arrHistoryShow = arrShow;
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "accountId" in item.beforeData
            ) {
              let beforeAccount = "None";
              let afterAccount = "None";
              if (item.beforeData.accountId !== null) {
                const result = arrCompany.find(
                  (account) => account.id === item.beforeData.accountId
                );
                beforeAccount = result.name;
              }
              if (item.afterData.accountId !== null) {
                const result = arrCompany.find(
                  (account) => account.id === item.afterData.accountId
                );
                afterAccount = result.name;
              }
              const account = {
                name: "Account",
                old: beforeAccount,
                new: afterAccount,
              };
              arrShow.push(account);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "typeId" in item.beforeData
            ) {
              let beforeEvent = "None";
              let afterEvent = "None";
              if (item.beforeData.typeId !== null) {
                const result = arrayEventType.find(
                  (type) => type.id === item.beforeData.typeId
                );
                beforeEvent = result.name;
              }
              if (item.afterData.typeId !== null) {
                const result = arrayEventType.find(
                  (type) => type.id === item.afterData.typeId
                );
                afterEvent = result.name;
              }
              const eventType = {
                name: "Event type",
                old: beforeEvent,
                new: afterEvent,
              };
              arrShow.push(eventType);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "supporterId" in item.beforeData
            ) {
              let beforeEvent = "None";
              let afterEvent = "None";
              if (item.beforeData.supporterId !== null) {
                const result = arrUser.find(
                  (user) => user.id === item.beforeData.supporterId
                );
                beforeEvent = `${result.lastName} ${result.firstName}`;
              }
              if (item.afterData.supporterId !== null) {
                const result = arrUser.find(
                  (user) => user.id === item.afterData.supporterId
                );
                afterEvent = `${result.lastName} ${result.firstName}`;
              }
              const supporter = {
                name: "Supporter",
                old: beforeEvent,
                new: afterEvent,
              };
              arrShow.push(supporter);
            }

            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "supportedAt" in item.beforeData
            ) {
              let timeBefore =
                item.beforeData.supportedAt === null
                  ? "None"
                  : convertTime(item.beforeData.supportedAt);
              let timeAfter =
                item.afterData.supportedAt === null
                  ? "None"
                  : convertTime(item.afterData.supportedAt);
              const time = {
                name: "Support date",
                old: timeBefore,
                new: timeAfter,
              };
              arrShow.push(time);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "isCharged" in item.beforeData
            ) {
              let chargeBefore = item.beforeData.isCharged === 0 ? "No" : "Yes";
              let chargeAfter = item.afterData.isCharged === 0 ? "No" : "Yes";
              const charge = {
                name: "Charge",
                old: chargeBefore,
                new: chargeAfter,
              };
              arrShow.push(charge);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "cost" in item.beforeData
            ) {
              let costBefore =
                item.beforeData.cost === null ? "None" : item.beforeData.cost;
              let costAfter =
                item.afterData.cost === null ? "None" : item.afterData.cost;
              const cost = {
                name: "Cost",
                old: costBefore,
                new: costAfter,
              };
              arrShow.push(cost);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "devices" in item.beforeData
            ) {
              let beforeDevices = [];
              let afterDevices = [];
              if (item.beforeData.devices.length !== 0) {
                for (let i = 0; i < item.beforeData.devices.length; i++) {
                  let itemChild = item.beforeData.devices[i];
                  const result = arrMovementDevice.find(
                    (device) => device.id === itemChild
                  );
                  beforeDevices.push(result.serialNumber);
                }
              } else {
                beforeDevices = ["None"];
              }
              if (item.afterData.devices.length !== 0) {
                for (let i = 0; i < item.afterData.devices.length; i++) {
                  let itemChild = item.afterData.devices[i];
                  const result = arrMovementDevice.find(
                    (device) => device.id === itemChild
                  );
                  afterDevices.push(result.serialNumber);
                }
              } else {
                afterDevices = ["None"];
              }
              const devices = {
                name: "Devices",
                old: beforeDevices.join(", "),
                new: afterDevices.join(", "),
              };
              arrShow.push(devices);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "address" in item.beforeData
            ) {
              let addressBefore =
                item.beforeData.address === null
                  ? "None"
                  : item.beforeData.address;
              let addressAfter =
                item.afterData.address === null
                  ? "None"
                  : item.afterData.address;
              const address = {
                name: "Address",
                old: addressBefore,
                new: addressAfter,
              };
              arrShow.push(address);
            }

            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "siteName" in item.beforeData
            ) {
              let siteNameBefore =
                item.beforeData.siteName === null
                  ? "None"
                  : item.beforeData.siteName;
              let siteNameAfter =
                item.afterData.siteName === null
                  ? "None"
                  : item.afterData.siteName;
              const siteName = {
                name: "Site name",
                old: siteNameBefore,
                new: siteNameAfter,
              };
              arrShow.push(siteName);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "notes" in item.beforeData
            ) {
              let notesBefore =
                item.beforeData.notes === null ? "None" : item.beforeData.notes;
              let notesAfter =
                item.afterData.notes === null ? "None" : item.afterData.notes;
              const notes = {
                name: "Notes",
                old: notesBefore,
                new: notesAfter,
              };
              arrShow.push(notes);
            }

            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {" "}
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    updated {dayjs(new Date(newItem.createdAt)).fromNow()}
                  </p>
                </div>
                {newItem.arrHistoryShow.map((item, index) => {
                  return (
                    <div key={index} className="description">
                      <strong>{item.name}</strong>: <span>{item.old}</span>
                      <i className="fa fa-long-arrow-alt-right"></i>
                      <span>{item.new}</span>
                    </div>
                  );
                })}
              </div>
            );
          }
        });
      } else {
        return (
          <div className="history_error">
            <div className="icon">
              <FileOutlined />
            </div>
            <p className="title">No data</p>
          </div>
        );
      }
    }
  };
  return <div className="history">{showHistory()}</div>;
}
