export const calculateActiveDays = (
  deviceRegisteredDate,
  targetMonth,
  targetYear
) => {
  if (deviceRegisteredDate !== null) {
    const past = new Date(deviceRegisteredDate);
    const pastMonth = past.getMonth() + 1;
    const pastYear = past.getFullYear();
    let totalMonths = (targetYear - pastYear) * 12 + (targetMonth - pastMonth);
    const targetMonthDate = new Date(targetYear, targetMonth - 1);
    let dayFraction = 0;

    if (past.getDate() > targetMonthDate.getDate()) {
      dayFraction =
        -1 +
        (past.getDate() - targetMonthDate.getDate()) /
          new Date(targetYear, targetMonth, 0).getDate();
    } else {
      dayFraction =
        (targetMonthDate.getDate() - past.getDate()) /
        new Date(pastYear, pastMonth, 0).getDate();
    }

    const exactMonths = totalMonths + dayFraction;
    return exactMonths.toFixed(2);
  } else {
    return "";
  }
};
