import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, message } from "antd";
import "../../Assets/scss/Component/Currency/_currencyDetail.scss";
import { updateCurrencyApi } from "../../Redux/currencyReducer/currencyReducer";
import { useFormik } from "formik";
import * as yup from "yup";
import { compareObject } from "../../Utils/compareObject";
import { renderUpdater } from "../../Utils/renderUpdater";

export default function CurrencyDetail() {
  const { currencyEdit } = useSelector((state) => state.currencyReducer);
  const dispatch = useDispatch();

  // Handle form
  const currencyDetailInformation = useFormik({
    initialValues: {
      name: currencyEdit.name,
      rate: currencyEdit.rate,
      description: currencyEdit.description,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name cannot empty"),
      rate: yup.string().required("Rate cannot empty"),
    }),
    onSubmit: (values) => {
      values.rate = Number(values.rate);
      const dataSend = {
        id: currencyEdit.id,
        data: values,
      };
      if (compareObject(values, currencyEdit)) {
        message.warning("Nothing has changed with this currency");
      } else {
        const action = updateCurrencyApi(dataSend);
        dispatch(action);
        message.loading("Loading", 1);
      }
    },
  });
  return (
    <div className="currency_detail">
      <div className="currency_current">
        <form onSubmit={currencyDetailInformation.handleSubmit}>
          <div className="current_detail">
            <ul>
              <li>
                <div className="detail_row">
                  <a className="row_1">
                    <div className="col_1">
                      <p>Name:</p>
                    </div>
                    <div className="col_2">
                      <Input
                        name="name"
                        onChange={currencyDetailInformation.handleChange}
                        onBlur={currencyDetailInformation.handleBlur}
                        value={currencyDetailInformation.values.name}
                        size="large"
                        className={
                          currencyDetailInformation.errors.name
                            ? "error_input"
                            : ""
                        }
                      />
                      {currencyDetailInformation.errors.name ? (
                        <div className="error_message">
                          <i className="fa fa-exclamation-circle"></i>
                          <span>{currencyDetailInformation.errors.name}</span>
                        </div>
                      ) : null}
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <a className="row_1">
                    <div className="col_1">
                      <p>Rate/AUD:</p>
                    </div>
                    <div className="col_2">
                      <Input
                        name="rate"
                        type="number"
                        min={0}
                        step={0.01}
                        onChange={currencyDetailInformation.handleChange}
                        onBlur={currencyDetailInformation.handleBlur}
                        value={currencyDetailInformation.values.rate}
                        size="large"
                        className={
                          currencyDetailInformation.errors.rate
                            ? "error_input"
                            : ""
                        }
                      />
                      {currencyDetailInformation.errors.rate ? (
                        <div className="error_message">
                          <i className="fa fa-exclamation-circle"></i>
                          <span>{currencyDetailInformation.errors.rate}</span>
                        </div>
                      ) : null}
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <a className="row_1">
                    <div className="col_1">
                      <p>Live rate:</p>
                    </div>
                    <div className="col_2">
                      <Input
                        name="liveRate"
                        type="number"
                        value={currencyEdit.liveRate}
                        disabled
                        size="large"
                      />
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <a className="row_1">
                    <div className="col_1">
                      <p>Description:</p>
                    </div>
                    <div className="col_2">
                      <Input.TextArea
                        name="description"
                        onChange={currencyDetailInformation.handleChange}
                        onBlur={currencyDetailInformation.handleBlur}
                        value={currencyDetailInformation.values.description}
                        size="large"
                      />
                    </div>
                  </a>
                </div>
              </li>
            </ul>
            <div className="add_action">
              <button type="submit">Save Change</button>
            </div>
          </div>
        </form>
      </div>
      <div className="user_addition">
        <div className="user_footer">{renderUpdater(currencyEdit,"currency")}</div>
      </div>
    </div>
  );
}
