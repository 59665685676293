import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Table,
  Space,
  Modal,
  Popconfirm,
  Tabs,
  message,
  Popover,
  Tooltip,
  FloatButton,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/scss/Pages/User/_user.scss";
import {
  SearchOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  deletePlatformApi,
  findOnePlatform,
  getAllPlatformApi,
  getChangeLog,
} from "../../Redux/platformReducer/platformReducer";
import CreatePlatform from "../../Components/Platform/CreatePlatform";
import PlatformDetail from "../../Components/Platform/PlatformDetail";
import { storage } from "../../Utils/storage";
import { getAllPlatformStatusApi } from "../../Redux/platformReducer/platformStatusReducer";
import PlatformHistory from "../../Components/Platform/PlatformHistory";
import ReactDragListView from "react-drag-listview";
import { showHideDeletedItem } from "../../Utils/showHideDeletedItem";
import {
  CURRENT_PAGE,
  DEFAULT_PAGESIZE,
} from "../../Utils/ConstCommon/constCommon";
import { renderShowColumn } from "../../Utils/renderShowColumn";
import ContentShowColumn from "../../Utils/ComponentCommon/ContentShowColumn";
import ContentShowHideDeleted from "../../Utils/ComponentCommon/ContentShowHideDeleted";

export default function Platform() {
  const dispatch = useDispatch();
  const { arrPlatform, platformEdit } = useSelector(
    (state) => state.platformReducer
  );
  const [arrSelect, setArrSelect] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [arrSelectBox, setArrSelectBox] = useState([]);
  const { arrPlatformStatus } = useSelector(
    (state) => state.platformStatusReducer
  );
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [showDeleted, setShowDeleted] = useState();
  const [data, setData] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(CURRENT_PAGE);
  const pageSizeRef = useRef(DEFAULT_PAGESIZE);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.url?.toLowerCase().includes(value.toLowerCase()) ||
          record.username?.toLowerCase().includes(value.toLowerCase()) ||
          record.email?.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          record.currentStatus.toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (text, record) => (
        <span
          className="open_detail"
          onClick={async () => {
            await dispatch(findOnePlatform(record.id));
            showModalDetail();
          }}
        >
          {text}
        </span>
      ),
      sorter: (beforeItem, afterItem) =>
        beforeItem.name.toLowerCase() > afterItem.name.toLowerCase()
          ? 1
          : beforeItem.name.toLowerCase() === afterItem.name.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      key: "shortName",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.url?.toLowerCase().includes(value.toLowerCase()) ||
          record.username?.toLowerCase().includes(value.toLowerCase()) ||
          record.email?.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          record.currentStatus.toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.shortName.toLowerCase() > afterItem.shortName.toLowerCase()
          ? 1
          : beforeItem.shortName.toLowerCase() ===
            afterItem.shortName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Status",
      dataIndex: "currentStatus",
      key: "currentStatus",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.url?.toLowerCase().includes(value.toLowerCase()) ||
          record.username?.toLowerCase().includes(value.toLowerCase()) ||
          record.email?.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          record.currentStatus.toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.currentStatus?.toLowerCase() >
        afterItem.currentStatus?.toLowerCase()
          ? 1
          : beforeItem.currentStatus?.toLowerCase() ===
            afterItem.currentStatus?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.url?.toLowerCase().includes(value.toLowerCase()) ||
          record.username?.toLowerCase().includes(value.toLowerCase()) ||
          record.email?.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          record.currentStatus.toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.url?.toLowerCase() > afterItem.url?.toLowerCase()
          ? 1
          : beforeItem.url?.toLowerCase() === afterItem.url?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.url?.toLowerCase().includes(value.toLowerCase()) ||
          record.username?.toLowerCase().includes(value.toLowerCase()) ||
          record.email?.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          record.currentStatus.toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.description?.toLowerCase() >
        afterItem.description?.toLowerCase()
          ? 1
          : beforeItem.description?.toLowerCase() ===
            afterItem.description?.toLowerCase()
          ? 0
          : -1,
      render: (text) => (
        <Tooltip title={text} color="#2962ff">
          <Typography.Paragraph ellipsis={{ rows: 1 }}>
            {text}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "80px",
      render: (_, record) => (
        <Space size="middle" className="user_action">
          <Popconfirm
            title="Delete Platform"
            placement="leftBottom"
            description="Are you sure to delete this platform?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              const action = deletePlatformApi(record.id);
              dispatch(action);
            }}
          >
            <button>
              <DeleteOutlined className="user_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  let [columnSelected, setColumnSelected] = useState(columns);
  for (let index = 0; index < columnSelected.length; index++) {
    let item = columnSelected[index];
    item.filteredValue = [searchValue];
  }

  // Show/hide column
  useEffect(() => {
    renderShowColumn(
      columns,
      "arrayPlatformShowColumn",
      "arrayPlatformRelocateTitleColumn",
      "arrayPlatformRelocateTitleColumnNew",
      setColumnSelected
    );
    document.title = "Tag Register - Platforms";
  }, []);

  const resetAll = () => {
    storage.removeStorage("arrayPlatformShowColumn");
    storage.removeStorage("arrayPlatformRelocateTitleColumn");
    storage.removeStorage("arrayPlatformRelocateTitleColumnNew");
    setColumnSelected(columns);
  };

  // Drag and drop column
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newFromIndex = fromIndex - 1;
      const newToIndex = toIndex - 1;
      const newColumns = [...columnSelected];
      const item = newColumns.splice(newFromIndex, 1)[0];
      newColumns.splice(newToIndex, 0, item);
      setColumnSelected(newColumns);
      let arrRelocate = newColumns.map((item) => {
        return item.key;
      });
      storage.setStorageJson("arrayPlatformRelocateTitleColumn", arrRelocate);
    },
    nodeSelector: "th",
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setArrSelect(selectedRows);
      return (
        `selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows
      );
    },
  };

  // Handle modal
  const showModal = () => {
    setOpen(true);
  };
  const showModalDetail = () => {
    setOpenDetail(true);
  };
  const handleOk = () => {
    message.loading("loading", 1);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const handleCancelDetail = () => {
    setOpenDetail(false);
  };

  // Call api
  useEffect(() => {
    dispatch(getAllPlatformApi());
    dispatch(getAllPlatformStatusApi());
  }, [dispatch]);

  // Recall api
  useEffect(() => {
    const reCallApi = setInterval(() => {
      dispatch(getAllPlatformApi());
    }, 10000);
    return () => clearInterval(reCallApi);
  }, []);

  const tabCallback = (key) => {
    if (key === "2") {
      const action = getChangeLog(platformEdit.id);
      dispatch(action);
    }
  };
  const items = [
    {
      key: "1",
      label: `Platform details`,
      children: <PlatformDetail handleCancelDetail={handleCancelDetail} />,
    },
    {
      key: "2",
      label: `History`,
      children: <PlatformHistory />,
    },
  ];

  useEffect(() => {
    let arrSelect = arrPlatformStatus.map((item, index) => {
      let obj = { value: item.id, label: item.name };
      return obj;
    });
    arrSelect.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setArrSelectBox(arrSelect);
  }, [arrPlatformStatus]);

  // Content for popover
  const content = (
    <ContentShowColumn
      columns={columns}
      storageShow="arrayPlatformShowColumn"
      storageRelocate="arrayPlatformRelocateTitleColumn"
      storageRelocateNew="arrayPlatformRelocateTitleColumnNew"
      action={setColumnSelected}
      resetAll={resetAll}
    />
  );

  // Show/hide deleted item
  const onChangeShowDeleted = (checked) => {
    showHideDeletedItem(checked, arrPlatform, setData, "showDeletedPlatform");
    setShowDeleted(checked);
  };
  useEffect(() => {
    const isShow = storage.getStore("showDeletedPlatform");
    if (isShow === undefined || isShow === "true") {
      setData(arrPlatform);
      setShowDeleted(true);
    } else {
      const arrayData = arrPlatform.filter((item) => item.isDeleted !== 1);
      setData(arrayData);
      setShowDeleted(false);
    }
  }, [arrPlatform]);

  const contentAction = (
    <ContentShowHideDeleted
      onChangeShowDeleted={onChangeShowDeleted}
      showDeleted={showDeleted}
      arrayData={arrPlatform}
      columnSelected={columnSelected}
      pageName="Platform"
      content={content}
    />
  );
  return (
    <div className="user">
      <div className="user_top">
        <div className="user_left">
          <p>Platforms</p>
        </div>
      </div>
      <div className="user_body">
        <div className="user_show">
          <div className="user_showChild">
            <div className="user_showLeft">
              <p>
                Selected: <strong>{arrSelect.length}</strong>
              </p>
              <button
                onClick={() => {
                  showModal();
                }}
                className="user_button-create"
              >
                + New
              </button>
              <Popover
                content={contentAction}
                trigger="click"
                placement="right"
              >
                <button className="button-second">
                  Action <i className="fa fa-caret-down"></i>
                </button>
              </Popover>
            </div>
            <div className="user_showRight">
              <div className="search_area">
                <SearchOutlined className="search_icon" />
                <Input
                  className="search_box"
                  placeholder="Search..."
                  allowClear
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="user_table">
          <ReactDragListView.DragColumn {...dragProps}>
            <Table
              rowSelection={rowSelection}
              columns={[...columnSelected]}
              dataSource={data}
              rowClassName={(record) =>
                record.isDeleted === 1 ? "deleted" : ""
              }
              pagination={{
                defaultPageSize: 10,
                current: currentPagination,
                showSizeChanger: true,
                showTotal: (total, range) => (
                  <p>
                    Showing{" "}
                    <strong>
                      {range[0]}-{range[1]}
                    </strong>{" "}
                    of <strong>{total}</strong> items
                  </p>
                ),
                onChange: (newCurrent, newPageSize) => {
                  const pageSizeChange = pageSizeRef.current !== newPageSize;
                  if (pageSizeChange) {
                    setCurrentPagination(1);
                  } else {
                    setCurrentPagination(newCurrent);
                  }
                  pageSizeRef.current = newPageSize;
                },
              }}
              bordered={true}
              scroll={{
                x: 1200,
              }}
              size="middle"
              sticky
            />
          </ReactDragListView.DragColumn>
        </div>
      </div>
      <Modal
        title="Create Platform"
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={600}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <CreatePlatform
          handleCancel={handleCancel}
          handleOk={handleOk}
          arrSelectBox={arrSelectBox}
        />
      </Modal>
      <Modal
        title={"Platform ID: " + platformEdit.id}
        open={openDetail}
        onCancel={handleCancelDetail}
        footer={null}
        width={600}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          size="small"
          className="user_tab"
          onChange={tabCallback}
          destroyInactiveTabPane
        />
      </Modal>
      <FloatButton.BackTop />
    </div>
  );
}
