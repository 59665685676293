import axiosClient from "./axiosClient";

const companyTypesApi = {
  companyTypesAll: async () => {
    const response = await axiosClient.get("/account-type");
    return response;
  },
  companyTypesAdd: async (data) => {
    const response = await axiosClient.post("/account-type", data);
    return response;
  },
  companyTypesUpdate: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/account-type/" + id, data);
    return response;
  },
  companyTypesDelete: async (id) => {
    const response = await axiosClient.delete("/account-type/" + id);
    return response;
  },
  companyTypesRestore: async (id) => {
    const response = await axiosClient.put("/account-type/restore/" + id);
    return response;
  },
  companyTypesFindOne: async (id) => {
    const response = await axiosClient.get("/account-type/" + id);
    return response;
  },
};

export default companyTypesApi;
