import axiosClient from "./axiosClient";

export const platformApi = {
  addNewPlatform: async (data) => {
    const response = await axiosClient.post("/platform", data);
    return response;
  },
  getAllPlatform: async () => {
    const response = await axiosClient.get("/platform");
    return response;
  },
  updatePlatform: async (params) => {
    let { id, data } = params;
    const response = await axiosClient.put("/platform/" + id, data);
    return response;
  },
  deletePlatform: async (id) => {
    const response = await axiosClient.delete("/platform/" + id);
    return response;
  },
  restorePlatform: async (id) => {
    const response = await axiosClient.put("/platform/restore/" + id);
    return response;
  },
  changeLog: async (id) => {
    const response = await axiosClient.get("/platform/history/" + id);
    return response;
  },
  findOne: async (id) => {
    const response = await axiosClient.get("/platform/" + id);
    return response;
  },
};
