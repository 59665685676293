import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Input, Modal, message } from "antd";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompany,
  getAllTypesCompany,
} from "../../Redux/companyReducer/companyReducer";
import { getAllCurrencyApi } from "../../Redux/currencyReducer/currencyReducer";
import { getAllBillingPolicy } from "../../Redux/billingPolicyReducer/billingPolicyReducer";
import { getAllPlatformApi } from "../../Redux/platformReducer/platformReducer";
import "../../Assets/scss/Component/Company/_createCompany.scss";
import CreateTypes from "./CreateTypes";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";

const CreateCompany = ({ cancelModalNew, resetForm }) => {
  const [valueSelectType, setValueSelectType] = useState([]);
  const [valueSelectParent, setValueSelectParent] = useState([]);
  const [valuePrimary, setValuePrimary] = useState([]);
  const [valueSelectCurrency, setValueSelectCurrency] = useState([]);
  const [valueSelectBilling, setValueSelectBilling] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [resetSelect, setResetSelect] = useState(false);
  const dispatch = useDispatch();
  const { arrPlatform } = useSelector((state) => state.platformReducer);
  const { arrNameCompanyTypes, arrCompany } = useSelector((state) => state.companyReducer);
  const { arrCurrency } = useSelector((state) => state.currencyReducer);
  const { arrBillingPolicy } = useSelector(
    (state) => state.billingPolicyReducer
  );

  // Handle form
  const formik = useFormik({
    initialValues: {
      name: "",
      shortName: "",
      typeId: "",
      primaryPlatformId: "",
      contactNameXero: "",
      street: "",
      city: "",
      state: "",
      country: "",
      currencyId: "",
      billingPolicyId: "",
      notes: "",
      defaultPrice: "",
      parentId: null
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      typeId: yup.string().required("Account type is required"),
    }),
    onSubmit: (values) => {
      values.defaultPrice !== ""
        ? Number(values.defaultPrice)
        : (values.defaultPrice = 1.5);
      values.typeId = values.typeId !== "" ? Number(values.typeId) : null;
      values.primaryPlatformId =
        values.primaryPlatformId !== ""
          ? Number(values.primaryPlatformId)
          : null;
      values.currencyId =
        values.currencyId !== "" ? Number(values.currencyId) : null;
      values.billingPolicyId =
        values.billingPolicyId !== "" ? Number(values.billingPolicyId) : null;
      values.shortName = values.shortName !== "" ? values.shortName : null;
      values.contactNameXero =
        values.contactNameXero !== "" ? values.contactNameXero : null;
      values.street = values.street !== "" ? values.street : null;
      values.city = values.city !== "" ? values.city : null;
      values.state = values.state !== "" ? values.state : null;
      values.country = values.country !== "" ? values.country : null;
      values.notes = values.notes !== "" ? values.notes : null;
      dispatch(addCompany(values));
      handleOk();
      cancelCreate();
    },
  });

  // Handle modal
  const handleOk = () => {
    message.loading("loading", 1);
  };
  const cancelCreate = () => {
    formik.resetForm();
    cancelModalNew();
  };

  const handleShowModal = () => {
    setShowModal(true);
    setResetSelect(true);
  };
  const handleCancelModal = () => {
    setShowModal(false);
    setResetSelect(false);
  };

  useEffect(() => {
    formik.resetForm();
  }, [resetForm]);

  // Call api
  useEffect(() => {
    dispatch(getAllTypesCompany());
    dispatch(getAllCurrencyApi());
    dispatch(getAllBillingPolicy());
    dispatch(getAllPlatformApi());
  }, [dispatch, resetSelect]);

  useEffect(() => {
    let arrSelect = [];
    arrNameCompanyTypes.map((item) => {
      let obj = { value: item.id, label: item.name };
      arrSelect.push(obj);
    });
    arrSelect.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setValueSelectType(arrSelect);
  }, [arrNameCompanyTypes]);

  useEffect(() => {
    CreateDataForSelect(arrPlatform, setValuePrimary);
  }, [arrPlatform]);

  useEffect(() => {
    let arrSelect = [];
    arrCurrency.map((item) => {
      let obj = { value: item.id, label: item.name };
      arrSelect.push(obj);
    });
    arrSelect.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setValueSelectCurrency(arrSelect);
  }, [arrCurrency]);

  useEffect(() => {
    let arrSelect = [];
    arrBillingPolicy.map((item) => {
      let obj = { value: item.id, label: item.name };
      arrSelect.push(obj);
    });
    arrSelect.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setValueSelectBilling(arrSelect);
  }, [arrBillingPolicy]);

  useEffect(() => {
    let arrSelect = [];
    arrCompany.map((item) => {
      let obj = { value: item.id, label: item.name };
      arrSelect.push(obj);
    });
    arrSelect.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setValueSelectParent(arrSelect);
  }, [arrCompany]);

  return (
    <div className="create_company">
      <form onSubmit={formik.handleSubmit}>
        <div className="create_company-title">
          <p>Information</p>
        </div>
        <div className="create_company-information">
          <div className="create_company-mergeField">
            <div className="create_company-fieldLeft">
              <label>Name*</label>
              <Input
                className={
                  formik.touched.name && formik.errors.name
                    ? "create_company-errorsInput"
                    : ""
                }
                size="large"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="create_company-errors">
                  <i className="fas fa-exclamation-circle"></i>
                  <span>{formik.errors.name}</span>
                </div>
              ) : null}
            </div>
            <div className="create_company-fieldRight">
              <label>Short Name</label>
              <Input
                className={
                  formik.touched.shortName && formik.errors.shortName
                    ? "create_company-errorsInput"
                    : ""
                }
                size="large"
                name="shortName"
                value={formik.values.shortName}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="create_company-mergeField">
            <div className="create_company-fieldLeftType">
              <label>Account Type*</label>
              <div className="create_company-fieldType">
                <div className="create_company-fieldTypeSelect">
                  <Select
                    className={
                      formik.touched.typeId && formik.errors.typeId
                        ? "create_company-errorsSelect"
                        : ""
                    }
                    name="typeId"
                    options={valueSelectType}
                    onChange={(e) => {
                      if (e !== null) {
                        formik.setFieldValue("typeId", e.value);
                      } else {
                        formik.setFieldValue("typeId", "");
                      }
                    }}
                  />
                  {formik.touched.typeId && formik.errors.typeId ? (
                    <div className="create_company-errors">
                      <i className="fas fa-exclamation-circle"></i>
                      <span>{formik.errors.typeId}</span>
                    </div>
                  ) : null}
                </div>
                <button onClick={handleShowModal}>Add</button>
              </div>
            </div>

            <div className="create_company-fieldRight">
              <label>Primary Platform</label>
              <Select
                size="large"
                name="primaryPlatformId"
                options={valuePrimary}
                onChange={(e) => {
                  if (e !== null) {
                    formik.setFieldValue("primaryPlatformId", e.value);
                  } else {
                    formik.setFieldValue("primaryPlatformId", "");
                  }
                }}
              />
            </div>
          </div>
          <div className="create_company-oneFieldInput">
            <label>"ContactName" in XERO</label>
            <Input
              size="large"
              name="contactNameXero"
              value={formik.values.contactNameXero}
              onChange={formik.handleChange}
            />
          </div>
          <div className="create_company-mergeField">
            <div className="create_company-fieldLeft">
              <label>Street Address</label>
              <Input
                size="large"
                name="street"
                value={formik.values.street}
                onChange={formik.handleChange}
              />
            </div>
            <div className="create_company-fieldRight">
              <label>City</label>
              <Input
                size="large"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="create_company-mergeField">
            <div className="create_company-fieldLeft">
              <label>State</label>
              <Input
                size="large"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
              />
            </div>
            <div className="create_company-fieldRight">
              <label>Country</label>
              <Input
                size="large"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="create_company-oneFieldTextarea">
            <label>Notes</label>
            <Input.TextArea
              name="notes"
              value={formik.values.notes}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="create_company-title">
          <p>For Invoice</p>
        </div>
        <div className="create_company-invoice">
          <div className="create_company-mergeField">
            <div className="create_company-fieldLeft">
              <label>Currency</label>
              <Select
                name="currencyId"
                options={valueSelectCurrency}
                onChange={(e) => {
                  if (e !== null) {
                    formik.setFieldValue("currencyId", e.value);
                  } else {
                    formik.setFieldValue("currencyId", "");
                  }
                }}
              />
            </div>
            <div className="create_company-fieldRight">
              <label>Billing Policy</label>
              <Select
                name="billingPolicyId"
                options={valueSelectBilling}
                onChange={(e) => {
                  if (e !== null) {
                    formik.setFieldValue("billingPolicyId", e.value);
                  } else {
                    formik.setFieldValue("billingPolicyId", "");
                  }
                }}
              />
            </div>
          </div>
          <div className="create_company-mergeFieldOne">
            <div className="create_company-field">
              <label>Default Price</label>
              <Input
                className={
                  formik.touched.defaultPrice && formik.errors.defaultPrice
                    ? "create_company-errorsInput"
                    : ""
                }
                size="large"
                name="defaultPrice"
                type="number"
                value={formik.values.defaultPrice}
                onChange={formik.handleChange}
                step={0.01}
                min={1.5}
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="create_company-submit">
          <button
            type="button"
            className="create_company-cancel"
            onClick={cancelCreate}
          >
            Cancel
          </button>
          <button type="submit" className="create_company-create">
            Create
          </button>
        </div>
      </form>

      <Modal
        title="Account Types"
        open={showModal}
        onCancel={handleCancelModal}
        footer={null}
        width={600}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateTypes
          handleCancelModal={handleCancelModal}
          setResetSelect={setResetSelect}
          resetSelect={resetSelect}
        />
      </Modal>
    </div>
  );
};

export default CreateCompany;
