import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export default function MovementColumnBody({
  item,
  id,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id, data: {...item} });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
  };

  return (
    <div
      className="body_item"
      id={id}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {item.name}
    </div>
  );
}
