import React, { useState } from "react";
import { Input, Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  deleteDeviceStateApi,
  restoreDeviceStateApi,
  updateDeviceStateApi,
} from "../../Redux/deviceReducer/deviceStateReducer";
import "../../Assets/scss/Component/Platform/_platformStatusDetail.scss";

export default function PlatformStatusDetail(props) {
  const { deviceStateEdit } = useSelector((state) => state.deviceStateReducer);
  const dispatch = useDispatch();
  const [showRestore, setShowRestore] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  // Handle modal
  const showModalRestore = () => {
    setShowRestore(true);
  };
  const cancelModalRestore = () => {
    setShowRestore(false);
  };
  const handleRestore = () => {
    message.loading("loading", 1);
    dispatch(restoreDeviceStateApi(deviceStateEdit.id));
    setShowRestore(false);
  };

  const showModalDelete = () => {
    setShowDelete(true);
  };
  const cancelModalDelete = () => {
    setShowDelete(false);
  };
  const handleDelete = () => {
    message.loading("loading", 1);
    dispatch(deleteDeviceStateApi(deviceStateEdit.id));
    setShowDelete(false);
    props.handleCancelStatus();
  };

  // Handle form
  const deviceStateDetailInformation = useFormik({
    initialValues: {
      name: deviceStateEdit.name,
      description: deviceStateEdit.description,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name cannot be empty"),
    }),
    onSubmit: (values) => {
      if (
        values.name === deviceStateEdit.name &&
        values.description === deviceStateEdit.description
      ) {
        message.warning("Nothing has changed with this device state");
      } else {
        const newObject = {};
        for (let item in values) {
          if (values[item] !== deviceStateEdit[item]) {
            newObject[item] = values[item];
          }
        }
        const dataSend = {
          id: deviceStateEdit.id,
          data: newObject,
        };
        message.loading("Loading", 1);
        dispatch(updateDeviceStateApi(dataSend));
      }
    },
  });

  return (
    <div>
      <form
        className="status_detail"
        onSubmit={deviceStateDetailInformation.handleSubmit}
      >
        <div className="status_item">
          <p>Name</p>
          <Input
            name="name"
            onChange={deviceStateDetailInformation.handleChange}
            onBlur={deviceStateDetailInformation.handleBlur}
            value={deviceStateDetailInformation.values.name}
            size="large"
            disabled={deviceStateEdit.isDeleted === 1 ? true : false}
          />
          {deviceStateDetailInformation.errors.name ? (
            <div className="err_status">
              <i className="fas fa-exclamation-circle"></i>
              <span>{deviceStateDetailInformation.errors.name}</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="status_item">
          <p>Description</p>
          <Input.TextArea
            name="description"
            onChange={deviceStateDetailInformation.handleChange}
            onBlur={deviceStateDetailInformation.handleBlur}
            value={deviceStateDetailInformation.values.description}
            size="small"
            disabled={deviceStateEdit.isDeleted === 1 ? true : false}
          />
        </div>
        <div className="status_action">
          <button
            type="button"
            className="status_action-cancel"
            onClick={props.handleCancelStatus}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="status_action-submit"
            disabled={deviceStateEdit.isDeleted === 1 ? true : false}
            style={{
              cursor: deviceStateEdit.isDeleted === 1 ? "no-drop" : "pointer",
            }}
          >
            Save change
          </button>
        </div>
      </form>

      {deviceStateEdit.isDeleted === 0 ? (
        <div className="status_delete">
          <h3>
            <span>
              <i className="fa fa-history"></i>
            </span>{" "}
            Delete state
          </h3>
          <div className="status_delete-button">
            <p>This action can't be undone!</p>
            <button type="button" onClick={showModalDelete}>
              Delete
            </button>
          </div>
        </div>
      ) : (
        <div className="status_restore">
          <h3>
            <span>
              <i className="fa fa-history"></i>
            </span>{" "}
            Restore state
          </h3>
          <div className="status_restore-button">
            <p>
              This state has been deleted. If you want to restore it, please
              click on the Restore button.
            </p>
            <button type="button" onClick={showModalRestore}>
              Restore
            </button>
          </div>
        </div>
      )}

      <Modal
        open={showDelete}
        onCancel={cancelModalDelete}
        footer={null}
        width={450}
      >
        <div className="status_modal">
          <p>
            <i className="fa fa-question"></i> Delete state
          </p>
          <span>Are you sure to delete this state?</span>
          <div className="status_modal-action">
            <button className="status_modal-cancel" onClick={cancelModalDelete}>
              Cancel
            </button>
            <button className="status_modal-delete" onClick={handleDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={showRestore}
        onCancel={cancelModalRestore}
        footer={null}
        width={450}
      >
        <div className="status_modal">
          <p>
            <i className="fa fa-question"></i> Restore state
          </p>
          <span>Are you sure to restore this state?</span>
          <div className="status_modal-action">
            <button
              className="status_modal-cancel"
              onClick={cancelModalRestore}
            >
              Cancel
            </button>
            <button className="status_modal-restore" onClick={handleRestore}>
              Restore
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
