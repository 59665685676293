import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import { useSelector } from "react-redux";
import { Skeleton, Tooltip } from "antd";
import { FileOutlined } from "@ant-design/icons";
import "../../Assets/scss/Component/Device/_deviceHistory.scss";

dayjs.extend(relativeTime);

const CompanyHistory = () => {
  const {
    arrNameCompanyTypes,
    arrChangeLogCompany,
    isLoadingHistory,
    arrCompany,
  } = useSelector((state) => state.companyReducer);
  const { arrPlatform } = useSelector((state) => state.platformReducer);
  const { arrBillingPolicy } = useSelector(
    (state) => state.billingPolicyReducer
  );
  const { arrCurrency } = useSelector((state) => state.currencyReducer);

  const showHistory = () => {
    if (isLoadingHistory === true) {
      return <Skeleton active avatar />;
    } else {
      if (arrChangeLogCompany !== 0) {
        return arrChangeLogCompany.map((item, index) => {
          if (item.action === "Created") {
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    created {dayjs(new Date(item.createdAt)).fromNow()}
                  </p>
                </div>
              </div>
            );
          } else if (item.action === "Deleted") {
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    deleted {dayjs(new Date(item.createdAt)).fromNow()}
                  </p>
                </div>
              </div>
            );
          } else if (item.action === "Restore") {
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    restored {dayjs(new Date(item.createdAt)).fromNow()}
                  </p>
                </div>
              </div>
            );
          } else {
            const arrShow = [];
            let newItem = { ...item };
            newItem.arrHistoryShow = arrShow;

            if ("name" in item.beforeData) {
              const name = {
                name: "Name",
                old: item.beforeData.name,
                new: item.afterData.name,
              };
              arrShow.push(name);
            }

            if ("shortName" in item.beforeData) {
              let before =
                item.beforeData.shortName === "" ||
                item.beforeData.shortName === null
                  ? "None"
                  : item.beforeData.shortName;
              let after =
                item.afterData.shortName === "" ||
                item.afterData.shortName === null
                  ? "None"
                  : item.afterData.shortName;
              const shortName = {
                name: "Short Name",
                old: before,
                new: after,
              };
              arrShow.push(shortName);
            }

            if ("typeId" in item.beforeData) {
              let beforeType = "None";
              let afterType = "None";
              if (item.beforeData.typeId !== null) {
                const result = arrNameCompanyTypes.find(
                  (state) => state.id === item.beforeData.typeId
                );
                beforeType = result?.name;
              }
              if (item.afterData.typeId !== null) {
                const result = arrNameCompanyTypes.find(
                  (state) => state.id === item.afterData.typeId
                );
                afterType = result?.name;
              }
              const type = {
                name: "Account Type",
                old: beforeType,
                new: afterType,
              };
              arrShow.push(type);
            }
            if ("parentId" in item.beforeData) {
              let beforeParent = "None";
              let afterParent = "None";
              if (item.beforeData.parentId !== null) {
                const result = arrCompany.find(
                  (state) => state.id === item.beforeData.parentId
                );
                beforeParent = result?.name;
              }
              if (item.afterData.parentId !== null) {
                const result = arrCompany.find(
                  (state) => state.id === item.afterData.parentId
                );
                afterParent = result?.name;
              }
              const parentId = {
                name: "Parent Account",
                old: beforeParent,
                new: afterParent,
              };
              arrShow.push(parentId);
            }

            if ("primaryPlatformId" in item.beforeData) {
              let beforeType = "None";
              let afterType = "None";

              if (item.beforeData.primaryPlatformId !== null) {
                const result = arrPlatform.find(
                  (state) => state.id === item.beforeData.primaryPlatformId
                );
                beforeType = result?.name;
              }
              if (item.afterData.primaryPlatformId !== null) {
                const result = arrPlatform.find(
                  (state) => state.id === item.afterData.primaryPlatformId
                );
                afterType = result?.name;
              }
              const primaryPlatform = {
                name: "Primary Platform",
                old: beforeType,
                new: afterType,
              };
              arrShow.push(primaryPlatform);
            }

            if ("contactNameXero" in item.beforeData) {
              let beforeXero =
                item.beforeData.contactNameXero === "" ||
                item.beforeData.contactNameXero === null
                  ? "None"
                  : item.beforeData.contactNameXero;
              let afterXero =
                item.afterData.contactNameXero === "" ||
                item.afterData.contactNameXero === null
                  ? "None"
                  : item.afterData.contactNameXero;
              const contactNameXero = {
                name: `"${"ContactName"}" in XERO`,
                old: beforeXero,
                new: afterXero,
              };
              arrShow.push(contactNameXero);
            }

            if ("street" in item.beforeData) {
              let beforeStreet =
                item.beforeData.street === "" || item.beforeData.street === null
                  ? "None"
                  : item.beforeData.street;
              let afterStreet =
                item.afterData.street === "" || item.afterData.street === null
                  ? "None"
                  : item.afterData.street;
              const street = {
                name: "Street Address",
                old: beforeStreet,
                new: afterStreet,
              };
              arrShow.push(street);
            }

            if ("city" in item.beforeData) {
              let beforeCity =
                item.beforeData.city === "" || item.beforeData.city === null
                  ? "None"
                  : item.beforeData.city;
              let afterCity =
                item.afterData.city === "" || item.afterData.city === null
                  ? "None"
                  : item.afterData.city;
              const city = {
                name: "City",
                old: beforeCity,
                new: afterCity,
              };
              arrShow.push(city);
            }

            if ("state" in item.beforeData) {
              let beforeState =
                item.beforeData.state === "" || item.beforeData.state === null
                  ? "None"
                  : item.beforeData.state;
              let afterState =
                item.afterData.state === "" || item.afterData.state === null
                  ? "None"
                  : item.afterData.state;
              const state = {
                name: "State",
                old: beforeState,
                new: afterState,
              };
              arrShow.push(state);
            }

            if ("country" in item.beforeData) {
              let beforeCountry =
                item.beforeData.country === "" ||
                item.beforeData.country === null
                  ? "None"
                  : item.beforeData.country;
              let afterCountry =
                item.afterData.country === "" || item.afterData.country === null
                  ? "None"
                  : item.afterData.country;
              const country = {
                name: "Country",
                old: beforeCountry,
                new: afterCountry,
              };
              arrShow.push(country);
            }

            if ("currencyId" in item.beforeData) {
              let beforeCurrency = "None";
              let afterCurrency = "None";
              if (item.beforeData.currencyId !== null) {
                const result = arrCurrency.find(
                  (state) => state.id === item.beforeData.currencyId
                );
                beforeCurrency = result?.name;
              }
              if (item.afterData.currencyId !== null) {
                const result = arrCurrency.find(
                  (state) => state.id === item.afterData.currencyId
                );
                afterCurrency = result?.name;
              }
              const currency = {
                name: "Currency",
                old: beforeCurrency,
                new: afterCurrency,
              };
              arrShow.push(currency);
            }

            if ("billingPolicyId" in item.beforeData) {
              let beforeBillingPolicy = "None";
              let afterBillingPolicy = "None";
              if (item.beforeData.billingPolicyId !== null) {
                const result = arrBillingPolicy.find(
                  (state) => state.id === item.beforeData.billingPolicyId
                );
                beforeBillingPolicy = result?.name;
              }
              if (item.afterData.billingPolicyId !== null) {
                const result = arrBillingPolicy.find(
                  (state) => state.id === item.afterData.billingPolicyId
                );
                afterBillingPolicy = result?.name;
              }
              const billingPolicy = {
                name: "Billing Policy",
                old: beforeBillingPolicy,
                new: afterBillingPolicy,
              };
              arrShow.push(billingPolicy);
            }

            if ("notes" in item.beforeData) {
              let beforeNotes =
                item.beforeData.notes === "" || item.beforeData.notes === null
                  ? "None"
                  : item.beforeData.notes;
              let afterNotes =
                item.afterData.notes === "" || item.afterData.notes === null
                  ? "None"
                  : item.afterData.notes;
              const notes = {
                name: "Notes",
                old: beforeNotes,
                new: afterNotes,
              };
              arrShow.push(notes);
            }

            if ("hasDiscount" in item.beforeData) {
              let beforeHasDiscount =
                item.beforeData.hasDiscount === 0 ? "No discount" : "Discount";
              let afterHasDiscount =
                item.afterData.hasDiscount === 0 ? "No discount" : "Discount";
              const hasDiscount = {
                name: "Discount",
                old: beforeHasDiscount,
                new: afterHasDiscount,
              };
              arrShow.push(hasDiscount);
            }

            if ("defaultPrice" in item.beforeData) {
              let beforeDailyPrice =
                item.beforeData.defaultPrice === "" ||
                item.beforeData.defaultPrice === null
                  ? "None"
                  : item.beforeData.defaultPrice;
              let afterDailyPrice =
                item.afterData.defaultPrice === "" ||
                item.afterData.defaultPrice === null
                  ? "None"
                  : item.afterData.defaultPrice;
              const defaultPrice = {
                name: "Default Price",
                old: beforeDailyPrice,
                new: afterDailyPrice,
              };
              arrShow.push(defaultPrice);
            }

            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {" "}
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    updated {dayjs(new Date(newItem.createdAt)).fromNow()}
                  </p>
                </div>
                {newItem.arrHistoryShow.map((item, index) => {
                  return (
                    <div key={index} className="description">
                      <strong>{item.name}</strong>: <span>{item.old}</span>
                      <i className="fa fa-long-arrow-alt-right"></i>
                      <span>{item.new}</span>
                    </div>
                  );
                })}
              </div>
            );
          }
        });
      } else {
        return (
          <div className="history_error">
            <div className="icon">
              <FileOutlined />
            </div>
            <p className="title">No data</p>
          </div>
        );
      }
    }
  };

  return (
    <div className="history">
      <div>{showHistory()}</div>
    </div>
  );
};

export default CompanyHistory;
