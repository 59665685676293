import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import { useSelector } from "react-redux";
import { Skeleton, Tooltip } from "antd";
import { FileOutlined } from "@ant-design/icons";
import "../../Assets/scss/Component/Device/_deviceHistory.scss";
dayjs.extend(relativeTime);

export default function PlatformHistory() {
  const { arrChangeLog, isLoading } = useSelector(
    (state) => state.platformReducer
  );
  const { arrPlatformStatus } = useSelector(
    (state) => state.platformStatusReducer
  );
  const showHistory = () => {
    if (isLoading === true) {
      return <Skeleton active avatar />;
    } else {
      if (arrChangeLog.length !== 0) {
        return arrChangeLog.map((item, index) => {
          if (item.action !== "Updated") {
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    {item.action.toLowerCase()}{" "}
                    {dayjs(new Date(item.createdAt)).fromNow()}
                  </p>
                </div>
              </div>
            );
          } else {
            const arrShow = [];
            let newItem = { ...item };
            newItem.arrHistoryShow = arrShow;
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "name" in item.beforeData
            ) {
              const name = {
                name: "Name",
                old: item.beforeData.name,
                new: item.afterData.name,
              };
              arrShow.push(name);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "shortName" in item.beforeData
            ) {
              const shortName = {
                name: "Short name",
                old: item.beforeData.shortName,
                new: item.afterData.shortName,
              };
              arrShow.push(shortName);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "url" in item.beforeData
            ) {
              let urlBefore =
                item.beforeData.url === null ? "None" : item.beforeData.url;
              let urlAfter =
                item.afterData.url === null ? "None" : item.afterData.url;
              const url = {
                name: "Url",
                old: urlBefore,
                new: urlAfter,
              };
              arrShow.push(url);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "statusId" in item.beforeData
            ) {
              let beforeStatus = "None";
              let afterStatus = "None";
              if (item.beforeData.statusId !== null) {
                const result = arrPlatformStatus.find(
                  (state) => state.id === item.beforeData.statusId
                );
                beforeStatus = result?.name;
              }
              if (item.afterData.statusId !== null) {
                const result = arrPlatformStatus.find(
                  (state) => state.id === item.afterData.statusId
                );
                afterStatus = result?.name;
              }
              const status = {
                name: "Status",
                old: beforeStatus,
                new: afterStatus,
              };
              arrShow.push(status);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "description" in item.beforeData
            ) {
              let descriptionBefore =
                item.beforeData.description === null
                  ? "None"
                  : item.beforeData.description;
              let descriptionAfter =
                item.afterData.description === null
                  ? "None"
                  : item.afterData.description;
              const description = {
                name: "Description",
                old: descriptionBefore,
                new: descriptionAfter,
              };
              arrShow.push(description);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "comment" in item.beforeData
            ) {
              let commentBefore =
                item.beforeData.comment === null
                  ? "None"
                  : item.beforeData.comment;
              let commentAfter =
                item.afterData.comment === null
                  ? "None"
                  : item.afterData.comment;
              const comment = {
                name: "Comment",
                old: commentBefore,
                new: commentAfter,
              };
              arrShow.push(comment);
            }
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {" "}
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    updated {dayjs(new Date(newItem.createdAt)).fromNow()}
                  </p>
                </div>
                {newItem.arrHistoryShow.map((item, index) => {
                  return (
                    <div key={index} className="description">
                      <strong>{item.name}</strong>: <span>{item.old}</span>
                      <i className="fa fa-long-arrow-alt-right"></i>
                      <span>{item.new}</span>
                    </div>
                  );
                })}
              </div>
            );
          }
        });
      } else {
        return (
          <div className="history_error">
            <div className="icon">
              <FileOutlined />
            </div>
            <p className="title">No data</p>
          </div>
        );
      }
    }
  };
  return <div className="history">{showHistory()}</div>;
}
