import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Table,
  Space,
  Modal,
  Popconfirm,
  Tabs,
  Popover,
  FloatButton,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/scss/Pages/User/_user.scss";
import {
  SearchOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { storage } from "../../Utils/storage";
import ReactDragListView from "react-drag-listview";
import { showHideDeletedItem } from "../../Utils/showHideDeletedItem";
import {
  CURRENT_PAGE,
  DEFAULT_PAGESIZE,
} from "../../Utils/ConstCommon/constCommon";
import { renderShowColumn } from "../../Utils/renderShowColumn";
import ContentShowColumn from "../../Utils/ComponentCommon/ContentShowColumn";
import ContentShowHideDeleted from "../../Utils/ComponentCommon/ContentShowHideDeleted";
import {
  deleteOneEventApi,
  findHistoryEventApi,
  findOneEventApi,
  getAllEventApi,
} from "../../Redux/eventReducer/eventReducer";
import CreateEvent from "../../Components/EventPage/CreateEvent";
import EventDetail from "../../Components/EventPage/EventDetail";
import EventHistory from "../../Components/EventPage/EventHistory";
import { convertLongToShortESN } from "../../Utils/convertESN";

export default function Event() {
  const dispatch = useDispatch();
  const { arrayEvent, pagination, eventEdit } = useSelector(
    (state) => state.eventReducer
  );
  const [arrSelect, setArrSelect] = useState([]);
  const [valueEnter, setValueEnter] = useState(false);
  const [warning, setWarning] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [showDeleted, setShowDeleted] = useState();
  const [data, setData] = useState([]);
  const currentPage = useRef(CURRENT_PAGE);
  const pageSizeRef = useRef(DEFAULT_PAGESIZE);
  const [currentPagination, setCurrentPagination] = useState(CURRENT_PAGE);

  const columns = [
    {
      title: "Account",
      dataIndex: "accountName",
      key: "accountName",
      width: "150px",
      sorter: (beforeItem, afterItem) =>
        beforeItem.accountName.toLowerCase() >
        afterItem.accountName.toLowerCase()
          ? 1
          : beforeItem.accountName.toLowerCase() ===
            afterItem.accountName.toLowerCase()
          ? 0
          : -1,
      render: (text, record) => (
        <div
          className="open_detail"
          onClick={async () => {
            await dispatch(findOneEventApi(record.id));
            showModalDetail();
          }}
        >
          {text}
        </div>
      ),
      onFilter: (value, record) => {
        return (
          record.devices.some((device) =>
            device.device.serialNumber
              .toLowerCase()
              .includes(value.toLowerCase())
          ) ||
          record.typeName.toLowerCase().includes(value.toLowerCase()) ||
          record.supporterName.toLowerCase().includes(value.toLowerCase()) ||
          record.supportTime.toLowerCase().includes(value.toLowerCase()) ||
          String(record.costShow)?.includes(value.toLowerCase()) ||
          record.accountName.toLowerCase().includes(value.toLowerCase()) ||
          record.addressShow?.toLowerCase().includes(value.toLowerCase()) ||
          record.siteNameShow?.toLowerCase().includes(value.toLowerCase()) ||
          record.notesShow?.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Type",
      dataIndex: "typeName",
      key: "typeName",
      align: "center",
      render: (text) => <strong>{text}</strong>,
      sorter: (beforeItem, afterItem) =>
        beforeItem.typeName.toLowerCase() > afterItem.typeName.toLowerCase()
          ? 1
          : beforeItem.typeName.toLowerCase() ===
            afterItem.typeName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Device list",
      dataIndex: "devices",
      key: "name",
      width: "150px",
      align: "center",
      render: (text) => (
        <div>
          {text.map((item, key) => {
            return (
              <p key={key}>{convertLongToShortESN(item.device.serialNumber)}</p>
            );
          })}
        </div>
      ),
    },
    {
      title: "Supporter",
      dataIndex: "supporterName",
      key: "supporterName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.supporterName.toLowerCase() >
        afterItem.supporterName.toLowerCase()
          ? 1
          : beforeItem.supporterName.toLowerCase() ===
            afterItem.supporterName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Support date",
      dataIndex: "supportTime",
      key: "supportTime",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.supportedAt.toLowerCase() >
        afterItem.supportedAt.toLowerCase()
          ? 1
          : beforeItem.supportedAt.toLowerCase() ===
            afterItem.supportedAt.toLowerCase()
          ? 0
          : -1,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Charge",
      dataIndex: "isCharged",
      key: "isCharged",
      align: "center",
      render: (text) => {
        if (text === 1) {
          return (
            <Tag color="#87d068" style={{ margin: "0" }}>
              Yes
            </Tag>
          );
        } else {
          return (
            <Tag color="#ff4550" style={{ margin: "0" }}>
              No
            </Tag>
          );
        }
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.isCharged > afterItem.isCharged
          ? 1
          : beforeItem.isCharged === afterItem.isCharged
          ? 0
          : -1,
    },
    {
      title: "Cost",
      dataIndex: "costShow",
      key: "costShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.costShow > afterItem.costShow
          ? 1
          : beforeItem.costShow === afterItem.costShow
          ? 0
          : -1,
    },
    {
      title: "Site name",
      dataIndex: "siteNameShow",
      key: "siteNameShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.siteNameShow?.toLowerCase() >
        afterItem.siteNameShow?.toLowerCase()
          ? 1
          : beforeItem.siteNameShow?.toLowerCase() ===
            afterItem.siteNameShow?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Address",
      dataIndex: "addressShow",
      key: "addressShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.addressShow?.toLowerCase() >
        afterItem.addressShow?.toLowerCase()
          ? 1
          : beforeItem.addressShow?.toLowerCase() ===
            afterItem.addressShow?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Notes",
      dataIndex: "notesShow",
      key: "notesShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.notesShow?.toLowerCase() > afterItem.notesShow?.toLowerCase()
          ? 1
          : beforeItem.notesShow?.toLowerCase() ===
            afterItem.notesShow?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "80px",
      render: (_, record) => (
        <Space size="middle" className="user_action">
          <Popconfirm
            title="Delete Event"
            placement="leftBottom"
            description="Are you sure to delete this event?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              const action = deleteOneEventApi(record.id);
              dispatch(action);
            }}
          >
            <button>
              <DeleteOutlined className="user_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  let [columnSelected, setColumnSelected] = useState(columns);

  for (let index = 0; index < columnSelected.length; index++) {
    let item = columnSelected[index];
    item.filteredValue = [searchValue];
  }
  // Show/hide column
  useEffect(() => {
    renderShowColumn(
      columns,
      "arrayEventShowColumn",
      "arrayEventRelocateTitleColumn",
      "arrayEventRelocateTitleColumnNew",
      setColumnSelected
    );
    document.title = "Tag Register - Events";
  }, []);

  const resetAll = () => {
    storage.removeStorage("arrayEventShowColumn");
    storage.removeStorage("arrayEventRelocateTitleColumn");
    storage.removeStorage("arrayEventRelocateTitleColumnNew");
    setColumnSelected(columns);
  };

  // Drag and drop column
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newFromIndex = fromIndex - 1;
      const newToIndex = toIndex - 1;
      const newColumns = [...columnSelected];
      const item = newColumns.splice(newFromIndex, 1)[0];
      newColumns.splice(newToIndex, 0, item);
      setColumnSelected(newColumns);
      let arrRelocate = newColumns.map((item) => {
        return item.key;
      });
      storage.setStorageJson("arrayEventRelocateTitleColumn", arrRelocate);
    },
    nodeSelector: "th",
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setArrSelect(selectedRows);
      return (
        `selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows
      );
    },
  };

  // Show modal warning
  const showModalWarning = () => {
    setWarning(true);
  };
  const cancelModalWarning = () => {
    setWarning(false);
  };

  // Handle modal
  const showModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };
  const showModalDetail = () => {
    setOpenDetail(true);
  };

  const handleCancel = () => {
    if (valueEnter === true) {
      showModalWarning();
    } else {
      setOpen(false);
    }
  };
  const handleCancelDetail = () => {
    setOpenDetail(false);
  };

  // Call api
  useEffect(() => {
    dispatch(getAllEventApi());
  }, [dispatch]);

  // Recall api
  useEffect(() => {
    const reCallApi = setInterval(() => {
      dispatch(
        getAllEventApi({
          limit: pageSizeRef.current,
          page: currentPage.current,
        })
      );
    }, 10000);
    return () => clearInterval(reCallApi);
  }, []);

  const tabCallback = (key) => {
    if (key === "2") {
      const action = findHistoryEventApi(eventEdit.id);
      dispatch(action);
    }
  };
  const items = [
    {
      key: "1",
      label: `Event details`,
      children: <EventDetail handleCancelDetail={handleCancelDetail} />,
    },
    {
      key: "2",
      label: `History`,
      children: <EventHistory />,
    },
  ];

  // Content for popover
  const content = (
    <ContentShowColumn
      columns={columns}
      storageShow="arrayEventShowColumn"
      storageRelocate="arrayEventRelocateTitleColumn"
      storageRelocateNew="arrayEventRelocateTitleColumnNew"
      action={setColumnSelected}
      resetAll={resetAll}
    />
  );

  // Show/hide deleted item
  const onChangeShowDeleted = (checked) => {
    showHideDeletedItem(checked, arrayEvent, setData, "showDeletedEvent");
    setShowDeleted(checked);
  };
  useEffect(() => {
    const isShow = storage.getStore("showDeletedEvent");
    if (isShow === undefined || isShow === "true") {
      setData(arrayEvent);
      setShowDeleted(true);
    } else {
      const arrayData = arrayEvent.filter((item) => item.isDeleted !== 1);
      setData(arrayData);
      setShowDeleted(false);
    }
  }, [arrayEvent]);

  const contentAction = (
    <ContentShowHideDeleted
      onChangeShowDeleted={onChangeShowDeleted}
      showDeleted={showDeleted}
      arrayData={arrayEvent}
      columnSelected={columnSelected}
      pageName="Event"
      content={content}
    />
  );
  return (
    <div className="user">
      <div className="user_top">
        <div className="user_left">
          <p>Events</p>
        </div>
      </div>
      <div className="user_body">
        <div className="user_show">
          <div className="user_showChild">
            <div className="user_showLeft">
              <p>
                Selected: <strong>{arrSelect.length}</strong>
              </p>
              <button
                onClick={() => {
                  showModal();
                }}
                className="user_button-create"
              >
                + New
              </button>
              <Popover
                content={contentAction}
                trigger="click"
                placement="right"
              >
                <button className="button-second">
                  Action <i className="fa fa-caret-down"></i>
                </button>
              </Popover>
            </div>
            <div className="user_showRight">
              <div className="search_area">
                <SearchOutlined className="search_icon" />
                <Input
                  className="search_box"
                  placeholder="Search..."
                  allowClear
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="user_table">
          <ReactDragListView.DragColumn {...dragProps}>
            <Table
              rowSelection={rowSelection}
              columns={[...columnSelected]}
              dataSource={data}
              rowClassName={(record) =>
                record.isDeleted === 1 ? "deleted" : ""
              }
              pagination={{
                defaultPageSize: 10,
                current: currentPagination,
                showSizeChanger: true,
                total: pagination?.totalItems,
                showTotal: (total, range) => (
                  <p>
                    Showing{" "}
                    <strong>
                      {range[0]}-{range[1]}
                    </strong>{" "}
                    of <strong>{total}</strong> items
                  </p>
                ),
                onChange: (newCurrent, newPageSize) => {
                  setCurrentPagination(newCurrent);
                  pageSizeRef.current = newPageSize;
                  currentPage.current = newCurrent;
                  dispatch(
                    getAllEventApi({ limit: newPageSize, page: newCurrent })
                  );
                  window.scrollTo({ top: 0, behavior: "smooth" });
                },
              }}
              bordered={true}
              scroll={{
                x: 1200,
              }}
              size="middle"
              sticky
            />
          </ReactDragListView.DragColumn>
        </div>
      </div>
      <Modal
        title="Create Event"
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <CreateEvent
          handleCancel={handleCancel}
          closeModal={closeModal}
          setValueEnter={setValueEnter}
        />
      </Modal>
      <Modal
        title={"Event ID: " + eventEdit.id}
        open={openDetail}
        onCancel={handleCancelDetail}
        footer={null}
        width={800}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          size="small"
          className="user_tab"
          onChange={tabCallback}
          destroyInactiveTabPane
        />
      </Modal>
      <Modal
        open={warning}
        onCancel={cancelModalWarning}
        footer={null}
        width={250}
        destroyOnClose
      >
        <div className="movement_warning">
          <h4 className="warning_title">
            {" "}
            <i className="fa fa-exclamation-circle"></i> Warning
          </h4>
          <p className="warning_text">Are you sure to leave?</p>
          <div className="warning_action">
            <button
              type="button"
              className="warning_no"
              onClick={cancelModalWarning}
            >
              No
            </button>
            <button
              type="button"
              className="warning_yes"
              onClick={() => {
                setOpen(false);
                cancelModalWarning();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
      <FloatButton.BackTop />
    </div>
  );
}
