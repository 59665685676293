import React, { useEffect, useState } from "react";
import "../../Assets/scss/Component/Platform/_platformDetail.scss";
import { Input, Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePlatformApi,
  restorePlatformApi,
  updatePlatformApi,
} from "../../Redux/platformReducer/platformReducer";
import { useFormik } from "formik";
import * as yup from "yup";
import PlatformStatusManagement from "./PlatformStatusManagement";
import Select from "react-select";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { compareObject } from "../../Utils/compareObject";
import { renderUpdater } from "../../Utils/renderUpdater";

export default function PlatformDetail(props) {
  const { platformEdit } = useSelector((state) => state.platformReducer);
  const { arrPlatformStatus } = useSelector(
    (state) => state.platformStatusReducer
  );
  const [openSetting, setOpenSetting] = useState(false);
  const [arrSelectBox, setArrSelectBox] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showRestore, setShowRestore] = useState(false);
  const dispatch = useDispatch();

  // Handle modal
  const showModalSetting = () => {
    setOpenSetting(true);
  };
  const handleOkSetting = () => {
    message.loading("loading", 1);
  };

  const handleCancelSetting = () => {
    setOpenSetting(false);
  };
  const showModalDelete = () => {
    setShowDelete(true);
  };
  const handleOkModalDelete = () => {
    message.loading("loading", 1);
    const action = deletePlatformApi(platformEdit.id);
    dispatch(action);
    setShowDelete(false);
    props.handleCancelDetail();
  };
  const handleCancelModalDelete = () => {
    setShowDelete(false);
  };
  const showModalRestore = () => {
    setShowRestore(true);
  };
  const handleOkModalRestore = () => {
    message.loading("loading", 1);
    const action = restorePlatformApi(platformEdit.id);
    dispatch(action);
    setShowRestore(false);
  };
  const handleCancelModalRestore = () => {
    setShowRestore(false);
  };

  // Handle form
  const platformDetailInformation = useFormik({
    initialValues: {
      name: platformEdit.name,
      shortName: platformEdit.shortName,
      url: platformEdit.url,
      description: platformEdit.description,
      comment: platformEdit.comment,
      statusId: platformEdit.statusId,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name cannot be empty"),
      shortName: yup.string().required("Short name cannot be empty"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      let newValues = { ...values };
      if (compareObject(newValues, platformEdit)) {
        message.warning("Nothing has changed with this platform");
      } else {
        const newObject = {};
        for (let item in newValues) {
          if (newValues[item] !== platformEdit[item]) {
            newObject[item] = newValues[item];
          }
        }
        const dataSend = {
          id: platformEdit.id,
          data: newObject,
        };
        const action = updatePlatformApi(dataSend);
        dispatch(action);
        handleOkSetting();
      }
    },
  });

  useEffect(() => {
    let arrSelect = [];
    arrPlatformStatus.map((item) => {
      let obj = { value: item.id, label: item.name };
      arrSelect.push(obj);
    });
    arrSelect.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setArrSelectBox(arrSelect);
  }, [arrPlatformStatus]);

  return (
    <form
      onSubmit={platformDetailInformation.handleSubmit}
      style={{ cursor: platformEdit.isDeleted === 1 ? "no-drop" : "" }}
    >
      <div className="platform_detail">
        <ul>
          <li>
            <div className="platform_row">
              <div className="row_1">
                <div className="col_1">
                  <p>Name:</p>
                </div>
                <div className="col_2">
                  <Input
                    name="name"
                    onChange={platformDetailInformation.handleChange}
                    onBlur={platformDetailInformation.handleBlur}
                    value={platformDetailInformation.values.name}
                    size="large"
                    className={
                      platformDetailInformation.errors.name ? "error_input" : ""
                    }
                    disabled={platformEdit.isDeleted === 1 ? true : false}
                  />
                  {platformDetailInformation.errors.name ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span className="cm_errors">
                        {platformDetailInformation.errors.name}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="platform_row">
              <div className="row_1">
                <div className="col_1">
                  <p>Short name:</p>
                </div>
                <div className="col_2">
                  <Input
                    name="shortName"
                    onChange={platformDetailInformation.handleChange}
                    onBlur={platformDetailInformation.handleBlur}
                    value={platformDetailInformation.values.shortName}
                    size="large"
                    className={
                      platformDetailInformation.errors.shortName
                        ? "error_input"
                        : ""
                    }
                    disabled={platformEdit.isDeleted === 1 ? true : false}
                  />
                  {platformDetailInformation.errors.shortName ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span className="cm_errors">
                        {platformDetailInformation.errors.shortName}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="platform_row">
              <div className="row_1">
                <div className="col_1">
                  <p>URL:</p>
                </div>
                <div className="col_2">
                  <Input
                    name="url"
                    onChange={platformDetailInformation.handleChange}
                    onBlur={platformDetailInformation.handleBlur}
                    value={platformDetailInformation.values.url}
                    size="large"
                    disabled={platformEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="platform_status">
              <div className="row_1">
                <div className="col_1">
                  <p>Status:</p>
                </div>
                <div className="col_2">
                  <Select
                    options={arrSelectBox}
                    name="statusId"
                    defaultValue={
                      platformDetailInformation.values.statusId !== null
                        ? {
                            value: platformDetailInformation.values.statusId,
                            label:
                              platformEdit.status !== null
                                ? platformEdit.status.name
                                : "",
                          }
                        : ""
                    }
                    className="select"
                    onChange={(e) => {
                      if (e !== null) {
                        platformDetailInformation.setFieldValue(
                          "statusId",
                          e.value
                        );
                      } else {
                        platformDetailInformation.setFieldValue("statusId", e);
                      }
                    }}
                    isDisabled={platformEdit.isDeleted === 1 ? true : false}
                  />
                  <button
                    type="button"
                    onClick={showModalSetting}
                    disabled={platformEdit.isDeleted === 1 ? true : false}
                    style={{
                      cursor:
                        platformEdit.isDeleted === 1 ? "no-drop" : "pointer",
                    }}
                  >
                    Status setting
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="platform_row">
              <div className="row_1">
                <div className="col_1">
                  <p>Description:</p>
                </div>
                <div className="col_2">
                  <Input.TextArea
                    name="description"
                    onChange={platformDetailInformation.handleChange}
                    onBlur={platformDetailInformation.handleBlur}
                    value={platformDetailInformation.values.description}
                    disabled={platformEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="platform_row">
              <div className="row_1">
                <div className="col_1">
                  <p>Comment:</p>
                </div>
                <div className="col_2">
                  <Input.TextArea
                    name="comment"
                    onChange={platformDetailInformation.handleChange}
                    onBlur={platformDetailInformation.handleBlur}
                    value={platformDetailInformation.values.comment}
                    disabled={platformEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div className="platform_action">
          <button
            type="submit"
            disabled={platformEdit.isDeleted === 1 ? true : false}
            style={{
              cursor: platformEdit.isDeleted === 1 ? "no-drop" : "pointer",
            }}
          >
            Save Change
          </button>
        </div>
      </div>
      {platformEdit.isDeleted === 0 ? (
        <div className="user_addition">
          <div className="add_detail">
            <div className="user_delete">
              <h3>
                <span>
                  <i className="fa fa-exclamation-circle"></i>
                </span>{" "}
                Delete platform
              </h3>
              <div className="user_password_row">
                <p>This action can't be undone!</p>
                <button type="button" onClick={showModalDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div className="user_footer">{renderUpdater(platformEdit,"platform")}</div>
        </div>
      ) : (
        <div className="user_addition">
          <div className="add_detail">
            <div className="user_restore">
              <h3>
                <span>
                  <i className="fa fa-history"></i>
                </span>{" "}
                Restore platform
              </h3>
              <div className="user_password_row">
                <p>
                  This platform has been deleted. If you want to restore it,
                  please click on the Restore button.
                </p>
                <button type="button" onClick={showModalRestore}>
                  Restore
                </button>
              </div>
            </div>
          </div>
          <div className="user_footer">{renderUpdater(platformEdit,"platform")}</div>
        </div>
      )}
      <Modal
        title="Platform status management"
        open={openSetting}
        onCancel={handleCancelSetting}
        footer={null}
        width={600}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <PlatformStatusManagement handleCancelSetting={handleCancelSetting} />
      </Modal>
 
      <Modal
        open={showDelete}
        onCancel={handleCancelModalDelete}
        footer={null}
        width={450}
      >
        <div className="delete_user">
          <p>
            <i className="fa fa-question"></i> Delete platform
          </p>
          <span>Are you sure to delete this platform?</span>
          <div className="delete_action">
            <button className="cancel" onClick={handleCancelModalDelete}>
              Cancel
            </button>
            <button className="delete" onClick={handleOkModalDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showRestore}
        onCancel={handleCancelModalRestore}
        footer={null}
        width={450}
      >
        <div className="restore_user">
          <p>
            <i className="fa fa-question"></i> Restore platform
          </p>
          <span>Are you sure to restore this platform?</span>
          <div className="restore_action">
            <button className="cancel" onClick={handleCancelModalRestore}>
              Cancel
            </button>
            <button className="restore" onClick={handleOkModalRestore}>
              Restore
            </button>
          </div>
        </div>
      </Modal>
    </form>
  );
}
