import React from "react";
import { useSelector } from "react-redux";
import "../../Assets/scss/Component/Device/_priceAndCost.scss";

export default function PriceAndCost() {
  const { deviceFind } = useSelector((state) => state.deviceReducer);

  return (
    <div className="price_cost">
      <div className="price_rows">
        <p className="price_title">Purchase prices and costs</p>
        <div className="price_field">
          <div className="price_field-name">
            <p>Unit cost:</p>
          </div>
          <div className="price_field-data">
            <p>
              {deviceFind.purchaseProduct && deviceFind.purchaseProduct !== null
                ? deviceFind.purchaseProduct.unitCost
                : "0"}
            </p>
          </div>
        </div>
        <div className="price_field">
          <div className="price_field-name">
            <p>Activation cost:</p>
          </div>
          <div className="price_field-data">
            <p>
              {deviceFind.purchaseProduct && deviceFind.purchaseProduct !== null
                ? deviceFind.purchaseProduct.activationCost
                : "0"}
            </p>
          </div>
        </div>
        <div className="price_field">
          <div className="price_field-name">
            <p>Batteries cost:</p>
          </div>
          <div className="price_field-data">
            <p>
              {deviceFind.purchaseProduct && deviceFind.purchaseProduct !== null
                ? deviceFind.purchaseProduct.batteriesCost
                : "0"}
            </p>
          </div>
        </div>
        <div className="price_field">
          <div className="price_field-name">
            <p>Other cost:</p>
          </div>
          <div className="price_field-data">
            <p>
              {deviceFind.purchaseProduct && deviceFind.purchaseProduct !== null
                ? deviceFind.purchaseProduct.otherCost
                : "0"}
            </p>
          </div>
        </div>
        <div className="price_field">
          <div className="price_field-name">
            <p>Total:</p>
          </div>
          <div className="price_field-data">
            <p>
              {deviceFind.purchaseProduct && deviceFind.purchaseProduct !== null
                ? (
                    deviceFind.purchaseProduct.unitCost +
                    deviceFind.purchaseProduct.activationCost +
                    deviceFind.purchaseProduct.batteriesCost +
                    deviceFind.purchaseProduct.otherCost
                  ).toFixed(2)
                : "0"}
            </p>
          </div>
        </div>
      </div>

      <div className="price_rows price_bottom">
        <p className="price_title">Sales prices and costs</p>

        <div className="price_field">
          <div className="price_field-name">
            <p>Base subs price:</p>
          </div>
          <div className="price_field-data">
            <p>
              {deviceFind.currentSaleProduct &&
              deviceFind.currentSaleProduct !== null
                ? deviceFind.currentSaleProduct.baseSubsPrice
                : "0"}
            </p>
          </div>
        </div>

        <div className="price_field">
          <div className="price_field-name">
            <p>Hw price:</p>
          </div>
          <div className="price_field-data">
            <p>
              {deviceFind.currentSaleProduct &&
              deviceFind.currentSaleProduct !== null
                ? deviceFind.currentSaleProduct.hwPrice
                : "0"}
            </p>
          </div>
        </div>

        <div className="price_field">
          <div className="price_field-name">
            <p>Haas price:</p>
          </div>
          <div className="price_field-data">
            <p>
              {deviceFind.currentSaleProduct &&
              deviceFind.currentSaleProduct !== null
                ? deviceFind.currentSaleProduct.haasPrice
                : "0"}
            </p>
          </div>
        </div>

        <div className="price_field">
          <div className="price_field-name">
            <p>Other cost:</p>
          </div>
          <div className="price_field-data">
            <p>
              {deviceFind.currentSaleProduct &&
              deviceFind.currentSaleProduct !== null
                ? deviceFind.currentSaleProduct.otherCost
                : "0"}
            </p>
          </div>
        </div>

        <div className="price_field">
          <div className="price_field-name">
            <p>Total:</p>
          </div>
          <div className="price_field-data">
            <p>
              {deviceFind.currentSaleProduct &&
              deviceFind.currentSaleProduct !== null
                ? (
                    deviceFind.currentSaleProduct.baseSubsPrice +
                    deviceFind.currentSaleProduct.hwPrice +
                    deviceFind.currentSaleProduct.haasPrice +
                    deviceFind.currentSaleProduct.otherCost
                  ).toFixed(2)
                : "0"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
