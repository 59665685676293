import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Table,
  Space,
  Modal,
  message,
  Popconfirm,
  Tag,
  Tabs,
  Popover,
  FloatButton,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/scss/Pages/User/_user.scss";
import {
  SearchOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import CreateUser from "../../Components/User/CreateUser";
import {
  delUserApi,
  getAllUserApi,
  getChangeLog,
  getAllRole,
  findOneUser,
} from "../../Redux/userReducer/userReducer";
import UserDetail from "../../Components/User/UserDetail";

import { getAllCompany } from "../../Redux/companyReducer/companyReducer";
import { storage } from "../../Utils/storage";
import ReactDragListView from "react-drag-listview";
import UserHistory from "../../Components/User/UserHistory";
import MovementReport from "../../Components/User/MovementReport";
import { showHideDeletedItem } from "../../Utils/showHideDeletedItem";
import {
  CURRENT_PAGE,
  DEFAULT_PAGESIZE,
} from "../../Utils/ConstCommon/constCommon";
import { renderShowColumn } from "../../Utils/renderShowColumn";
import ContentShowColumn from "../../Utils/ComponentCommon/ContentShowColumn";
import ContentShowHideDeleted from "../../Utils/ComponentCommon/ContentShowHideDeleted";

export default function User() {
  const dispatch = useDispatch();
  const { arrUser, userEdit } = useSelector((state) => state.userReducer);
  const [arrSelect, setArrSelect] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [showDeleted, setShowDeleted] = useState();
  const [data, setData] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(CURRENT_PAGE);
  const pageSizeRef = useRef(DEFAULT_PAGESIZE);

  // Use for table
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "300px",
      onFilter: (value, record) => {
        return (
          record.email.toLowerCase().includes(value.toLowerCase()) ||
          record.firstName.toLowerCase().includes(value.toLowerCase()) ||
          record.lastName.toLowerCase().includes(value.toLowerCase()) ||
          record.companyName?.toLowerCase().includes(value.toLowerCase()) ||
          record.displayName.toLowerCase().includes(value.toLowerCase()) ||
          record.phone?.toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (text, record) => (
        <span
          className="open_detail"
          onClick={async () => {
            await dispatch(findOneUser(record.id));
            showModalDetail();
          }}
        >
          {text}
        </span>
      ),
      sorter: (beforeItem, afterItem) =>
        beforeItem.email.toLowerCase() > afterItem.email.toLowerCase()
          ? 1
          : beforeItem.email.toLowerCase() === afterItem.email.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.email.toLowerCase().includes(value.toLowerCase()) ||
          record.firstName.toLowerCase().includes(value.toLowerCase()) ||
          record.lastName.toLowerCase().includes(value.toLowerCase()) ||
          record.companyName?.toLowerCase().includes(value.toLowerCase()) ||
          record.displayName.toLowerCase().includes(value.toLowerCase()) ||
          record.phone?.toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.firstName.toLowerCase() > afterItem.firstName.toLowerCase()
          ? 1
          : beforeItem.firstName.toLowerCase() ===
            afterItem.firstName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.email.toLowerCase().includes(value.toLowerCase()) ||
          record.firstName.toLowerCase().includes(value.toLowerCase()) ||
          record.lastName.toLowerCase().includes(value.toLowerCase()) ||
          record.companyName?.toLowerCase().includes(value.toLowerCase()) ||
          record.displayName.toLowerCase().includes(value.toLowerCase()) ||
          record.phone?.toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.lastName.toLowerCase() > afterItem.lastName.toLowerCase()
          ? 1
          : beforeItem.lastName.toLowerCase() ===
            afterItem.lastName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Account",
      dataIndex: "companyName",
      key: "companyName",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.email.toLowerCase().includes(value.toLowerCase()) ||
          record.firstName.toLowerCase().includes(value.toLowerCase()) ||
          record.lastName.toLowerCase().includes(value.toLowerCase()) ||
          record.companyName?.toLowerCase().includes(value.toLowerCase()) ||
          record.displayName.toLowerCase().includes(value.toLowerCase()) ||
          record.phone?.toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.companyName?.toLowerCase() >
        afterItem.companyName?.toLowerCase()
          ? 1
          : beforeItem.companyName?.toLowerCase() ===
            afterItem.companyName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Role",
      dataIndex: "displayName",
      key: "displayName",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.email.toLowerCase().includes(value.toLowerCase()) ||
          record.firstName.toLowerCase().includes(value.toLowerCase()) ||
          record.lastName.toLowerCase().includes(value.toLowerCase()) ||
          record.companyName?.toLowerCase().includes(value.toLowerCase()) ||
          record.displayName.toLowerCase().includes(value.toLowerCase()) ||
          record.phone?.toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.displayName.toLowerCase() >
        afterItem.displayName.toLowerCase()
          ? 1
          : beforeItem.displayName.toLowerCase() ===
            afterItem.displayName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.email.toLowerCase().includes(value.toLowerCase()) ||
          record.firstName.toLowerCase().includes(value.toLowerCase()) ||
          record.lastName.toLowerCase().includes(value.toLowerCase()) ||
          record.companyName?.toLowerCase().includes(value.toLowerCase()) ||
          record.displayName.toLowerCase().includes(value.toLowerCase()) ||
          record.phone?.toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) => beforeItem.phone - afterItem.phone,
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.email.toLowerCase().includes(value.toLowerCase()) ||
          record.firstName.toLowerCase().includes(value.toLowerCase()) ||
          record.lastName.toLowerCase().includes(value.toLowerCase()) ||
          record.companyName?.toLowerCase().includes(value.toLowerCase()) ||
          record.displayName.toLowerCase().includes(value.toLowerCase()) ||
          record.phone?.toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (record) => {
        if (record.isActived === 1) {
          return (
            <Tag color="#87d068" style={{ margin: "0" }}>
              ENABLED
            </Tag>
          );
        } else if (record.isActived === 0) {
          return (
            <Tag color="#ff4550" style={{ margin: "0" }}>
              DISABLED
            </Tag>
          );
        }
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.isActived - afterItem.isActived,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "80px",
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" className="user_action">
          <Popconfirm
            title="Delete User"
            placement="leftBottom"
            description="Are you sure to delete this user?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              const action = delUserApi(record.id);
              dispatch(action);
            }}
          >
            <button>
              <DeleteOutlined className="user_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [columnSelected, setColumnSelected] = useState(columns);
  for (let index = 0; index < columnSelected.length; index++) {
    let item = columnSelected[index];
    item.filteredValue = [searchValue];
  }

  // Drag and drop
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newFromIndex = fromIndex - 1;
      const newToIndex = toIndex - 1;
      const newColumns = [...columnSelected];
      const item = newColumns.splice(newFromIndex, 1)[0];
      newColumns.splice(newToIndex, 0, item);
      setColumnSelected(newColumns);
      let arrRelocate = newColumns.map((item) => {
        return item.key;
      });
      storage.setStorageJson("arrayUserRelocateTitleColumn", arrRelocate);
    },
    nodeSelector: "th",
  };

  // Show/hide column
  useEffect(() => {
    renderShowColumn(
      columns,
      "arrayUserShowColumn",
      "arrayUserRelocateTitleColumn",
      "arrayUserRelocateTitleColumnNew",
      setColumnSelected
    );
    document.title = "Tag Register - Users";
  }, []);

  const resetAll = () => {
    storage.removeStorage("arrayUserShowColumn");
    storage.removeStorage("arrayUserRelocateTitleColumn");
    storage.removeStorage("arrayUserRelocateTitleColumnNew");
    setColumnSelected(columns);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setArrSelect(selectedRows);
      return (
        `selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows
      );
    },
  };

  // Handle modal
  const showModal = () => {
    setOpen(true);
  };
  const showModalDetail = () => {
    setOpenDetail(true);
  };
  const handleOk = () => {
    message.loading("loading", 1);
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleCancelDetail = () => {
    setOpenDetail(false);
  };

  // Call api
  useEffect(() => {
    dispatch(getAllUserApi());
    dispatch(getAllCompany());
    dispatch(getAllRole());
  }, [dispatch]);

  // Recall api
  useEffect(() => {
    const reCallApi = setInterval(() => {
      dispatch(getAllUserApi());
    }, 10000);
    return () => clearInterval(reCallApi);
  }, []);

  // User for detail
  const tabCallback = (key) => {
    if (key === "2") {
      const action = getChangeLog(userEdit.id);
      dispatch(action);
    }
  };
  const items = [
    {
      key: "1",
      label: `User details`,
      children: <UserDetail handleCancelDetail={handleCancelDetail} />,
    },
    {
      key: "2",
      label: `History`,
      children: <UserHistory />,
    },
    {
      key: "3",
      label: `Movement report`,
      children: <MovementReport />,
    },
  ];

  // Content for popover
  const content = (
    <ContentShowColumn
      columns={columns}
      storageShow="arrayUserShowColumn"
      storageRelocate="arrayUserRelocateTitleColumn"
      storageRelocateNew="arrayUserRelocateTitleColumnNew"
      action={setColumnSelected}
      resetAll={resetAll}
    />
  );

  // Show/hide deleted item
  const onChangeShowDeleted = (checked) => {
    showHideDeletedItem(checked, arrUser, setData, "showDeletedUser");
    setShowDeleted(checked);
  };

  useEffect(() => {
    const isShow = storage.getStore("showDeletedUser");
    if (isShow === undefined || isShow === "true") {
      setData(arrUser);
      setShowDeleted(true);
    } else {
      const arrayData = arrUser.filter((item) => item.isDeleted !== 1);
      setData(arrayData);
      setShowDeleted(false);
    }
  }, [arrUser]);

  const contentAction = (
    <ContentShowHideDeleted
      onChangeShowDeleted={onChangeShowDeleted}
      showDeleted={showDeleted}
      arrayData={arrUser}
      columnSelected={columnSelected}
      pageName="User"
      content={content}
    />
  );
  return (
    <div className="user">
      <div className="user_top">
        <div className="user_left">
          <p>Users</p>
        </div>
      </div>
      <div className="user_body">
        <div className="user_show">
          <div className="user_showChild">
            <div className="user_showLeft">
              <p>
                Selected: <strong>{arrSelect.length}</strong>
              </p>
              <button
                onClick={() => {
                  showModal();
                }}
                className="user_button-create"
              >
                + New
              </button>
              <Popover
                content={contentAction}
                trigger="click"
                placement="right"
              >
                <button className="button-second">
                  Action <i className="fa fa-caret-down"></i>
                </button>
              </Popover>
            </div>
            <div className="user_showRight">
              <div className="search_area">
                <SearchOutlined className="search_icon" />
                <Input
                  className="search_box"
                  placeholder="Search..."
                  allowClear
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="user_table">
          <ReactDragListView.DragColumn {...dragProps}>
            <Table
              rowSelection={rowSelection}
              columns={[...columnSelected]}
              dataSource={data}
              rowClassName={(record) =>
                record.isDeleted === 1 ? "deleted" : ""
              }
              pagination={{
                defaultPageSize: 10,
                current: currentPagination,
                showSizeChanger: true,
                showTotal: (total, range) => (
                  <p>
                    Showing{" "}
                    <strong>
                      {range[0]}-{range[1]}
                    </strong>{" "}
                    of <strong>{total}</strong> items
                  </p>
                ),
                onChange: (newCurrent, newPageSize) => {
                  const pageSizeChange = pageSizeRef.current !== newPageSize;
                  if (pageSizeChange) {
                    setCurrentPagination(1);
                  } else {
                    setCurrentPagination(newCurrent);
                  }
                  pageSizeRef.current = newPageSize;
                },
              }}
              bordered={true}
              scroll={{
                x: 1300,
              }}
              size="middle"
              sticky
            />
          </ReactDragListView.DragColumn>
        </div>
      </div>

      <Modal
        title="Create User"
        open={open}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        <CreateUser handleCancel={handleCancel} handleOk={handleOk} />
      </Modal>
      <Modal
        title={"User ID: " + userEdit.id}
        open={openDetail}
        onCancel={handleCancelDetail}
        footer={null}
        width={600}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          size="small"
          className="user_tab"
          onChange={tabCallback}
          destroyInactiveTabPane
        />
      </Modal>
      <FloatButton.BackTop />
    </div>
  );
}
