import Input from "antd/es/input/Input";
import React from "react";
import "../../Assets/scss/Component/Currency/_createCurrency.scss";
import { useDispatch } from "react-redux";
import { addCurrencyApi } from "../../Redux/currencyReducer/currencyReducer";
import { useFormik } from "formik";
import * as yup from "yup";

export default function CreateCurrency({ handleOk, handleCancel }) {
  const dispatch = useDispatch();
  const currencyInformation = useFormik({
    initialValues: {
      name: "",
      rate: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("This field is required"),
      rate: yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      if (values.description === "") {
        values.description = null;
      }
      const action = addCurrencyApi(values);
      dispatch(action);
      handleOk();
    },
  });
  return (
    <div>
      <form onSubmit={currencyInformation.handleSubmit}>
        <div className="create_currency">
          <div className="currency_item">
            <p>Name*</p>
            <Input
              name="name"
              onChange={currencyInformation.handleChange}
              onBlur={currencyInformation.handleBlur}
              value={currencyInformation.values.name}
              size="large"
              className={currencyInformation.errors.name ? "error_input" : ""}
            />
            {currencyInformation.errors.name ? (
              <div className="error_message">
                <i className="fa fa-exclamation-circle"></i>
                <span>{currencyInformation.errors.name}</span>
              </div>
            ) : null}
          </div>
          <div className="currency_item">
            <p>Rate/AUD*</p>
            <Input
              name="rate"
              type="number"
              min={0}
              step={0.01}
              onChange={currencyInformation.handleChange}
              onBlur={currencyInformation.handleBlur}
              value={currencyInformation.values.rate}
              size="large"
              className={currencyInformation.errors.rate ? "error_input" : ""}
            />
            {currencyInformation.errors.rate ? (
              <div className="error_message">
                <i className="fa fa-exclamation-circle"></i>
                <span>{currencyInformation.errors.rate}</span>
              </div>
            ) : null}
          </div>
          <div className="currency_item">
            <p>Description</p>
            <Input.TextArea
              name="description"
              onChange={currencyInformation.handleChange}
              onBlur={currencyInformation.handleBlur}
              value={currencyInformation.values.description}
              size="large"
            />
            <span className="currency_err">
              {" "}
              {currencyInformation.errors.description}
            </span>
          </div>
        </div>
        <div className="form_action">
          <button
            className="cancel"
            type="button"
            onClick={() => {
              handleCancel();
            }}
          >
            Cancel
          </button>
          <button className="create" type="submit">
            Create
          </button>
        </div>
      </form>
    </div>
  );
}
