import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMovement } from "../../Redux/movementReducer/movementReducer";
import "../../Assets/scss/Component/Movement/_detailMovement.scss";
import BillingGroupManagement from "./BillingGroupManagement";
import DetailTypeNull from "./DetailTypeNull";
import DetailTypeNewStock from "./DetailTypeNewStock";
import DetailTypeMovement from "./DetailTypeMovement";
import DetailTypeReturnStock from "./DetailTypeReturnStock";
import { Modal } from "antd";
import { renderUpdater } from "../../Utils/renderUpdater";
import DetailTypeWarranty from "./DetailTypeWarranty";
import DetailReturnAfterWarranty from "./DetailReturnAfterWarranty";
import DetailToBeReturn from "./DetailToBeReturn";

const DetailMovement = (props) => {
  const dispatch = useDispatch();
  const { movementEdit } = useSelector((state) => state.movementReducer);
  const [openSetting, setOpenSetting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  // Handle modal
  const showModalSetting = () => {
    setOpenSetting(true);
  };

  const handleCancelSetting = () => {
    setOpenSetting(false);
  };

  const showModalDelete = () => {
    setShowDelete(true);
  };
  const handleOkModalDelete = () => {
    const action = deleteMovement(movementEdit.id);
    dispatch(action);
    setShowDelete(false);
    props.cancelModalDetail();
  };
  const handleCancelModalDelete = () => {
    setShowDelete(false);
  };
 
  // Render detail depend on type
  const renderDetail = () => {
    if (movementEdit.type === null) {
      return <DetailTypeNull showModalSetting={showModalSetting} />;
    }
    if (movementEdit.type === "new-stock") {
      return <DetailTypeNewStock />;
    }
    if (movementEdit.type === "movement") {
      return <DetailTypeMovement showModalSetting={showModalSetting} />;
    }
    if (movementEdit.type === "return-stock") {
      return <DetailTypeReturnStock />;
    }
    if(movementEdit.type === "warranty"){
      return <DetailTypeWarranty/>
    }
    if(movementEdit.type === "return-after-warranty"){
      return <DetailReturnAfterWarranty/>
    }
    if(movementEdit.type === "to-be-returned"){
      return <DetailToBeReturn/>
    }
  };
  return (
    <div className="detail">
      {renderDetail()}
      {movementEdit.isDeleted === 0 ? (
        <div className="user_addition">
          <div className="add_detail">
            <div className="user_delete">
              <h3>
                <span>
                  <i className="fa fa-exclamation-circle"></i>
                </span>{" "}
                Delete movement
              </h3>
              <div className="user_password_row">
                <p>This action can't be undone!</p>
                <button type="button" onClick={showModalDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div className="user_footer">{renderUpdater(movementEdit,"movement")}</div>
        </div>
      ) : (
        <div className="dt_last-update">{renderUpdater(movementEdit,"movement")}</div>
      )}
      <Modal
        title="Billing group management"
        open={openSetting}
        onCancel={handleCancelSetting}
        footer={null}
        width={600}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <BillingGroupManagement handleCancelSetting={handleCancelSetting} />
      </Modal>
      <Modal
        open={showDelete}
        onCancel={handleCancelModalDelete}
        footer={null}
        width={450}
      >
        <div className="delete_user">
          <p>
            <i className="fa fa-question"></i> Delete movement
          </p>
          <span>Are you sure to delete this movement?</span>
          <div className="delete_action">
            <button className="cancel" onClick={handleCancelModalDelete}>
              Cancel
            </button>
            <button className="delete" onClick={handleOkModalDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DetailMovement;
