export const convertLongToShortESN = (serialNumber) => {
  if (serialNumber.length > 20) {
    let esn = serialNumber.split("-");
    let prefix = esn[0];
    let suffixes = esn[1];
    prefix = prefix.replace(/[^0-9]/g, "");
    prefix = prefix.split("0").join("");
    if (suffixes === undefined) {
      return prefix.slice(prefix.length - 6);
    }
    return prefix.slice(prefix.length - 6) + "-" + suffixes;
  } else {
    return serialNumber;
  }
};
