import axiosClient from "./axiosClient";

const saleProductApi = {
  addProduct: async (data) => {
    const response = await axiosClient.post("/sales-product", data);
    return response;
  },
  getAllProduct: async () => {
    const response = await axiosClient.get("/sales-product");
    return response;
  },
  deleteProduct: async (id) => {
    const response = await axiosClient.delete("/sales-product/" + id);
    return response;
  },
  updateProduct: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/sales-product/" + id, data);
    return response;
  },
  getTotalProduct: async () => {
    const response = await axiosClient.get("/sales-product/total");
    return response;
  },
  changeLog: async (id) => {
    const response = await axiosClient.get("/sales-product/history/" + id);
    return response;
  },
  restore: async (id) => {
    const response = await axiosClient.put("/sales-product/restore/" + id);
    return response;
  },
  findOne: async (id) => {
    const response = await axiosClient.get("/sales-product/" + id);
    return response;
  },
};
export default saleProductApi;
