import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import "../../Assets/scss/Pages/ForgotPassword/_forgotpassword.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { verifyOtp } from "../../Redux/authReducer/authReducer";
import Loading from "../../Components/Loading/Loading";

const validationSchema = yup.object({
  otp: yup.string().required("Please enter OTP code !"),
});

export default function CheckOtpPage() {
  const { email, isLoading, isErrorOtp } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(null);
  const [count, setCount] = useState("120");
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.email = email.email;
      dispatch(verifyOtp(values));
      setLoading(true);
    },
  });
  const setTime = () => {
    if (count > 0) {
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    } else {
      return <p>time out</p>;
    }
  };
  const renderTime = () => {
    if (count > 0) {
      return (
        <div className="forgot_timer-number">
          <span> OTP code will expire in:</span>
          <p style={{ fontSize: "25px", margin: 0 }}>
            <strong>{count}</strong> s
          </p>
        </div>
      );
    } else {
      return (
        <div className="forgot_timer-text">
          <span>
            The OTP code has expired. Please resend the email to get the new OTP
            code
          </span>
        </div>
      );
    }
  };
  useEffect(() => {
    document.title = "Tag Register - Forgot password";
  }, []);

  useEffect(() => {
    setTime();
  }, [count]);
  useEffect(() => {
    if (isLoading === true) {
      const timeoutTrue = setTimeout(() => {
        setLoading(false);
      }, 2000);
      return () => {
        clearTimeout(timeoutTrue);
      };
    }
    if (isLoading === false) {
      const timeoutFalse = setTimeout(() => {
        setLoading(false);
      }, 2000);
      return () => {
        clearTimeout(timeoutFalse);
      };
    }
  }, [isLoading]);

  return (
    <div className="forgot">
      <div className="forgot_body">
        <div className="forgot_content">
          <div className="forgot_avatar">
            <i className="fas fa-user"></i>
          </div>
          <div className="forgot_text">
            <p>Check OTP</p>
          </div>
          <div className="forgot_note">
            <p>
              We sent an OTP code to <strong>{email.email}</strong>. Please take
              and enter it here to reset your password
            </p>
            {renderTime()}
          </div>

          <div className="forgot_form">
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              {count > 0 ? (
                <div className="forgot_field">
                  <input
                    id="otp"
                    name="otp"
                    className={
                      formik.touched.otp && formik.errors.otp
                        ? "forgot_errors-input"
                        : "forgot_input"
                    }
                    placeholder="Enter OTP code"
                    value={formik.values.otp}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.otp && formik.errors.otp ? (
                    <div className="forgot_errors">
                      <i className="fas fa-exclamation-circle"></i>
                      <span>{formik.errors.otp}</span>
                    </div>
                  ) : null}
                </div>
              ) : (
                ""
              )}
              {count > 0 ? (
                <div className="forgot_action">
                  <button type="submit">Reset password</button>
                </div>
              ) : (
                <div className="forgot_action">
                  <button type="submit" className="forgot_resend">
                    <NavLink to="/forgot-password">Resend email</NavLink>
                  </button>
                </div>
              )}
              {isErrorOtp && loading === false ? (
                <div className="forgot_errors-server">
                  <span>{isErrorOtp}</span>
                </div>
              ) : null}
            </form>
          </div>
          <div className="forgot_to-login">
            <Link to="/sign-in">
              <span className="forgot_icon">
                <i className="fas fa-chevron-left"></i>
              </span>
              <span>Back to Sign in</span>
            </Link>
          </div>
        </div>
      </div>
      {loading === true ? <Loading /> : null}
    </div>
  );
}
