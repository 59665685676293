import React from "react";
import { Input } from "antd";
import "../../Assets/scss/Component/Currency/_createCurrency.scss";
import { useDispatch } from "react-redux";
import { addBillingPolicy } from "../../Redux/billingPolicyReducer/billingPolicyReducer";
import { useFormik } from "formik";
import * as yup from "yup";

const CreateBillingPolicy = ({ handleOk, cancelModalNew }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      dispatch(addBillingPolicy(values));
      handleOk();
    },
  });
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="create_currency">
          <div className="currency_item">
            <p>Name*</p>
            <Input
              className={
                formik.touched.name && formik.errors.name ? "error_input" : ""
              }
              size="large"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error_message">
                <i className="fas fa-exclamation-circle"></i>
                <span>{formik.errors.name}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="currency_item">
            <p>Description</p>
            <Input.TextArea
              size="small"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="form_action">
          <button
            type="button"
            className="cancel"
            onClick={() => {
              cancelModalNew();
            }}
          >
            Cancel
          </button>
          <button type="submit" className="create">
            Create
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateBillingPolicy;
