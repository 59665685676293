import React, { useEffect, useState } from "react";
import { DatePicker, Input, Modal, message } from "antd";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAllDeviceApi } from "../../Redux/deviceReducer/deviceReducer";
import {
  getAllCompany,
  getTotalStocker,
} from "../../Redux/companyReducer/companyReducer";
import {
  getAllDeviceStateMovement,
  returnStockMovement,
} from "../../Redux/movementReducer/movementReducer";
import CreateStateMovement from "./CreateStateMovement";
import "../../Assets/scss/Component/Movement/_returnStock.scss";
import CreateCompany from "../Company/CreateCompany";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { onChangeDatePicker } from "../../Utils/onChangeDatePicker";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";

const ReturnStock = ({
  cancelModalReturnStock,
  setShowReturnStock,
  setValueEnter,
}) => {
  const [arrDeviceValue, setArrDeviceValue] = useState([]);
  const [arrDeviceStateValue, setArrDeviceStateValue] = useState([]);
  const [arrStockerValue, setArrStockerValue] = useState([]);
  const [arrShowSerialNumber, setShowArrSerialNumber] = useState([]);
  const [arrSendSerialNumber, setSendArrSerialNumber] = useState([]);
  const [valueHideSelect, setValueHideSelect] = useState([]);
  const [valueNotesSerial, setValueNotesSerial] = useState({});
  const [serialNumberName, setSerialNumberName] = useState("");
  const [openStockNote, setOpenStockNote] = useState(false);
  const [openStateDevice, setOpenStateDevice] = useState(false);
  const [serialValidation, setSerialValidation] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const [checkValues, setCheckValues] = useState({});
  const dispatch = useDispatch();
  const { arrDevice } = useSelector((state) => state.deviceReducer);
  const { arrayStocker } = useSelector((state) => state.companyReducer);
  const { arrTransaction } = useSelector((state) => state.movementReducer);
  const [value, setValue] = useState(false);

  // Show and cancel modal return stock note
  const showModalStockNote = (e) => {
    const itemFind = arrSendSerialNumber.find(
      (object) => object.deviceId === e.id
    );
    setSerialNumberName(e.shortEsn);
    setValueNotesSerial(itemFind);
    setOpenStockNote(true);
  };
  const cancelModalStockNote = () => {
    setOpenStockNote(false);
  };

  //Show and cancel modal device state
  const showDeviceState = () => {
    setOpenStateDevice(true);
  };
  const cancelAddState = () => {
    setOpenStateDevice(false);
  };

  //Show and cancel modal create new account
  const showModalNew = () => {
    setShowAddNew(true);
  };
  const cancelModalNew = () => {
    setShowAddNew(false);
  };

  const renderSerialList = (e) => {
    const arrayShow = [];
    const arraySend = [];
    for (let i = 0; i < e.length; i++) {
      const item = e[i].value;
      const itemFind = arrDevice.find((object) => object.id === item);
      if (itemFind !== undefined) {
        const itemSend = {
          deviceId: item,
          notes: null,
        };
        arraySend.push(itemSend);
        arrayShow.push(itemFind);
      }
    }
    for (let i = 0; i < arrSendSerialNumber.length; i++) {
      const item = arrSendSerialNumber[i];
      const itemFind = arraySend.findIndex(
        (obj) => obj.deviceId === item.deviceId
      );
      if (itemFind !== -1) {
        arraySend[itemFind] = item;
      }
    }
    setSendArrSerialNumber(arraySend);
    setShowArrSerialNumber(arrayShow);
    setValueHideSelect(e);
  };

  const handleRemoveValueSelect = (id) => {
    setValueHideSelect((prevSelectedOptions) => {
      return prevSelectedOptions.filter(
        (valueHideSelect) => valueHideSelect.value !== id
      );
    });
    setSendArrSerialNumber((prevSelectedOptions) => {
      return prevSelectedOptions.filter(
        (sendArrSerialNumber) => sendArrSerialNumber.deviceId !== id
      );
    });
    setShowArrSerialNumber((prevSelectedOptions) => {
      return prevSelectedOptions.filter(
        (showArrSerialNumber) => showArrSerialNumber.id !== id
      );
    });
  };

  const handleRemoveAllItem = () => {
    setValueHideSelect([]);
    setSendArrSerialNumber([]);
    setShowArrSerialNumber([]);
  };

  const handleSetValueNotes = () => {
    for (let i = 0; i < arrSendSerialNumber.length; i++) {
      if (arrSendSerialNumber[i].deviceId === valueNotesSerial.deviceId) {
        if (valueNotesSerial.notes === "") {
          valueNotesSerial.notes = null;
        }
        arrSendSerialNumber[i].notes = valueNotesSerial.notes;
        break;
      }
    }
    setOpenStockNote(false);
  };

  // Handle form
  const formik = useFormik({
    initialValues: {
      deviceStateId: null,
      toAccountId: null,
      movementDate: null,
      notes: null,
    },
    validationSchema: yup.object().shape({
      deviceStateId: yup.mixed().required("Device state is required"),
      toAccountId: yup.mixed().required("TO stock is required"),
      movementDate: yup.mixed().required("Movement date is required"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      let newValues = { ...values };
      if (arrSendSerialNumber.length === 0) {
        setSerialValidation(true);
      } else {
        newValues.devices = arrSendSerialNumber;
        dispatch(returnStockMovement(newValues));
        message.loading("Loading", 1);
        setShowReturnStock(false);
      }
    },
  });

  // Change value enter
  useEffect(() => {
    const data = {};
    convertEmptyToNull(formik.values);
    for (let item in formik.values) {
      if (formik.values[item] !== formik.initialValues[item]) {
        data[item] = formik.values[item];
      }
    }
    setCheckValues(data);
  }, [formik.values]);

  useEffect(() => {
    if (Object.keys(checkValues).length !== 0) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [checkValues]);

  useEffect(() => {
    if (arrShowSerialNumber.length !== 0) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [arrShowSerialNumber]);

  useEffect(() => {
    setValueEnter(value);
  }, [value]);

  // Call API
  useEffect(() => {
    dispatch(getAllDeviceApi());
    dispatch(getAllCompany());
    dispatch(getTotalStocker());
    dispatch(getAllDeviceStateMovement());
  }, [dispatch]);

  //-----//
  useEffect(() => {
    let arrSelect = [];
    arrDevice.map((item) => {
      if (item.isDeleted === 0) {
        let obj = {
          value: item.id,
          label: item.shortEsn,
        };
        arrSelect.push(obj);
      }
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setArrDeviceValue(arrSelect);
  }, [arrDevice]);

  useEffect(() => {
    CreateDataForSelect(arrTransaction, setArrDeviceStateValue);
  }, [arrTransaction]);

  useEffect(() => {
    CreateDataForSelect(arrayStocker, setArrStockerValue);
  }, [arrayStocker]);

  return (
    <div className="return_stock">
      <form onSubmit={formik.handleSubmit}>
        <div className="return_stock-content">
          <div className="return_stock-top">
            <div className="return_stock-left">
              <div className="return_stock-leftField">
                <p>Serial number*</p>
                <div className="return_stock-leftSelect">
                  <Select
                    className={
                      serialValidation === true &&
                      arrShowSerialNumber.length === 0
                        ? "error_left-validation"
                        : ""
                    }
                    isMulti
                    value={valueHideSelect}
                    controlShouldRenderValue={false}
                    options={arrDeviceValue}
                    onChange={renderSerialList}
                  />
                  {serialValidation === true &&
                  arrShowSerialNumber.length === 0 ? (
                    <div className="rt_left-error">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>Serial number is required</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="return_stock-leftList">
                <div className="return_stock-leftTitle">
                  <div className="return_stock-leftTitleLeft">
                    <p>Device list</p>
                    {arrShowSerialNumber?.length > 0 ? (
                      <span>
                        (Total: <strong>{arrShowSerialNumber.length}</strong>)
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="return_stock-leftTitleRight">
                    {arrShowSerialNumber?.length > 0 ? (
                      <p
                        onClick={() => {
                          handleRemoveAllItem();
                          setValueEnter([]);
                        }}
                      >
                        Clear all
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="return_stock-leftItem">
                  {arrShowSerialNumber?.length > 0
                    ? arrShowSerialNumber.map((item, key) => {
                        return (
                          <div key={key} className="return_stockList">
                            <button
                              className="return_stockListRemove"
                              type="button"
                              onClick={() => handleRemoveValueSelect(item.id)}
                            >
                              <i className="fa fa-times"></i>
                            </button>

                            <div className="return_stockList-item">
                              <div className="return_stockList-content">
                                <span>{item.shortEsn}</span>
                                {item.currentAccount !== null ? (
                                  <p>
                                    Current Account:{" "}
                                    <strong>{item.currentAccount.name}</strong>
                                  </p>
                                ) : (
                                  <p>
                                    Current Account: <strong>None</strong>
                                  </p>
                                )}
                              </div>
                              <button
                                type="button"
                                onClick={() => showModalStockNote(item)}
                              >
                                <i className="fa fa-pencil-alt"></i>
                              </button>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
            <div className="return_stockRight">
              <div className="return_stockState">
                <p>Device state*</p>
                <div className="return_stockStateContent">
                  <div className="return_stockStateSelect">
                    <Select
                      className={
                        formik.touched.deviceStateId &&
                        formik.errors.deviceStateId
                          ? "error_state-validation"
                          : ""
                      }
                      name="deviceStateId"
                      options={arrDeviceStateValue}
                      onChange={(e) => {
                        formik.setFieldValue("deviceStateId", e.value);
                      }}
                    />
                    {formik.touched.deviceStateId &&
                    formik.errors.deviceStateId ? (
                      <div className="return_stockStateError">
                        <i className="fa fa-exclamation-circle"></i>
                        <span>{formik.errors.deviceStateId}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <button type="button" onClick={showDeviceState}>
                    Add
                  </button>
                </div>
              </div>
              <div className="return_stockState">
                <p>TO stock*</p>
                <div className="return_stockStateContent">
                  <div className="return_stockStateSelect">
                    <Select
                      className={
                        formik.touched.toAccountId && formik.errors.toAccountId
                          ? "error_select-validation"
                          : ""
                      }
                      name="toAccountId"
                      options={arrStockerValue}
                      onChange={(e) => {
                        formik.setFieldValue("toAccountId", e.value);
                      }}
                    />
                    {formik.touched.toAccountId && formik.errors.toAccountId ? (
                      <div className="return_stockStateError">
                        <i className="fa fa-exclamation-circle"></i>
                        <span>{formik.errors.toAccountId}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <button type="button" onClick={showModalNew}>
                    Add
                  </button>
                </div>
              </div>
              <div className="return_stockRightField">
                <p>Movement date*</p>
                <div className="return_stockRightSelect">
                  <DatePicker
                    className={
                      formik.touched.movementDate && formik.errors.movementDate
                        ? "error_input-validation"
                        : ""
                    }
                    name="movementDate"
                    showTime={true}
                    format="DD/MM/YYYY hh:mm A"
                    placeholder="Select..."
                    onChange={(date) => {
                      onChangeDatePicker(date, "movementDate", formik);
                    }}
                  />
                  {formik.touched.movementDate && formik.errors.movementDate ? (
                    <div className="return_stockStateError">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.movementDate}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="return_stockRightField">
                <p>Notes</p>
                <div className="return_stockRightSelect">
                  <Input.TextArea
                    size="large"
                    name="notes"
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="return_stockAction">
          <button type="button" onClick={cancelModalReturnStock}>
            Cancel
          </button>
          <button type="submit">Create</button>
        </div>
      </form>

      <Modal
        title={"Serial number: " + serialNumberName}
        open={openStockNote}
        onCancel={cancelModalStockNote}
        width={600}
        style={{ top: "50px" }}
        footer={null}
        destroyOnClose
      >
        <div className="return_stockNotes">
          <div className="return_stockNotes-content">
            <div className="return_stockNotes-field">
              <p>Notes</p>
              <div className="return_stockNotes-input">
                <Input.TextArea
                  name="notes"
                  size="small"
                  value={valueNotesSerial.notes}
                  onChange={(e) =>
                    setValueNotesSerial({
                      ...valueNotesSerial,
                      notes: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="return_stockNotes-action">
            <button type="button" onClick={cancelModalStockNote}>
              Cancel
            </button>
            <button type="button" onClick={handleSetValueNotes}>
              Create
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        title="Add New State"
        open={openStateDevice}
        onCancel={cancelAddState}
        footer={null}
        destroyOnClose
      >
        <CreateStateMovement cancelAddState={cancelAddState} />
      </Modal>
      <Modal
        title="Create New Account"
        open={showAddNew}
        onCancel={cancelModalNew}
        width={800}
        style={{ top: "50px" }}
        footer={null}
        destroyOnClose
      >
        <CreateCompany cancelModalNew={cancelModalNew} />
      </Modal>
    </div>
  );
};

export default ReturnStock;
