import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authReducer/authReducer";
import deviceReducer from "./deviceReducer/deviceReducer";
import userReducer from "./userReducer/userReducer";
import companyReducer from "./companyReducer/companyReducer";
import companyTypesReducer from "./companyReducer/companyTypesReducer";
import platformReducer from "./platformReducer/platformReducer";
import currencyReducer from "./currencyReducer/currencyReducer";
import billingPolicyReducer from "./billingPolicyReducer/billingPolicyReducer";
import movementReducer from "./movementReducer/movementReducer";
import accountReducer from "./accountReducer/accountReducer";
import purchaseProductReducer from "./productReducer/purchaseProductReducer";
import saleProductReducer from "./productReducer/saleProductReducer";
import platformStatusReducer from "./platformReducer/platformStatusReducer";
import deviceStateReducer from "./deviceReducer/deviceStateReducer";
import billingGroupReducer from "./billingGroupReducer/billingGroupReducer";
import billingSummaryReducer from "./billingSummaryReducer/billingSummaryReducer";
import columnReducer from "./columnReducer/columnReducer";
import wirelessTechReducer from "./wirelessTechReducer/wirelessTechReducer";
import eventReducer from "./eventReducer/eventReducer";
import eventTypeReducer from "./eventReducer/eventTypeReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    userReducer: userReducer,
    deviceReducer: deviceReducer,
    companyReducer: companyReducer,
    companyTypesReducer: companyTypesReducer,
    platformReducer: platformReducer,
    currencyReducer: currencyReducer,
    billingPolicyReducer: billingPolicyReducer,
    accountReducer: accountReducer,
    movementReducer: movementReducer,
    purchaseProductReducer: purchaseProductReducer,
    saleProductReducer: saleProductReducer,
    platformStatusReducer:platformStatusReducer,
    deviceStateReducer:deviceStateReducer,
    billingGroupReducer:billingGroupReducer,
    columnReducer:columnReducer,
    billingSummaryReducer: billingSummaryReducer,
    wirelessTechReducer:wirelessTechReducer,
    eventReducer:eventReducer,
    eventTypeReducer: eventTypeReducer
  },
});
