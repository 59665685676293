import React, { useEffect, useRef, useState } from "react";
import "../../Assets/scss/Component/DatePickerMonthYear/_datePickerMonthYear.scss";

const DatePickerMonthYear = ({ type, onClick, resetInputMonth, className }) => {
  const [showBoard, setShowBoard] = useState(false);
  const [showBoardYear, setShowBoardYear] = useState(false);
  const [valueItemMonth, setValueItemMonth] = useState(null);
  const [valueItemYear, setValueItemYear] = useState(null);
  const [valueShowInput, setValueShowInput] = useState("");

  const input = document.querySelector(".picker_content");
  const handleClick = () => {
    input.focus();
    setShowBoard(true);
    setShowBoardYear(true);
  };

  const dateLocal = new Date();
  const yearLocal = dateLocal.getFullYear();
  const monthLocal = dateLocal.getMonth() + 1;

  let getMonth = [];
  for (let i = 1; i < 13; i++) {
    getMonth.push(i);
  }
  let getYear = [];
  for (let i = 2000; i < yearLocal + 1; i++) {
    getYear.push(i);
  }

  const getValueInput = () => {
    if (valueItemYear === null) {
      if (valueItemMonth < 10) {
        let defaultValue = `0${valueItemMonth}/${yearLocal}`;
        setValueShowInput(defaultValue);
        onClick(defaultValue);
      } else {
        let defaultValue = `${valueItemMonth}/${yearLocal}`;
        setValueShowInput(defaultValue);
        onClick(defaultValue);
      }
    } else {
      if (valueItemMonth < 10) {
        let defaultValue = `0${valueItemMonth}/${valueItemYear}`;
        setValueShowInput(defaultValue);
        onClick(defaultValue);
      } else {
        let defaultValue = `${valueItemMonth}/${valueItemYear}`;
        setValueShowInput(defaultValue);
        onClick(defaultValue);
      }
    }
    setShowBoard(false);
  };

  const getValueInputYear = () => {
    let defaultValue = valueItemYear;
    setValueShowInput(defaultValue);
    onClick(defaultValue);
    setShowBoardYear(false);
  };

  const getTimeNow = () => {
    if (type === "month") {
      if (monthLocal < 10) {
        let defaultValue = `0${monthLocal}/${yearLocal}`;
        setValueItemMonth(monthLocal);
        setValueItemYear(yearLocal);
        setValueShowInput(defaultValue);
        onClick(defaultValue);
      } else {
        let defaultValue = `${monthLocal}/${yearLocal}`;
        setValueItemMonth(monthLocal);
        setValueItemYear(yearLocal);
        setValueShowInput(defaultValue);
        onClick(defaultValue);
      }
    } else {
      let defaultValue = `${yearLocal}`;
      setValueItemYear(yearLocal);
      setValueShowInput(defaultValue);
      onClick(defaultValue);
    }
    setShowBoard(false);
    setShowBoardYear(false);
  };

  const menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setShowBoard(false);
        setShowBoardYear(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const resetMonth = () => {
    setValueItemMonth(null);
    setValueItemYear(null);
    setValueShowInput("");
  };

  useEffect(() => {
    setValueItemMonth(null);
    setValueItemYear(null);
    setValueShowInput("");
  }, [resetInputMonth]);

  return (
    <div className={`picker ${className}`} ref={menuRef}>
      <div className="picker_content" onClick={handleClick}>
        <input
          className="picker_input"
          value={valueShowInput !== "" ? valueShowInput : ""}
          onChange={() => {
            setValueShowInput(valueShowInput);
          }}
          placeholder={type === "month" ? "--/----" : "----"}
        />
        {valueShowInput !== "" ? (
          <i
            className="fa-solid fa-circle-xmark"
            onClick={resetMonth}
            style={{ cursor: "pointer" }}
          ></i>
        ) : (
          <i className="fa-regular fa-calendar"></i>
        )}
      </div>

      <div
        className={`picker_dropdown ${
          showBoard === true && type === "month" ? "show-month" : "off-month"
        }`}
      >
        <div className="picker_value">
          <div className="picker_month">
            {getMonth.map((item) => {
              if (valueItemYear === yearLocal && item > monthLocal) {
                return (
                  <div key={item} className="picker_month-disabled">
                    {item}
                  </div>
                );
              } else {
                return (
                  <div
                    key={item}
                    className={`picker_month-item ${
                      item === valueItemMonth ? "picker_month-active" : ""
                    }`}
                    onClick={() => {
                      setValueItemMonth(item);
                    }}
                  >
                    {item}
                  </div>
                );
              }
            })}
          </div>
          <div className="picker_month-year">
            {getYear.map((item) => {
              return (
                <div
                  key={item}
                  className={`picker_month-year-item ${
                    item === valueItemYear ? "picker_month-year-active" : ""
                  }`}
                  onClick={() => {
                    setValueItemYear(item);
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
        <div className="picker_action">
          <div className="picker_now">
            <button type="button" onClick={getTimeNow}>
              Now
            </button>
          </div>
          <div className="picker_ok">
            <button
              type="button"
              disabled={valueItemMonth === null ? true : false}
              onClick={getValueInput}
            >
              OK
            </button>
          </div>
        </div>
      </div>

      <div
        className={`picker_dropdown ${
          showBoardYear === true && type === "year" ? "show-year" : "off-year"
        }`}
      >
        <div className="picker_value">
          <div className="picker_year">
            {getYear.map((item) => {
              return (
                <div
                  key={item}
                  className={`picker_year-item ${
                    item === valueItemYear ? "picker_year-active" : ""
                  }`}
                  onClick={() => {
                    setValueItemYear(item);
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
        <div className="picker_action">
          <div className="picker_now">
            <button type="button" onClick={getTimeNow}>
              Now
            </button>
          </div>
          <div className="picker_ok">
            <button
              type="button"
              disabled={valueItemYear === null ? true : false}
              onClick={getValueInputYear}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatePickerMonthYear;
