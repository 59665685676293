import React, { useEffect } from "react";
import "../../Assets/scss/Pages/Dashboard/_dashboard.scss";
import DashboardMiddle from "../../Components/Dashboard/DashboardMiddle";
import DashboardBottom from "../../Components/Dashboard/DashboardBottom";

export default function Dashboard() {
  useEffect(() => {
    document.title = "Tag Register - Dashboard";
  }, []);
  return (
    <div className="dashboard">
      <div className="dashboard_top">
        <div className="dashboard_left">
          <span>Dashboard</span>
        </div>
      </div>
      <div className="dashboard_body">
        <DashboardMiddle />
        <DashboardBottom />
      </div>
    </div>
  );
}
