import { TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import { storage } from "../../Utils/storage";
dayjs.extend(utc);
const format = "D";
const formatTime = "H:mm";

export default function FormMonthly(props) {
  const renderDefaultDate = () => {
    const arrayDefault = [];
    if (props.reportDetail.values.daysOfMonth !== null) {
      const arrayDate = props.reportDetail.values.daysOfMonth.split(",");
      arrayDate.map((item) => {
        arrayDefault.push(new DateObject().set({ day: Number(item), format }));
      });
      return arrayDefault;
    } else {
      return [];
    }
  };
  const [value, setValue] = useState(renderDefaultDate());
  const onChange = (time) => {
    let user = storage.getStorageJson("user");
    if (user.timezone && user.timezone === null) {
      const utcHour = dayjs(time.$d).utc().hour();
      let utcMinute = dayjs(time.$d).utc().minute();
      if (utcMinute < 10) {
        utcMinute = "0" + utcMinute;
      }
      props.setTime(`${utcHour}:${utcMinute}`);
    } else {
      let newDay = new Date(
        `${dayjs(time.$d).format("M/DD/YYYY HH:mm:ss")}  UTC ${
          user.timezone.offset
        }`
      );
      const utcHour = newDay.getUTCHours();
      let utcMinute = newDay.getUTCMinutes();
      if (utcMinute < 10) {
        utcMinute = "0" + utcMinute;
      }
      props.setTime(`${utcHour}:${utcMinute}`);
    }
  };
  useEffect(() => {
    const arrayDate = [];
    if (value.length !== 0) {
      value.map((item) => {
        arrayDate.push(item.format());
      });
      props.reportDetail.setFieldValue("daysOfMonth", arrayDate.toString());
    }
    if (value.length === 0) {
      props.reportDetail.setFieldValue("daysOfMonth", null);
    }
  }, [value]);

  return (
    <>
      <div className="movement_report-row">
        <div className="row_left">
          <p>Set days:</p>
        </div>
        <div className="row_right">
          <DatePicker
            multiple
            sort
            format={format}
            buttons={false}
            calendarPosition="bottom-center"
            placeholder="Select..."
            hideWeekDays
            hideMonth
            hideYear
            highlightToday={false}
            onChange={setValue}
            defaultValue
            value={value}
          />
        </div>
      </div>
      <div className="movement_report-row">
        <div className="row_left">
          <p>Time send:</p>
        </div>
        <div className="row_right">
          <TimePicker
            format={formatTime}
            placeholder="Select..."
            defaultValue={dayjs(
              props.reportDetail.values.reportSendAt,
              formatTime
            )}
            onChange={onChange}
            allowClear={false}
          />
        </div>
      </div>
    </>
  );
}
