import React, { useEffect, useState } from "react";
import { Input, Modal, message, DatePicker } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getTotalStocker } from "../../Redux/companyReducer/companyReducer";
import {
  findLastMovementByID,
  resetLatestMovementData,
  toBeReturnMovement,
} from "../../Redux/movementReducer/movementReducer";
import "../../Assets/scss/Component/Movement/_movementNewTransaction.scss";
import CreateCompany from "../Company/CreateCompany";
import { onChangeDatePicker } from "../../Utils/onChangeDatePicker";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";
import { convertLongToShortESN } from "../../Utils/convertESN";

const ToBeReturn = ({
  setShowToBeReturn,
  setValueEnter,
  cancelModalToBeReturn,
}) => {
  const [arrCurrentCompany, setArrCurrentCompany] = useState([]);
  const [arrCurrentDevice, setArrCurrentDevice] = useState([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const dispatch = useDispatch();
  const { arrMovementDevice, latestMovementData } = useSelector(
    (state) => state.movementReducer
  );
  const { arrayStocker } = useSelector((state) => state.companyReducer);
  const [checkValues, setCheckValues] = useState({});
  const [value, setValue] = useState(false);

  // Show modal add account
  const cancelModalCreateNew = () => {
    setResetForm(!resetForm);
    setShowAddNew(false);
  };
  const showModalNew = () => {
    setShowAddNew(true);
  };
  const cancelModalNew = () => {
    setShowAddNew(false);
  };

  // Handle form
  const formik = useFormik({
    initialValues: {
      deviceId: "",
      toAccountId: "",
      movementDate: "",
      startBillDate: "",
      notes: null,
      comment: null,
    },
    validationSchema: yup.object().shape({
      deviceId: yup.mixed().required("Device is required"),
      toAccountId: yup.mixed().required("TO stock is required"),
      movementDate: yup.mixed().required("Movement date is required"),
      startBillDate: yup.mixed().required("Start bill date is required"),
    }),
    onSubmit: (values) => {
      if (
        latestMovementData !== null &&
        latestMovementData.type === "movement"
      ) {
        message.loading("Loading", 1);
        dispatch(toBeReturnMovement(values));
        setShowToBeReturn(false);
        dispatch(resetLatestMovementData({}));
      }
    },
  });

  // Change values enter
  useEffect(() => {
    const data = {};
    convertEmptyToNull(formik.values);
    for (let item in formik.values) {
      if (formik.values[item] !== formik.initialValues[item]) {
        data[item] = formik.values[item];
      }
    }
    setCheckValues(data);
  }, [formik.values]);

  useEffect(() => {
    if (Object.keys(checkValues).length !== 0) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [checkValues]);

  useEffect(() => {
    setValueEnter(value);
  }, [value]);

  // Call api
  useEffect(() => {
    dispatch(getTotalStocker());
  }, [dispatch]);

  useEffect(() => {
    CreateDataForSelect(arrayStocker, setArrCurrentCompany);
  }, [arrayStocker]);

  useEffect(() => {
    let arrSelect = [];
    arrMovementDevice.map((item) => {
      if (item.isDeleted === 0) {
        let obj = {
          value: item.id,
          label: convertLongToShortESN(item.serialNumber),
        };
        arrSelect.push(obj);
      }
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setArrCurrentDevice(arrSelect);
  }, [arrMovementDevice]);
  return (
    <div className="transaction">
      <form onSubmit={formik.handleSubmit}>
        <div className="transaction_form">
          {latestMovementData === null ||
          (Object.keys(latestMovementData).length !== 0 &&
            latestMovementData.type !== "movement") ? (
            <p className="notice_toBeReturn">
              <i className="fa fa-exclamation-circle"></i> To set the movement
              type <strong>"To Be Returned"</strong>, the latest movement type
              of this device must be <strong>"Movement"</strong>. Please check
              again!
            </p>
          ) : (
            ""
          )}
          <div className="transaction_top">
            <div className="transaction_left">
              <div className="transaction_item">
                <p>Device*</p>
                <Select
                  name="deviceId"
                  options={arrCurrentDevice}
                  onChange={(e) => {
                    formik.setFieldValue("deviceId", e.value);
                    dispatch(findLastMovementByID(e.value));
                  }}
                  className={
                    formik.touched.deviceId && formik.errors.deviceId
                      ? "error_select"
                      : ""
                  }
                />
                {formik.touched.deviceId && formik.errors.deviceId ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.deviceId}</span>
                  </div>
                ) : null}
              </div>
              <div className="transaction_item">
                <div className="transaction_itemChild">
                  <div className="transaction_itemChildAccount">
                    <p>FROM account</p>
                    <Input
                      size="large"
                      readOnly
                      value={
                        latestMovementData !== null
                          ? latestMovementData.fromAccount?.name
                          : "None"
                      }
                    />
                  </div>
                  <div className="transaction_itemChildAccount">
                    <p>TO account</p>
                    <Input
                      size="large"
                      readOnly
                      value={
                        latestMovementData !== null
                          ? latestMovementData.toAccount?.name
                          : "None"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="transaction_item">
                <p>Latest movement type</p>
                <Input
                  size="large"
                  readOnly
                  value={
                    latestMovementData !== null
                      ? latestMovementData?.type
                      : "None"
                  }
                />
              </div>
            </div>
            <div className="transaction_right">
              <div className="transaction_item">
                <div className="transaction_title">
                  <p>TO stock*</p>
                </div>
                <div className="transaction_item-state">
                  <div className="transaction_state-label">
                    <Select
                      name="toAccountId"
                      options={arrCurrentCompany}
                      onChange={(e) =>
                        formik.setFieldValue("toAccountId", e.value)
                      }
                      className={
                        formik.touched.toAccountId && formik.errors.toAccountId
                          ? "error_select"
                          : ""
                      }
                    />
                    {formik.touched.toAccountId && formik.errors.toAccountId ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span>{formik.errors.toAccountId}</span>
                      </div>
                    ) : null}
                  </div>
                  <button type="button" onClick={showModalNew}>
                    Add
                  </button>
                </div>
              </div>
              <div className="transaction_item">
                <p>Movement date*</p>
                <DatePicker
                  name="movementDate"
                  className={
                    formik.touched.movementDate && formik.errors.movementDate
                      ? "error_input"
                      : ""
                  }
                  showTime={true}
                  format="DD/MM/YYYY hh:mm A"
                  placeholder="Select..."
                  onChange={(date) => {
                    onChangeDatePicker(date, "movementDate", formik);
                  }}
                />
                {formik.touched.movementDate && formik.errors.movementDate ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.movementDate}</span>
                  </div>
                ) : null}
              </div>
              <div className="transaction_item">
                <p>Start bill date*</p>
                <DatePicker
                  name="startBillDate"
                  showTime={true}
                  format="DD/MM/YYYY hh:mm A"
                  placeholder="Select..."
                  onChange={(date) => {
                    onChangeDatePicker(date, "startBillDate", formik);
                  }}
                  className={
                    formik.touched.startBillDate && formik.errors.startBillDate
                      ? "error_input"
                      : ""
                  }
                />
                {formik.touched.startBillDate && formik.errors.startBillDate ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.startBillDate}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="transaction_bottom">
            <div className="transaction_item">
              <p>Notes</p>
              <Input.TextArea
                name="notes"
                size="large"
                onChange={formik.handleChange}
                value={formik.values.notes}
              />
            </div>
            <div className="transaction_item">
              <p>Comment</p>
              <Input.TextArea
                name="comment"
                size="large"
                onChange={formik.handleChange}
                value={formik.values.comment}
              />
            </div>
          </div>
        </div>
        <div className="transaction_action">
          <button
            type="button"
            className="transaction_action-cancel"
            onClick={() => {
              cancelModalToBeReturn();
            }}
          >
            Cancel
          </button>
          <button type="submit" className="transaction_action-create">
            Create
          </button>
        </div>
      </form>
      <Modal
        title="Create New Account"
        open={showAddNew}
        onCancel={cancelModalCreateNew}
        footer={null}
        width={800}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateCompany
          cancelModalNew={cancelModalNew}
          setShowAddNew={setShowAddNew}
          resetForm={resetForm}
        />
      </Modal>
    </div>
  );
};

export default ToBeReturn;
