export default function calculateDefaultPrice(
  wirelessTechnology,
  defaultPrice,
  array,
  action
) {
  let newPrice = defaultPrice;
  for (let discount of array) {
    if (
      wirelessTechnology === discount.wirelessTechnology.name &&
      action >= discount.afterMonth
    ) {
      if (discount.price < newPrice) {
        newPrice = discount.price;
      }
    }
  }
  return newPrice;
}
