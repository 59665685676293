import React from "react";
import { exportExcel } from "../exportExcel";
import { Popover, Switch } from "antd";

export default function ContentShowHideDeleted({onChangeShowDeleted,showDeleted,arrayData,columnSelected,pageName,content}) {
  return (
    <div className="table_action">
      <div className="table_action-item">
        <Switch
          onChange={onChangeShowDeleted}
          checkedChildren="Show deleted"
          unCheckedChildren="Hide deleted"
          checked={showDeleted}
        />
      </div>
      <div className="table_action-item">
        <button
          onClick={() => {
            exportExcel(arrayData, columnSelected, pageName);
          }}
        >
          Export
        </button>
      </div>
      <div className="table_action-item">
        <Popover content={content} trigger="click" placement="bottom">
          <button>Column</button>
        </Popover>
      </div>
    </div>
  );
}
