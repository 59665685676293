import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { DatePicker, Input } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import { message } from "antd";
import { updateMovement } from "../../Redux/movementReducer/movementReducer";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { compareObject } from "../../Utils/compareObject";
import dayjs from "dayjs";
import { onChangeDatePicker } from "../../Utils/onChangeDatePicker";
import {
  getTotalStocker,
  getTotalSupplier,
} from "../../Redux/companyReducer/companyReducer";
import { getAllPurchaseProductApi } from "../../Redux/productReducer/purchaseProductReducer";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";

export default function DetailTypeNewStock() {
  const [checkAccount, setCheckAccount] = useState(null);
  const [arrPreviousCompany, setArrPreviousCompany] = useState([]);
  const [arrCurrentCompany, setArrCurrentCompany] = useState([]);
  const [arrValueProduct, setArrValueProduct] = useState([]);
  const dispatch = useDispatch();
  const { movementEdit } = useSelector((state) => state.movementReducer);
  const { arraySupplier, arrayStocker } = useSelector(
    (state) => state.companyReducer
  );
  const { arrPurchaseProduct } = useSelector(
    (state) => state.purchaseProductReducer
  );

  // Call API
  useEffect(() => {
    dispatch(getTotalSupplier());
    dispatch(getTotalStocker());
    dispatch(getAllPurchaseProductApi());
  }, [dispatch]);

  // Handle form
  const formik = useFormik({
    initialValues: {
      fromAccountId: movementEdit.fromAccountId,
      toAccountId: movementEdit.toAccountId,
      purchaseProductId: movementEdit.purchaseProductId,
      movementDate: movementEdit.movementDateShowDetail,
      receivedAt: movementEdit.receiveDateShowDetail,
      invoiceCode: movementEdit.invoiceCode,
      notes: movementEdit.notes,
    },
    validationSchema: yup.object({
      fromAccountId: yup.mixed().required("From account cannot be empty"),
      toAccountId: yup.mixed().required("To account cannot be empty"),
      movementDate: yup.string().required("Movement date cannot be empty"),
      purchaseProductId: yup
        .mixed()
        .required("Purchase product cannot be empty"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      let newValue = { ...values };
      if (checkAccount !== null) {
        formik.setFieldError(
          "fromAccountId",
          "From account and To account cannot be the same"
        );
        formik.setFieldError(
          "toAccountId",
          "From account and To account cannot be the same"
        );
        return;
      }

      if (compareObject(newValue, movementEdit)) {
        message.warning("Nothing has changed with this movement");
      } else {
        const newObject = {};
        for (let item in newValue) {
          if (
            item === "movementDate" &&
            newValue.movementDate !== movementEdit.movementDateShowDetail
          ) {
            newObject.movementDate = newValue.movementDate;
          }

          if (
            item === "receivedAt" &&
            newValue.receivedAt !== movementEdit.receiveDateShowDetail
          ) {
            newObject.receivedAt = newValue.receivedAt;
          }
          if (
            item !== "movementDate" &&
            item !== "receivedAt" &&
            newValue[item] !== movementEdit[item]
          ) {
            newObject[item] = newValue[item];
          }
        }
        newObject.deviceId = movementEdit.deviceId;
        const dataSend = {
          id: movementEdit.id,
          data: newObject,
        };
        dispatch(updateMovement(dataSend));
        message.loading("Loading", 0.5);
      }
    },
  });
  useEffect(() => {
    formik.setFieldValue("movementDate", movementEdit.movementDateShowDetail);
    formik.setFieldValue("receivedAt", movementEdit.receiveDateShowDetail);
  }, [movementEdit]);

  //-----//
  useEffect(() => {
    CreateDataForSelect(arraySupplier, setArrPreviousCompany);
  }, [arraySupplier]);

  useEffect(() => {
    CreateDataForSelect(arrayStocker, setArrCurrentCompany);
  }, [arrayStocker]);

  useEffect(() => {
    CreateDataForSelect(arrPurchaseProduct, setArrValueProduct);
  }, [arrPurchaseProduct]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        className="detail_content"
        style={{ cursor: movementEdit.isDeleted === 1 ? "no-drop" : "" }}
      >
        <div className="detail_form">
          <ul>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>ESN:</p>
                </div>
                <div className="detail_field-data">
                  <p>{movementEdit.shortESN}</p>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Type:</p>
                </div>
                <div className="detail_field-data">
                  {movementEdit.type !== null ? <p>{movementEdit.type}</p> : ""}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>FROM supplier:</p>
                </div>
                <div className="detail_field-data">
                  <Select
                    name="fromAccountId"
                    options={arrPreviousCompany}
                    defaultValue={
                      formik.values.fromAccountId !== null
                        ? {
                            value: formik.values.fromAccountId,
                            label: movementEdit.fromAccount.name,
                          }
                        : ""
                    }
                    onChange={(e) => {
                      if (e.value === formik.values.toAccountId) {
                        formik.setFieldError(
                          "fromAccountId",
                          "From account and To account cannot be the same"
                        );
                        setCheckAccount(e.value);
                      } else {
                        formik.setFieldValue("fromAccountId", e.value);
                        setCheckAccount(null);
                      }
                    }}
                    isDisabled={movementEdit.isDeleted === 1 ? true : false}
                    className={
                      formik.errors.fromAccountId ? "error_select" : ""
                    }
                  />
                  {formik.errors.fromAccountId ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.fromAccountId}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>TO stock:</p>
                </div>
                <div className="detail_field-data">
                  <Select
                    name="toAccountId"
                    options={arrCurrentCompany}
                    defaultValue={
                      formik.values.toAccountId !== null
                        ? {
                            value: formik.values.toAccountId,
                            label: movementEdit.toAccount.name,
                          }
                        : ""
                    }
                    onChange={(e) => {
                      if (e.value === formik.values.fromAccountId) {
                        formik.setFieldError(
                          "toAccountId",
                          "From account and To account cannot be the same"
                        );
                        setCheckAccount(e.value);
                      } else {
                        formik.setFieldValue("toAccountId", e.value);
                        setCheckAccount(null);
                      }
                    }}
                    isDisabled={movementEdit.isDeleted === 1 ? true : false}
                    className={formik.errors.toAccountId ? "error_select" : ""}
                  />
                  {formik.errors.toAccountId ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.toAccountId}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Purchase product:</p>
                </div>
                <div className="detail_field-data">
                  <Select
                    name="purchaseProductId"
                    options={arrValueProduct}
                    defaultValue={
                      formik.values.purchaseProductId !== null
                        ? {
                            value: formik.values.purchaseProductId,
                            label: movementEdit.device?.purchaseProduct.name,
                          }
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("purchaseProductId", e.value);
                    }}
                    isDisabled={movementEdit.isDeleted === 1 ? true : false}
                    className={
                      formik.errors.purchaseProductId ? "error_select" : ""
                    }
                  />
                  {formik.errors.purchaseProductId ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.purchaseProductId}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Movement date:</p>
                </div>
                <div className="detail_field-data">
                  <DatePicker
                    name="movementDate"
                    defaultValue={dayjs(movementEdit.movementDateShowDetail)}
                    format="DD/MM/YYYY hh:mm A"
                    allowClear={false}
                    showTime={true}
                    placeholder="Select..."
                    onChange={(date) => {
                      onChangeDatePicker(date, "movementDate", formik);
                    }}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                    className={
                      formik.touched.movementDate && formik.errors.movementDate
                        ? "error_input"
                        : ""
                    }
                  />
                  {formik.touched.movementDate && formik.errors.movementDate ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.movementDate}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Receive date:</p>
                </div>
                <div className="detail_field-data">
                  <DatePicker
                    name="receivedAt"
                    defaultValue={
                      movementEdit.receiveDateShowDetail !== null
                        ? dayjs(movementEdit.receiveDateShowDetail)
                        : null
                    }
                    format="DD/MM/YYYY hh:mm A"
                    showTime={true}
                    placeholder="Select..."
                    onChange={(date) => {
                      onChangeDatePicker(date, "receivedAt", formik);
                    }}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Invoice#:</p>
                </div>
                <div className="detail_field-data">
                  <Input
                    name="invoiceCode"
                    size="large"
                    value={formik.values.invoiceCode}
                    onChange={formik.handleChange}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Note:</p>
                </div>
                <div className="detail_field-data">
                  <Input.TextArea
                    name="notes"
                    size="medium"
                    className="notes"
                    onChange={formik.handleChange}
                    value={formik.values.notes}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>
          </ul>
          <div className="detail_action">
            <button
              type="submit"
              disabled={movementEdit.isDeleted === 1 ? true : false}
              style={{
                cursor: movementEdit.isDeleted === 1 ? "no-drop" : "pointer",
              }}
            >
              Save Change
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
