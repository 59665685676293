import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import eventTypeApi from "../../Services/eventTypeApi";
import { message } from "antd";

export const getAllEventType = createAsyncThunk(
  "eventType/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await eventTypeApi.getAll();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createNewEventType = createAsyncThunk(
  "eventType/createNew",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await eventTypeApi.addNew(data);
      dispatch(getAllEventType());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteEventType = createAsyncThunk(
  "eventType/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await eventTypeApi.delete(id);
      dispatch(getAllEventType());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateEventType = createAsyncThunk(
  "eventType/update",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await eventTypeApi.update(params);
      dispatch(getAllEventType());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const restoreEventType = createAsyncThunk(
  "eventType/restore",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await eventTypeApi.restore(id);
      dispatch(getAllEventType());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findOneEventType = createAsyncThunk(
  "eventType/findOne",
  async (id, { rejectWithValue }) => {
    try {
      const response = await eventTypeApi.findOne(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findHistoryEventType = createAsyncThunk(
  "eventType/findHistory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await eventTypeApi.findHistory(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const eventTypeReducer = createSlice({
  name: "eventTypeReducer",
  initialState: {
    arrayEventType: [],
    eventTypeEdit: {},
    errorMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEventType.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            item.description =
              item.description !== null ? item.description : "";
            newData.push(item);
          }
        }
        state.arrayEventType = newData;
        state.errorMessage = null;
      })
      .addCase(getAllEventType.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(createNewEventType.fulfilled, (state) => {
        state.errorMessage = null;
        message.success("Create Event type success", 3);
      })
      .addCase(createNewEventType.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(deleteEventType.fulfilled, (state) => {
        state.errorMessage = null;
        message.success("Delete Event type success", 3);
      })
      .addCase(deleteEventType.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(findOneEventType.fulfilled, (state, action) => {
        let data = action.payload.data;
        data.key = data.id;
        data.description = data.description !== null ? data.description : "";
        state.eventTypeEdit = data;
        state.errorMessage = null;
      })
      .addCase(findOneEventType.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(updateEventType.fulfilled, (state, action) => {
        let data = action.payload.data;
        data.key = data.id;
        data.description = data.description !== null ? data.description : "";
        state.eventTypeEdit = data;
        state.errorMessage = null;
        message.success("Update Event type success", 3);
      })
      .addCase(updateEventType.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(restoreEventType.fulfilled, (state, action) => {
        let data = action.payload.data;
        data.key = data.id;
        data.description = data.description !== null ? data.description : "";
        state.eventTypeEdit = data;
        state.errorMessage = null;
        message.success("Restore Event type success", 3);
      })
      .addCase(restoreEventType.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
  },
});

export const {} = eventTypeReducer.actions;

export default eventTypeReducer.reducer;
