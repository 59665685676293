import axiosClient from "./axiosClient";

const billingPolicyApi = {
  getAllBillingPolicy: async () => {
    const response = await axiosClient.get("/billing-policy");
    return response;
  },
  addBillingPolicy: async (data) => {
    const response = await axiosClient.post("/billing-policy", data);
    return response;
  },
  updateBillingPolicy: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/billing-policy/" + id, data);
    return response;
  },
  deleteBillingPolicy: async (id) => {
    const response = await axiosClient.delete("/billing-policy/" + id);
    return response;
  },
  restoreBillingPolicy: async (id) => {
    const response = await axiosClient.put("/billing-policy/restore/" + id);
    return response;
  },
  findOne: async (id) => {
    const response = await axiosClient.get("/billing-policy/" + id);
    return response;
  },
};

export default billingPolicyApi;
