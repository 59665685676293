import axiosClient from "./axiosClient";

const movementApi = {
  getAllMovement: async (params) => {
    let url = "/movement";
    if (params) {
      const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");
      url = `${url}?${queryString}`;
    }
    const response = await axiosClient.get(url);
    return response;
  },
  createMovement: async (data) => {
    const response = await axiosClient.post("/movement", data);
    return response;
  },
  createBulkMovement: async (data) => {
    const response = await axiosClient.post("/movement/bulk", data);
    return response;
  },
  findMovementByDeviceId: async (id) => {
    const response = await axiosClient.get("/movement/device-id/" + id);
    return response;
  },
  findMovementByESN: async (esn, params = {}) => {
    let url = `/movement/device-esn/${esn}`;
    if (params.limit && params.page) {
      url += `?limit=${params.limit}&page=${params.page}`;
    }
    const response = await axiosClient.get(url);
    return response;
  },
  updateMovement: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/movement/" + id, data);
    return response;
  },
  deleteMovement: async (id) => {
    const response = await axiosClient.delete("/movement/" + id);
    return response;
  },
  latestMovement: async (id) => {
    const response = await axiosClient.get("/movement/latest/" + id);
    return response;
  },
  newTransactionMovement: async (data) => {
    const response = await axiosClient.post("/movement/new-stock", data);
    return response;
  },
  returnStockMovement: async (data) => {
    const response = await axiosClient.post("/movement/return", data);
    return response;
  },
  findTotal: async () => {
    const response = await axiosClient.get("/movement/total");
    return response;
  },
  findTotalMovementByYear: async (year) => {
    const response = await axiosClient.get("/movement/monthly-graph/" + year);
    return response;
  },
  findAllMovementToday: async () => {
    const response = await axiosClient.get("/movement/today");
    return response;
  },
  findOneMovement: async (id) => {
    const response = await axiosClient.get("/movement/" + id);
    return response;
  },
  warrantyMovement: async (data) => {
    const response = await axiosClient.post("/movement/warranty", data);
    return response;
  },
  returnAfterWarrantyMovement: async (data) => {
    const response = await axiosClient.post("/movement/return-after-warranty", data);
    return response;
  },
  toBeReturn: async (data) => {
    const response = await axiosClient.post("/movement/to-be-returned", data);
    return response;
  },
};

export default movementApi;
