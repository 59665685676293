import axiosClient from "./axiosClient";

const deviceStateApi = {
  addDeviceState: async (data) => {
    const response = await axiosClient.post("/device-state", data);
    return response;
  },
  getAllDeviceState: async () => {
    const response = await axiosClient.get("/device-state");
    return response;
  },
  deleteDeviceState: async (id) => {
    const response = await axiosClient.delete("/device-state/" + id);
    return response;
  },
  updateDeviceState: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/device-state/" + id, data);
    return response;
  },
  restoreDeviceState: async (id) => {
    const response = await axiosClient.put("/device-state/restore/" + id);
    return response;
  }
};

export default deviceStateApi;
