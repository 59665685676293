import { convertTimeToUtc } from "./convertToUtcTime";

export const onChangeDatePicker = (date, field, action) => {
  if (date !== null) {
    let month = date.$M + 1;
    let day = date.$D;
    let hour = date.$H;
    let minute = date.$m;
    const year = date.$y;
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minute < 10) {
      minute = "0" + minute;
    }
    const dateSend = convertTimeToUtc(
      `${year}-${month}-${day}T${hour}:${minute}`
    );
    action.setFieldValue(field, dateSend);
  } else {
    action.setFieldValue(field, null);
  }
};
