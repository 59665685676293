import React from "react";
import MovementColumnBody from "./MovementColumnBody";
import { Tooltip } from "antd";

export default function MovementColumnSelect({ column }) {
  return (
    <div className="movement_column-item" id="selected">
      <p>
        Selected columns
        <Tooltip
          title="The order of displaying columns in the report (from left to right) is similar to the arrangement in this section (from top to bottom)"
          color="#2962ff"
        >
          <i className="fa fa-exclamation-circle"></i>
        </Tooltip>
      </p>
      <div className="movement_column-body">
        {column.map((item, index) => {
          if (item.status === "selected") {
            return <MovementColumnBody item={item} id={item.id} key={index}/>;
          }
        })}
      </div>
    </div>
  );
}
