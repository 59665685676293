export const compareObject = (object1, object2) => {
  if (object1) {
    const keys1 = Object.keys(object1);
    for (let key of keys1) {
      if (key === "movementDate") {
        if (object1[key] !== object2["movementDateShowDetail"]) {
          return false;
        }
      } else if (key === "startBillDate") {
        if (object1[key] !== object2["startBillDateShowDetail"]) {
          return false;
        }
      } else if (key === "receivedAt") {
        if (object1[key] !== object2["receiveDateShowDetail"]) {
          return false;
        }
      } else if (key === "deliveredAt") {
        if (object1[key] !== object2["deliverDateShowDetail"]) {
          return false;
        }
      } else if (key === "currentMovementDate") {
        if (object1[key] !== object2["currentMovementDateDetail"]) {
          return false;
        }
      } else if (key === "purchaseProductId") {
        if (object1[key] !== object2.device["purchaseProductId"]) {
          return false;
        }
      } else if (key === "supportedAt") {
        if (object1[key] !== object2["supportedDateShowDetail"]) {
          return false;
        }
      } else if (key === "devices") {
        const arrayDeviceId = [];
        for (let i = 0; i < object2.devices.length; i++) {
          const itemDevice = object2.devices[i];
          arrayDeviceId.push(itemDevice.deviceId);
        }
        if (JSON.stringify(object1.devices) !== JSON.stringify(arrayDeviceId)) {
          return false;
        }
      } else {
        if (object1[key] !== object2[key]) {
          return false;
        }
      }
    }
    return true;
  }
};
