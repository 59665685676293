import React from "react";
import { Input, message } from "antd";
import "../../Assets/scss/Component/Platform/_platformStatusDetail.scss";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { updateBillingGroup } from "../../Redux/billingGroupReducer/billingGroupReducer";

export default function BillingGroupDetail(props) {
  const { billingGroupEdit } = useSelector(
    (state) => state.billingGroupReducer
  );
  const dispatch = useDispatch();
  const platformStatusDetailInformation = useFormik({
    initialValues: {
      name: billingGroupEdit.name,
      description: billingGroupEdit.description,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      if (
        values.name === billingGroupEdit.name &&
        values.description === billingGroupEdit.description
      ) {
        message.warning("Nothing has changed with this billing group");
      } else {
        const dataSend = {
          id: billingGroupEdit.id,
          data: values,
        };
        const action = updateBillingGroup(dataSend);
        dispatch(action);
      }
    },
  });
  return (
    <form
      className="status_detail"
      onSubmit={platformStatusDetailInformation.handleSubmit}
    >
      <div className="status_item">
        <p>Name*</p>
        <Input
          name="name"
          onChange={platformStatusDetailInformation.handleChange}
          onBlur={platformStatusDetailInformation.handleBlur}
          value={platformStatusDetailInformation.values.name}
          size="large"
          className={
            platformStatusDetailInformation.errors.name ? "error_input" : ""
          }
        />
        {platformStatusDetailInformation.errors.name ? (
          <div className="error_message">
            <i className="fa fa-exclamation-circle"></i>
            <span className="ms_errors">
              {platformStatusDetailInformation.errors.name}
            </span>
          </div>
        ) : null}
      </div>
      <div className="status_item">
        <p>Description</p>
        <Input.TextArea
          name="description"
          onChange={platformStatusDetailInformation.handleChange}
          onBlur={platformStatusDetailInformation.handleBlur}
          value={platformStatusDetailInformation.values.description}
          size="large"
        />
      </div>
      <div className="status_action">
        <button
          type="button"
          className="status_action-cancel"
          onClick={props.handleCancelStatus}
        >
          Cancel
        </button>
        <button type="submit" className="status_action-submit">
          Save Change
        </button>
      </div>
    </form>
  );
}
