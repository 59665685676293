import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { store } from "./Redux/configStore";
import { Provider } from "react-redux";
export const history = createBrowserHistory();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div>
    <Provider store={store}>
        <HistoryRouter history={history}>
          <App />
        </HistoryRouter>
    </Provider>
  </div>
);
