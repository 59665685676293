import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { Input, message } from "antd";
import Select from "react-select";
import { addDiscount } from "../../Redux/companyReducer/companyReducer";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";

export default function CreateDiscount({ handleCancelCreate }) {
  const dispatch = useDispatch();
  const [arrWirelessTechSelect, setArrWirelessTechSelect] = useState([]);
  const { arrWirelessTech } = useSelector((state) => state.wirelessTechReducer);
  const { companyEdit } = useSelector((state) => state.companyReducer);

  // Handle form
  const discountInformation = useFormik({
    initialValues: {
      wirelessTechnologyId: null,
      afterMonth: null,
      price: null,
    },
    validationSchema: yup.object().shape({
      wirelessTechnologyId: yup
        .mixed()
        .required("Wireless technology is required"),
      afterMonth: yup.mixed().required("After month is required"),
      price: yup.mixed().required("Price is required"),
    }),
    onSubmit: (values) => {
      const dataSend = { ...values, accountId: companyEdit.id };
      const action = addDiscount(dataSend);
      dispatch(action);
      message.loading("loading", 1);
      handleCancelCreate();
    },
  });

  useEffect(() => {
    CreateDataForSelect(arrWirelessTech, setArrWirelessTechSelect);
  }, [arrWirelessTech]);
  return (
    <form onSubmit={discountInformation.handleSubmit}>
      <div className="create_user">
        <div className="user_field">
          <p>Wireless technology*</p>
          <Select
            name="wirelessTechnologyId"
            options={arrWirelessTechSelect}
            onChange={(e) => {
              discountInformation.setFieldValue(
                "wirelessTechnologyId",
                e.value
              );
            }}
            className={
              discountInformation.errors.wirelessTechnologyId
                ? "error_select"
                : ""
            }
          />

          {discountInformation.errors.wirelessTechnologyId ? (
            <div className="error_message">
              <i className="fa fa-exclamation-circle"></i>
              <span className="cm_errors">
                {discountInformation.errors.wirelessTechnologyId}
              </span>
            </div>
          ) : null}
        </div>
        <div className="user_field">
          <p>After month*</p>
          <Input
            name="afterMonth"
            type="number"
            min={0}
            placeholder="0"
            className={
              discountInformation.errors.afterMonth ? "error_input" : ""
            }
            onChange={discountInformation.handleChange}
            onBlur={discountInformation.handleBlur}
            value={discountInformation.values.afterMonth}
            size="large"
          />
          {discountInformation.errors.afterMonth ? (
            <div className="error_message">
              <i className="fa fa-exclamation-circle"></i>
              <span className="cm_errors">
                {discountInformation.errors.afterMonth}
              </span>
            </div>
          ) : null}
        </div>
        <div className="user_field">
          <p>Price*</p>
          <Input
            name="price"
            type="number"
            min={0}
            step={0.1}
            placeholder="0"
            className={discountInformation.errors.price ? "error_input" : ""}
            onChange={discountInformation.handleChange}
            onBlur={discountInformation.handleBlur}
            value={discountInformation.values.price}
            size="large"
          />
          {discountInformation.errors.price ? (
            <div className="error_message">
              <i className="fa fa-exclamation-circle"></i>
              <span className="cm_errors">
                {discountInformation.errors.price}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="form_action">
        <button
          type="button"
          className="cancel"
          onClick={() => {
            handleCancelCreate();
          }}
        >
          Cancel
        </button>
        <button type="submit" className="create">
          Create
        </button>
      </div>
    </form>
  );
}
