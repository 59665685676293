import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Table,
  Space,
  Modal,
  Popconfirm,
  Tabs,
  Popover,
  Tooltip,
  FloatButton,
  Typography,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/scss/Pages/User/_user.scss";
import {
  SearchOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { storage } from "../../Utils/storage";
import ReactDragListView from "react-drag-listview";
import { showHideDeletedItem } from "../../Utils/showHideDeletedItem";
import {
  deleteWirelessTechApi,
  findOneWirelessTechApi,
  getAllWirelessTechApi,
} from "../../Redux/wirelessTechReducer/wirelessTechReducer";
import CreateWirelessTech from "../../Components/Product/CreateWirelessTech";
import WirelessTechDetail from "../../Components/Product/WirelessTechDetail";
import {
  CURRENT_PAGE,
  DEFAULT_PAGESIZE,
} from "../../Utils/ConstCommon/constCommon";
import ContentShowColumn from "../../Utils/ComponentCommon/ContentShowColumn";
import ContentShowHideDeleted from "../../Utils/ComponentCommon/ContentShowHideDeleted";
import { renderShowColumn } from "../../Utils/renderShowColumn";

export default function WirelessTechnology() {
  const [arrSelect, setArrSelect] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const { arrWirelessTech, wirelessTechEdit } = useSelector(
    (state) => state.wirelessTechReducer
  );
  const [currentPagination, setCurrentPagination] = useState(CURRENT_PAGE);
  const pageSizeRef = useRef(DEFAULT_PAGESIZE);
  const [showDeleted, setShowDeleted] = useState();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (text, record) => (
        <span
          className="open_detail"
          onClick={async () => {
            await dispatch(findOneWirelessTechApi(record.id));
            showModalDetail();
          }}
        >
          {text}
        </span>
      ),
      sorter: (beforeItem, afterItem) =>
        beforeItem.name.toLowerCase() > afterItem.name.toLowerCase()
          ? 1
          : beforeItem.name.toLowerCase() === afterItem.name.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Short name",
      dataIndex: "shortName",
      key: "shortName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.shortName.toLowerCase() > afterItem.shortName.toLowerCase()
          ? 1
          : beforeItem.shortName.toLowerCase() ===
            afterItem.shortName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.description?.toLowerCase() >
        afterItem.description?.toLowerCase()
          ? 1
          : beforeItem.description?.toLowerCase() ===
            afterItem.description?.toLowerCase()
          ? 0
          : -1,
      render: (text) => (
        <Tooltip title={text} color="#2962ff">
          <Typography.Paragraph ellipsis={{ rows: 1 }}>
            {text}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "80px",
      render: (_, record) => (
        <Space size="middle" className="user_action">
          <Popconfirm
            title="Delete Wireless Technology"
            placement="leftBottom"
            description="Are you sure to delete this wireless technology?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              const action = deleteWirelessTechApi(record.id);
              dispatch(action);
              message.loading("Loading", 1);
            }}
          >
            <button>
              <DeleteOutlined className="user_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  let [columnSelected, setColumnSelected] = useState(columns);
  for (let index = 0; index < columnSelected.length; index++) {
    let item = columnSelected[index];
    item.filteredValue = [searchValue];
  }

  // Show/ Hide column
  useEffect(() => {
    renderShowColumn(
      columns,
      "arrWirelessShowColumn",
      "arrayWirelessRelocateTitleColumn",
      "arrayWirelessRelocateTitleColumnNew",
      setColumnSelected
    );
    document.title = "Tag Register - Wireless Technology";
  }, []);

  const resetAll = () => {
    storage.removeStorage("arrWirelessShowColumn");
    storage.removeStorage("arrayWirelessRelocateTitleColumn");
    storage.removeStorage("arrayWirelessRelocateTitleColumnNew");
    setColumnSelected(columns);
  };

  // Drag and drop column
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newFromIndex = fromIndex - 1;
      const newToIndex = toIndex - 1;
      const newColumns = [...columnSelected];
      const item = newColumns.splice(newFromIndex, 1)[0];
      newColumns.splice(newToIndex, 0, item);
      setColumnSelected(newColumns);
      let arrRelocate = newColumns.map((item) => {
        return item.key;
      });
      storage.setStorageJson("arrayWirelessRelocateTitleColumn", arrRelocate);
    },
    nodeSelector: "th",
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setArrSelect(selectedRows);
      return (
        `selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows
      );
    },
  };

  // Handle modal
  const showModalCreate = () => {
    setOpenCreate(true);
  };
  const showModalDetail = () => {
    setOpenDetail(true);
  };
  const handleCancelCreate = () => {
    setOpenCreate(false);
  };
  const handleCancelDetail = () => {
    setOpenDetail(false);
  };

  // Detail item
  const items = [
    {
      key: "1",
      label: `Wireless technology details`,
      children: <WirelessTechDetail handleCancelDetail={handleCancelDetail} />,
    },
    {
      key: "2",
      label: `History`,
      children: <p>Coming soon</p>,
    },
  ];

  // Call api
  useEffect(() => {
    const action = getAllWirelessTechApi();
    dispatch(action);
  }, [dispatch]);

  // Recall api
  useEffect(() => {
    const reCallApi = setInterval(() => {
      dispatch(getAllWirelessTechApi());
    }, 10000);
    return () => clearInterval(reCallApi);
  }, []);

  // Content of popover
  const content = (
    <ContentShowColumn
      columns={columns}
      storageShow="arrWirelessShowColumn"
      storageRelocate="arrayWirelessRelocateTitleColumn"
      storageRelocateNew="arrayWirelessRelocateTitleColumnNew"
      action={setColumnSelected}
      resetAll={resetAll}
    />
  );

  // Show/hide selected
  const onChangeShowDeleted = (checked) => {
    showHideDeletedItem(checked, arrWirelessTech, setData, "showDeletedSale");
    setShowDeleted(checked);
  };
  useEffect(() => {
    const isShow = storage.getStore("showDeletedSale");
    if (isShow === undefined || isShow === "true") {
      setData(arrWirelessTech);
      setShowDeleted(true);
    } else {
      const arrayData = arrWirelessTech.filter((item) => item.isDeleted !== 1);
      setData(arrayData);
      setShowDeleted(false);
    }
  }, [arrWirelessTech]);

  const contentAction = (
    <ContentShowHideDeleted
      onChangeShowDeleted={onChangeShowDeleted}
      showDeleted={showDeleted}
      arrayData={arrWirelessTech}
      columnSelected={columnSelected}
      pageName="WirelessTechnology"
      content={content}
    />
  );
  return (
    <div className="user">
      <div className="user_top">
        <div className="user_left">
          <p>Wireless Technology</p>
        </div>
      </div>
      <div className="user_body">
        <div className="user_show">
          <div className="user_showChild">
            <div className="user_showLeft">
              <p>
                Selected: <strong>{arrSelect.length}</strong>
              </p>
              <button
                onClick={() => {
                  showModalCreate();
                }}
                className="user_button-create"
              >
                + New
              </button>
              <Popover
                content={contentAction}
                trigger="click"
                placement="right"
              >
                <button className="button-second">
                  Action <i className="fa fa-caret-down"></i>
                </button>
              </Popover>
            </div>
            <div className="user_showRight">
              <div className="search_area">
                <SearchOutlined className="search_icon" />
                <Input
                  className="search_box"
                  placeholder="Search..."
                  allowClear
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="user_table">
          <ReactDragListView.DragColumn {...dragProps}>
            <Table
              rowSelection={rowSelection}
              columns={[...columnSelected]}
              dataSource={data}
              rowClassName={(record) =>
                record.isDeleted === 1 ? "deleted" : ""
              }
              pagination={{
                defaultPageSize: 10,
                current: currentPagination,
                showSizeChanger: true,
                showTotal: (total, range) => (
                  <p>
                    Showing{" "}
                    <strong>
                      {range[0]}-{range[1]}
                    </strong>{" "}
                    of <strong>{total}</strong> items
                  </p>
                ),
                onChange: (newCurrent, newPageSize) => {
                  const pageSizeChange = pageSizeRef.current !== newPageSize;
                  if (pageSizeChange) {
                    setCurrentPagination(1);
                  } else {
                    setCurrentPagination(newCurrent);
                  }
                  pageSizeRef.current = newPageSize;
                },
              }}
              bordered={true}
              size="middle"
              sticky
            />
          </ReactDragListView.DragColumn>
        </div>
      </div>
      <Modal
        title="Create Wireless Technology"
        open={openCreate}
        onCancel={handleCancelCreate}
        footer={null}
        width={500}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateWirelessTech handleCancelCreate={handleCancelCreate} />
      </Modal>
      <Modal
        title={"Wireless technology ID: " + wirelessTechEdit.id}
        open={openDetail}
        onCancel={handleCancelDetail}
        footer={null}
        width={600}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          size="small"
          className="user_tab"
          destroyInactiveTabPane={true}
        />
      </Modal>
      <FloatButton.BackTop />
    </div>
  );
}
