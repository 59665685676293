import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import timeZoneApi from "../../Services/timeZoneApi";
import accountApi from "../../Services/accountApi";
import { message } from "antd";

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await accountApi.getUserProfile();
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllTimeZone = createAsyncThunk(
  "timezone/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await timeZoneApi.getAllTimeZone();
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAccount = createAsyncThunk(
  "account/updateProfile",
  async (params, { rejectWithValue }) => {
    try {
      const response = await accountApi.updateProfile(params);
      setTimeout(() => {
        message.success("Update profile success!");
      }, 1000);
      return response;
    } catch (error) {
      message.error("Update profile failed! Please try again");
    }
  }
);

export const updateAccountTimeZone = createAsyncThunk(
  "account/updateTimeZone",
  async (params, { rejectWithValue }) => {
    try {
      const response = await accountApi.updateTimeZone(params);
      setTimeout(() => {
        message.success("Update timezone success!");
      }, 1000);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changeAccountPassword = createAsyncThunk(
  "account/changeAccountPassword",
  async (params) => {
    try {
      const response = await accountApi.updatePassword(params);
      setTimeout(() => {
        message.success("Change password success!");
      }, 1000);
      return response;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

const accountSlice = createSlice({
  name: "accountReducer",
  initialState: {
    arrUserProfile: [],
    arrTimeZone: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.arrUserProfile = action.payload.data;
      })
      .addCase(getAllTimeZone.fulfilled, (state, action) => {
        state.arrTimeZone = action.payload.data;
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        state.arrUserProfile = action.payload.data;
      })
      .addCase(updateAccountTimeZone.fulfilled, (state, action) => {
        state.arrUserProfile = action.payload.data;
      });
  },
});

export default accountSlice.reducer;
