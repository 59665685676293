import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import columnApi from "../../Services/columnApi";

const initialState = {
  arrayColumn: [],
};

export const getAllColumnApi = createAsyncThunk(
  "column/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await columnApi.getColumn();
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const columnReducer = createSlice({
  name: "columnReducer",
  initialState,
  reducers: {},
  extraReducers:(builder)=>{
    builder
    .addCase(getAllColumnApi.fulfilled,(state,action)=>{
        let data = action.payload.data
        state.arrayColumn = data
    })
  }
});

export const {} = columnReducer.actions;

export default columnReducer.reducer;
