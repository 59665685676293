import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { changeAccountPassword } from "../../Redux/accountReducer/accountReducer";
import { Input, message } from "antd";
import "../../Assets/scss/Component/Account/_accountPassword.scss";

const validationSchema = yup.object({
  currentPassword: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Please enter current password"),
  newPassword: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Please enter new password"),
  confirmPassword: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Please enter confirm password"),
});

const AccountPassword = () => {
  const [showIconPassword, setShowIconPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const handleShowPassowrd = () => {
    setShowIconPassword(!showIconPassword);
    setShowPassword(!showPassword);
  };

  const handleCancelPassword = () => {
    setShowPassword(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.newPassword !== values.confirmPassword) {
        message.warning("New passwords must be same");
      }
      if (values.newPassword === values.confirmPassword) {
        const data = {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        };
        dispatch(changeAccountPassword(data));
        message.loading("loading", 1);
      }
    },
  });

  return (
    <div className="account_password">
      <div className="account_password-change">
        <button onClick={() => handleShowPassowrd()}>
          <div className="account_password-show">
            <p>Change Password</p>
            {showIconPassword === false ? (
              <i className="fas fa-caret-down"></i>
            ) : (
              <i className="fas fa-caret-up"></i>
            )}
          </div>
        </button>
      </div>
      {showPassword === true ? (
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <div className="account_password-password">
            <div className="account_password-field">
              <p>Current Password:</p>
              <div className="account_password-input">
                <Input.Password
                  className={
                    formik.touched.currentPassword &&
                    formik.errors.currentPassword
                      ? "account_password-errInput"
                      : "account_password-inputPassword"
                  }
                  size="large"
                  name="currentPassword"
                  value={formik.values.currentPassword}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />

                {formik.touched.currentPassword &&
                formik.errors.currentPassword ? (
                  <div className="account_password-err">
                    <i className="fas fa-exclamation-circle"></i>
                    <span>{formik.errors.currentPassword}</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="account_password-field">
              <p>New Password:</p>
              <div className="account_password-input">
                <Input.Password
                  className={
                    formik.touched.newPassword && formik.errors.newPassword
                      ? "account_password-errInput"
                      : "account_password-inputPassword"
                  }
                  size="large"
                  name="newPassword"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <div className="account_password-err">
                    <i className="fas fa-exclamation-circle"></i>
                    <span>{formik.errors.newPassword}</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="account_password-field">
              <p>Confirm Password:</p>
              <div className="account_password-input">
                <Input.Password
                  className={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                      ? "account_password-errInput"
                      : "account_password-inputPassword"
                  }
                  size="large"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div className="account_password-err">
                    <i className="fas fa-exclamation-circle"></i>
                    <span>{formik.errors.confirmPassword}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="account_password-action">
            <button className="account_password-update" type="submit">
              Save change
            </button>
            <button
              className="account_password-cancel"
              type="button"
              onClick={() => {
                handleCancelPassword();
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      ) : (
        ""
      )}
    </div>
  );
};

export default AccountPassword;
