import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function Notice() {
  useEffect(()=>{
    document.title = "Tag Register - Forgot password";
  },[])
  return (
    <div className="forgot">
      <div className="forgot_body">
        <div className="forgot_content">
          <div className="forgot_avatar">
            <i className="fas fa-user"></i>
          </div>

          <div className="forgot_text">
            <p>Reset Password</p>
          </div>

          <div className="forgot_note">
            <p className="forgot_note-success">Your password has been successfully changed</p>
          </div>

          <div className="notice_submit">
            <button type="submit">
              <NavLink to="/sign-in">Back to Login</NavLink>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
