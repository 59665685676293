import { renderShowColumn } from "./renderShowColumn";
import { storage } from "./storage";

export const toggleButtonClass = (e,columns,storageShow, storageRelocate, storageRelocateNew,action) => {
    if (e.target.className === "active") {
      e.target.classList.remove("active");
    } else {
      e.target.classList.add("active");
    }
    const checkedValues = e.target.value;
    const arrCheck = storage.getStorageJson(storageShow);
    if (arrCheck && arrCheck.length !== 0) {
      const data = arrCheck.includes(checkedValues);
      if (data === true) {
        const arrNew = arrCheck.filter((item) => item !== checkedValues);
        storage.setStorageJson(storageShow, arrNew);
      } else {
        arrCheck.push(checkedValues);
        storage.setStorageJson(storageShow, arrCheck);
      }
      renderShowColumn(columns,storageShow, storageRelocate, storageRelocateNew,action);
    }
    if (arrCheck && arrCheck.length === 0) {
      arrCheck.push(checkedValues);
      storage.setStorageJson(storageShow, arrCheck);
      renderShowColumn(columns,storageShow, storageRelocate, storageRelocateNew,action);
    }
    if (arrCheck === undefined) {
      let data = columns.filter((item) => item.key !== checkedValues);
      let arrShowColumn = data.map((item) => {
        return item.key;
      });
      storage.setStorageJson(storageShow, arrShowColumn);
      renderShowColumn(columns,storageShow, storageRelocate, storageRelocateNew,action);
    }
  };