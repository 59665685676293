import React, { useEffect, useRef, useState } from "react";
import "../../Assets/scss/Component/DatePickerMonthYear/_datePickerMonthYear.scss";
import { useDispatch } from "react-redux";
import { getTotalMovementByYear } from "../../Redux/movementReducer/movementReducer";

const DashboardDatePicker = ({
  valueShowInput,
  setValueShowInput,
  yearLocal,
}) => {
  const dispatch = useDispatch();
  const menuRef = useRef();
  const [showBoardYear, setShowBoardYear] = useState(false);
  const input = document.querySelector(".picker_content");
  const handleClick = () => {
    input.focus();
    setShowBoardYear(true);
  };
  let getYear = [];
  for (let i = 2000; i < yearLocal + 1; i++) {
    getYear.push(i);
  }

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setShowBoardYear(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div className="picker" ref={menuRef}>
      <div className="picker_content" onClick={handleClick}>
        <input className="picker_input" value={valueShowInput} readOnly />
        <i className="fa-regular fa-calendar"></i>
      </div>
      <div
        className={`picker_dropdown ${
          showBoardYear === true ? "" : "off-year"
        }`}
      >
        <div className="picker_value">
          <div className="picker_year">
            {getYear.reverse().map((item) => {
              return (
                <div
                  key={item}
                  className={`picker_year-item ${
                    item === valueShowInput ? "picker_year-active" : ""
                  }`}
                  onClick={() => {
                    setValueShowInput(item);
                    dispatch(getTotalMovementByYear(item));
                    setShowBoardYear(false);
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardDatePicker;
