import { storage } from "./storage";

export const renderShowColumn = (columns,storageShow, storageRelocate, storageRelocateNew,action) => {
    const arrColumnStorage = storage.getStorageJson(storageShow);
    const arrRelocateStorage = storage.getStorageJson(
        storageRelocate
    );

    if (arrColumnStorage && arrRelocateStorage) {
      let newArr = [];
      for (let i = 0; i < arrRelocateStorage.length; i++) {
        const item = arrRelocateStorage[i];
        const itemFind = arrColumnStorage.find((obj) => obj === item);
        if (itemFind !== undefined) {
          newArr.push(itemFind);
        }
        storage.setStorageJson(storageRelocateNew, newArr);
      }
    }

    if (arrRelocateStorage) {
      let arrRelocate = [];
      for (let i = 0; i < arrRelocateStorage.length; i++) {
        const item = arrRelocateStorage[i];
        const itemFind = columns.find((obj) => obj.key === item);
        if (itemFind !== undefined) {
          arrRelocate.push(itemFind);
        }
      }
      action(
        arrRelocate.filter((item) => arrRelocateStorage.includes(item.key))
      );
    }

    if (arrColumnStorage) {
      action(
        columns.filter((item) => arrColumnStorage.includes(item.key))
      );
    }

    const arrRelocateTitleColumnNew = storage.getStorageJson(
      storageRelocateNew
    );

    if (
      arrRelocateTitleColumnNew &&
      arrColumnStorage &&
      arrRelocateTitleColumnNew.length === arrColumnStorage.length
    ) {
      let arrRelocate = [];
      for (let i = 0; i < arrRelocateTitleColumnNew.length; i++) {
        const item = arrRelocateTitleColumnNew[i];
        const itemFind = columns.find((obj) => obj.key === item);
        if (itemFind !== undefined) {
          arrRelocate.push(itemFind);
        }
      }
      action(
        arrRelocate.filter((item) =>
          arrRelocateTitleColumnNew.includes(item.key)
        )
      );
    } else {
      return columns;
    }
  };
