import { Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import FormDaily from "./FormDaily";
import FormWeekly from "./FormWeekly";
import FormMonthly from "./FormMonthly";
import "../../Assets/scss/Component/User/_movementReport.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllColumnApi } from "../../Redux/columnReducer/columnReducer";
import MovementColumnRows from "./MovementColumnRows";
import { useFormik } from "formik";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import objectSupport from "dayjs/plugin/objectSupport.js";
import { updateUserApi } from "../../Redux/userReducer/userReducer";
dayjs.extend(utc);
dayjs.extend(objectSupport);

export default function MovementReport() {
  const dispatch = useDispatch();
  const { userEdit } = useSelector((state) => state.userReducer);
  const [item, setItem] = useState("");
  const [time, setTime] = useState(userEdit.reportSendAt);
  const [selected, setSelected] = useState(null);
  const [column, setColumn] = useState([]);
  const { arrayColumn } = useSelector((state) => state.columnReducer);
  const arrayTrigger = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
  ];

  // Call api
  useEffect(() => {
    dispatch(getAllColumnApi());
  }, []);

  // Handle form
  const reportDetail = useFormik({
    initialValues: {
      enableReport: userEdit.enableReport,
      reportTrigger: userEdit.reportTrigger,
      reportSendAt: time,
      daysOfWeek: userEdit.daysOfWeek,
      daysOfMonth: userEdit.daysOfMonth,
      reportColumns: userEdit.reportColumns,
    },
    onSubmit: (values) => {
      values.reportSendAt = time;
      if (selected !== null) {
        values.reportColumns = selected.toString();
      } else {
        values.reportColumns = null;
      }
      const newValues = { ...values };
      if (
        newValues.enableReport === userEdit.enableReport &&
        newValues.reportSendAt === userEdit.reportSendAt &&
        newValues.reportTrigger === userEdit.reportTrigger &&
        newValues.daysOfWeek === userEdit.daysOfWeek &&
        newValues.daysOfMonth === userEdit.daysOfMonth &&
        newValues.reportColumns === userEdit.reportColumns
      ) {
        message.warning("Nothing has changed with this user");
      } else {
        const newObject = {};
        for (let item in newValues) {
          if (newValues[item] !== userEdit[item]) {
            newObject[item] = newValues[item];
          }
        }
        const dataSend = {
          id: userEdit.id,
          data: newObject,
        };
        const action = updateUserApi(dataSend);
        dispatch(action);
        message.loading("loading", 1);
      }
    },
  });
  useEffect(() => {
    if (userEdit.reportColumns === null) {
      const arrayColumnNew = [];
      for (let index = 0; index < arrayColumn.length; index++) {
        let item = arrayColumn[index];
        item = { ...item, status: "available" };
        arrayColumnNew.push(item);
      }
      setColumn(arrayColumnNew);
    } else {
      const arrayColumnSelected = userEdit.reportColumns.split(",");
      const arrayColumnNew = [...arrayColumn];
      const arraySelectedStatus = [];
      const arrayAvailableStatus = [];
      for (let index = 0; index < arrayColumnSelected.length; index++) {
        const item = arrayColumnSelected[index];
        const arraySelectedFind = arrayColumnNew.find(
          (column) => column.id === Number(item)
        );
        if (arraySelectedFind !== undefined) {
          arraySelectedStatus.push({
            ...arraySelectedFind,
            status: "selected",
          });
        }
      }
      for (let index = 0; index < arrayColumnNew.length; index++) {
        const column = arrayColumnNew[index];
        const itemAvailable = arrayColumnSelected.find(
          (item) => Number(item) === column.id
        );

        if (itemAvailable === undefined) {
          arrayAvailableStatus.push({ ...column, status: "available" });
        }
      }
      setColumn([...arraySelectedStatus, ...arrayAvailableStatus]);
    }
  }, [arrayColumn]);

  useEffect(() => {
    setItem(userEdit.reportTrigger);
  }, [userEdit]);

  const renderForm = () => {
    switch (item) {
      case "daily": {
        return <FormDaily reportDetail={reportDetail} setTime={setTime} />;
      }
      case "weekly": {
        return <FormWeekly reportDetail={reportDetail} setTime={setTime} />;
      }
      case "monthly": {
        return <FormMonthly reportDetail={reportDetail} setTime={setTime} />;
      }
      default: {
        return;
      }
    }
  };

  return (
    <form className="movement_report" onSubmit={reportDetail.handleSubmit}>
      <div className="movement_report-row">
        <div className="row_left">
          <p>Enable/Disable:</p>
        </div>
        <div className="row_right">
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            defaultChecked={userEdit.enableReport === 0 ? false : true}
            onChange={(checked) => {
              if (checked === false) {
                reportDetail.setFieldValue("enableReport", 0);
                setItem("");
              } else {
                reportDetail.setFieldValue("enableReport", 1);
              }
            }}
          />
        </div>
      </div>
      {reportDetail.values.enableReport === 1 ? (
        <>
          <div className="movement_report-row">
            <div className="row_left">
              <p>Trigger:</p>
            </div>
            <div className="row_right">
              <Select
                options={arrayTrigger}
                onChange={(e) => {
                  if (e !== null) {
                    setItem(e.value);
                    reportDetail.setFieldValue("reportTrigger", e.value);
                  } else {
                    setItem("");
                    reportDetail.setFieldValue("reportTrigger", e);
                  }
                }}
                defaultValue={
                  userEdit.reportTrigger !== null
                    ? {
                        value: userEdit.reportTrigger,
                        label:
                          userEdit.reportTrigger.charAt(0).toUpperCase() +
                          userEdit.reportTrigger.slice(1),
                      }
                    : null
                }
              />
            </div>
          </div>
          {renderForm()}
          <div className="movement_column">
            <p>Columns displayed in the Report email:</p>
            <div className="movement_column-rows">
              <MovementColumnRows
                column={column}
                setColumn={setColumn}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          </div>
        </>
      ) : null}
      <div className="movement_action">
        <button type="submit">Save Change</button>
      </div>
    </form>
  );
}
