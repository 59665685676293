import React, { useEffect, useState } from "react";
import { storage } from "../Utils/storage";
import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "../Utils/isTokenExpried";

export default function ResponseItem(props) {
  const navigate = useNavigate();
  const token = storage.getStore("token");
  const [isLoading, setIsLoading] = useState(true);
  const [screen, setScreen] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  useEffect(() => {
    if (!token || isTokenExpired(token)) {
      storage.removeStorage("token");
      navigate("/sign-in");
    } else {
      setIsLoading(false);  
    }
  }, [token, navigate]);

  useEffect(() => {
    const handleSetScreen = () => {
      setScreen({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleSetScreen);

    return () => {
      window.removeEventListener("resize", handleSetScreen);
    };
  }, [screen.width]);

  if (isLoading) {
    return <div></div>; 
  }
  let Component = props.component;
  if (screen.width < 1200 && props.mobileComponent) {
    Component = props.mobileComponent;
  }

  return <Component />;
}
