import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import billingGroupApi from "../../Services/billingGroupApi";
const initialState = {
  arrayBillingGroup: [],
  billingGroupEdit: {},
};

export const getAllBillingGroup = createAsyncThunk(
  "billingGroup/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await billingGroupApi.getAll();
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addNewBillingGroup = createAsyncThunk(
  "billingGroup/addNew",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await billingGroupApi.addNew(data);
      setTimeout(() => {
        message.success("Create billing group success!");
      });
      dispatch(getAllBillingGroup());
      return response;
    } catch (error) {
      message.error(error.response.data.message);

      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteBillingGroup = createAsyncThunk(
  "billingGroup/delete",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await billingGroupApi.deleteOne(id);
      setTimeout(() => {
        message.success("Delete billing group success!");
      }, 1000);
      dispatch(getAllBillingGroup());
      return response;
    } catch (error) {
      message.error("Creating a new billing group failed. Please try again!");

      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateBillingGroup = createAsyncThunk(
  "billingGroup/update",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await billingGroupApi.updateOne(params);
      setTimeout(() => {
        message.success("Update billing group success!");
      }, 1000);
      dispatch(getAllBillingGroup());
      return response;
    } catch (error) {
      message.error("Update billing group failed. Please try again!");

      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const billingGroupReducer = createSlice({
  name: "billingGroupReducer",
  initialState,
  reducers: {
    editBillingGroup: (state, action) => {
      state.billingGroupEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBillingGroup.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            newData.unshift(item);
          }
        }
        state.arrayBillingGroup = newData;
      })
      .addCase(updateBillingGroup.fulfilled, (state, action) => {
        let data = action.payload.data;
        state.billingGroupEdit = data;
      });
  },
});

export const { editBillingGroup } = billingGroupReducer.actions;

export default billingGroupReducer.reducer;
