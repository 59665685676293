import React, { useEffect, useState } from "react";
import { Input, message, Modal, DatePicker, Checkbox } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompany } from "../../Redux/companyReducer/companyReducer";
import { getAllDeviceMovement } from "../../Redux/movementReducer/movementReducer";
import "../../Assets/scss/Component/Movement/_movementNewTransaction.scss";
import { convertLongToShortESN } from "../../Utils/convertESN";
import { onChangeDatePicker } from "../../Utils/onChangeDatePicker";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";
import { getAllUserApi } from "../../Redux/userReducer/userReducer";
import { getAllEventType } from "../../Redux/eventReducer/eventTypeReducer";
import {
  deleteOneEventApi,
  restoreOneEventApi,
  updateOneEventApi,
} from "../../Redux/eventReducer/eventReducer";
import dayjs from "dayjs";
import { renderUpdater } from "../../Utils/renderUpdater";
import { compareObject } from "../../Utils/compareObject";
import EventTypeDetail from "./EventTypeDetail";

const EventDetail = ({ handleCancelDetail }) => {
  const [serialList, setSerialList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [arrPreviousCompany, setArrPreviousCompany] = useState([]);
  const [arrDevice, setArrDevice] = useState([]);
  const [arrayEventTypeShow, setArrayEventTypeShow] = useState([]);
  const [arrayValue, setArrayValue] = useState([]);
  const [charge, setCharge] = useState(false);
  const [arrSupporter, setArrSupporter] = useState([]);
  const [showType, setShowType] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showRestore, setShowRestore] = useState(false);
  const dispatch = useDispatch();
  const { arrCompany } = useSelector((state) => state.companyReducer);
  const { arrMovementDevice } = useSelector((state) => state.movementReducer);
  const { eventEdit } = useSelector((state) => state.eventReducer);
  const { arrayEventType } = useSelector((state) => state.eventTypeReducer);
  const { arrUser } = useSelector((state) => state.userReducer);

  // Handle form
  const formik = useFormik({
    initialValues: {
      typeId: eventEdit.typeId,
      supporterId: eventEdit.supporterId,
      supportedAt: eventEdit.supportedDateShowDetail,
      isCharged: eventEdit.isCharged,
      cost: eventEdit.cost,
      accountId: eventEdit.accountId,
      address: eventEdit.address,
      siteName: eventEdit.siteName,
      notes: eventEdit.notes,
    },
    validationSchema: yup.object().shape({
      cost: yup.mixed().when("isCharged", {
        is: 1,
        then: yup.mixed().required("Cost is required"),
        otherwise: yup.mixed(),
      }),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      let newData = { ...values };
      newData.devices = deviceList;
      if (compareObject(newData, eventEdit)) {
        message.warning("Nothing has changed with this event");
      } else {
        const newObject = {};
        for (let item in newData) {
          if (
            item === "supportedAt" &&
            newData.supportedAt !== eventEdit.supportedDateShowDetail
          ) {
            newObject.supportedAt = newData.supportedAt;
          }
          if (item === "devices") {
            const arrayDeviceId = [];
            for (let i = 0; i < eventEdit.devices.length; i++) {
              const itemDevice = eventEdit.devices[i];
              arrayDeviceId.push(itemDevice.deviceId);
            }
            if (
              JSON.stringify(newData.devices) !== JSON.stringify(arrayDeviceId)
            ) {
              newObject.devices = newData.devices;
            }
          }
          if (
            item !== "supportedAt" &&
            item !== "devices" &&
            newData[item] !== eventEdit[item]
          ) {
            newObject[item] = newData[item];
          }
        }
        const dataSend = {
          id: eventEdit.id,
          data: newObject,
        };
        dispatch(updateOneEventApi(dataSend));
        message.loading("Loading", 0.5);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("supportedAt", eventEdit.supportedDateShowDetail);
  }, [eventEdit]);
  // Show modal add new state
  const showAddType = () => {
    setShowType(true);
  };
  const cancelAddType = () => {
    setShowType(false);
  };

  // Handle modal delete and restore
  const showModalDelete = () => {
    setShowDelete(true);
  };
  const handleOkModalDelete = () => {
    message.loading("loading", 1);
    const action = deleteOneEventApi(eventEdit.id);
    dispatch(action);
    setShowDelete(false);
    handleCancelDetail();
  };
  const handleCancelModalDelete = () => {
    setShowDelete(false);
  };
  const showModalRestore = () => {
    setShowRestore(true);
  };
  const handleOkModalRestore = () => {
    message.loading("loading", 1);
    const action = restoreOneEventApi(eventEdit.id);
    dispatch(action);
    setShowRestore(false);
  };
  const handleCancelModalRestore = () => {
    setShowRestore(false);
  };

  // Call api
  useEffect(() => {
    dispatch(getAllCompany());
    dispatch(getAllDeviceMovement());
    dispatch(getAllUserApi());
    dispatch(getAllEventType());
  }, []);

  useEffect(() => {
    let arrSelect = [];
    arrCompany.map((item) => {
      if (item.typeId === 1) {
        let obj = {
          value: item.id,
          label: item.name,
        };
        arrSelect.push(obj);
      }
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setArrPreviousCompany(arrSelect);
  }, [arrCompany]);

  useEffect(() => {
    let arrSelect = [];
    arrMovementDevice.map((item) => {
      if (item.isDeleted === 0) {
        let obj = {
          value: item.id,
          label: convertLongToShortESN(item.serialNumber),
        };
        arrSelect.push(obj);
      }
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setArrDevice(arrSelect);
  }, [arrMovementDevice]);

  useEffect(() => {
    CreateDataForSelect(arrayEventType, setArrayEventTypeShow);
  }, [arrayEventType]);

  useEffect(() => {
    let arrSelect = [];
    arrUser.map((item) => {
      if (item.isDeleted === 0) {
        let obj = {
          value: item.id,
          label: `${item.lastName} ${item.firstName}`,
        };
        arrSelect.push(obj);
      }
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setArrSupporter(arrSelect);
  }, [arrUser]);

  const renderSerialList = (e) => {
    setArrayValue(e);
    const arrayShow = [];
    const arraySend = [];
    for (let i = 0; i < e.length; i++) {
      const item = e[i].value;
      const itemFind = arrMovementDevice.find((object) => object.id === item);
      if (itemFind !== undefined) {
        const itemSend = itemFind.id;
        const itemShow = {
          deviceId: itemFind.id,
          device: { serialNumber: itemFind.serialNumber },
        };
        arrayShow.push(itemShow);
        arraySend.push(itemSend);
      }
    }
    setSerialList(arrayShow);
    setDeviceList(arraySend);
  };

  const removeItem = (data) => {
    setArrayValue(arrayValue.filter((item) => item.value !== data.deviceId));
    setSerialList(serialList.filter((item) => item.deviceId !== data.deviceId));
    setDeviceList(deviceList.filter((item) => item !== data.deviceId));
  };

  const removeAllItem = () => {
    setArrayValue([]);
    setSerialList([]);
    setDeviceList([]);
  };

  const onChangeCheckbox = (e) => {
    if (e.target.checked === true) {
      formik.setFieldValue("isCharged", 1);
    } else {
      formik.setFieldValue("isCharged", 0);
    }
    setCharge(e.target.checked);
  };

  useEffect(() => {
    const arrayShowed = [];
    const arraySent = [];
    const data = eventEdit.devices;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const itemShowed = {
        value: item.device.id,
        label: item.device.serialNumber,
      };
      const itemSent = item.device.id;
      arrayShowed.push(itemShowed);
      arraySent.push(itemSent);
    }
    setArrayValue(arrayShowed);
    setSerialList(eventEdit.devices);
    setDeviceList(arraySent);
  }, [eventEdit]);

  useEffect(() => {
    if (eventEdit.isCharged === 1) {
      setCharge(true);
    } else {
      setCharge(false);
    }
  }, [eventEdit]);

  return (
    <div className="transaction">
      <form onSubmit={formik.handleSubmit}>
        <div
          className="transaction_form"
          style={{ cursor: eventEdit.isDeleted === 1 ? "no-drop" : "" }}
        >
          <div className="transaction_top">
            <div className="transaction_right">
              <div className="transaction_item">
                <p>Account*</p>
                <Select
                  name="accountId"
                  options={arrPreviousCompany}
                  defaultValue={{
                    value: formik.values.accountId,
                    label: eventEdit.account.name,
                  }}
                  onChange={(e) => {
                    formik.setFieldValue("accountId", e.value);
                  }}
                  className={
                    formik.touched.accountId && formik.errors.accountId
                      ? "error_select"
                      : ""
                  }
                  isDisabled={eventEdit.isDeleted === 1 ? true : false}
                />
                {formik.touched.accountId && formik.errors.accountId ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.accountId}</span>
                  </div>
                ) : null}
              </div>
              <div className="transaction_item">
                <p>Event type*</p>
                <div className="transaction_item-state">
                  <div className="transaction_state-label">
                    <Select
                      name="typeId"
                      options={arrayEventTypeShow}
                      defaultValue={{
                        value: formik.values.typeId,
                        label: eventEdit.type.name,
                      }}
                      onChange={(e) => {
                        formik.setFieldValue("typeId", e.value);
                      }}
                      className={
                        formik.touched.typeId && formik.errors.typeId
                          ? "error_select"
                          : ""
                      }
                      isDisabled={eventEdit.isDeleted === 1 ? true : false}
                    ></Select>
                    {formik.touched.typeId && formik.errors.typeId ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span className="cm_errors">
                          {formik.errors.typeId}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <button
                    type="button"
                    onClick={showAddType}
                    disabled={eventEdit.isDeleted === 1 ? true : false}
                    style={{
                      cursor: eventEdit.isDeleted === 1 ? "no-drop" : "pointer",
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="transaction_item">
                <p>Supporter*</p>
                <Select
                  name="supporterId"
                  options={arrSupporter}
                  defaultValue={{
                    value: formik.values.supporterId,
                    label: `${eventEdit.supporter.lastName} ${eventEdit.supporter.firstName}`,
                  }}
                  onChange={(e) => {
                    formik.setFieldValue("supporterId", e.value);
                  }}
                  className={
                    formik.touched.supporterId && formik.errors.supporterId
                      ? "error_select"
                      : ""
                  }
                  isDisabled={eventEdit.isDeleted === 1 ? true : false}
                />
                {formik.touched.supporterId && formik.errors.supporterId ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.supporterId}</span>
                  </div>
                ) : null}
              </div>
              <div className="transaction_item">
                <p>Support date*</p>
                <DatePicker
                  name="supportedAt"
                  showTime={true}
                  allowClear={false}
                  format="DD/MM/YYYY hh:mm A"
                  placeholder="Select..."
                  defaultValue={dayjs(eventEdit.supportedDateShowDetail)}
                  onChange={(date) => {
                    onChangeDatePicker(date, "supportedAt", formik);
                  }}
                  className={
                    formik.touched.supportedAt && formik.errors.supportedAt
                      ? "error_input"
                      : ""
                  }
                  disabled={eventEdit.isDeleted === 1 ? true : false}
                />
                {formik.touched.supportedAt && formik.errors.supportedAt ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.supportedAt}</span>
                  </div>
                ) : null}
              </div>
              <div className="transaction_item">
                <div className="transaction_itemChild">
                  <div className="transaction_itemLeft">
                    <p>Charge</p>
                    <Checkbox
                      defaultChecked={eventEdit.isCharged === 1 ? true : false}
                      onChange={onChangeCheckbox}
                      disabled={eventEdit.isDeleted === 1 ? true : false}
                    >
                      Yes/No
                    </Checkbox>
                  </div>
                  <div className="transaction_itemRight">
                    {charge === true ? (
                      <div>
                        <p>Cost*</p>
                        <Input
                          name="cost"
                          size="large"
                          type="number"
                          min={0}
                          step={0.1}
                          onChange={formik.handleChange}
                          value={formik.values.cost}
                          className={
                            formik.touched.cost && formik.errors.cost
                              ? "error_input"
                              : ""
                          }
                          disabled={eventEdit.isDeleted === 1 ? true : false}
                        />
                        {formik.touched.cost && formik.errors.cost ? (
                          <div className="error_message">
                            <i className="fa fa-exclamation-circle"></i>
                            <span>{formik.errors.cost}</span>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="transaction_left">
              <div className="transaction_item">
                <div className="transaction_serial-name">
                  <>
                    <div className="transaction_title">
                      <div className="transaction_title-left">
                        <p>Choose device</p>{" "}
                      </div>
                    </div>
                    <Select
                      options={arrDevice}
                      isMulti
                      onChange={renderSerialList}
                      controlShouldRenderValue={false}
                      value={arrayValue}
                      isDisabled={eventEdit.isDeleted === 1 ? true : false}
                    ></Select>
                  </>
                </div>
              </div>
              <div className="transaction_number">
                <div className="transaction_number-name">
                  <div className="transaction_number-title">
                    <div className="transaction_number-title-left">
                      <p>Serial number list</p>
                      {serialList?.length > 0 ? (
                        <span>
                          (Total: <strong>{serialList.length}</strong>)
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="transaction_number-title-right"
                      style={{
                        cursor:
                          eventEdit.isDeleted === 1 ? "no-drop" : "pointer",
                      }}
                    >
                      {serialList?.length > 0 ? (
                        <p
                          onClick={() => {
                            removeAllItem();
                          }}
                          style={{
                            pointerEvents:
                              eventEdit.isDeleted === 1 ? "none" : "",
                          }}
                        >
                          Clear all
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div
                  className="transaction_number-list-movement"
                  style={{
                    borderColor:
                      eventEdit.isDeleted === 1 ? "#d9d9d9" : "#aaaaaa",
                  }}
                >
                  {serialList?.length > 0
                    ? serialList.map((item, key) => {
                        return (
                          <div className="transaction_number-tag" key={key}>
                            <button
                              type="button"
                              onClick={() => {
                                removeItem(item);
                              }}
                              className="remove"
                              style={{
                                cursor:
                                  eventEdit.isDeleted === 1
                                    ? "no-drop"
                                    : "pointer",
                              }}
                              disabled={
                                eventEdit.isDeleted === 1 ? true : false
                              }
                            >
                              <i className="fa fa-times"></i>
                            </button>
                            <div className="transaction_number-item">
                              <div className="transaction_number-text">
                                <span>
                                  {convertLongToShortESN(
                                    item.device.serialNumber
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="transaction_bottom">
            <div className="transaction_item">
              <p>Site name</p>
              <Input
                name="siteName"
                size="large"
                onChange={formik.handleChange}
                value={formik.values.siteName}
                disabled={eventEdit.isDeleted === 1 ? true : false}
              />
            </div>
            <div className="transaction_item">
              <p>Address</p>
              <Input
                name="address"
                size="large"
                onChange={formik.handleChange}
                value={formik.values.address}
                className="input_ant"
                disabled={eventEdit.isDeleted === 1 ? true : false}
              />
            </div>
            <div className="transaction_comment">
              <p>Notes</p>
              <Input.TextArea
                name="notes"
                size="large"
                onChange={formik.handleChange}
                value={formik.values.notes}
                disabled={eventEdit.isDeleted === 1 ? true : false}
              />
            </div>
          </div>
          <div className="detail_action">
            <button
              type="submit"
              disabled={eventEdit.isDeleted === 1 ? true : false}
              style={{
                cursor: eventEdit.isDeleted === 1 ? "no-drop" : "pointer",
              }}
            >
              Save Change
            </button>
          </div>
        </div>
      </form>
      {eventEdit.isDeleted === 0 ? (
        <div className="user_addition">
          <div className="add_detail">
            <div className="user_delete">
              <h3>
                <span>
                  <i className="fa fa-exclamation-circle"></i>
                </span>{" "}
                Delete event
              </h3>
              <div className="user_password_row">
                <p>This action can't be undone!</p>
                <button type="button" onClick={showModalDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div className="user_footer">{renderUpdater(eventEdit, "event")}</div>
        </div>
      ) : (
        <div className="user_addition">
          <div className="add_detail">
            <div className="user_restore">
              <h3>
                <span>
                  <i className="fa fa-history"></i>
                </span>{" "}
                Restore event
              </h3>
              <div className="user_password_row">
                <p>
                  This event has been deleted. If you want to restore it, please
                  click on the Restore button.
                </p>
                <button type="button" onClick={showModalRestore}>
                  Restore
                </button>
              </div>
            </div>
          </div>
          <div className="user_footer">{renderUpdater(eventEdit, "event")}</div>
        </div>
      )}
      <Modal
        title="Event type detail"
        open={showType}
        onCancel={cancelAddType}
        footer={null}
        destroyOnClose
      >
        <EventTypeDetail cancelAddType={cancelAddType} />
      </Modal>
      <Modal
        open={showDelete}
        onCancel={handleCancelModalDelete}
        footer={null}
        width={450}
      >
        <div className="delete_user">
          <p>
            <i className="fa fa-question"></i> Delete event
          </p>
          <span>Are you sure to delete this event?</span>
          <div className="delete_action">
            <button className="cancel" onClick={handleCancelModalDelete}>
              Cancel
            </button>
            <button className="delete" onClick={handleOkModalDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showRestore}
        onCancel={handleCancelModalRestore}
        footer={null}
        width={450}
      >
        <div className="restore_user">
          <p>
            <i className="fa fa-question"></i> Restore event
          </p>
          <span>Are you sure to restore this event?</span>
          <div className="restore_action">
            <button className="cancel" onClick={handleCancelModalRestore}>
              Cancel
            </button>
            <button className="restore" onClick={handleOkModalRestore}>
              Restore
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EventDetail;
