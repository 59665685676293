import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import { resetPassword } from "../../Redux/authReducer/authReducer";
import { storage } from "../../Utils/storage";
import "../../Assets/scss/Pages/ForgotPassword/_forgotpassword.scss";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Please enter new password!")
    .min(6, "Password should be of minimum 6 characters length"),
  confirmPassword: yup
    .string()
    .required("Please enter confirm password!")
    .oneOf([yup.ref("password")], "Confirm password does not match"),
});

export default function ResetPassword() {
  const [loading, setLoading] = useState(null);
  const [iconPassword, setIconPassword] = useState(false);
  const [iconConfirm, setIconConfirm] = useState(false);

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    document.title = "Tag Register - Forgot password";
  }, []);
  const handleShowPassword = () => {
    setIconPassword(!iconPassword);
  };
  const handleShowConfirm = () => {
    setIconConfirm(!iconConfirm);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.token = storage.getStore("tokenResetPassword");
      const action = resetPassword(values);
      dispatch(action);
      setLoading(true);
      localStorage.removeItem("tokenResetPassword");
    },
  });
  useEffect(() => {
    if (isLoading === true) {
      const timeoutTrue = setTimeout(() => {
        setLoading(false);
      }, 2000);
      return () => {
        clearTimeout(timeoutTrue);
      };
    }
    if (isLoading === false) {
      const timeoutFalse = setTimeout(() => {
        setLoading(false);
      }, 2000);
      return () => {
        clearTimeout(timeoutFalse);
      };
    }
  }, [isLoading]);

  return (
    <div className="forgot">
      <div className="forgot_body">
        <div className="forgot_content">
          <div className="forgot_avatar">
            <i className="fas fa-user"></i>
          </div>

          <div className="forgot_text">
            <p>Reset Password</p>
          </div>

          <div className="forgot_form">
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="forgot_field">
                <p className="forgot_label">New password*</p>
                <input
                  className={
                    formik.touched.password && formik.errors.password
                      ? "forgot_errors-input"
                      : "forgot_input"
                  }
                  id="password"
                  name="password"
                  type={iconPassword === false ? "password" : "text"}
                  placeholder="Enter new password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  autoComplete="password"
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="forgot_errors-reset">
                    <i className="fas fa-exclamation-circle"></i>
                    <span>{formik.errors.password}</span>
                  </div>
                ) : null}
                <div className="forgot_show-password">
                  {iconPassword === false ? (
                    <i className="fas fa-eye" onClick={handleShowPassword}></i>
                  ) : (
                    <i
                      className="fas fa-eye-slash"
                      onClick={handleShowPassword}
                    ></i>
                  )}
                </div>
              </div>

              <div className="forgot_field">
                <p className="forgot_label">Confirm password*</p>
                <input
                  className={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                      ? "forgot_errors-input"
                      : "forgot_input"
                  }
                  id="confirmPassword"
                  name="confirmPassword"
                  type={iconConfirm === false ? "password" : "text"}
                  placeholder="Enter confirm password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  autoComplete="confirmPassword"
                />
                <div className="forgot_show-password">
                  {iconPassword === false ? (
                    <i className="fas fa-eye" onClick={handleShowConfirm}></i>
                  ) : (
                    <i
                      className="fas fa-eye-slash"
                      onClick={handleShowConfirm}
                    ></i>
                  )}
                </div>
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div className="forgot_errors-reset">
                    <i className="fas fa-exclamation-circle"></i>
                    <span>{formik.errors.confirmPassword}</span>
                  </div>
                ) : null}
              </div>
              <div className="forgot_action">
                <button type="submit">Reset</button>
              </div>
            </form>
          </div>

          <div className="forgot_to-login">
            <Link to="/sign-in">
              <span className="forgot_icon">
                <i className="fas fa-chevron-left"></i>
              </span>
              <span>Back to Sign in</span>
            </Link>
          </div>
        </div>
      </div>
      {loading === true ? <Loading /> : null}
    </div>
  );
}
