import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Modal, message } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import {
  updateCompany,
  getAllTypesCompany,
  restoreCompany,
  deleteCompany,
} from "../../Redux/companyReducer/companyReducer";
import { getAllBillingPolicy } from "../../Redux/billingPolicyReducer/billingPolicyReducer";
import { getAllCurrencyApi } from "../../Redux/currencyReducer/currencyReducer";
import { getAllPlatformApi } from "../../Redux/platformReducer/platformReducer";
import { compareObject } from "../../Utils/compareObject";
import "../../Assets/scss/Component/Company/_detailCompany.scss";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import CreateTypes from "./CreateTypes";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";
import { renderUpdater } from "../../Utils/renderUpdater";

const DetailCompany = ({ cancelModalDetail }) => {
  const dispatch = useDispatch();
  const { arrNameCompanyTypes, companyEdit, arrCompany } = useSelector(
    (state) => state.companyReducer
  );
  const { arrPlatform } = useSelector((state) => state.platformReducer);
  const { arrBillingPolicy } = useSelector(
    (state) => state.billingPolicyReducer
  );
  const { arrCurrency } = useSelector((state) => state.currencyReducer);
  const [valueType, setValueType] = useState([]);
  const [valueSelectParent, setValueSelectParent] = useState([]);
  const [valuePrimary, setValuePrimary] = useState([]);
  const [valueBillingPolicy, setValueBillingPolicy] = useState([]);
  const [valueCurrency, setValueCurrency] = useState([]);
  const [showRestore, setShowRestore] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [resetSelect, setResetSelect] = useState(false);

  // Handle modal
  const handleShowModal = () => {
    setShowModal(true);
    setResetSelect(true);
  };
  const handleCancelModal = () => {
    setShowModal(false);
    setResetSelect(false);
  };

  const showModalRestore = () => {
    setShowRestore(true);
  };

  const cancelModalRestore = () => {
    setShowRestore(false);
  };

  const handleRestore = () => {
    message.loading("loading", 1);
    dispatch(restoreCompany(companyEdit.id));
    setShowRestore(false);
  };

  const showModalDelete = () => {
    setShowDelete(true);
  };

  const cancelModalDelete = () => {
    setShowDelete(false);
  };

  const handleDelete = () => {
    message.loading("loading", 1);
    dispatch(deleteCompany(companyEdit.id));
    setShowDelete(false);
    cancelModalDetail();
  };

  // Handle form
  const formik = useFormik({
    initialValues: {
      name: companyEdit.name,
      shortName: companyEdit.shortName,
      typeId: companyEdit.typeId !== null ? companyEdit.typeId : "",
      contactNameXero: companyEdit.contactNameXero,
      primaryPlatformId: companyEdit.primaryPlatformId,
      street: companyEdit.street,
      city: companyEdit.city,
      state: companyEdit.state,
      country: companyEdit.country,
      currencyId: companyEdit.currencyId,
      billingPolicyId: companyEdit.billingPolicyId,
      notes: companyEdit.notes,
      defaultPrice: companyEdit.defaultPrice,
      parentId: companyEdit.parentId
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name cannot be empty"),
      typeId: yup.string().required("Account Type cannot be empty"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      let data = {};

      if (compareObject(values, companyEdit)) {
        message.warning("Nothing has changed with this account");
      } else {
        const { ...newData } = values;
        for (let item in newData) {
          if (newData[item] !== companyEdit[item]) {
            data[item] = newData[item];
          }
        }
        let dataSend = {
          id: companyEdit.id,
          data: data,
        };
        message.loading("Loading", 1);
        dispatch(updateCompany(dataSend));
      }
    },
  });

  // Call api
  useEffect(() => {
    dispatch(getAllTypesCompany());
    dispatch(getAllBillingPolicy());
    dispatch(getAllCurrencyApi());
    dispatch(getAllPlatformApi());
  }, [dispatch, resetSelect]);

  useEffect(() => {
    CreateDataForSelect(arrNameCompanyTypes, setValueType);
  }, [arrNameCompanyTypes]);

  useEffect(() => {
    CreateDataForSelect(arrPlatform, setValuePrimary);
  }, [arrPlatform]);

  useEffect(() => {
    let arrSelect = [];
    arrBillingPolicy.map((item) => {
      let obj = { value: item.id, label: item.name };
      arrSelect.push(obj);
    });
    arrSelect.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setValueBillingPolicy(arrSelect);
  }, [arrBillingPolicy]);

  useEffect(() => {
    let arrSelect = [];
    arrCurrency.map((item) => {
      let obj = { value: item.id, label: item.name };
      arrSelect.push(obj);
    });
    arrSelect.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setValueCurrency(arrSelect);
  }, [arrCurrency]);

  useEffect(() => {
    let arrSelect = [];
    arrCompany.map((item) => {
      let obj = { value: item.id, label: item.name };
      arrSelect.push(obj);
    });
    arrSelect.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setValueSelectParent(arrSelect);
  }, [arrCompany]);
  return (
    <div className="detail_company">
      <div className="detail_company-notice">
        <i className="fa fa-exclamation-circle"></i>{" "}
        <p>
          Setting discount for this account? Move to the{" "}
          <strong>"Discount"</strong> tab
        </p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="detail_company-title">
          <p>Information</p>
        </div>
        <div
          className="detail_company-information"
          style={{ cursor: companyEdit.isDeleted === 1 ? "no-drop" : "" }}
        >
          <div className="detail_company-mergeField">
            <div className="detail_company-fieldLeft">
              <label>Name</label>
              <Input
                className={
                  formik.errors.name ? "detail_company-errorsInput" : ""
                }
                size="large"
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={companyEdit.isDeleted === 1 ? true : false}
              />
              {formik.errors.name ? (
                <div className="detail_company-errors">
                  <i className="fas fa-exclamation-circle"></i>
                  <span>{formik.errors.name}</span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="detail_company-fieldRight">
              <label>Short Name</label>
              <Input
                size="large"
                name="shortName"
                value={formik.values.shortName}
                onChange={formik.handleChange}
                disabled={companyEdit.isDeleted === 1 ? true : false}
              />
            </div>
          </div>
          <div className="detail_company-mergeField">
            <div className="detail_company-fieldLeft">
              <label>Account Type</label>
              <div className="detail_company-fieldType">
                <div className="detail_company-fieldTypeSelect">
                  <Select
                    className={
                      formik.errors.typeId ? "detail_company-errorsSelect" : ""
                    }
                    name="typeId"
                    options={valueType}
                    defaultValue={
                      companyEdit.typeId !== null
                        ? {
                            value: companyEdit.typeId,
                            label:
                              companyEdit.type && companyEdit.type !== null
                                ? companyEdit.typeName || companyEdit.type.name
                                : "",
                          }
                        : ""
                    }
                    onChange={(e) => {
                      if (e !== null) {
                        formik.setFieldValue("typeId", e.value);
                      } else {
                        formik.setFieldValue("typeId", "");
                      }
                    }}
                    isDisabled={companyEdit.isDeleted === 1 ? true : false}
                  />
                </div>

                <button type="button" onClick={handleShowModal}>
                  Add
                </button>
              </div>
            </div>
            <div className="detail_company-fieldRight">
              <label>Primary Platform</label>
              <Select
                name="primaryPlatformId"
                options={valuePrimary}
                defaultValue={
                  companyEdit.primaryPlatformId !== null
                    ? {
                        value: companyEdit.primaryPlatformId,
                        label:
                          companyEdit.primaryPlatform &&
                          companyEdit.primaryPlatform !== null
                            ? companyEdit.primaryPlatform.name
                            : "",
                      }
                    : ""
                }
                onChange={(e) => {
                  if (e !== null) {
                    formik.setFieldValue("primaryPlatformId", e.value);
                  } else {
                    formik.setFieldValue("primaryPlatformId", "");
                  }
                }}
                isDisabled={companyEdit.isDeleted === 1 ? true : false}
              />
            </div>
          </div>
          <div className="detail_company-oneField">
            <label>"ContactName" in XERO</label>
            <Input
              size="large"
              name="contactNameXero"
              value={formik.values.contactNameXero}
              onChange={formik.handleChange}
              disabled={companyEdit.isDeleted === 1 ? true : false}
            />
          </div>

          <div className="detail_company-mergeField">
            <div className="detail_company-fieldLeft">
              <label>Street Address</label>
              <Input
                size="large"
                name="street"
                value={formik.values.street}
                onChange={formik.handleChange}
                disabled={companyEdit.isDeleted === 1 ? true : false}
              />
            </div>
            <div className="detail_company-fieldRight">
              <label>City</label>
              <Input
                size="large"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                disabled={companyEdit.isDeleted === 1 ? true : false}
              />
            </div>
          </div>

          <div className="detail_company-mergeField">
            <div className="detail_company-fieldLeft">
              <label>State</label>
              <Input
                size="large"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                disabled={companyEdit.isDeleted === 1 ? true : false}
              />
            </div>
            <div className="detail_company-fieldRight">
              <label>Country</label>
              <Input
                size="large"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                disabled={companyEdit.isDeleted === 1 ? true : false}
              />
            </div>
          </div>
          <div className="detail_company-oneField">
            <label>Notes</label>
            <Input.TextArea
              name="notes"
              size="small"
              aria-hidden="true"
              value={formik.values.notes}
              onChange={formik.handleChange}
              disabled={companyEdit.isDeleted === 1 ? true : false}
            />
          </div>
        </div>

        <div className="detail_company-title">
          <p>For Invoice</p>
        </div>
        <div
          className="detail_company-invoice"
          style={{ cursor: companyEdit.isDeleted === 1 ? "no-drop" : "" }}
        >
          <div className="detail_company-mergeField">
            <div className="detail_company-fieldLeft">
              <label>Currency</label>
              <Select
                name="currencyId"
                options={valueCurrency}
                defaultValue={
                  companyEdit.currencyId !== null
                    ? {
                        value: companyEdit.currencyId,
                        label:
                          companyEdit.currency && companyEdit.currency !== null
                            ? companyEdit.currency.name || companyEdit.currency
                            : "",
                      }
                    : ""
                }
                onChange={(e) => {
                  if (e !== null) {
                    formik.setFieldValue("currencyId", e.value);
                  } else {
                    formik.setFieldValue("currencyId", "");
                  }
                }}
                isDisabled={companyEdit.isDeleted === 1 ? true : false}
              />
            </div>
            <div className="detail_company-fieldRight">
              <label>Billing Policy</label>
              <Select
                name="billingPolicyId"
                options={valueBillingPolicy}
                defaultValue={
                  companyEdit.billingPolicyId !== null
                    ? {
                        value: companyEdit.billingPolicyId,
                        label:
                          companyEdit.billingPolicy &&
                          companyEdit.billingPolicy !== null
                            ? companyEdit.billingPolicy.name ||
                              companyEdit.billingPolicy
                            : "",
                      }
                    : ""
                }
                onChange={(e) => {
                  if (e !== null) {
                    formik.setFieldValue("billingPolicyId", e.value);
                  } else {
                    formik.setFieldValue("billingPolicyId", "");
                  }
                }}
                isDisabled={companyEdit.isDeleted === 1 ? true : false}
              />
            </div>
          </div>
          <div className="detail_company-mergeField">
            <div className="detail_company-fieldLeft">
              <label>Default Price</label>
              <Input
                className={
                  formik.errors.defaultPrice ? "detail_company-errorsInput" : ""
                }
                size="large"
                name="defaultPrice"
                type="number"
                value={formik.values.defaultPrice}
                onChange={formik.handleChange}
                disabled={companyEdit.isDeleted === 1 ? true : false}
                step={0.01}
                min={1.5}
              />
            </div>
          </div>
        </div>

        <div className="detail_company-action">
          <button
            type="submit"
            disabled={companyEdit.isDeleted === 1 ? true : false}
            style={{
              cursor: companyEdit.isDeleted === 1 ? "no-drop" : "pointer",
            }}
          >
            Save Change
          </button>
        </div>

        {companyEdit.isDeleted === 0 ? (
          <div className="detail_company-delete">
            <h3>
              <span>
                <i className="fa fa-history"></i>
              </span>{" "}
              Delete account
            </h3>
            <div className="detail_company-deleteButton">
              <p>This action can't be undone!</p>
              <button type="button" onClick={showModalDelete}>
                Delete
              </button>
            </div>
          </div>
        ) : (
          <div className="detail_company-restore">
            <h3>
              <span>
                <i className="fa fa-history"></i>
              </span>{" "}
              Restore account
            </h3>
            <div className="detail_company-restoreButton">
              <p>
                This account has been deleted. If you want to restore it, please
                click on the Restore button.
              </p>
              <button type="button" onClick={showModalRestore}>
                Restore
              </button>
            </div>
          </div>
        )}

        <div className="detail_company-lastUpdate">
          {renderUpdater(companyEdit, "account")}
        </div>
      </form>

      <Modal
        title="Account Types"
        open={showModal}
        onCancel={handleCancelModal}
        footer={null}
        width={600}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateTypes
          handleCancelModal={handleCancelModal}
          setResetSelect={setResetSelect}
          resetSelect={resetSelect}
        />
      </Modal>

      <Modal
        open={showRestore}
        onCancel={cancelModalRestore}
        footer={null}
        width={450}
      >
        <div className="detail_company-modal">
          <p>
            <i className="fa fa-question"></i> Restore account
          </p>
          <span>Are you sure to restore this account?</span>
          <div className="detail_company-modalAction">
            <button
              className="detail_company-modalCancel"
              onClick={cancelModalRestore}
            >
              Cancel
            </button>
            <button
              className="detail_company-modalRestore"
              onClick={handleRestore}
            >
              Restore
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showDelete}
        onCancel={cancelModalDelete}
        footer={null}
        width={450}
      >
        <div className="detail_company-modal">
          <p>
            <i className="fa fa-question"></i> Delete account
          </p>
          <span>Are you sure to delete this account?</span>
          <div className="detail_company-modalAction">
            <button
              className="detail_company-modalCancel"
              onClick={cancelModalDelete}
            >
              Cancel
            </button>
            <button
              className="detail_company-modalDelete"
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DetailCompany;
