import axiosClient from "./axiosClient";

const wirelessTechApi = {
  addNew: async (data) => {
    const response = await axiosClient.post("/wireless-technology", data);
    return response;
  },
  getAll: async () => {
    const response = await axiosClient.get("/wireless-technology");
    return response;
  },
  delete: async (id) => {
    const response = await axiosClient.delete("/wireless-technology/" + id);
    return response;
  },
  update: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/wireless-technology/" + id, data);
    return response;
  },
  restore: async (id) => {
    const response = await axiosClient.put("/wireless-technology/restore/" + id);
    return response;
  },
  findOne: async (id) => {
    const response = await axiosClient.get("/wireless-technology/" + id);
    return response;
  },
};
export default wirelessTechApi;
