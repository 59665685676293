import React, { useState } from "react";
import { Input, message, Modal } from "antd";
import "../../Assets/scss/Component/Platform/_createPlatform.scss";
import { useDispatch } from "react-redux";
import CreatePlatformStatus from "./CreatePlatformStatus";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { addNewPlatformStatusApi } from "../../Redux/platformReducer/platformStatusReducer";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { addNewPlatformApi } from "../../Redux/platformReducer/platformReducer";

export default function CreatePlatform({
  handleOk,
  handleCancel,
  arrSelectBox,
}) {
  const [openStatus, setOpenStatus] = useState(false);
  const dispatch = useDispatch();

  // Handle modal
  const showModalStatus = () => {
    setOpenStatus(true);
  };
  const handleOkStatus = () => {
    message.loading("loading", 0.5);
    setOpenStatus(false);
  };
  const handleCancelStatus = () => {
    setOpenStatus(false);
    platformStatusInformation.resetForm();
  };

  //Handle form
  const platformStatusInformation = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      const action = addNewPlatformStatusApi(values);
      dispatch(action);
      handleOkStatus();
      platformStatusInformation.resetForm();
    },
  });

  const platformInformation = useFormik({
    initialValues: {
      name: "",
      shortName: "",
      url: null,
      email: null,
      comment: null,
      description: null,
      statusId: null,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      shortName: yup.string().required("Short name is required"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      const newValues = { ...values };
      const action = addNewPlatformApi(newValues);
      dispatch(action);
      handleOk();
    },
  });
  return (
    <form onSubmit={platformInformation.handleSubmit}>
      <div className="create_platform">
        <div className="platform_row">
          <div className="platform_field">
            <p>Name*</p>
            <Input
              name="name"
              className={platformInformation.errors.name ? "error_input" : ""}
              onChange={platformInformation.handleChange}
              onBlur={platformInformation.handleBlur}
              value={platformInformation.values.name}
              size="large"
            />
            {platformInformation.errors.name ? (
              <div className="error_message">
                <i className="fa fa-exclamation-circle"></i>
                <span>{platformInformation.errors.name}</span>
              </div>
            ) : null}
          </div>
          <div className="platform_field">
            <p>Short name*</p>
            <Input
              name="shortName"
              className={
                platformInformation.errors.shortName ? "error_input" : ""
              }
              onChange={platformInformation.handleChange}
              onBlur={platformInformation.handleBlur}
              value={platformInformation.values.shortName}
              size="large"
            />
            {platformInformation.errors.shortName ? (
              <div className="error_message">
                <i className="fa fa-exclamation-circle"></i>
                <span>{platformInformation.errors.shortName}</span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="platform_row">
          <div className="platform_url">
            <p>URL</p>
            <Input
              name="url"
              className="input"
              onChange={platformInformation.handleChange}
              onBlur={platformInformation.handleBlur}
              value={platformInformation.values.url}
              size="large"
            />
          </div>
        </div>
        <div className="platform_status">
          <p>Status</p>
          <div className="status_row">
            <Select
              options={arrSelectBox}
              name="statusId"
              onChange={(e) => {
                if (e !== null) {
                  platformInformation.setFieldValue("statusId", e.value);
                } else {
                  platformInformation.setFieldValue("statusId", e);
                }
              }}
              className="select"
            />
            <button type="button" onClick={showModalStatus}>
              New status +
            </button>
          </div>
        </div>
        <div className="platform_area">
          <p>Description</p>
          <Input.TextArea
            name="description"
            onChange={platformInformation.handleChange}
            onBlur={platformInformation.handleBlur}
            value={platformInformation.values.description}
          />
        </div>
        <div className="platform_area">
          <p>Comment</p>
          <Input.TextArea
            name="comment"
            onChange={platformInformation.handleChange}
            onBlur={platformInformation.handleBlur}
            value={platformInformation.values.comment}
          />
        </div>
      </div>
      <div className="form_action">
        <button
          type="button"
          className="cancel"
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </button>
        <button type="submit" className="create">
          Create
        </button>
      </div>
      <Modal
        title="Create platform status"
        open={openStatus}
        onCancel={handleCancelStatus}
        footer={null}
        width={500}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <CreatePlatformStatus
          handleCancelStatus={handleCancelStatus}
          platformStatusInformation={platformStatusInformation}
        />
      </Modal>
    </form>
  );
}
