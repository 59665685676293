import axiosClient from "./axiosClient";

const billingGroupApi = {
  getAll: async () => {
    const response = await axiosClient.get("/billing-group");
    return response;
  },
  addNew: async (data) => {
    const response = await axiosClient.post("/billing-group", data);
    return response;
  },
  updateOne: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/billing-group/" + id, data);
    return response;
  },
  deleteOne: async (id) => {
    const response = await axiosClient.delete("/billing-group/" + id);
    return response;
  },
};

export default billingGroupApi;
