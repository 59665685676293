export const CreateDataForSelect = (array, action)=>{
    let arrSelect = [];
    array.map((item) => {
      if (item.isDeleted === 0) {
        let obj = { value: item.id, label: item.name };
        arrSelect.push(obj);
      }
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    action(arrSelect);
}