import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDragListView from "react-drag-listview";
import {
  Input,
  Table,
  Space,
  Modal,
  Popconfirm,
  Tabs,
  Popover,
  Tooltip,
  message,
  FloatButton,
  Typography,
  Switch,
} from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import Select from "react-select";
import { getAllDeviceStateApi } from "../../Redux/deviceReducer/deviceStateReducer";
import {
  deleteDeviceApi,
  findOneDeviceApi,
  getAllDeviceApi,
  getChangeLog,
} from "../../Redux/deviceReducer/deviceReducer";
import DeviceDetail from "../../Components/Device/DeviceDetail";
import BulkUpDate from "../../Components/Device/BulkUpDate";
import PriceAndCost from "../../Components/Device/PriceAndCost";
import DeviceHistory from "../../Components/Device/DeviceHistory";
import DeviceMovement from "../../Components/Device/DeviceMovement";
import { storage } from "../../Utils/storage";
import { exportExcel } from "../../Utils/exportExcel";
import { getAllCompany } from "../../Redux/companyReducer/companyReducer";
import { showHideDeletedItem } from "../../Utils/showHideDeletedItem";
import {
  findMovementByDeviceId,
  resetMovementDevice,
} from "../../Redux/movementReducer/movementReducer";
import "../../Assets/scss/Pages/Company/_company.scss";
import { getAllPurchaseProductApi } from "../../Redux/productReducer/purchaseProductReducer";
import { getAllSaleProductApi } from "../../Redux/productReducer/saleProductReducer";
import { renderShowColumn } from "../../Utils/renderShowColumn";
import { toggleButtonClass } from "../../Utils/toggleButtonClass";
import {
  CURRENT_PAGE,
  DEFAULT_PAGESIZE,
} from "../../Utils/ConstCommon/constCommon";

export default function Device() {
  const dispatch = useDispatch();
  const { arrDevice, deviceEdit } = useSelector((state) => state.deviceReducer);
  const { arrCompany } = useSelector((state) => state.companyReducer);
  const [arrSelect, setArrSelect] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [openDetail, setOpenDetail] = useState(false);
  const [showBulkUpdate, setShowBulkUpdate] = useState(false);
  const [dataBulkUpdate, setDataBulkUpdate] = useState({});
  const [showExportAccount, setShowExportAccount] = useState(false);
  const [arrAccountSelect, setArrAccountSelect] = useState([]);
  const [accountIdExport, setAccountIdExport] = useState(null);
  const [showDeleted, setShowDeleted] = useState();
  const [data, setData] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(CURRENT_PAGE);
  const pageSizeRef = useRef(DEFAULT_PAGESIZE);

  // Handle modal
  const showModalBulkUpdate = () => {
    let data = [];
    for (let i = 0; i < arrSelect.length; i++) {
      if (arrSelect[i].isDeleted === 1) {
        message.warning("The list has devices that have been deleted");
        data = [];
        break;
      } else {
        data.push(arrSelect[i]);
      }
    }
    setDataBulkUpdate(data);
    if (data.length > 0) {
      setShowBulkUpdate(true);
    }
  };
  const hideModalBulkUpdate = () => {
    setShowBulkUpdate(false);
  };

  const showModalDetail = () => {
    setOpenDetail(true);
  };
  const handleCancelDetail = () => {
    setOpenDetail(false);
    dispatch(resetMovementDevice());
  };

  // Handle export account
  const handleExportByAccount = () => {
    setShowExportAccount(!showExportAccount);
  };

  // Use for table
  const columns = [
    {
      title: "Serial number",
      dataIndex: "serialNumber",
      key: "serialNumber",
      onFilter: (value, record) => {
        return (
          record.serialNumber.toLowerCase().includes(value.toLowerCase()) ||
          record.shortEsn.toLowerCase().includes(value.toLowerCase()) ||
          record.currentMovementDateShow
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          record.currentStartBillDateShow
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          record.purchaseProductName
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          record.purchaseProductSortName
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          record.salesProductName
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          record.currentAccountName
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          record.supplierName?.toLowerCase().includes(value.toLowerCase()) ||
          record.stateName?.toLowerCase().includes(value.toLowerCase()) ||
          record.buyDate?.toLowerCase().includes(value.toLowerCase()) ||
          record.invoiceCode?.toLowerCase().includes(value.toLowerCase()) ||
          String(record.unitCost)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.activationCost)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.batteriesCost)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.otherCost)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.totalPurchase)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.baseSubPrice)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.hwPrice)?.toLowerCase().includes(value.toLowerCase()) ||
          String(record.haasPrice)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.saleOtherCost)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.totalSaleCostRoundUp)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.valueAddedSimCostExGst)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.valueAddedOtherCost)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.totalValueAddRoundUp)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.totalRoundUp)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.note)?.toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (text, record) => (
        <span
          className="open_detail"
          onClick={async () => {
            await dispatch(findOneDeviceApi(record.id));
            showModalDetail();
          }}
        >
          {text}
        </span>
      ),
      sorter: (beforeItem, afterItem) =>
        beforeItem.serialNumber.toLowerCase() >
        afterItem.serialNumber.toLowerCase()
          ? 1
          : beforeItem.serialNumber.toLowerCase() ===
            afterItem.serialNumber.toLowerCase()
          ? 0
          : -1,
      filterSearch: true,
    },
    {
      title: "Short ESN",
      dataIndex: "shortEsn",
      key: "shortEsn",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.shortEsn.toLowerCase() > afterItem.shortEsn.toLowerCase()
          ? 1
          : beforeItem.shortEsn.toLowerCase() ===
            afterItem.shortEsn.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Current movement date",
      dataIndex: "currentMovementDateShow",
      key: "currentMovementDateShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.currentMovementDateTable?.toLowerCase() >
        afterItem.currentMovementDateTable?.toLowerCase()
          ? 1
          : beforeItem.currentMovementDateTable?.toLowerCase() ===
            afterItem.currentMovementDateTable?.toLowerCase()
          ? 0
          : -1,
      render: (record) => <strong>{record}</strong>,
    },
    {
      title: "Current start bill date",
      dataIndex: "currentStartBillDateShow",
      key: "currentStartBillDateShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.currentStartBillDateTable?.toLowerCase() >
        afterItem.currentStartBillDateTable?.toLowerCase()
          ? 1
          : beforeItem.currentStartBillDateTable?.toLowerCase() ===
            afterItem.currentStartBillDateTable?.toLowerCase()
          ? 0
          : -1,
      render: (record) => <strong>{record}</strong>,
    },
    {
      title: "First movement date",
      dataIndex: "buyDate",
      key: "buyDate",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.firstMovementDateTable?.toLowerCase() >
        afterItem.firstMovementDateTable?.toLowerCase()
          ? 1
          : beforeItem.firstMovementDateTable?.toLowerCase() ===
            afterItem.firstMovementDateTable?.toLowerCase()
          ? 0
          : -1,
      render: (record) => <strong>{record}</strong>,
    },
    {
      title: "Purchase product",
      dataIndex: "purchaseProductName",
      key: "purchaseProductName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.purchaseProductName?.toLowerCase() >
        afterItem.purchaseProductName?.toLowerCase()
          ? 1
          : beforeItem.purchaseProductName?.toLowerCase() ===
            afterItem.purchaseProductName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Purchase product code",
      dataIndex: "purchaseProductSortName",
      key: "purchaseProductSortName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.purchaseProductSortName?.toLowerCase() >
        afterItem.purchaseProductSortName?.toLowerCase()
          ? 1
          : beforeItem.purchaseProductSortName?.toLowerCase() ===
            afterItem.purchaseProductSortName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Sales product",
      dataIndex: "salesProductName",
      key: "salesProductName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.salesProductName?.toLowerCase() >
        afterItem.salesProductName?.toLowerCase()
          ? 1
          : beforeItem.salesProductName?.toLowerCase() ===
            afterItem.salesProductName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Sales product code",
      dataIndex: "salesProductSortName",
      key: "salesProductSortName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.salesProductSortName?.toLowerCase() >
        afterItem.salesProductSortName?.toLowerCase()
          ? 1
          : beforeItem.salesProductSortName?.toLowerCase() ===
            afterItem.salesProductSortName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Current account",
      dataIndex: "currentAccountName",
      key: "currentAccountName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.currentAccountName?.toLowerCase() >
        afterItem.currentAccountName?.toLowerCase()
          ? 1
          : beforeItem.currentAccountName?.toLowerCase() ===
            afterItem.currentAccountName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.supplierName?.toLowerCase() >
        afterItem.supplierName?.toLowerCase()
          ? 1
          : beforeItem.supplierName?.toLowerCase() ===
            afterItem.supplierName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "State",
      dataIndex: "stateName",
      key: "stateName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.stateName.toLowerCase() > afterItem.stateName.toLowerCase()
          ? 1
          : beforeItem.stateName.toLowerCase() ===
            afterItem.stateName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Invoice code",
      dataIndex: "invoiceCode",
      key: "invoiceCode",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.invoiceCode?.toLowerCase() >
        afterItem.invoiceCode?.toLowerCase()
          ? 1
          : beforeItem.invoiceCode?.toLowerCase() ===
            afterItem.invoiceCode?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Purchase unit cost",
      dataIndex: "unitCost",
      key: "unitCost",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.unitCost - afterItem.unitCost,
    },
    {
      title: "Purchase activation cost",
      dataIndex: "activationCost",
      key: "activationCost",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.activationCost - afterItem.activationCost,
    },
    {
      title: "Purchase batteries cost",
      dataIndex: "batteriesCost",
      key: "batteriesCost",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.batteriesCost - afterItem.batteriesCost,
    },
    {
      title: "Purchase other cost",
      dataIndex: "otherCost",
      key: "otherCost",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.otherCost - afterItem.otherCost,
    },
    {
      title: "Purchase total cost",
      dataIndex: "totalPurchaseRoundUp",
      key: "totalPurchaseRoundUp",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.totalPurchaseRoundUp - afterItem.totalPurchaseRoundUp,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: " Sales base subs price",
      dataIndex: "baseSubPrice",
      key: "baseSubPrice",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.baseSubPrice - afterItem.baseSubPrice,
    },
    {
      title: "Sales hw price",
      dataIndex: "hwPrice",
      key: "hwPrice",
      align: "center",
      sorter: (beforeItem, afterItem) => beforeItem.hwPrice - afterItem.hwPrice,
    },
    {
      title: "Sales haas price",
      dataIndex: "haasPrice",
      key: "haasPrice",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.haasPrice - afterItem.haasPrice,
    },
    {
      title: "Sales other cost",
      dataIndex: "saleOtherCost",
      key: "saleOtherCost",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.saleOtherCost - afterItem.saleOtherCost,
    },
    {
      title: "Total sale cost",
      dataIndex: "totalSaleCostRoundUp",
      key: "totalSaleCostRoundUp",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.totalSaleCostRoundUp - afterItem.totalSaleCostRoundUp,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Value added sim cost Ex gst",
      dataIndex: "valueAddedSimCostExGst",
      key: "valueAddedSimCostExGst",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.valueAddedSimCostExGst - afterItem.valueAddedSimCostExGst,
    },
    {
      title: "Value added other cost",
      dataIndex: "valueAddedOtherCost",
      key: "valueAddedOtherCost",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.valueAddedOtherCost - afterItem.valueAddedOtherCost,
    },
    {
      title: "Total value added cost",
      dataIndex: "totalValueAddRoundUp",
      key: "totalValueAddRoundUp",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.totalValueAdd - afterItem.totalValueAdd,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Total cost",
      dataIndex: "totalRoundUp",
      key: "totalRoundUp",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.totalRoundUp - afterItem.totalRoundUp,
      render: (text) => <strong style={{ color: "red" }}>{text}</strong>,
    },
    {
      title: "Current haas price",
      dataIndex: "currentHaasPrice",
      key: "currentHaasPrice",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.currentHaasPrice - afterItem.currentHaasPrice,
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.note?.toLowerCase() > afterItem.note?.toLowerCase()
          ? 1
          : beforeItem.note?.toLowerCase() === afterItem.note?.toLowerCase()
          ? 0
          : -1,
      render: (text) => (
        <Tooltip title={text} color="#2962ff">
          <Typography.Paragraph ellipsis={{ rows: 1 }}>
            {text}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "80px",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Delete Device"
            placement="leftBottom"
            description={
              record.isDeleted === 0
                ? "Are you sure to delete this device"
                : `This device will be removed from the system.
            Are you sure to remove it?`
            }
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              dispatch(deleteDeviceApi(record.id));
            }}
          >
            <button className="company_delete">
              <DeleteOutlined className="company_delete-icon" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [columnSelected, setColumnSelected] = useState(columns);
  for (let index = 0; index < columnSelected.length; index++) {
    let item = columnSelected[index];
    item.filteredValue = [searchValue];
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setArrSelect(selectedRows);
      return (
        `selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows
      );
    },
  };

  // Drag and drop
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newFromIndex = fromIndex - 1;
      const newToIndex = toIndex - 1;
      const newColumns = [...columnSelected];
      const item = newColumns.splice(newFromIndex, 1)[0];
      newColumns.splice(newToIndex, 0, item);
      setColumnSelected(newColumns);
      let arrRelocate = newColumns.map((item) => {
        return item.key;
      });
      storage.setStorageJson("arrDevicesRelocateTitleColumn", arrRelocate);
    },
    nodeSelector: "th",
  };

  // Show/hide columns
  useEffect(() => {
    renderShowColumn(
      columns,
      "arrDevicesShowColumn",
      "arrDevicesRelocateTitleColumn",
      "arrDevicesRelocateTitleColumnNew",
      setColumnSelected
    );
    document.title = "Tag Register - Devices";
  }, []);

  const resetAll = () => {
    storage.removeStorage("arrDevicesShowColumn");
    storage.removeStorage("arrDevicesRelocateTitleColumn");
    storage.removeStorage("arrDevicesRelocateTitleColumnNew");
    setColumnSelected(columns);
  };

  // Detail
  const items = [
    {
      key: "1",
      label: `Device details`,
      children: <DeviceDetail handleCancelDetail={handleCancelDetail} />,
    },
    {
      key: "2",
      label: `Prices and Costs`,
      children: <PriceAndCost />,
    },
    {
      key: "3",
      label: `History`,
      children: <DeviceHistory />,
    },
    {
      key: "4",
      label: `Movement`,
      children: <DeviceMovement />,
    },
  ];

  const tabCallback = (key) => {
    if (key === "2") {
      dispatch(findOneDeviceApi(deviceEdit.id));
    }
    if (key === "3") {
      dispatch(getChangeLog(deviceEdit.id));
    }
    if (key === "4") {
      dispatch(findMovementByDeviceId(deviceEdit.id));
    }
  };

  // Content of popover
  const content = (
    <div className="company_content-show-device">
      {columns.map((item, index) => {
        const dataShow = storage.getStorageJson("arrDevicesShowColumn");
        if (dataShow && dataShow.includes(item.key) === true) {
          return (
            <button
              className="company_content-active-device"
              key={index}
              value={item.key}
              onClick={(e) => {
                toggleButtonClass(
                  e,
                  columns,
                  "arrDevicesShowColumn",
                  "arrDevicesRelocateTitleColumn",
                  "arrDevicesRelocateTitleColumnNew",
                  setColumnSelected
                );
              }}
            >
              {item.title}
            </button>
          );
        }
        if (dataShow && dataShow.includes(item.key) === false) {
          return (
            <button
              className="company_content-disable-device"
              key={index}
              value={item.key}
              onClick={(e) => {
                toggleButtonClass(
                  e,
                  columns,
                  "arrDevicesShowColumn",
                  "arrDevicesRelocateTitleColumn",
                  "arrDevicesRelocateTitleColumnNew",
                  setColumnSelected
                );
              }}
            >
              {item.title}
            </button>
          );
        }
        if (dataShow === undefined) {
          return (
            <button
              className="company_content-active-device"
              key={index}
              value={item.key}
              onClick={(e) => {
                toggleButtonClass(
                  e,
                  columns,
                  "arrDevicesShowColumn",
                  "arrDevicesRelocateTitleColumn",
                  "arrDevicesRelocateTitleColumnNew",
                  setColumnSelected
                );
              }}
            >
              {item.title}
            </button>
          );
        }
      })}
      <button className="company_content-restore-device" onClick={resetAll}>
        Restore visibility
      </button>
    </div>
  );

  // Call api
  useEffect(() => {
    dispatch(getAllDeviceApi());
    dispatch(getAllDeviceStateApi());
    dispatch(getAllCompany());
    dispatch(getAllSaleProductApi());
    dispatch(getAllPurchaseProductApi());
  }, [dispatch]);

  // Recall api
  useEffect(() => {
    const reCallApi = setInterval(() => {
      dispatch(getAllDeviceApi());
    }, 10000);
    return () => clearInterval(reCallApi);
  }, []);

  useEffect(() => {
    let arrSelect = arrCompany.map((item) => {
      if (item.isDeleted === 0) {
        let obj = { value: item.id, label: item.name };
        return obj;
      }
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setArrAccountSelect(arrSelect);
  }, [arrCompany]);

  // Handle export by account
  const exportExcelByAccount = () => {
    if (accountIdExport !== null) {
      const arrayExport = arrDevice.filter(
        (item) => item.currentAccountId === accountIdExport
      );
      exportExcel(arrayExport, columnSelected, "Device");
      setAccountIdExport(null);
    }
  };
  const contentExport = (
    <div className="content_export">
      <div className="content_export-table">
        <button
          onClick={() => {
            exportExcel(arrDevice, columnSelected, "Device");
          }}
        >
          Export data from the table
        </button>
      </div>
      <div className="content_export-account">
        <div className="content_export-account-button">
          <button onClick={handleExportByAccount}>
            Export data by account
          </button>
        </div>
        {showExportAccount ? (
          <div className="content_export-account-action">
            <Select
              options={arrAccountSelect}
              className="account_select"
              placeholder="Select account"
              onChange={(e) => {
                setAccountIdExport(e.value);
              }}
            />
            <div className="export">
              <button
                onClick={() => {
                  exportExcelByAccount();
                }}
              >
                Export
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );

  // Show/hide selected item
  const onChangeShowDeleted = (checked) => {
    showHideDeletedItem(checked, arrDevice, setData, "showDeletedDevice");
    setShowDeleted(checked);
  };
  useEffect(() => {
    const isShow = storage.getStore("showDeletedDevice");
    if (isShow === undefined || isShow === "true") {
      setData(arrDevice);
      setShowDeleted(true);
    } else {
      const arrayData = arrDevice.filter((item) => item.isDeleted !== 1);
      setData(arrayData);
      setShowDeleted(false);
    }
  }, [arrDevice]);

  const contentAction = (
    <div className="table_action">
      <div className="table_action-item">
        <Switch
          onChange={onChangeShowDeleted}
          checkedChildren="Show deleted"
          unCheckedChildren="Hide deleted"
          checked={showDeleted}
        />
      </div>
      <div className="table_action-item">
        <Popover content={contentExport} trigger="click" placement="bottom">
          <button>Export</button>
        </Popover>
      </div>
      <div className="table_action-item">
        <Popover content={content} trigger="click" placement="bottom">
          <button>Column</button>
        </Popover>
      </div>
    </div>
  );
  return (
    <div className="company">
      <div className="company_header">
        <p>Devices</p>
      </div>
      <div className="company_body">
        <div className="company_action">
          <div className="company_action-left">
            <div className="company_action-selected">
              <p>
                Selected: <strong>{arrSelect.length}</strong>
              </p>
            </div>
            <Popover
              title="To add new Devices, go to the Movements Page"
              placement="bottom"
            >
              <div className="company_action-warning">
                <i className="fas fa-exclamation-circle"></i>
              </div>
            </Popover>
            {arrSelect.length > 0 ? (
              <button
                className="company_action-bulk"
                onClick={showModalBulkUpdate}
              >
                Bulk Update
              </button>
            ) : (
              ""
            )}
            <Popover content={contentAction} trigger="click" placement="bottom">
              <button className="button-second">
                Action <i className="fa fa-caret-down"></i>
              </button>
            </Popover>
          </div>
          <div className="company_action-right">
            <div className="company_action-search">
              <SearchOutlined className="search_icon" />
              <Input
                placeholder="Search..."
                allowClear
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <ReactDragListView.DragColumn {...dragProps}>
          <Table
            dataSource={data}
            columns={[...columnSelected]}
            rowSelection={rowSelection}
            rowClassName={(record) => (record.isDeleted === 1 ? "deleted" : "")}
            pagination={{
              defaultPageSize: 10,
              current: currentPagination,
              showSizeChanger: true,
              showTotal: (total, range) => (
                <p>
                  Showing{" "}
                  <strong>
                    {range[0]}-{range[1]}
                  </strong>{" "}
                  of <strong>{total}</strong> items
                </p>
              ),
              onChange: (newCurrent, newPageSize) => {
                const pageSizeChange = pageSizeRef.current !== newPageSize;
                if (pageSizeChange) {
                  setCurrentPagination(1);
                } else {
                  setCurrentPagination(newCurrent);
                }
                pageSizeRef.current = newPageSize;
              },
            }}
            bordered={true}
            scroll={{
              x: 4800,
            }}
            sticky
            size="middle"
          />
        </ReactDragListView.DragColumn>
      </div>

      <Modal
        title={"Device ID: " + deviceEdit.id}
        open={openDetail}
        onCancel={handleCancelDetail}
        footer={null}
        width={800}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          size="small"
          className="user_tab"
          onChange={tabCallback}
          destroyInactiveTabPane={true}
        />
      </Modal>
      <Modal
        open={showBulkUpdate}
        onCancel={hideModalBulkUpdate}
        footer={null}
        width={600}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <BulkUpDate
          hideModalBulkUpdate={hideModalBulkUpdate}
          dataBulkUpdate={dataBulkUpdate}
        />
      </Modal>
      <FloatButton.BackTop />
    </div>
  );
}
