import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Table,
  Space,
  Modal,
  message,
  Popconfirm,
  Tabs,
  Popover,
  FloatButton,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/scss/Pages/User/_user.scss";
import {
  SearchOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  deleteCurrencyApi,
  findOneCurrencyApi,
  getAllCurrencyApi,
} from "../../Redux/currencyReducer/currencyReducer";
import CreateCurrency from "../../Components/Currency/CreateCurrency";
import CurrencyDetail from "../../Components/Currency/CurrencyDetail";
import { storage } from "../../Utils/storage";
import ReactDragListView from "react-drag-listview";
import { showHideDeletedItem } from "../../Utils/showHideDeletedItem";
import {
  CURRENT_PAGE,
  DEFAULT_PAGESIZE,
} from "../../Utils/ConstCommon/constCommon";
import { renderShowColumn } from "../../Utils/renderShowColumn";
import ContentShowColumn from "../../Utils/ComponentCommon/ContentShowColumn";
import ContentShowHideDeleted from "../../Utils/ComponentCommon/ContentShowHideDeleted";

export default function Currency() {
  const dispatch = useDispatch();
  const { arrCurrency, currencyEdit } = useSelector(
    (state) => state.currencyReducer
  );
  const [arrSelect, setArrSelect] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [showDeleted, setShowDeleted] = useState();
  const [data, setData] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(CURRENT_PAGE);
  const pageSizeRef = useRef(DEFAULT_PAGESIZE);

  // Use for table
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          String(record.rate).toLowerCase().includes(value.toLowerCase()) ||
          record.descriptionShow.toLowerCase().includes(value.toLowerCase()) ||
          String(record.liveRate)?.toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (text, record) => (
        <span
          className="open_detail"
          onClick={async () => {
            await dispatch(findOneCurrencyApi(record.id));
            showModalDetail();
          }}
        >
          {text}
        </span>
      ),
      sorter: (beforeItem, afterItem) =>
        beforeItem.name.toLowerCase() > afterItem.name.toLowerCase()
          ? 1
          : beforeItem.name.toLowerCase() === afterItem.name.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Rate/AUD",
      dataIndex: "rate",
      key: "rate",
      align: "center",
      sorter: (beforeItem, afterItem) => beforeItem.rate - afterItem.rate,
    },
    {
      title: "Live rate",
      dataIndex: "liveRate",
      key: "liveRate",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.liveRate - afterItem.liveRate,
    },
    {
      title: "Description",
      dataIndex: "descriptionShow",
      key: "descriptionShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.descriptionShow.toLowerCase() >
        afterItem.descriptionShow.toLowerCase()
          ? 1
          : beforeItem.descriptionShow.toLowerCase() ===
            afterItem.descriptionShow.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "80px",
      render: (_, record) => (
        <Space size="middle" className="user_action">
          <Popconfirm
            title="Delete Currency"
            placement="leftBottom"
            description="Are you sure to delete this currency?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              const action = deleteCurrencyApi(record.id);
              dispatch(action);
            }}
          >
            <button>
              <DeleteOutlined className="user_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  let [columnSelected, setColumnSelected] = useState(columns);
  for (let index = 0; index < columnSelected.length; index++) {
    let item = columnSelected[index];
    item.filteredValue = [searchValue];
  }

  // Drag and drop column
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newFromIndex = fromIndex - 1;
      const newToIndex = toIndex - 1;
      const newColumns = [...columnSelected];
      const item = newColumns.splice(newFromIndex, 1)[0];
      newColumns.splice(newToIndex, 0, item);
      setColumnSelected(newColumns);
      let arrRelocate = newColumns.map((item) => {
        return item.key;
      });
      storage.setStorageJson("arrCurrenciesRelocateTitleColumn", arrRelocate);
    },
    nodeSelector: "th",
  };

  // Show/ hide column
  useEffect(() => {
    renderShowColumn(
      columns,
      "arrCurrenciesShowColumn",
      "arrCurrenciesRelocateTitleColumn",
      "arrCurrenciesRelocateTitleColumn2",
      setColumnSelected
    );
    document.title = "Tag Register - Currencies";
  }, []);
  const resetAll = () => {
    storage.removeStorage("arrCurrenciesShowColumn");
    storage.removeStorage("arrCurrenciesRelocateTitleColumn");
    storage.removeStorage("arrCurrenciesRelocateTitleColumn2");
    setColumnSelected(columns);
  };

  // Selected rows
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setArrSelect(selectedRows);
      return (
        `selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows
      );
    },
  };

  // Handle modal
  const showModal = () => {
    setOpen(true);
  };

  const showModalDetail = () => {
    setOpenDetail(true);
  };

  const handleOk = () => {
    message.loading("loading", 1);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCancelDetail = () => {
    setOpenDetail(false);
  };

  // Call api
  useEffect(() => {
    const action = getAllCurrencyApi();
    dispatch(action);
  }, [dispatch]);

  // Recall api
  useEffect(() => {
    const reCallApi = setInterval(() => {
      dispatch(getAllCurrencyApi());
    }, 10000);
    return () => clearInterval(reCallApi);
  }, []);

  // Use for detail
  const items = [
    {
      key: "1",
      label: `Currency details`,
      children: <CurrencyDetail />,
    },
    {
      key: "2",
      label: `History`,
      children: `Coming soon`,
    },
  ];

  // Content for popover
  const content = (
    <ContentShowColumn
      columns={columns}
      storageShow="arrCurrenciesShowColumn"
      storageRelocate="arrCurrenciesRelocateTitleColumn"
      storageRelocateNew="arrCurrenciesRelocateTitleColumn2"
      action={setColumnSelected}
      resetAll={resetAll}
    />
  );

  // Show/hide deleted item
  const onChangeShowDeleted = (checked) => {
    showHideDeletedItem(checked, arrCurrency, setData, "showDeletedCurrency");
    setShowDeleted(checked);
  };
  useEffect(() => {
    const isShow = storage.getStore("showDeletedCurrency");
    if (isShow === undefined || isShow === "true") {
      setData(arrCurrency);
      setShowDeleted(true);
    } else {
      const arrayData = arrCurrency.filter((item) => item.isDeleted !== 1);
      setData(arrayData);
      setShowDeleted(false);
    }
  }, [arrCurrency]);
  const contentAction = (
    <ContentShowHideDeleted
      onChangeShowDeleted={onChangeShowDeleted}
      showDeleted={showDeleted}
      arrayData={arrCurrency}
      columnSelected={columnSelected}
      pageName="Currency"
      content={content}
    />
  );
  return (
    <div className="user">
      <div className="user_top">
        <div className="user_left">
          <p>Currencies</p>
        </div>
      </div>
      <div className="user_body">
        <div className="user_show">
          <div className="user_showChild">
            <div className="user_showLeft">
              <p>
                Selected: <strong>{arrSelect.length}</strong>
              </p>
              <button
                onClick={() => {
                  showModal();
                }}
                className="user_button-create"
              >
                + New
              </button>
              <Popover
                content={contentAction}
                trigger="click"
                placement="right"
              >
                <button className="button-second">
                  Action <i className="fa fa-caret-down"></i>
                </button>
              </Popover>
            </div>
            <div className="user_showRight">
              <div className="search_area">
                <SearchOutlined className="search_icon" />
                <Input
                  className="search_box"
                  placeholder="Search..."
                  allowClear
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="user_table">
          <ReactDragListView.DragColumn {...dragProps}>
            <Table
              rowSelection={rowSelection}
              columns={[...columnSelected]}
              dataSource={data}
              pagination={{
                defaultPageSize: 10,
                current: currentPagination,
                showSizeChanger: true,
                showTotal: (total, range) => (
                  <p>
                    Showing{" "}
                    <strong>
                      {range[0]}-{range[1]}
                    </strong>{" "}
                    of <strong>{total}</strong> items
                  </p>
                ),
                onChange: (newCurrent, newPageSize) => {
                  const pageSizeChange = pageSizeRef.current !== newPageSize;
                  if (pageSizeChange) {
                    setCurrentPagination(1);
                  } else {
                    setCurrentPagination(newCurrent);
                  }
                  pageSizeRef.current = newPageSize;
                },
              }}
              bordered={true}
              scroll={{
                x: 1200,
              }}
              size="middle"
              sticky
            />
          </ReactDragListView.DragColumn>
        </div>
      </div>
      <Modal
        title="Create Currency"
        open={open}
        onCancel={handleCancel}
        footer={null}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateCurrency handleCancel={handleCancel} handleOk={handleOk} />
      </Modal>
      <Modal
        title={"Currency ID: " + currencyEdit.id}
        open={openDetail}
        onCancel={handleCancelDetail}
        footer={null}
        width={600}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          size="small"
          className="user_tab"
        />
      </Modal>
      <FloatButton.BackTop />
    </div>
  );
}
