import { TimePicker } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import React from "react";
import { useSelector } from "react-redux";
const format = "H:mm";
dayjs.extend(utc);

export default function FormDaily(props) {
  const { userEdit } = useSelector((state) => state.userReducer);
  const onChange = (time) => {
    if (userEdit.timezone === null) {
      const utcHour = dayjs(time.$d).utc().hour();
      let utcMinute = dayjs(time.$d).utc().minute();
      if (utcMinute < 10) {
        utcMinute = "0" + utcMinute;
      }
      props.setTime(`${utcHour}:${utcMinute}`);
    } else {
      let newDay = new Date(
        `${dayjs(time.$d).format("M/DD/YYYY HH:mm:ss")}  UTC ${
          userEdit.timezone.offset
        }`
      );
      const utcHour = newDay.getUTCHours();
      let utcMinute = newDay.getUTCMinutes();
      if (utcMinute < 10) {
        utcMinute = "0" + utcMinute;
      }
      props.setTime(`${utcHour}:${utcMinute}`);
    }
  };
  return (
    <div className="movement_report-row">
      <div className="row_left">
        <p>Time send:</p>
      </div>
      <div className="row_right">
        <TimePicker
          format={format}
          placeholder="Select..."
          defaultValue={dayjs(props.reportDetail.values.reportSendAt, format)}
          onChange={onChange}
          allowClear={false}
        />
      </div>
    </div>
  );
}
