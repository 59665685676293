import React, { useState } from "react";
import { Input, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { convertTime } from "../../Utils/convertTime";
import { useFormik } from "formik";
import * as yup from "yup";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { compareObject } from "../../Utils/compareObject";
import {
  deleteWirelessTechApi,
  restoreWirelessTechApi,
  updateWirelessTechApi,
} from "../../Redux/wirelessTechReducer/wirelessTechReducer";
import { renderUpdater } from "../../Utils/renderUpdater";

export default function WirelessTechDetail({ handleCancelDetail }) {
  const { wirelessTechEdit } = useSelector(
    (state) => state.wirelessTechReducer
  );
  const [showDelete, setShowDelete] = useState(false);
  const [showRestore, setShowRestore] = useState(false);
  const dispatch = useDispatch();

  // Handle modal
  const showModalDelete = () => {
    setShowDelete(true);
  };
  const handleOkModalDelete = () => {
    message.loading("loading", 1);
    const action = deleteWirelessTechApi(wirelessTechEdit.id);
    dispatch(action);
    setShowDelete(false);
    handleCancelDetail();
  };
  const handleCancelModalDelete = () => {
    setShowDelete(false);
  };
  const showModalRestore = () => {
    setShowRestore(true);
  };
  const handleOkModalRestore = () => {
    message.loading("loading", 1);
    const action = restoreWirelessTechApi(wirelessTechEdit.id);
    dispatch(action);
    setShowRestore(false);
  };
  const handleCancelModalRestore = () => {
    setShowRestore(false);
  };

  // Handle form
  const wirelessTechDetailInformation = useFormik({
    initialValues: {
      name: wirelessTechEdit.name,
      shortName: wirelessTechEdit.shortName,
      description: wirelessTechEdit.description,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name cannot be empty"),
      shortName: yup.string().required("Short name cannot be empty"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      const newData = { ...values };
      if (compareObject(newData, wirelessTechEdit)) {
        message.warning("Nothing has changed with this wireless technology");
      } else {
        const newObject = {};
        for (let item in newData) {
          if (newData[item] !== wirelessTechEdit[item]) {
            newObject[item] = newData[item];
          }
        }
        const dataSend = {
          id: wirelessTechEdit.id,
          data: newObject,
        };
        const action = updateWirelessTechApi(dataSend);
        dispatch(action);
        message.loading("loading", 1);
      }
    },
  });
  return (
    <div className="user_detail">
      <div
        className="user_current"
        style={{ cursor: wirelessTechEdit.isDeleted === 1 ? "no-drop" : "" }}
      >
        <form
          className="current_detail"
          onSubmit={wirelessTechDetailInformation.handleSubmit}
        >
          <ul>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>Name:</p>
                  </div>
                  <div className="col_2">
                    <Input
                      name="name"
                      onChange={wirelessTechDetailInformation.handleChange}
                      onBlur={wirelessTechDetailInformation.handleBlur}
                      value={wirelessTechDetailInformation.values.name}
                      size="large"
                      className={
                        wirelessTechDetailInformation.errors.name
                          ? "error_input"
                          : ""
                      }
                      disabled={wirelessTechEdit.isDeleted === 1 ? true : false}
                    />
                    {wirelessTechDetailInformation.errors.name ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span>{wirelessTechDetailInformation.errors.name}</span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>Short name:</p>
                  </div>
                  <div className="col_2">
                    <Input
                      name="shortName"
                      onChange={wirelessTechDetailInformation.handleChange}
                      onBlur={wirelessTechDetailInformation.handleBlur}
                      value={wirelessTechDetailInformation.values.shortName}
                      size="large"
                      className={
                        wirelessTechDetailInformation.errors.shortName
                          ? "error_input"
                          : ""
                      }
                      disabled={wirelessTechEdit.isDeleted === 1 ? true : false}
                    />
                    {wirelessTechDetailInformation.errors.shortName ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span>
                          {wirelessTechDetailInformation.errors.shortName}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>Description:</p>
                  </div>
                  <div className="col_2">
                    <Input.TextArea
                      name="description"
                      onChange={wirelessTechDetailInformation.handleChange}
                      onBlur={wirelessTechDetailInformation.handleBlur}
                      value={wirelessTechDetailInformation.values.description}
                      disabled={wirelessTechEdit.isDeleted === 1 ? true : false}
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="add_action">
            <button
              type="submit"
              disabled={wirelessTechEdit.isDeleted === 1 ? true : false}
              style={{
                cursor:
                  wirelessTechEdit.isDeleted === 1 ? "no-drop" : "pointer",
              }}
            >
              Save Change
            </button>
          </div>
        </form>
      </div>
      {wirelessTechEdit.isDeleted === 0 ? (
        <>
          <div className="user_addition">
            <div className="add_detail">
              <div className="user_delete">
                <h3>
                  <span>
                    <i className="fa fa-exclamation-circle"></i>
                  </span>{" "}
                  Delete Wireless technology
                </h3>
                <div className="user_password_row">
                  <p>This action can't be undone!</p>
                  <button type="button" onClick={showModalDelete}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="user_footer">{renderUpdater(wirelessTechEdit,"wireless technology")}</div>
          </div>
        </>
      ) : (
        <div className="user_addition">
          <div className="add_detail">
            <div className="user_restore">
              <h3>
                <span>
                  <i className="fa fa-history"></i>
                </span>{" "}
                Restore Wireless technology
              </h3>
              <div className="user_password_row">
                <p>
                  This wireless technology has been deleted. If you want to
                  restore it, please click on the Restore button.
                </p>
                <button type="button" onClick={showModalRestore}>
                  Restore
                </button>
              </div>
            </div>
          </div>
          <div className="user_footer">{renderUpdater(wirelessTechEdit,"wireless technology")}</div>
        </div>
      )}
      <Modal
        open={showDelete}
        onCancel={handleCancelModalDelete}
        footer={null}
        width={450}
      >
        <div className="delete_user">
          <p>
            <i className="fa fa-question"></i> Delete Wireless technology
          </p>
          <span>Are you sure to delete this wireless technology?</span>
          <div className="delete_action">
            <button className="cancel" onClick={handleCancelModalDelete}>
              Cancel
            </button>
            <button className="delete" onClick={handleOkModalDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showRestore}
        onCancel={handleCancelModalRestore}
        footer={null}
        width={450}
      >
        <div className="restore_user">
          <p>
            <i className="fa fa-question"></i> Restore Wireless technology
          </p>
          <span>Are you sure to restore this wireless technology?</span>
          <div className="restore_action">
            <button className="cancel" onClick={handleCancelModalRestore}>
              Cancel
            </button>
            <button className="restore" onClick={handleOkModalRestore}>
              Restore
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
