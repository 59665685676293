import React from "react";
import MovementColumnBody from "./MovementColumnBody";

export default function MovementColumnAvailable({ column }) {
  return (
    <div className="movement_column-item" id="available">
      <p>Available columns</p>
      <div className="movement_column-body">
        {column.map((item, index) => {
          if (item.status === "available") {
            return (
              <MovementColumnBody
                item={item}
                id={item.id}
                key={index}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
