import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { message, Input } from "antd";
import { useDispatch } from "react-redux";
import "../../Assets/scss/Component/Movement/_createStateMovement.scss";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { createNewEventType } from "../../Redux/eventReducer/eventTypeReducer";

const CreateEventType = ({ cancelAddType }) => {
  const dispatch = useDispatch();

   // Handle modal
   const handleOk = () => {
    message.loading("loading", 1);
    cancelAddType()
  };

  // Handle form
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      dispatch(createNewEventType(values));
      handleOk(); 
    },
  });


  return (
    <div className="movement_state">
      <form onSubmit={formik.handleSubmit}>
        <div className="movement_state-form">
          <div className="movement_state-field">
            <label>Name*</label>
            <input
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className={
                formik.touched.name && formik.errors.name ? "input_error" : ""
              }
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error_message">
                <i className="fa fa-exclamation-circle"></i>
                <span className="cm_errors">
                  {formik.touched.name && formik.errors.name}
                </span>
              </div>
            ) : null}
          </div>
          <div className="movement_state-field">
            <label>Description</label>
            <Input.TextArea
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="movement_state-submit">
          <button
            type="button"
            className="movement_state-cancel"
            onClick={cancelAddType}
          >
            Cancel
          </button>
          <button type="submit" className="movement_state-create">
            Create
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateEventType;
