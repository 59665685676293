import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { Input, message, Modal } from "antd";
import Select from "react-select";
import {
  deleteDiscount,
  restoreDiscount,
  updateDiscount,
} from "../../Redux/companyReducer/companyReducer";
import { compareObject } from "../../Utils/compareObject";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";
import { renderUpdater } from "../../Utils/renderUpdater";

export default function DetailDiscount({ handleCancelDetail }) {
  const dispatch = useDispatch();
  const [arrWirelessTechSelect, setArrWirelessTechSelect] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showRestore, setShowRestore] = useState(false);
  const { arrWirelessTech } = useSelector((state) => state.wirelessTechReducer);
  const { discountEdit } = useSelector((state) => state.companyReducer);

  // Handle modal
  const showModalDelete = () => {
    setShowDelete(true);
  };
  const handleOkModalDelete = () => {
    message.loading("loading", 1);
    const action = deleteDiscount({
      id: discountEdit.id,
      accountId: discountEdit.accountId,
    });
    dispatch(action);
    setShowDelete(false);
    handleCancelDetail();
  };
  const handleCancelModalDelete = () => {
    setShowDelete(false);
  };
  const showModalRestore = () => {
    setShowRestore(true);
  };
  const handleOkModalRestore = () => {
    message.loading("loading", 1);
    const action = restoreDiscount({
      id: discountEdit.id,
      accountId: discountEdit.accountId,
    });
    dispatch(action);
    setShowRestore(false);
  };
  const handleCancelModalRestore = () => {
    setShowRestore(false);
  };

  // Handle form
  const discountInformation = useFormik({
    initialValues: {
      wirelessTechnologyId: discountEdit.wirelessTechnologyId,
      afterMonth: discountEdit.afterMonth,
      price: discountEdit.price,
    },
    validationSchema: yup.object().shape({
      wirelessTechnologyId: yup
        .mixed()
        .required("Wireless technology cannot be empty"),
      afterMonth: yup.mixed().required("After month cannot be empty"),
      price: yup.mixed().required("Price cannot be empty"),
    }),
    onSubmit: (values) => {
      const newData = { ...values, accountId: discountEdit.accountId };
      if (compareObject(newData, discountEdit)) {
        message.warning("Nothing has changed with this discount");
      } else {
        const newObject = {};
        for (let item in newData) {
          if (newData[item] !== discountEdit[item]) {
            newObject[item] = newData[item];
          }
        }
        const dataSend = {
          id: discountEdit.id,
          data: newObject,
          accountId: discountEdit.accountId,
        };
        const action = updateDiscount(dataSend);
        dispatch(action);
        message.loading("loading", 1);
      }
    },
  });
  useEffect(() => {
    CreateDataForSelect(arrWirelessTech, setArrWirelessTechSelect);
  }, [arrWirelessTech]);

  return (
    <div className="user_detail">
      <div
        className="user_current"
        style={{ cursor: discountEdit.isDeleted === 1 ? "no-drop" : "" }}
      >
        <form
          className="current_detail"
          onSubmit={discountInformation.handleSubmit}
        >
          <ul>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>Wireless technology:</p>
                  </div>
                  <div className="col_2">
                    <Select
                      name="wirelessTechnologyId"
                      options={arrWirelessTechSelect}
                      defaultValue={{
                        value: discountEdit.wirelessTechnologyId,
                        label: discountEdit.wirelessTechnology.name,
                      }}
                      onChange={(e) => {
                        discountInformation.setFieldValue(
                          "wirelessTechnologyId",
                          e.value
                        );
                      }}
                      isDisabled={discountEdit.isDeleted === 1 ? true : false}
                    />

                    {discountInformation.errors.wirelessTechnologyId ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span className="cm_errors">
                          {discountInformation.errors.wirelessTechnologyId}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>After month:</p>
                  </div>
                  <div className="col_2">
                    <Input
                      name="afterMonth"
                      type="number"
                      min={0}
                      placeholder="0"
                      className={
                        discountInformation.errors.afterMonth
                          ? "error_input"
                          : ""
                      }
                      onChange={discountInformation.handleChange}
                      onBlur={discountInformation.handleBlur}
                      value={discountInformation.values.afterMonth}
                      size="large"
                      disabled={discountEdit.isDeleted === 1 ? true : false}
                    />
                    {discountInformation.errors.afterMonth ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span className="cm_errors">
                          {discountInformation.errors.afterMonth}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>Price:</p>
                  </div>
                  <div className="col_2">
                    <Input
                      name="price"
                      type="number"
                      min={0}
                      step={0.1}
                      placeholder="0"
                      className={
                        discountInformation.errors.price ? "error_input" : ""
                      }
                      onChange={discountInformation.handleChange}
                      onBlur={discountInformation.handleBlur}
                      value={discountInformation.values.price}
                      size="large"
                      disabled={discountEdit.isDeleted === 1 ? true : false}
                    />
                    {discountInformation.errors.price ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span className="cm_errors">
                          {discountInformation.errors.price}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="add_action">
            <button
              type="submit"
              disabled={discountEdit.isDeleted === 1 ? true : false}
              style={{
                cursor: discountEdit.isDeleted === 1 ? "no-drop" : "pointer",
              }}
            >
              Save Change
            </button>
          </div>
        </form>
      </div>
      {discountEdit.isDeleted === 0 ? (
        <>
          <div className="user_addition">
            <div className="add_detail">
              <div className="user_delete">
                <h3>
                  <span>
                    <i className="fa fa-exclamation-circle"></i>
                  </span>{" "}
                  Delete Discount
                </h3>
                <div className="user_password_row">
                  <p>This action can't be undone!</p>
                  <button type="button" onClick={showModalDelete}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="user_footer">
              {renderUpdater(discountEdit, "discount")}
            </div>
          </div>
        </>
      ) : (
        <div className="user_addition">
          <div className="add_detail">
            <div className="user_restore">
              <h3>
                <span>
                  <i className="fa fa-history"></i>
                </span>{" "}
                Restore Discount
              </h3>
              <div className="user_password_row">
                <p>
                  This discount has been deleted. If you want to restore it,
                  please click on the Restore button.
                </p>
                <button type="button" onClick={showModalRestore}>
                  Restore
                </button>
              </div>
            </div>
          </div>
          <div className="user_footer">
            {renderUpdater(discountEdit, "discount")}
          </div>
        </div>
      )}
      <Modal
        open={showDelete}
        onCancel={handleCancelModalDelete}
        footer={null}
        width={450}
      >
        <div className="delete_user">
          <p>
            <i className="fa fa-question"></i> Delete Discount
          </p>
          <span>Are you sure to delete this discount?</span>
          <div className="delete_action">
            <button className="cancel" onClick={handleCancelModalDelete}>
              Cancel
            </button>
            <button className="delete" onClick={handleOkModalDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showRestore}
        onCancel={handleCancelModalRestore}
        footer={null}
        width={450}
      >
        <div className="restore_user">
          <p>
            <i className="fa fa-question"></i> Restore Discount
          </p>
          <span>Are you sure to restore this discount?</span>
          <div className="restore_action">
            <button className="cancel" onClick={handleCancelModalRestore}>
              Cancel
            </button>
            <button className="restore" onClick={handleOkModalRestore}>
              Restore
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
