import axiosClient from "./axiosClient";

const purchaseProductApi = {
  addProduct: async (data) => {
    const response = await axiosClient.post("/purchase-product", data);
    return response;
  },
  getAllProduct: async () => {
    const response = await axiosClient.get("/purchase-product");
    return response;
  },
  deleteProduct: async (id) => {
    const response = await axiosClient.delete("/purchase-product/" + id);
    return response;
  },
  updateProduct: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/purchase-product/" + id, data);
    return response;
  },
  getTotalProduct: async () => {
    const response = await axiosClient.get("/purchase-product/total");
    return response;
  },
  changeLog: async (id) => {
    const response = await axiosClient.get("/purchase-product/history/" + id);
    return response;
  },
  restoreProduct: async (id) => {
    const response = await axiosClient.put("/purchase-product/restore/" + id);
    return response;
  },
  findOne: async (id) => {
    const response = await axiosClient.get("/purchase-product/" + id);
    return response;
  },
};
export default purchaseProductApi;
