import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import { useSelector } from "react-redux";
import { Skeleton, Tooltip } from "antd";
import { FileOutlined } from "@ant-design/icons";
import "../../Assets/scss/Component/Device/_deviceHistory.scss";
dayjs.extend(relativeTime);

export default function UserHistory() {
  const { arrChangeLog, isLoading } = useSelector((state) => state.userReducer);
  const { arrCompany } = useSelector((state) => state.companyReducer);
  const showHistory = () => {
    if (isLoading === true) {
      return <Skeleton active avatar />;
    } else {
      if (arrChangeLog.length !== 0) {
        return arrChangeLog.map((item, index) => {
          if (item.action !== "Updated") {
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    {item.action.toLowerCase()}{" "}
                    {dayjs(new Date(item.createdAt)).fromNow()}
                  </p>
                </div>
              </div>
            );
          } else {
            const arrShow = [];
            let newItem = { ...item };
            newItem.arrHistoryShow = arrShow;
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "firstName" in item.beforeData
            ) {
              const firstName = {
                name: "First name",
                old: item.beforeData.firstName,
                new: item.afterData.firstName,
              };
              arrShow.push(firstName);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "lastName" in item.beforeData
            ) {
              const lastName = {
                name: "Last name",
                old: item.beforeData.lastName,
                new: item.afterData.lastName,
              };
              arrShow.push(lastName);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "accountId" in item.beforeData
            ) {
              let beforeCompany = "None";
              let afterCompany = "None";
              if (item.beforeData.accountId !== null) {
                const result = arrCompany.find(
                  (company) => company.id === item.beforeData.accountId
                );
                beforeCompany = result.name;
              }
              if (item.afterData.accountId !== null) {
                const result = arrCompany.find(
                  (company) => company.id === item.afterData.accountId
                );
                afterCompany = result.name;
              }
              const company = {
                name: "Account",
                old: beforeCompany,
                new: afterCompany,
              };
              arrShow.push(company);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "roleId" in item.beforeData
            ) {
              let roleBefore = "";
              let roleAfter = "";
              if (item.beforeData.roleId === 1) {
                roleBefore = "Super Admin";
              }
              if (item.beforeData.roleId === 2) {
                roleBefore = "Admin";
              }
              if (item.beforeData.roleId === 3) {
                roleBefore = "Customer";
              }
              if (item.beforeData.roleId === 4) {
                roleBefore = "Operator";
              }
              if (item.beforeData.roleId === 5) {
                roleBefore = "Guest";
              }
              if (item.afterData.roleId === 1) {
                roleAfter = "Super Admin";
              }
              if (item.afterData.roleId === 2) {
                roleAfter = "Admin";
              }
              if (item.afterData.roleId === 3) {
                roleAfter = "Customer";
              }
              if (item.afterData.roleId === 4) {
                roleAfter = "Operator";
              }
              if (item.afterData.roleId === 5) {
                roleAfter = "Guest";
              }
              const role = {
                name: "Role",
                old: roleBefore,
                new: roleAfter,
              };
              arrShow.push(role);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "isActived" in item.beforeData
            ) {
              let statusBefore = "";
              let statusAfter = "";
              if (item.beforeData.isActived === 0) {
                statusBefore = "Disabled";
              }
              if (item.beforeData.isActived === 1) {
                statusBefore = "Enabled";
              }
              if (item.afterData.isActived === 0) {
                statusAfter = "Disabled";
              }
              if (item.afterData.isActived === 1) {
                statusAfter = "Enabled";
              }
              const status = {
                name: "Status",
                old: statusBefore,
                new: statusAfter,
              };
              arrShow.push(status);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "phone" in item.beforeData
            ) {
              let phoneBefore =
                item.beforeData.phone === null ? "None" : item.beforeData.phone;
              let phoneAfter =
                item.afterData.phone === null ? "None" : item.afterData.phone;
              const phone = {
                name: "Phone",
                old: phoneBefore,
                new: phoneAfter,
              };
              arrShow.push(phone);
            }
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {" "}
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    updated {dayjs(new Date(newItem.createdAt)).fromNow()}
                  </p>
                </div>
                {newItem.arrHistoryShow.map((item, index) => {
                  return (
                    <div key={index} className="description">
                      <strong>{item.name}</strong>: <span>{item.old}</span>
                      <i className="fa fa-long-arrow-alt-right"></i>
                      <span>{item.new}</span>
                    </div>
                  );
                })}
              </div>
            );
          }
        });
      } else {
        return (
          <div className="history_error">
            <div className="icon">
              <FileOutlined />
            </div>
            <p className="title">No data</p>
          </div>
        );
      }
    }
  };
  return <div className="history">{showHistory()}</div>;
}
