import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import eventApi from "../../Services/eventApi";
import { message } from "antd";
import { convertTime } from "../../Utils/convertTime";
import { convertTimeShowDetail } from "../../Utils/convertTimeShowDetail";

export const getAllEventApi = createAsyncThunk(
  "event/getAll",
  async (params, { rejectWithValue }) => {
    try {
      const response = await eventApi.getAll(params);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createNewEventApi = createAsyncThunk(
  "event/createNew",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await eventApi.createNew(data);
      dispatch(getAllEventApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findTotalEventApi = createAsyncThunk(
  "event/findTotal",
  async (_, { rejectWithValue }) => {
    try {
      const response = await eventApi.findTotal();
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateOneEventApi = createAsyncThunk(
  "event/updateOne",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await eventApi.updateOne(params);
      dispatch(getAllEventApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteOneEventApi = createAsyncThunk(
  "event/deleteOne",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await eventApi.deleteOne(id);
      dispatch(getAllEventApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const restoreOneEventApi = createAsyncThunk(
  "event/restoreOne",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await eventApi.restoreOne(id);
      dispatch(getAllEventApi());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findOneEventApi = createAsyncThunk(
  "event/findOne",
  async (id, { rejectWithValue }) => {
    try {
      const response = await eventApi.findOne(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findHistoryEventApi = createAsyncThunk(
  "event/findHistory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await eventApi.findHistory(id);
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const eventReducer = createSlice({
  name: "eventReducer",
  initialState: {
    arrayEvent: [],
    eventEdit: {},
    pagination: {},
    errorMessage: null,
    isLoading: false,
    arrayChangeLog:[]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEventApi.fulfilled, (state, action) => {
        let data = action.payload.data;
        state.pagination = action.payload.pagination;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            item.typeName = item.type.name;
            item.accountName = item.account.name;
            item.supportTime = convertTime(item.supportedAt);
            item.supporterName = `${item.supporter.lastName} ${item.supporter.firstName}`;
            item.costShow = item.cost !== null ? item.cost : "";
            item.addressShow = item.address !== null ? item.address : "";
            item.siteNameShow = item.siteName !== null ? item.siteName : "";
            item.notesShow = item.notes !== null ? item.notes : "";
            newData.push(item);
          }
        }
        state.arrayEvent = newData;
        state.errorMessage = null;
      })
      .addCase(getAllEventApi.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(createNewEventApi.fulfilled, (state, action) => {
        state.errorMessage = null;
        message.success("Create new Event success", 3);
      })
      .addCase(createNewEventApi.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(deleteOneEventApi.fulfilled, (state) => {
        state.errorMessage = null;
        message.success("Delete Event success", 3);
      })
      .addCase(deleteOneEventApi.rejected, (state, action) => {
        state.errorMessage = action.payload;
       message.error(`${state.errorMessage}`, 3);
      })
      .addCase(findOneEventApi.fulfilled, (state,action) => {
        let data = action.payload.data
        data.key = data.id
        data.supportedDateShowDetail = convertTimeShowDetail(data.supportedAt)
        state.eventEdit = data
        state.errorMessage = null;
      })
      .addCase(findOneEventApi.rejected, (state, action) => {
        state.errorMessage = action.payload;
       message.error(`${state.errorMessage}`, 3);
      })
      .addCase(restoreOneEventApi.fulfilled, (state,action) => {
        let data = action.payload.data
        data.key = data.id
        data.supportedDateShowDetail = convertTimeShowDetail(data.supportedAt)
        state.eventEdit = data
        state.errorMessage = null;
        message.success("Restore Event success", 3);
      })
      .addCase(restoreOneEventApi.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(updateOneEventApi.fulfilled, (state,action) => {
        let data = action.payload.data
        data.key = data.id
        data.supportedDateShowDetail = convertTimeShowDetail(data.supportedAt)
        state.eventEdit = data
        state.errorMessage = null;
        message.success("Update Event success", 3);
      })
      .addCase(updateOneEventApi.rejected, (state, action) => {
        state.errorMessage = action.payload;
        message.error(`${state.errorMessage}`, 3);
      })
      .addCase(findHistoryEventApi.fulfilled, (state, action) => {
        state.arrayChangeLog = action.payload.data;
        state.isLoading = false;
        state.errorMessage = null;
      })
      .addCase(findHistoryEventApi.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = null;
      })
      .addCase(findHistoryEventApi.rejected, (state, action) => {
        state.isLoading = false;
        state.arrayChangeLog = [];
        state.errorMessage = action.payload
        message.error(`${state.errorMessage}`, 3);
      })
      
  },
});

export const {} = eventReducer.actions;

export default eventReducer.reducer;
