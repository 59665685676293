import React, { useState } from "react";
import { Input, message, Modal } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import "../../Assets/scss/Component/Company/_detailCompanyType.scss";
import { renderUpdater } from "../../Utils/renderUpdater";
import { compareObject } from "../../Utils/compareObject";
import { deleteEventType, restoreEventType, updateEventType } from "../../Redux/eventReducer/eventTypeReducer";

const EventTypeDetailChild = ({ eventTypeEdit, handleCancelModalDetail }) => {
  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [showRestore, setShowRestore] = useState(false);

  // Handle modal delete and restore
  const showModalDelete = () => {
    setShowDelete(true);
  };
  const handleOkModalDelete = () => {
    message.loading("loading", 1);
    const action = deleteEventType(eventTypeEdit.id);
    dispatch(action);
    setShowDelete(false);
    handleCancelModalDetail();
  };
  const handleCancelModalDelete = () => {
    setShowDelete(false);
  };
  const showModalRestore = () => {
    setShowRestore(true);
  };
  const handleOkModalRestore = () => {
    message.loading("loading", 1);
    const action = restoreEventType(eventTypeEdit.id);
    dispatch(action);
    setShowRestore(false);
  };
  const handleCancelModalRestore = () => {
    setShowRestore(false);
  };

  // Handle form
  const formik = useFormik({
    initialValues: {
      name: eventTypeEdit.name,
      description: eventTypeEdit.description,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name cannot be empty"),
    }),
    onSubmit: (values) => {
      let data = {};
      if (compareObject(values, eventTypeEdit)) {
        message.warning("Nothing has changed with this event type");
      } else {
        for (let item in values) {
          if (values[item] !== eventTypeEdit[item]) {
            data[item] = values[item];
          }
        }
        let dataSend = {
          id: eventTypeEdit.id,
          data: data,
        };
        message.loading("Loading", 1);
        dispatch(updateEventType(dataSend));
      }
    },
  });

  return (
    <div className="detail_type">
      <form onSubmit={formik.handleSubmit}>
        <div
          className="detail_type-content"
          style={{ cursor: eventTypeEdit.isDeleted === 1 ? "no-drop" : "" }}
        >
          <div className="detail_type-field">
            <div className="detail_type-fieldName">
              <p>Name:</p>
            </div>
            <div className="detail_type-fieldInput">
              <Input
                className={formik.errors.name ? "detail_type-errorInput" : ""}
                size="large"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={eventTypeEdit.isDeleted === 1 ? true : false}
              />
              {formik.errors.name ? (
                <div className="detail_type-error">
                  <i className="fas fa-exclamation-circle"></i>
                  <span>{formik.errors.name}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="detail_type-field">
            <div className="detail_type-fieldName">
              <p>Description:</p>
            </div>
            <div className="detail_type-fieldInput">
              <Input.TextArea
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                disabled={eventTypeEdit.isDeleted === 1 ? true : false}
              />
            </div>
          </div>

          <div className="detail_type-submit">
            <button
              type="submit"
              disabled={eventTypeEdit.isDeleted === 1 ? true : false}
              style={{ cursor: eventTypeEdit.isDeleted === 1 ? "no-drop" : "" }}
            >
              Save Change
            </button>
          </div>
        </div>

        {eventTypeEdit.isDeleted === 0 ? (
          <div className="user_addition">
            <div className="add_detail">
              <div className="user_delete">
                <h3>
                  <span>
                    <i className="fa fa-exclamation-circle"></i>
                  </span>{" "}
                  Delete event type
                </h3>
                <div className="user_password_row">
                  <p>This action can't be undone!</p>
                  <button type="button" onClick={showModalDelete}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="user_footer">
              {renderUpdater(eventTypeEdit, "event type")}
            </div>
          </div>
        ) : (
          <div className="user_addition">
            <div className="add_detail">
              <div className="user_restore">
                <h3>
                  <span>
                    <i className="fa fa-history"></i>
                  </span>{" "}
                  Restore event type
                </h3>
                <div className="user_password_row">
                  <p>
                    This event type has been deleted. If you want to restore it,
                    please click on the Restore button.
                  </p>
                  <button type="button" onClick={showModalRestore}>
                    Restore
                  </button>
                </div>
              </div>
            </div>
            <div className="user_footer">
              {renderUpdater(eventTypeEdit, "event type")}
            </div>
          </div>
        )}
      </form>
      <Modal
        open={showDelete}
        onCancel={handleCancelModalDelete}
        footer={null}
        width={450}
      >
        <div className="delete_user">
          <p>
            <i className="fa fa-question"></i> Delete event type
          </p>
          <span>Are you sure to delete this event type?</span>
          <div className="delete_action">
            <button className="cancel" onClick={handleCancelModalDelete}>
              Cancel
            </button>
            <button className="delete" onClick={handleOkModalDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showRestore}
        onCancel={handleCancelModalRestore}
        footer={null}
        width={450}
      >
        <div className="restore_user">
          <p>
            <i className="fa fa-question"></i> Restore event type
          </p>
          <span>Are you sure to restore this event type?</span>
          <div className="restore_action">
            <button className="cancel" onClick={handleCancelModalRestore}>
              Cancel
            </button>
            <button className="restore" onClick={handleOkModalRestore}>
              Restore
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EventTypeDetailChild;
