import React, { useState } from "react";
import { Input, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSaleProductApi,
  restoreSaleProductApi,
  updateSaleProduct,
} from "../../Redux/productReducer/saleProductReducer";
import { useFormik } from "formik";
import * as yup from "yup";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { compareObject } from "../../Utils/compareObject";
import { renderUpdater } from "../../Utils/renderUpdater";

export default function PurchaseProductDetail(props) {
  const { productEdit } = useSelector((state) => state.saleProductReducer);
  const [showDelete, setShowDelete] = useState(false);
  const [showRestore, setShowRestore] = useState(false);
  const dispatch = useDispatch();
 
  // Handle modal
  const showModalDelete = () => {
    setShowDelete(true);
  };
  const handleOkModalDelete = () => {
    message.loading("loading", 1);
    const action = deleteSaleProductApi(productEdit.id);
    dispatch(action);
    setShowDelete(false);
    props.handleCancelSaleDetail();
  };
  const handleCancelModalDelete = () => {
    setShowDelete(false);
  };
  const showModalRestore = () => {
    setShowRestore(true);
  };
  const handleOkModalRestore = () => {
    message.loading("loading", 1);
    const action = restoreSaleProductApi(productEdit.id);
    dispatch(action);
    setShowRestore(false);
  };
  const handleCancelModalRestore = () => {
    setShowRestore(false);
  };

  // Handle form
  const saleProductDetailInformation = useFormik({
    initialValues: {
      name: productEdit.name,
      shortName: productEdit.shortName,
      haasPrice: productEdit.haasPrice,
      baseSubsPrice: productEdit.baseSubsPrice,
      hwPrice: productEdit.hwPrice,
      otherCost: productEdit.otherCost,
      description: productEdit.description,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name cannot be empty"),
      shortName: yup.string().required("Short name cannot be empty"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      const newData = { ...values };
      if (compareObject(newData, productEdit)) {
        message.warning("Nothing has changed with this sale product");
      } else {
        const newObject = {};
        for (let item in newData) {
          if (newData[item] !== productEdit[item]) {
            newObject[item] = newData[item];
          }
        }
        const dataSend = {
          id: productEdit.id,
          data: newObject,
        };
        const action = updateSaleProduct(dataSend);
        dispatch(action);
        message.loading("loading", 1);
      }
    },
  });
  return (
    <div className="user_detail">
      <div
        className="user_current"
        style={{ cursor: productEdit.isDeleted === 1 ? "no-drop" : "" }}
      >
        <form
          className="current_detail"
          onSubmit={saleProductDetailInformation.handleSubmit}
        >
          <ul>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>Name:</p>
                  </div>
                  <div className="col_2">
                    <Input
                      name="name"
                      onChange={saleProductDetailInformation.handleChange}
                      onBlur={saleProductDetailInformation.handleBlur}
                      value={saleProductDetailInformation.values.name}
                      size="large"
                      className={
                        saleProductDetailInformation.errors.name
                          ? "error_input"
                          : ""
                      }
                      disabled={productEdit.isDeleted === 1 ? true : false}
                    />
                    {saleProductDetailInformation.errors.name ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span>{saleProductDetailInformation.errors.name}</span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>Short name:</p>
                  </div>
                  <div className="col_2">
                    <Input
                      name="shortName"
                      onChange={saleProductDetailInformation.handleChange}
                      onBlur={saleProductDetailInformation.handleBlur}
                      value={saleProductDetailInformation.values.shortName}
                      size="large"
                      className={
                        saleProductDetailInformation.errors.shortName
                          ? "error_input"
                          : ""
                      }
                      disabled={productEdit.isDeleted === 1 ? true : false}
                    />
                    {saleProductDetailInformation.errors.shortName ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span>
                          {saleProductDetailInformation.errors.shortName}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>Haas price:</p>
                  </div>
                  <div className="col_2">
                    <Input
                      name="haasPrice"
                      type="number"
                      step={0.01}
                      min={0}
                      onChange={saleProductDetailInformation.handleChange}
                      onBlur={saleProductDetailInformation.handleBlur}
                      value={saleProductDetailInformation.values.haasPrice}
                      size="large"
                      disabled={productEdit.isDeleted === 1 ? true : false}
                    />
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>Base subs price:</p>
                  </div>
                  <div className="col_2">
                    <Input
                      name="baseSubsPrice"
                      type="number"
                      step={0.01}
                      min={0}
                      onChange={saleProductDetailInformation.handleChange}
                      onBlur={saleProductDetailInformation.handleBlur}
                      value={saleProductDetailInformation.values.baseSubsPrice}
                      size="large"
                      disabled={productEdit.isDeleted === 1 ? true : false}
                    />
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>Hw price:</p>
                  </div>
                  <div className="col_2">
                    <Input
                      name="hwPrice"
                      type="number"
                      step={0.01}
                      min={0}
                      onChange={saleProductDetailInformation.handleChange}
                      onBlur={saleProductDetailInformation.handleBlur}
                      value={saleProductDetailInformation.values.hwPrice}
                      size="large"
                      disabled={productEdit.isDeleted === 1 ? true : false}
                    />
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>Other cost:</p>
                  </div>
                  <div className="col_2">
                    <Input
                      name="otherCost"
                      type="number"
                      step={0.01}
                      min={0}
                      onChange={saleProductDetailInformation.handleChange}
                      onBlur={saleProductDetailInformation.handleBlur}
                      value={saleProductDetailInformation.values.otherCost}
                      size="large"
                      disabled={productEdit.isDeleted === 1 ? true : false}
                    />
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_row">
                <div className="row_1">
                  <div className="col_1">
                    <p>Description:</p>
                  </div>
                  <div className="col_2">
                    <Input.TextArea
                      name="description"
                      onChange={saleProductDetailInformation.handleChange}
                      onBlur={saleProductDetailInformation.handleBlur}
                      value={saleProductDetailInformation.values.description}
                      disabled={productEdit.isDeleted === 1 ? true : false}
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="add_action">
            <button
              type="submit"
              disabled={productEdit.isDeleted === 1 ? true : false}
              style={{
                cursor: productEdit.isDeleted === 1 ? "no-drop" : "pointer",
              }}
            >
              Save Change
            </button>
          </div>
        </form>
      </div>
      {productEdit.isDeleted === 0 ? (
        <>
          <div className="user_addition">
            <div className="add_detail">
              <div className="user_delete">
                <h3>
                  <span>
                    <i className="fa fa-exclamation-circle"></i>
                  </span>{" "}
                  Delete sales product
                </h3>
                <div className="user_password_row">
                  <p>This action can't be undone!</p>
                  <button type="button" onClick={showModalDelete}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="user_footer">{renderUpdater(productEdit,"product")}</div>
          </div>
        </>
      ) : (
        <div className="user_addition">
          <div className="add_detail">
            <div className="user_restore">
              <h3>
                <span>
                  <i className="fa fa-history"></i>
                </span>{" "}
                Restore sales product
              </h3>
              <div className="user_password_row">
                <p>
                  This sales product has been deleted. If you want to restore
                  it, please click on the Restore button.
                </p>
                <button type="button" onClick={showModalRestore}>
                  Restore
                </button>
              </div>
            </div>
          </div>
          <div className="user_footer">{renderUpdater(productEdit,"product")}</div>
        </div>
      )}
      <Modal
        open={showDelete}
        onCancel={handleCancelModalDelete}
        footer={null}
        width={450}
      >
        <div className="delete_user">
          <p>
            <i className="fa fa-question"></i> Delete sales product
          </p>
          <span>Are you sure to delete this sales product?</span>
          <div className="delete_action">
            <button className="cancel" onClick={handleCancelModalDelete}>
              Cancel
            </button>
            <button className="delete" onClick={handleOkModalDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showRestore}
        onCancel={handleCancelModalRestore}
        footer={null}
        width={450}
      >
        <div className="restore_user">
          <p>
            <i className="fa fa-question"></i> Restore sales product
          </p>
          <span>Are you sure to restore this sales product?</span>
          <div className="restore_action">
            <button className="cancel" onClick={handleCancelModalRestore}>
              Cancel
            </button>
            <button className="restore" onClick={handleOkModalRestore}>
              Restore
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
