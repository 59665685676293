import { Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "../../Assets/scss/Component/Product/_createPurchaseProduct.scss";
import Select from "react-select";
import { addPurchaseProductApi } from "../../Redux/productReducer/purchaseProductReducer";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { getAllWirelessTechApi } from "../../Redux/wirelessTechReducer/wirelessTechReducer";
import CreateWirelessTech from "./CreateWirelessTech";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";

export default function CreatePurchaseProduct(props) {
  const dispatch = useDispatch();
  const [openCreate, setOpenCreate] = useState(false);
  const { arrPlatform } = useSelector((state) => state.platformReducer);
  const { arrWirelessTech } = useSelector((state) => state.wirelessTechReducer);
  const [arrPlatformSelectBox, setArrPlatformSelectBox] = useState([]);
  const [arrWirelessTechSelect, setArrWirelessTechSelect] = useState([]);

  // Call api
  useEffect(() => {
    dispatch(getAllWirelessTechApi());
  }, []);

  // Handle modal
  const showModalCreate = () => {
    setOpenCreate(true);
  };
  const handleCancelCreate = () => {
    setOpenCreate(false);
  };

  // Handle form
  const purchaseProductInformation = useFormik({
    initialValues: {
      name: "",
      shortName: "",
      wirelessTechnologyId: null,
      description: null,
      platformId: null,
      unitCost: "",
      activationCost: "",
      batteriesCost: "",
      otherCost: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      shortName: yup.string().required("Short name is required"),
      wirelessTechnologyId: yup
        .mixed()
        .required("Wireless technology is required"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      const action = addPurchaseProductApi(values);
      dispatch(action);
      message.loading("loading", 1);
      props.handleCancel();
    },
  });

  useEffect(() => {
    CreateDataForSelect(arrPlatform, setArrPlatformSelectBox);
  }, [arrPlatform]);

  useEffect(() => {
    CreateDataForSelect(arrWirelessTech, setArrWirelessTechSelect);
  }, [arrWirelessTech]);
  return (
    <div>
      <form
        className="createPurchase_product"
        onSubmit={purchaseProductInformation.handleSubmit}
      >
        <div className="purchase_body">
          <div className="purchase_row">
            <div className="purchase_col">
              <div className="purchase_item">
                <p>Name*</p>
                <Input
                  name="name"
                  onChange={purchaseProductInformation.handleChange}
                  onBlur={purchaseProductInformation.handleBlur}
                  value={purchaseProductInformation.values.name}
                  size="large"
                  className={
                    purchaseProductInformation.errors.name ? "error_input" : ""
                  }
                />
                {purchaseProductInformation.errors.name ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span className="cm_errors">
                      {purchaseProductInformation.errors.name}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="purchase_col">
              <div className="purchase_item">
                <p>Short name*</p>
                <Input
                  name="shortName"
                  onChange={purchaseProductInformation.handleChange}
                  onBlur={purchaseProductInformation.handleBlur}
                  value={purchaseProductInformation.values.shortName}
                  size="large"
                  className={
                    purchaseProductInformation.errors.shortName
                      ? "error_input"
                      : ""
                  }
                />
                {purchaseProductInformation.errors.shortName ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span className="cm_errors">
                      {purchaseProductInformation.errors.shortName}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="purchase_row">
            <div className="purchase_col">
              <div className="purchase_item">
                <p>Wireless technology*</p>
                <div className="wireless_row">
                  <div className="wireless_item-left">
                    <Select
                      name="wirelessTechnologyId"
                      options={arrWirelessTechSelect}
                      onChange={(e) => {
                        purchaseProductInformation.setFieldValue(
                          "wirelessTechnologyId",
                          e.value
                        );
                      }}
                      className={
                        purchaseProductInformation.errors.wirelessTechnologyId
                          ? "error_select"
                          : ""
                      }
                    />
                    {purchaseProductInformation.errors.wirelessTechnologyId ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span className="cm_errors">
                          {
                            purchaseProductInformation.errors
                              .wirelessTechnologyId
                          }
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="wireless_item-right">
                    <button type="button" onClick={showModalCreate}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
              <div className="purchase_item">
                <p>Unit cost</p>
                <Input
                  name="unitCost"
                  type="number"
                  step={0.01}
                  min={0}
                  onChange={purchaseProductInformation.handleChange}
                  onBlur={purchaseProductInformation.handleBlur}
                  value={purchaseProductInformation.values.unitCost}
                  size="large"
                  placeholder="0"
                />
              </div>
              <div className="purchase_item">
                <p>Batteries cost</p>
                <Input
                  name="batteriesCost"
                  type="number"
                  step={0.01}
                  min={0}
                  onChange={purchaseProductInformation.handleChange}
                  onBlur={purchaseProductInformation.handleBlur}
                  value={purchaseProductInformation.values.batteriesCost}
                  size="large"
                  placeholder="0"
                />
              </div>
            </div>
            <div className="purchase_col">
              <div className="purchase_item">
                <p>Source platform</p>
                <Select
                  options={arrPlatformSelectBox}
                  name="platformId"
                  onChange={(e) => {
                    if (e !== null) {
                      purchaseProductInformation.setFieldValue(
                        "platformId",
                        e.value
                      );
                    } else {
                      purchaseProductInformation.setFieldValue("platformId", e);
                    }
                  }}
                  className="select"
                />
              </div>

              <div className="purchase_item">
                <p>Activation cost</p>
                <Input
                  name="activationCost"
                  type="number"
                  step={0.01}
                  min={0}
                  onChange={purchaseProductInformation.handleChange}
                  onBlur={purchaseProductInformation.handleBlur}
                  value={purchaseProductInformation.values.activationCost}
                  size="large"
                  placeholder="0"
                />
              </div>
              <div className="purchase_item">
                <p>Other cost</p>
                <Input
                  name="otherCost"
                  type="number"
                  step={0.01}
                  min={0}
                  onChange={purchaseProductInformation.handleChange}
                  onBlur={purchaseProductInformation.handleBlur}
                  value={purchaseProductInformation.values.otherCost}
                  size="large"
                  placeholder="0"
                />
              </div>
            </div>
            <div className="purchase_description">
              <p>Description</p>
              <Input.TextArea
                name="description"
                onChange={purchaseProductInformation.handleChange}
                onBlur={purchaseProductInformation.handleBlur}
                value={purchaseProductInformation.values.description}
              />
            </div>
          </div>
        </div>

        <div className="form_action">
          <button
            className="cancel"
            type="button"
            onClick={() => {
              props.handleCancel();
            }}
          >
            Cancel
          </button>
          <button className="create" type="submit">
            Create
          </button>
        </div>
      </form>
      <Modal
        title="Create Wireless Technology"
        open={openCreate}
        onCancel={handleCancelCreate}
        footer={null}
        width={500}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateWirelessTech handleCancelCreate={handleCancelCreate} />
      </Modal>
    </div>
  );
}
