import React from "react";
import { Input } from "antd";
import "../../Assets/scss/Component/User/_createUser.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { addUserApi } from "../../Redux/userReducer/userReducer";

export default function CreateUser({ handleOk, handleCancel }) {
  const dispatch = useDispatch();
  const userInformation = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("Email is not valid")
        .required("Email is required"),
      firstName: yup.string().required("First name is required"),
      lastName: yup.string().required("Last name is required"),
      password: yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      const action = addUserApi(values);
      dispatch(action);
      handleOk();
    },
  });
  return (
    <form onSubmit={userInformation.handleSubmit}>
      <div className="create_user">
        <div className="user_field">
          <p>Email*</p>
          <Input
            name="email"
            onChange={userInformation.handleChange}
            onBlur={userInformation.handleBlur}
            value={userInformation.values.email}
            size="large"
            className={userInformation.errors.email ? "error_input" : ""}
          />

          {userInformation.errors.email ? (
            <div className="error_message">
              <i className="fa fa-exclamation-circle"></i>
              <span className="cm_errors">{userInformation.errors.email}</span>
            </div>
          ) : null}
        </div>
        <div className="user_field">
          <p>First name*</p>
          <Input
            name="firstName"
            className={userInformation.errors.firstName ? "error_input" : ""}
            onChange={userInformation.handleChange}
            onBlur={userInformation.handleBlur}
            value={userInformation.values.firstName}
            size="large"
          />
          {userInformation.errors.firstName ? (
            <div className="error_message">
              <i className="fa fa-exclamation-circle"></i>
              <span className="cm_errors">
                {userInformation.errors.firstName}
              </span>
            </div>
          ) : null}
        </div>
        <div className="user_field">
          <p>Last name*</p>
          <Input
            name="lastName"
            className={userInformation.errors.lastName ? "error_input" : ""}
            onChange={userInformation.handleChange}
            onBlur={userInformation.handleBlur}
            value={userInformation.values.lastName}
            size="large"
          />
          {userInformation.errors.lastName ? (
            <div className="error_message">
              <i className="fa fa-exclamation-circle"></i>
              <span className="cm_errors">
                {userInformation.errors.lastName}
              </span>
            </div>
          ) : null}
        </div>
        <div className="user_field">
          <p>Password*</p>
          <Input.Password
            name="password"
            onChange={userInformation.handleChange}
            onBlur={userInformation.handleBlur}
            value={userInformation.values.password}
            size="large"
            className={userInformation.errors.password ? "error_input" : ""}
          />
          {userInformation.errors.password ? (
            <div className="error_message">
              <i className="fa fa-exclamation-circle"></i>
              <span className="cm_errors">
                {userInformation.errors.password}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="form_action">
        <button
          type="button"
          className="cancel"
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </button>
        <button type="submit" className="create">
          Create
        </button>
      </div>
    </form>
  );
}
