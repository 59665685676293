import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Table, Empty, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTotalMovementToday } from "../../Redux/movementReducer/movementReducer";
import { findTotalDeviceToday } from "../../Redux/deviceReducer/deviceReducer";
import { convertTime } from "../../Utils/convertTime";
import { convertLongToShortESN } from "../../Utils/convertESN";

export default function DashboardBottom() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTotalMovementToday());
    dispatch(findTotalDeviceToday());
  }, []);
  const [searchValue, setSearchValue] = useState("");
  const { totalMovementToday } = useSelector((state) => state.movementReducer);
  const { totalDeviceToday } = useSelector((state) => state.deviceReducer);
  const columns = [
    {
      title: "ESN",
      dataIndex: "shortESN",
      key: "shortESN",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.shortESN?.toLowerCase().includes(value.toLowerCase()) ||
          record.movementAt?.toLowerCase().includes(value.toLowerCase()) ||
          record.startBillAt?.toLowerCase().includes(value.toLowerCase()) ||
          record.fromAccountName?.toLowerCase().includes(value.toLowerCase()) ||
          record.toAccountName?.toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.shortESN?.toLowerCase() > afterItem.shortESN?.toLowerCase()
          ? 1
          : beforeItem.shortESN?.toLowerCase() ===
            afterItem.shortESN?.toLowerCase()
          ? 0
          : -1,
      render: (text, record) => (
        <span className="movement_action-modal">{text}</span>
      ),
    },
    {
      title: "Movement date",
      dataIndex: "movementAt",
      key: "movementAt",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.movementDate?.toLowerCase() >
        afterItem.movementDate?.toLowerCase()
          ? 1
          : beforeItem.movementDate?.toLowerCase() ===
            afterItem.movementDate?.toLowerCase()
          ? 0
          : -1,
      render: (record) => <strong>{record}</strong>,
    },
    {
      title: "Type",
      dataIndex: "typeShow",
      key: "typeShow",
      align: "center",
      render: (record) => {
        if (record === "new-stock") {
          return (
            <Tag color="#4cbc9a" style={{ margin: "0" }}>
              {record}
            </Tag>
          );
        } else if (record === "movement") {
          return (
            <Tag color="#ffa500" style={{ margin: "0" }}>
              {record}
            </Tag>
          );
        } else if (record === "return-stock") {
          return (
            <Tag color="#ff0f0f" style={{ margin: "0" }}>
              {record}
            </Tag>
          );
        } else if (record === "warranty") {
          return (
            <Tag color="#ad82d9" style={{ margin: "0" }}>
              {record}
            </Tag>
          );
        } else if (record === "return-after-warranty") {
          return (
            <Tag color="#60a4bf" style={{ margin: "0" }}>
              {record}
            </Tag>
          );
        } else {
          return (
            <Tag color="#BC85A3" style={{ margin: "0" }}>
              {record}
            </Tag>
          );
        }
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.typeShow?.toLowerCase() > afterItem.typeShow?.toLowerCase()
          ? 1
          : beforeItem.typeShow?.toLowerCase() ===
            afterItem.typeShow?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "FROM account",
      dataIndex: "fromAccountName",
      key: "fromAccountName",
      align: "center",
      render: (text) => <span style={{ color: "#2962ff" }}>{text}</span>,
      sorter: (beforeItem, afterItem) =>
        beforeItem.fromAccountName?.toLowerCase() >
        afterItem.fromAccountName?.toLowerCase()
          ? 1
          : beforeItem.fromAccountName?.toLowerCase() ===
            afterItem.fromAccountName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "TO account",
      dataIndex: "toAccountName",
      key: "toAccountName",
      align: "center",
      render: (text) => <span style={{ color: "#2962ff" }}>{text}</span>,
      sorter: (beforeItem, afterItem) =>
        beforeItem.toAccountName?.toLowerCase() >
        afterItem.toAccountName?.toLowerCase()
          ? 1
          : beforeItem.toAccountName?.toLowerCase() ===
            afterItem.toAccountName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Start bill date",
      dataIndex: "startBillAt",
      key: "startBillAt",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.startBillDate?.toLowerCase() >
        afterItem.startBillDate?.toLowerCase()
          ? 1
          : beforeItem.startBillDate?.toLowerCase() ===
            afterItem.startBillDate?.toLowerCase()
          ? 0
          : -1,
      render: (record) => <strong>{record}</strong>,
    },
  ];
  const [columnSelected, setColumnSelected] = useState(columns);

  for (let index = 0; index < columnSelected.length; index++) {
    let item = columnSelected[index];
    item.filteredValue = [searchValue];
  }

  // Render new device
  const renderNewDeviceToday = () => {
    if (totalDeviceToday.length === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    } else {
      return totalDeviceToday.map((item, index) => {
        if (item !== totalDeviceToday[0]) {
          return (
            <div className="body_item" key={index}>
              <div className="body_item-line">
                <div className="body_item-dot"></div>
              </div>
              <div className="body_item-text">
                <p className="body_item-time">{convertTime(item.createdAt)}</p>
                <p className="body_item-name">
                  <strong>{`${item.author.firstName} ${item.author.lastName}`}</strong>{" "}
                  created new device:
                </p>
                <div className="body_item-esn">
                  <span>{convertLongToShortESN(item.serialNumber)}</span>
                </div>
                <div className="body_item-supplier">
                  <i>
                    From supplier: <strong>{item.supplier.name}</strong>
                  </i>
                </div>
              </div>
              <div className="body_item-notice" style={{ opacity: "0" }}>
                <span>New</span>
              </div>
            </div>
          );
        } else {
          return (
            <div className="body_item" key={index}>
              <div className="body_item-line">
                <div className="body_item-dot"></div>
              </div>
              <div className="body_item-text">
                <p className="body_item-time">{convertTime(item.createdAt)}</p>
                <p className="body_item-name">
                  <strong>{`${item.author.firstName} ${item.author.lastName}`}</strong>{" "}
                  created new device:
                </p>
                <div className="body_item-esn">
                  <span>{convertLongToShortESN(item.serialNumber)}</span>
                </div>
                <div className="body_item-supplier">
                  <i>
                    From supplier: <strong>{item.supplier.name}</strong>
                  </i>
                </div>
              </div>
              <div className="body_item-notice">
                <span>New</span>
              </div>
            </div>
          );
        }
      });
    }
  };
  return (
    <div className="dashboard_bot">
      <div className="dashboard_bot-left">
        <div className="left_title">
          <h4>Daily movement</h4>
          <div className="search_area">
            <SearchOutlined className="search_icon" />
            <Input
              className="search_box"
              placeholder="Search..."
              allowClear
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="left_body">
          <Table
            dataSource={totalMovementToday}
            columns={[...columnSelected]}
            pagination={{
              defaultPageSize: 5,
              defaultCurrent: 1,
              showSizeChanger: true,
              showTotal: (total, range) => (
                <p>
                  Showing{" "}
                  <strong>
                    {range[0]}-{range[1]}
                  </strong>{" "}
                  of <strong>{total}</strong> items
                </p>
              ),
            }}
            scroll={{
              x: 700,
            }}
            bordered={true}
            size="middle"
          />
        </div>
      </div>
      <div className="dashboard_bot-right">
        <div className="right_title">
          <h4>New device today</h4>
        </div>
        <div className="right_body">{renderNewDeviceToday()}</div>
      </div>
    </div>
  );
}
