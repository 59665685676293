import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { Input, Modal, Popconfirm, Space, Table, message } from "antd";
import {
  addCompanyTypes,
  deleteCompanyTypes,
  findOneCompanyTypes,
} from "../../Redux/companyReducer/companyTypesReducer";
import "../../Assets/scss/Component/Company/_createTypeCompany.scss";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import DetailTypes from "./DetailTypes";

const CreateTypes = ({ handleCancelModal, setResetSelect, resetSelect }) => {
  const dispatch = useDispatch();
  const { arrCompanyTypes } = useSelector((state) => state.companyTypesReducer);
  const { companyTypesEdit } = useSelector(
    (state) => state.companyTypesReducer
  );

  // Handle modal
  const [modalDetail, setModalDetail] = useState(false);
  const handleOpenModalDetail = () => {
    setModalDetail(true);
  };
  const handleCancelModalDetail = () => {
    setModalDetail(false);
  };

  // Use for table
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "30%",
      sorter: (a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : a.name.toLowerCase() === b.name.toLowerCase()
          ? 0
          : -1,
      render: (text, record) => (
        <span
          className="movement_action-modal"
          onClick={async () => {
            await dispatch(findOneCompanyTypes(record.id));
            handleOpenModalDetail();
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "50%",
      sorter: (a, b) =>
        a.description.toLowerCase() > b.description.toLowerCase()
          ? 1
          : a.description.toLowerCase() === b.description.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "15%",
      render: (_, record) => (
        <Space size="middle" className="user_action">
          <Popconfirm
            title="Delete account type"
            placement="leftBottom"
            description="Are you sure to delete this account type?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              dispatch(deleteCompanyTypes(record.id));
            }}
          >
            <button type="button">
              <DeleteOutlined className="user_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Handle form
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      dispatch(addCompanyTypes(values));
      message.loading("loading", 1);
      formik.resetForm();
    },
  });

  const cancelCreate = () => {
    formik.resetForm();
    handleCancelModal();
    setResetSelect(!resetSelect);
  };

  return (
    <>
      <div className="create_type">
        <p>Create new account type:</p>
        <form onSubmit={formik.handleSubmit}>
          <div className="create_type-form">
            <div className="create_type-field">
              <p>Name*</p>
              <Input
                className={
                  formik.touched.name && formik.errors.name
                    ? "create_type-errorsInput"
                    : ""
                }
                size="large"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="create_type-errors">
                  <i className="fas fa-exclamation-circle"></i>
                  <span>{formik.errors.name}</span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="create_type-field">
              <p>Description</p>
              <Input.TextArea
                size="small"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="create_type-submit">
            <button type="submit" className="create_type-create">
              Create
            </button>
          </div>
        </form>
        <div className="create_type-detail">
          <p>Account type list</p>
          <div className="create_type-table">
            <Table
              columns={columns}
              dataSource={arrCompanyTypes}
              pagination={{
                defaultPageSize: 5,
                defaultCurrent: 1,
                showSizeChanger: true,
                showTotal: (total, range) => (
                  <p>
                    Showing{" "}
                    <strong>
                      {range[0]}-{range[1]}
                    </strong>{" "}
                    of <strong>{total}</strong> items
                  </p>
                ),
              }}
              bordered={true}
              size="small"
            />
          </div>
        </div>
      </div>
      <div className="create_type-cancel">
        <button onClick={() => cancelCreate()}>Close</button>
      </div>
      <Modal
        title={"Account Type Id : " + companyTypesEdit.id}
        open={modalDetail}
        onCancel={handleCancelModalDetail}
        width={600}
        footer={null}
        destroyOnClose
      >
        <DetailTypes companyTypesEdit={companyTypesEdit} />
      </Modal>
    </>
  );
};

export default CreateTypes;
