import React from "react";
import { Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateBillingPolicy } from "../../Redux/billingPolicyReducer/billingPolicyReducer";
import "../../Assets/scss/Component/Company/_detailCompanyType.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { compareObject } from "../../Utils/compareObject";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { renderUpdater } from "../../Utils/renderUpdater";

const DetailBillingPolicy = () => {
  const dispatch = useDispatch();
  const { arrBillingPolicyEdit } = useSelector(
    (state) => state.billingPolicyReducer
  );

  // Handle form
  const formik = useFormik({
    initialValues: {
      name: arrBillingPolicyEdit.name,
      description: arrBillingPolicyEdit.description,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name cannot be empty"),
    }),
    onSubmit: (values) => {
      let data = {};
      if (compareObject(values, arrBillingPolicyEdit)) {
        message.warning("Nothing has changed with this billing policy");
      } else {
        convertEmptyToNull(values);
        for (let item in values) {
          if (values[item] !== arrBillingPolicyEdit[item]) {
            data[item] = values[item];
          }
        }
        let dataSend = {
          id: arrBillingPolicyEdit.id,
          data: data,
        };
        message.loading("Loading", 1);
        dispatch(updateBillingPolicy(dataSend));
      }
    },
  });

  return (
    <div className="detail_type">
      <form onSubmit={formik.handleSubmit}>
        <div className="detail_type-content">
          <div className="detail_type-field">
            <div className="detail_type-fieldName">
              <p>Name:</p>
            </div>
            <div className="detail_type-fieldInput">
              <Input
                className={formik.errors.name ? "detail_type-errorInput" : ""}
                size="large"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="detail_type-error">
                  <i className="fas fa-exclamation-circle"></i>
                  <span>{formik.errors.name}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="detail_type-field">
            <div className="detail_type-fieldName">
              <p>Description:</p>
            </div>
            <div className="detail_type-fieldInput">
              <Input.TextArea
                size="small"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="detail_type-submit">
            <button type="submit">Save Change</button>
          </div>
        </div>

        <div className="detail_type-footer">
          {renderUpdater(arrBillingPolicyEdit, "billing policy")}
        </div>
      </form>
    </div>
  );
};

export default DetailBillingPolicy;
