import React from "react";
import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import "../../Assets/scss/Component/Product/_createSaleProduct.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { addSaleProductApi } from "../../Redux/productReducer/saleProductReducer";
import { useDispatch } from "react-redux";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";

export default function CreateSaleProduct(props) {
  const dispatch = useDispatch();

  // Handle form
  const saleProductInformation = useFormik({
    initialValues: {
      name: "",
      shortName: "",
      description: null,
      otherCost: "",
      haasPrice: "",
      baseSubsPrice: "",
      hwPrice: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      shortName: yup.string().required("Short name is required"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      const action = addSaleProductApi(values);
      dispatch(action);
      props.handleOkCreateSale();
    },
  });
  return (
    <div>
      <form onSubmit={saleProductInformation.handleSubmit}>
        <div className="sale_row">
          <div className="sale_col">
            <div className="sale_col1">
              <div className="sale_item">
                <p>Name*</p>
                <Input
                  name="name"
                  onChange={saleProductInformation.handleChange}
                  onBlur={saleProductInformation.handleBlur}
                  value={saleProductInformation.values.name}
                  size="large"
                  className={
                    saleProductInformation.errors.name ? "input_error" : ""
                  }
                />
                {saleProductInformation.errors.name ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span className="cm_errors">
                      {saleProductInformation.errors.name}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="sale_item">
                <p>Other cost</p>
                <Input
                  name="otherCost"
                  type="number"
                  step={0.01}
                  min={0}
                  onChange={saleProductInformation.handleChange}
                  onBlur={saleProductInformation.handleBlur}
                  value={saleProductInformation.values.otherCost}
                  size="large"
                  placeholder="0"
                />
              </div>
              <div className="sale_item">
                <p>Haas price</p>
                <Input
                  name="haasPrice"
                  type="number"
                  step={0.01}
                  min={0}
                  onChange={saleProductInformation.handleChange}
                  onBlur={saleProductInformation.handleBlur}
                  value={saleProductInformation.values.haasPrice}
                  size="large"
                  placeholder="0"
                />
              </div>
            </div>
            <div className="sale_col1">
              <div className="sale_item">
                <p>Short name*</p>
                <Input
                  name="shortName"
                  onChange={saleProductInformation.handleChange}
                  onBlur={saleProductInformation.handleBlur}
                  value={saleProductInformation.values.shortName}
                  size="large"
                  className={
                    saleProductInformation.errors.shortName ? "input_error" : ""
                  }
                />
                {saleProductInformation.errors.shortName ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span className="cm_errors">
                      {saleProductInformation.errors.shortName}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="sale_item">
                <p>Base subs price</p>
                <Input
                  name="baseSubsPrice"
                  type="number"
                  step={0.01}
                  min={0}
                  onChange={saleProductInformation.handleChange}
                  onBlur={saleProductInformation.handleBlur}
                  value={saleProductInformation.values.baseSubsPrice}
                  size="large"
                  placeholder="0"
                />
              </div>
              <div className="sale_item">
                <p>Hw price</p>
                <Input
                  name="hwPrice"
                  type="number"
                  step={0.01}
                  min={0}
                  onChange={saleProductInformation.handleChange}
                  onBlur={saleProductInformation.handleBlur}
                  value={saleProductInformation.values.hwPrice}
                  size="large"
                  placeholder="0"
                />
              </div>
            </div>
          </div>

          <div className="sale_item">
            <p>Description</p>
            <TextArea
              name="description"
              onChange={saleProductInformation.handleChange}
              onBlur={saleProductInformation.handleBlur}
              value={saleProductInformation.values.description}
            />
          </div>
        </div>
        <div className="form_action">
          <button
            className="cancel"
            type="button"
            onClick={() => {
              props.handleCancelCreateSale();
            }}
          >
            Cancel
          </button>
          <button className="create" type="submit">
            Create
          </button>
        </div>
      </form>
    </div>
  );
}
