import axiosClient from "./axiosClient";

export const currencyApi = {
  addCurrency: async (data) => {
    const response = await axiosClient.post("/currency", data);
    return response;
  },
  getAllCurrency: async () => {
    const response = await axiosClient.get("/currency");
    return response;
  },
  deleteCurrency: async (id) => {
    const response = await axiosClient.delete("/currency/" + id);
    return response;
  },
  updateCurrency: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/currency/" + id, data);
    return response;
  },
  findOne: async (id) => {
    const response = await axiosClient.get("/currency/" + id);
    return response;
  },
};
