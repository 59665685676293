export const checkMoveInMonth = (deviceRegisteredDate, selectedMonth) => {
  const deviceRegisteredDateMonth = new Date(deviceRegisteredDate).getMonth();
  const deviceRegisteredDateYear = new Date(deviceRegisteredDate).getFullYear();
  const currentYear = new Date().getFullYear();
  if (
    deviceRegisteredDateMonth === selectedMonth - 1 &&
    deviceRegisteredDateYear === currentYear
  ) {
    return "X";
  } else {
    return "";
  }
};
