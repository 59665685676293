import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { currencyApi } from "../../Services/currencyApi";
import { message } from "antd";

const initialState = {
  arrCurrency: [],
  currencyEdit: {},
};

export const getAllCurrencyApi = createAsyncThunk(
  "currency/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await currencyApi.getAllCurrency();
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addCurrencyApi = createAsyncThunk(
  "currency/addCurrency",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await currencyApi.addCurrency(data);
      setTimeout(() => {
        message.success("Create currency success!");
      }, 1000);
      dispatch(getAllCurrencyApi());
      return response;
    } catch (error) {
      message.error("Creating a new currency failed. Please try again!");

      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteCurrencyApi = createAsyncThunk(
  "currency/deleteCurrency",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await currencyApi.deleteCurrency(id);
      setTimeout(() => {
        message.success("Delete currency success!");
      }, 1000);
      dispatch(getAllCurrencyApi());
      return response;
    } catch (error) {
      message.error("Deleting a currency failed. Please try again!");

      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateCurrencyApi = createAsyncThunk(
  "currency/updateCurrency",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await currencyApi.updateCurrency(params);
      setTimeout(() => {
        message.success("Update currency success!");
      }, 1000);
      dispatch(getAllCurrencyApi());
      return response;
    } catch (error) {
      message.error("Update currency failed! Please try again");

      if (error.response && error.response.data.statusCode) {
        return rejectWithValue(error.response.data.statusCode);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findOneCurrencyApi = createAsyncThunk(
  "currency/findOne",
  async (id, { rejectWithValue }) => {
    try {
      const response = await currencyApi.findOne(id);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        setTimeout(() => {
          message.error(`${error.response.data.message}`);
        }, 1000);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const currencyReducer = createSlice({
  name: "currencyReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCurrencyApi.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            item.descriptionShow =
              item.description !== null ? item.description : "";
            newData.unshift(item);
          }
        }
        state.arrCurrency = newData;
      })
      .addCase(updateCurrencyApi.fulfilled, (state, action) => {
        state.currencyEdit = action.payload.data;
      })
      .addCase(findOneCurrencyApi.fulfilled, (state, action) => {
        state.currencyEdit = action.payload;
      });
  },
});

export const {} = currencyReducer.actions;

export default currencyReducer.reducer;
