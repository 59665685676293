import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import billingPolicyApi from "../../Services/billingPolicyApi";
import { message } from "antd";

export const getAllBillingPolicy = createAsyncThunk(
  "billingPolicy/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await billingPolicyApi.getAllBillingPolicy();
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addBillingPolicy = createAsyncThunk(
  "billingPolicy/add",
  async (data, { dispatch }) => {
    try {
      const response = await billingPolicyApi.addBillingPolicy(data);
      setTimeout(() => {
        message.success("Create billing policy success!");
      }, 1000);
      dispatch(getAllBillingPolicy());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 400) {
        setTimeout(() => {
          message.error(
            "Billing policy already exists! Please try again with another billing policy"
          );
        }, 1000);
      } else {
        setTimeout(() => {
          message.error("Create pilling Policy failed! PLease try again");
        }, 1000);
      }
    }
  }
);

export const updateBillingPolicy = createAsyncThunk(
  "billingPolicy/update",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await billingPolicyApi.updateBillingPolicy(params);
      setTimeout(() => {
        message.success("Update billing policy success!");
      }, 1000);
      dispatch(getAllBillingPolicy());
      return response;
    } catch (error) {
      message.error("Update a billing policy failed! Please try again");
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteBillingPolicy = createAsyncThunk(
  "billingPolicy/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await billingPolicyApi.deleteBillingPolicy(id);
      setTimeout(() => {
        message.success("Delete billing policy success!");
      }, 1000);
      dispatch(getAllBillingPolicy());
      return response;
    } catch (error) {
      message.error("Delete a billing policy failed! Please try again");
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const restoreBillingPolicy = createAsyncThunk(
  "billingPolicy/restore",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await billingPolicyApi.restoreBillingPolicy(id);
      setTimeout(() => {
        message.success(
          "Restore billing policy success! Now you can edit information"
        );
      }, 1000);
      dispatch(getAllBillingPolicy());
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        setTimeout(() => {
          message.error("Restore billing policy failed!");
        }, 1000);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const findOneBillingPolicy = createAsyncThunk(
  "billingPolicy/findOne",
  async (id, { rejectWithValue }) => {
    try {
      const response = await billingPolicyApi.findOne(id);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        setTimeout(() => {
          message.error(`${error.response.data.message}`);
        }, 1000);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const billingPolicySlice = createSlice({
  name: "billingPolicyReducer",
  initialState: {
    arrBillingPolicy: [],
    arrBillingPolicyEdit: [],
  },
  reducers: {
    // checkBillingPolicy: (state, action) => {
    //   state.arrBillingPolicyEdit = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBillingPolicy.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            item.name = item.name !== null ? item.name : "";
            item.description =
              item.description !== null ? item.description : "";
            newData.unshift(item);
          }
        }
        state.arrBillingPolicy = newData;
      })
      .addCase(updateBillingPolicy.fulfilled, (state, action) => {
        state.arrBillingPolicyEdit = action.payload.data;
      })
      .addCase(findOneBillingPolicy.fulfilled, (state, action) => {
        state.arrBillingPolicyEdit = action.payload.data;
      });
  },
});

export const {} = billingPolicySlice.actions;
export default billingPolicySlice.reducer;
