import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Modal, message } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import { convertTime } from "../../Utils/convertTime";
import {
  deleteDeviceApi,
  restoreDevice,
  updateDeviceApi,
} from "../../Redux/deviceReducer/deviceReducer";
import DeviceStatusDetail from "./DeviceStatusDetail";
import { convertLongToShortESN } from "../../Utils/convertESN";
import { compareObject } from "../../Utils/compareObject";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { convertTimeShowDetail } from "../../Utils/convertTimeShowDetail";
import "../../Assets/scss/Component/Device/_deviceDetail.scss";

export default function DeviceDetail({ handleCancelDetail }) {
  const { deviceEdit } = useSelector((state) => state.deviceReducer);
  const [openDetail, setOpenDetail] = useState(false);
  const [showRestore, setShowRestore] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useDispatch();

  // Handle modal
  const showModalSetting = () => {
    setOpenDetail(true);
  };
  const handleCancelStatus = () => {
    setOpenDetail(false);
  };

  const showModalRestore = () => {
    setShowRestore(true);
  };
  const cancelModalRestore = () => {
    setShowRestore(false);
  };
  const handleRestore = () => {
    message.loading("loading", 1);
    dispatch(restoreDevice(deviceEdit.id));
    setShowRestore(false);
  };

  const showModalDelete = () => {
    setShowDelete(true);
  };
  const cancelModalDelete = () => {
    setShowDelete(false);
  };
  const handleDelete = () => {
    dispatch(deleteDeviceApi(deviceEdit.id));
    message.loading("loading", 1);
    handleCancelDetail();
    setShowDelete(false);
  };

  // Handle form
  const formik = useFormik({
    initialValues: {
      serialNumber: deviceEdit.serialNumber,
      note: deviceEdit.note,
    },
    validationSchema: yup.object().shape({
      serialNumber: yup.string().required("Serial number cannot be empty"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      let newValue = { ...values };
      if (compareObject(newValue, deviceEdit)) {
        message.warning("Nothing has changed with this device");
      } else {
        const newObject = {};
        if (newValue.serialNumber !== deviceEdit.serialNumber) {
          newObject.serialNumber = newValue.serialNumber;
        }
        if (newValue.note !== deviceEdit.note) {
          newObject.note = newValue.note;
        }
        const dataSend = {
          id: deviceEdit.id,
          data: newObject,
        };
        dispatch(updateDeviceApi(dataSend));
        message.loading("Loading", 1);
      }
    },
  });

  return (
    <div className="dd">
      <form onSubmit={formik.handleSubmit}>
        <div
          className="dd_info"
          style={{
            cursor: deviceEdit.isDeleted === 1 ? "no-drop" : "pointer",
          }}
        >
          <div className="dd_field">
            <div className="dd_field-label">
              <p>Serial number:</p>
            </div>
            <div className="dd_field-input">
              <Input
                className={formik.errors.serialNumber ? "dd_error-input" : ""}
                size="large"
                name="serialNumber"
                value={formik.values.serialNumber}
                onChange={formik.handleChange}
                disabled={deviceEdit.isDeleted === 1 ? true : false}
              />
              {formik.errors.serialNumber ? (
                <div className="dd_error">
                  <i className="fas fa-exclamation-circle"></i>
                  <span>{formik.errors.serialNumber}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="dd_field">
            <div className="dd_field-label">
              <p>Short ESN:</p>
            </div>
            <div className="dd_field-input">
              <p>{convertLongToShortESN(formik.values.serialNumber)}</p>
            </div>
          </div>

          <div className="dd_field">
            <div className="dd_field-label">
              <p>Current account:</p>
            </div>
            <div className="dd_field-input">
              <p>
                {deviceEdit.currentAccount !== null
                  ? deviceEdit?.currentAccount?.name
                  : ""}
              </p>
            </div>
          </div>

          <div className="dd_field">
            <div className="dd_field-label">
              <p>Purchase product:</p>
            </div>
            <div className="dd_field-input">
              <p>
                {deviceEdit.purchaseProduct !== null
                  ? deviceEdit.purchaseProduct.name
                  : ""}
              </p>
            </div>
          </div>

          <div className="dd_field">
            <div className="dd_field-label">
              <p>Current sales product:</p>
            </div>
            <div className="dd_field-input">
              <p>
                {deviceEdit.currentSaleProduct !== null
                  ? deviceEdit.currentSaleProduct.name
                  : ""}
              </p>
            </div>
          </div>

          <div className="dd_field">
            <div className="dd_field-label">
              <p>First movement date:</p>
            </div>
            <div className="dd_field-input">
              <p>
                {deviceEdit.firstMovementDate !== null
                  ? convertTime(
                      convertTimeShowDetail(deviceEdit.firstMovementDate)
                    )
                  : ""}
              </p>
            </div>
          </div>

          <div className="dd_field">
            <div className="dd_field-label">
              <p>Start billing date:</p>
            </div>
            <div className="dd_field-input">
              <p>
                {deviceEdit.currentStartBillDate !== null
                  ? convertTime(
                      convertTimeShowDetail(deviceEdit.currentStartBillDate)
                    )
                  : ""}
              </p>
            </div>
          </div>

          <div className="dd_field">
            <div className="dd_field-label">
              <p>Current movement date:</p>
            </div>
            <div className="dd_field-input">
              <p>
                {deviceEdit.currentMovementDate !== null
                  ? convertTime(
                      convertTimeShowDetail(deviceEdit.currentMovementDate)
                    )
                  : ""}
              </p>
            </div>
          </div>

          <div className="dd_field">
            <div className="dd_field-label">
              <p>Supplier:</p>
            </div>
            <div className="dd_field-input">
              <p>
                {deviceEdit.supplier !== null
                  ? deviceEdit.supplier.name
                  : ""}
              </p>
            </div>
          </div>

          <div className="dd_field">
            <div className="dd_field-label">
              <p>Current haas price:</p>
            </div>
            <div className="dd_field-input">
              <p>
                {deviceEdit.currentHaasPrice !== null
                  ? deviceEdit.currentHaasPrice
                  : ""}
              </p>
            </div>
          </div>

          <div className="dd_state">
            <div className="dd_state-label">
              <p>State:</p>
            </div>
            <div className="dd_state-input">
              <p>{deviceEdit.state !== null ? deviceEdit.state.name : ""}</p>
              <button
                type="button"
                disabled={deviceEdit.isDeleted === 1 ? true : false}
                style={{
                  cursor: deviceEdit.isDeleted === 1 ? "no-drop" : "pointer",
                }}
                onClick={showModalSetting}
              >
                State setting
              </button>
            </div>
          </div>

          <div className="dd_field">
            <div className="dd_field-label">
              <p>Invoice code:</p>
            </div>
            <div className="dd_field-input">
              <p>
                {deviceEdit.invoiceCode !== null ? deviceEdit.invoiceCode : ""}
              </p>
            </div>
          </div>

          <div className="dd_field">
            <div className="dd_field-label">
              <p>Note:</p>
            </div>
            <div className="dd_field-input">
              <Input.TextArea
                size="small"
                name="note"
                value={formik.values.note}
                onChange={formik.handleChange}
                disabled={deviceEdit.isDeleted === 1 ? true : false}
              />
            </div>
          </div>

          <div className="dd_submit">
            <button
              type="submit"
              disabled={deviceEdit.isDeleted === 1 ? true : false}
              style={{
                cursor: deviceEdit.isDeleted === 1 ? "no-drop" : "pointer",
              }}
            >
              Save Change
            </button>
          </div>
        </div>
      </form>

      {deviceEdit.isDeleted === 0 ? (
        <div className="dd_delete">
          <h3>
            <span>
              <i className="fa fa-history"></i>
            </span>{" "}
            Delete device
          </h3>
          <div className="dd_delete-button">
            <p>This action can't be undone!</p>
            <button type="button" onClick={showModalDelete}>
              Delete
            </button>
          </div>
        </div>
      ) : (
        <div className="dd_delete">
          <h3>
            <span>
              <i className="fa fa-history"></i>
            </span>{" "}
            Delete device
          </h3>
          <div className="dd_delete-button">
            <p>This action can't be undone!</p>
            <button type="button" onClick={showModalDelete}>
              Delete
            </button>
          </div>
        </div>
      )}

      {deviceEdit.isDeleted === 1 ? (
        <div className="dd_restore">
          <h3>
            <span>
              <i className="fa fa-history"></i>
            </span>{" "}
            Restore device
          </h3>
          <div className="dd_restore-button">
            <p>
              This device has been deleted. If you want to restore it, please
              click on the Restore button.
            </p>
            <button type="button" onClick={showModalRestore}>
              Restore
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="dd_last-update">
        <i>
          The last user updated this device was{" "}
          <strong>
            {deviceEdit.updater.firstName + " " + deviceEdit.updater.lastName}
          </strong>{" "}
          at <strong>{convertTime(deviceEdit.updatedAt)}</strong>
        </i>
      </div>

      <Modal
        open={showRestore}
        onCancel={cancelModalRestore}
        footer={null}
        width={450}
      >
        <div className="dd_modal">
          <p>
            <i className="fa fa-question"></i> Restore device
          </p>
          <span>Are you sure to restore this device?</span>
          <div className="dd_modal-action">
            <button className="dd_modal-cancel" onClick={cancelModalRestore}>
              Cancel
            </button>
            <button className="dd_modal-restore" onClick={handleRestore}>
              Restore
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={showDelete}
        onCancel={cancelModalDelete}
        footer={null}
        width={450}
      >
        {deviceEdit.isDeleted === 1 ? (
          <div className="dd_modal">
            <p>
              <i className="fa fa-question"></i> Delete device
            </p>
            <span>
              This device will be removed from the system. Are you sure to
              remove it?
            </span>
            <div className="dd_modal-action">
              <button className="dd_modal-cancel" onClick={cancelModalDelete}>
                Cancel
              </button>
              <button className="dd_modal-delete" onClick={handleDelete}>
                Delete
              </button>
            </div>
          </div>
        ) : (
          <div className="dd_modal">
            <p>
              <i className="fa fa-question"></i> Delete device
            </p>
            <span>Are you sure to delete this device?</span>
            <div className="dd_modal-action">
              <button className="dd_modal-cancel" onClick={cancelModalDelete}>
                Cancel
              </button>
              <button className="dd_modal-delete" onClick={handleDelete}>
                Delete
              </button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title="Device state management"
        open={openDetail}
        onCancel={handleCancelStatus}
        footer={null}
        width={600}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <DeviceStatusDetail handleCancelStatus={handleCancelStatus} />
      </Modal>
    </div>
  );
}
