import React from "react";
import { Table, Tag } from "antd";
import { useSelector } from "react-redux";
import "../../Assets/scss/Component/Device/_deviceMovement.scss";

const DeviceMovement = () => {
  const { arrMovementOneDevice } = useSelector(
    (state) => state.movementReducer
  );
  const data = arrMovementOneDevice;

  const columns = [
    {
      title: "From account",
      dataIndex: "fromAccount",
      key: "fromAccount",
    },
    {
      title: "To account",
      dataIndex: "toAccount",
      key: "toAccount",
    },
    {
      title: "Movement type",
      dataIndex: "movementType",
      key: "movementType",
      render: (record) => {
        if (record === "new-stock") {
          return (
            <Tag color="#4cbc9a" style={{ margin: "0" }}>
              new-stock
            </Tag>
          );
        } else if (record === "movement") {
          return (
            <Tag color="#ffa500" style={{ margin: "0" }}>
              movement
            </Tag>
          );
        } else if (record === "return-stock") {
          return (
            <Tag color="#ff0f0f" style={{ margin: "0" }}>
              return-stock
            </Tag>
          );
        } else if (record === "warranty") {
          return (
            <Tag color="#ad82d9" style={{ margin: "0" }}>
              warranty
            </Tag>
          );
        } else if (record === "return-after-warranty") {
          return (
            <Tag color="#60a4bf" style={{ margin: "0" }}>
              return-after-warranty
            </Tag>
          );
        }
      },
    },
    {
      title: "Movement data",
      dataIndex: "movementDateShow",
      key: "movementDateShow",
    },
    {
      title: "Start bill date",
      dataIndex: "startBillDateShow",
      key: "startBillDateShow",
    },
  ];

  return (
    <div className="device_movement">
      <Table columns={columns} dataSource={data} bordered={true} />
    </div>
  );
};

export default DeviceMovement;
