import axiosClient from "./axiosClient";

const timeZoneApi = {
  getAllTimeZone: async () => {
    const response = await axiosClient.get("/timezone");
    return response;
  },
  findTimeZoneById: async (id) => {
    const response = await axiosClient.get("/timezone/" + id);
    return response;
  },
};

export default timeZoneApi;
