import axiosClient from "./axiosClient";

const deviceApi = {
  addDevice: async (data) => {
    const response = await axiosClient.post("/device", data);
    return response;
  },
  getAllDevice: async () => {
    const response = await axiosClient.get("/device");
    return response;
  },
  deleteDevice: async (id) => {
    const response = await axiosClient.delete("/device/" + id);
    return response;
  },
  updateDevice: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/device/" + id, data);
    return response;
  },
  bulkUpdate: async (data) => {
    const response = await axiosClient.put("/device/bulk-update", data);
    return response;
  },
  getTotalDevice: async () => {
    const response = await axiosClient.get("/device/total");
    return response;
  },
  checkExistSerial: async (esn) => {
    const response = await axiosClient.get("/device/check-exist/" + esn);
    return response;
  },
  bulkCreate: async (data) => {
    const response = await axiosClient.post("/device/bulk", data);
    return response;
  },
  historyDevice: async (id) => {
    const response = await axiosClient.get("/device/history/" + id);
    return response;
  },
  restoreDevice: async (id) => {
    const response = await axiosClient.put("/device/restore/" + id);
    return response;
  },
  findOne: async (id) => {
    const response = await axiosClient.get("/device/" + id);
    return response;
  },
  findAllDeviceToday: async () => {
    const response = await axiosClient.get("/device/today");
    return response;
  },
};
export default deviceApi;
