import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userApi from "../../Services/userApi";
import { storage } from "../../Utils/storage";
import { message } from "antd";
import { history } from "../..";

export const signin = createAsyncThunk(
  "auth/signIn",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userApi.signin(data);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const signout = createAsyncThunk("auth/signOut", async (token) => {
  const response = await userApi.signout(token);
  return response;
});
export const forgotPassword = createAsyncThunk(
  "/auth/forgot-password",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userApi.forgotPassword(data);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verify-otp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userApi.verifyOtp(data);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "/auth/reset-password",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userApi.resetPassword(data);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const authSlice = createSlice({
  name: "userReducer",
  initialState: {
    account: {
      isSuccess: localStorage.getItem("token") ? true : false,
    },
    isLoading: false,
    isError: null,
    isErrorForgotPassword: null,
    isErrorOtp: null,
    email: "",
  },
  reducers: {
    saveEmail: (state, action) => {
      state.email = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.fulfilled, (state, action) => {
        localStorage.setItem("token", action.payload.data.accessToken);
        storage.setStorageJson("user", action.payload.data.user);
        state.account.isSuccess = true;
        state.isError = null;
        state.isLoading = false;
      })
      .addCase(signin.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(signin.rejected, (state, { payload }) => {
        state.account.isSuccess = false;
        state.isLoading = false;
        state.isError = payload;
      })
      .addCase(signout.fulfilled, (state, action) => {
        localStorage.removeItem("token");
        state.account.isSuccess = false;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isErrorForgotPassword = null;
        setTimeout(() => {
          message.success("Sent OTP code to email success");
          history.push("/check-otp");
        }, 2000);
      })
      .addCase(forgotPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isErrorForgotPassword = payload;
        setTimeout(() => {
          message.error("Send OPT code to email failed. Please try again");
        }, 2000);
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isErrorOtp = null;
        storage.setStorage("tokenResetPassword", action.payload.data.token);
        setTimeout(() => {
          message.success("Verified OTP code success");
          history.push("/reset-password");
        }, 2000);
      })
      .addCase(verifyOtp.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(verifyOtp.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isErrorOtp = payload;
        setTimeout(() => {
          message.error("Verify OPT code failed. Please try again");
        }, 2000);
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        setTimeout(() => {
          message.success("Reset password success");
          history.push("/notice");
        }, 2000);
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.isLoading = false;
        setTimeout(() => {
          message.error("Reset password failed. Please try again");
        }, 2000);
      });
  },
});

export const { saveEmail } = authSlice.actions;
export default authSlice.reducer;
