import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { DatePicker, Input } from "antd";
import { convertLongToShortESN } from "../../Utils/convertESN";
import { useFormik } from "formik";
import * as yup from "yup";
import { message } from "antd";
import { getAllCompanyMovement, getAllDeviceMovement, updateMovement } from "../../Redux/movementReducer/movementReducer";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { compareObject } from "../../Utils/compareObject";
import dayjs from "dayjs";
import { onChangeDatePicker } from "../../Utils/onChangeDatePicker";
import { getAllSaleProductApi } from "../../Redux/productReducer/saleProductReducer";
import { getAllBillingGroup } from "../../Redux/billingGroupReducer/billingGroupReducer";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";

export default function DetailTypeNull(props) {
  const [checkAccount, setCheckAccount] = useState(null);
  const dispatch = useDispatch();
  const { movementEdit, arrMovementCompany, arrMovementDevice } = useSelector(
    (state) => state.movementReducer
  );
  const { arrayBillingGroup } = useSelector(
    (state) => state.billingGroupReducer
  );
  const { arrSaleProduct } = useSelector((state) => state.saleProductReducer);
  const [arrPreviousCompany, setArrPreviousCompany] = useState([]);
  const [arrDevice, setArrDevice] = useState([]);
  const [arrBillingGroup, setArrBillingGroup] = useState([]);
  const [arraySaleProductShow, setArraySaleProductShow] = useState([]);
   // Call API
   useEffect(() => {
    dispatch(getAllCompanyMovement());
    dispatch(getAllSaleProductApi());
    dispatch(getAllDeviceMovement());
    dispatch(getAllBillingGroup());
  }, [dispatch]);

  // Handle form
  const formik = useFormik({
    initialValues: {
      fromAccountId: movementEdit.fromAccountId,
      toAccountId: movementEdit.toAccountId,
      movementDate: movementEdit.movementDateShowDetail,
      startBillDate: movementEdit.startBillDateShowDetail,
      haasPrice: movementEdit.haasPrice,
      deliveredAt: movementEdit.deliverDateShowDetail,
      receivedAt: movementEdit.receiveDateShowDetail,
      billingGroupId: movementEdit.billingGroupId,
      salesProductId: movementEdit.salesProductId,
      replaceDeviceId: movementEdit.replaceDeviceId,
      invoiceCode: movementEdit.invoiceCode,
      shippingCode: movementEdit.shippingCode,
      billCode: movementEdit.billCode,
      comment: movementEdit.comment,
      notes: movementEdit.notes,
    },
    validationSchema: yup.object({
      fromAccountId: yup.mixed().required("From account cannot be empty"),
      toAccountId: yup.mixed().required("To account cannot be empty"),
      movementDate: yup.mixed().required("Movement date cannot be empty"),
      startBillDate: yup.mixed().required("Start bill date cannot be empty"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      let newValue = { ...values };
      if (checkAccount !== null) {
        formik.setFieldError(
          "fromAccountId",
          "From account and To account cannot be the same"
        );
        formik.setFieldError(
          "toAccountId",
          "From account and To account cannot be the same"
        );
        return;
      }
      if (compareObject(newValue, movementEdit)) {
        message.warning("Nothing has changed with this movement");
      } else {
        const newObject = {};
        for (let item in newValue) {
          if (
            item === "movementDate" &&
            newValue.movementDate !== movementEdit.movementDateShowDetail
          ) {
            newObject.movementDate = newValue.movementDate;
          }
          if (
            item === "startBillDate" &&
            newValue.startBillDate !== movementEdit.startBillDateShowDetail
          ) {
            newObject.startBillDate = newValue.startBillDate;
          }
          if (
            item === "deliveredAt" &&
            newValue.deliveredAt !== movementEdit.deliverDateShowDetail
          ) {
            newObject.deliveredAt = newValue.deliveredAt;
          }
          if (
            item === "receivedAt" &&
            newValue.receivedAt !== movementEdit.receiveDateShowDetail
          ) {
            newObject.receivedAt = newValue.receivedAt;
          }
          if (
            item !== "movementDate" &&
            item !== "startBillDate" &&
            item !== "deliveredAt" &&
            item !== "receivedAt" &&
            newValue[item] !== movementEdit[item]
          ) {
            newObject[item] = newValue[item];
          }
        }
        newObject.deviceId = movementEdit.deviceId;
        const dataSend = {
          id: movementEdit.id,
          data: newObject,
        };
        dispatch(updateMovement(dataSend));
        message.loading("Loading", 0.5);
      }
    },
  });
  useEffect(() => {
    formik.setFieldValue("movementDate", movementEdit.movementDateShowDetail);
    formik.setFieldValue("startBillDate", movementEdit.startBillDateShowDetail);
    formik.setFieldValue("deliveredAt", movementEdit.deliverDateShowDetail);
    formik.setFieldValue("receivedAt", movementEdit.receiveDateShowDetail);
  }, [movementEdit]);

  useEffect(() => {
    CreateDataForSelect(arrMovementCompany,setArrPreviousCompany)
  }, [arrMovementCompany]);

  useEffect(() => {
    CreateDataForSelect(arrMovementDevice,setArrDevice)
  }, [arrMovementDevice]);

  useEffect(() => {
    CreateDataForSelect(arrayBillingGroup,setArrBillingGroup)
  }, [arrayBillingGroup]);

  useEffect(() => {
    CreateDataForSelect(arrSaleProduct,setArraySaleProductShow)
  }, [arrSaleProduct]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        className="detail_content"
        style={{ cursor: movementEdit.isDeleted === 1 ? "no-drop" : "" }}
      >
        <div className="detail_form">
          <ul>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>ESN:</p>
                </div>
                <div className="detail_field-data">
                  <p>{movementEdit.shortESN}</p>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Type:</p>
                </div>
                <div className="detail_field-data">
                  {movementEdit.type !== null ? <p>{movementEdit.type}</p> : ""}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>FROM account:</p>
                </div>
                <div className="detail_field-data">
                  <Select
                    name="fromAccountId"
                    options={arrPreviousCompany}
                    defaultValue={
                      formik.values.fromAccountId !== null
                        ? {
                            value: formik.values.fromAccountId,
                            label: movementEdit.fromAccountName,
                          }
                        : ""
                    }
                    onChange={(e) => {
                      if (e.value === formik.values.toAccountId) {
                        formik.setFieldError(
                          "fromAccountId",
                          "From account and To account cannot be the same"
                        );
                        setCheckAccount(e.value);
                      } else {
                        formik.setFieldValue("fromAccountId", e.value);
                        setCheckAccount(null);
                      }
                    }}
                    isDisabled={movementEdit.isDeleted === 1 ? true : false}
                    className={
                      formik.errors.fromAccountId ? "error_select" : ""
                    }
                  />
                  {formik.errors.fromAccountId ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.fromAccountId}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>TO account:</p>
                </div>
                <div className="detail_field-data">
                  <Select
                    name="toAccountId"
                    options={arrPreviousCompany}
                    defaultValue={
                      formik.values.toAccountId !== null
                        ? {
                            value: formik.values.toAccountId,
                            label: movementEdit.toAccountName,
                          }
                        : ""
                    }
                    onChange={(e) => {
                      if (e.value === formik.values.fromAccountId) {
                        formik.setFieldError(
                          "toAccountId",
                          "From account and To account cannot be the same"
                        );
                        setCheckAccount(e.value);
                      } else {
                        formik.setFieldValue("toAccountId", e.value);
                        setCheckAccount(null);
                      }
                    }}
                    isDisabled={movementEdit.isDeleted === 1 ? true : false}
                    className={formik.errors.toAccountId ? "error_select" : ""}
                  />
                  {formik.errors.toAccountId ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.toAccountId}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Movement date:</p>
                </div>
                <div className="detail_field-data">
                  <DatePicker
                    name="movementDate"
                    showTime={true}
                    placeholder="Select..."
                    format="DD/MM/YYYY hh:mm A"
                    defaultValue={
                      movementEdit.movementDateShowDetail !== null
                        ? dayjs(movementEdit.movementDateShowDetail)
                        : ""
                    }
                    onChange={(date) => {
                      onChangeDatePicker(date, "movementDate", formik);
                    }}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                    className={
                      formik.touched.movementDate && formik.errors.movementDate
                        ? "error_input"
                        : ""
                    }
                  />
                  {formik.touched.movementDate && formik.errors.movementDate ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.movementDate}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Delivery date:</p>
                </div>
                <div className="detail_field-data">
                  <DatePicker
                    name="deliveredAt"
                    showTime={true}
                    placeholder="Select..."
                    format="DD/MM/YYYY hh:mm A"
                    defaultValue={
                      movementEdit.deliverDateShowDetail !== null
                        ? dayjs(movementEdit.deliverDateShowDetail)
                        : ""
                    }
                    onChange={(date) => {
                      onChangeDatePicker(date, "deliveredAt", formik);
                    }}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Receive date:</p>
                </div>
                <div className="detail_field-data">
                  <DatePicker
                    name="receivedAt"
                    showTime={true}
                    placeholder="Select..."
                    format="DD/MM/YYYY hh:mm A"
                    defaultValue={
                      movementEdit.receiveDateShowDetail !== null
                        ? dayjs(movementEdit.receiveDateShowDetail)
                        : ""
                    }
                    onChange={(date) => {
                      onChangeDatePicker(date, "receivedAt", formik);
                    }}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Sales product:</p>
                </div>
                <div className="detail_field-data">
                  <Select
                    name="salesProductId"
                    options={arraySaleProductShow}
                    defaultValue={
                      formik.values.salesProductId !== null
                        ? {
                            value: formik.values.salesProductId,
                            label:
                              movementEdit.device.currentSaleProduct !== null
                                ? movementEdit.device.currentSaleProduct.name
                                : "",
                          }
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("salesProductId", e.value);
                    }}
                    isDisabled={movementEdit.isDeleted === 1 ? true : false}
                    className={
                      formik.touched.salesProductId &&
                      formik.errors.salesProductId
                        ? "error_select"
                        : ""
                    }
                  />
                  {formik.touched.salesProductId &&
                  formik.errors.salesProductId ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.salesProductId}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>

            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Replace device:</p>
                </div>
                <div className="detail_field-data">
                  <Select
                    name="replaceDeviceId"
                    options={arrDevice}
                    defaultValue={
                      formik.values.replaceDeviceId !== ""
                        ? {
                            value: formik.values.replaceDeviceId,
                            label:
                              movementEdit.replaceDevice !== null
                                ? convertLongToShortESN(
                                    movementEdit.replaceDevice.serialNumber
                                  )
                                : "",
                          }
                        : ""
                    }
                    onChange={(e) => {
                      if (e !== null) {
                        formik.setFieldValue("replaceDeviceId", e.value);
                      } else {
                        formik.setFieldValue("replaceDeviceId", e);
                      }
                    }}
                    isDisabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Billing group:</p>
                </div>
                <div className="detail_field-rows">
                  <div className="detail_field-select">
                    <Select
                      name="billingGroupId"
                      options={arrBillingGroup}
                      defaultValue={
                        formik.values.billingGroupId !== ""
                          ? {
                              value: formik.values.billingGroupId,
                              label:
                                movementEdit.billingGroup !== null
                                  ? movementEdit.billingGroup.name
                                  : "",
                            }
                          : ""
                      }
                      onChange={(e) => {
                        if (e !== null) {
                          formik.setFieldValue("billingGroupId", e.value);
                        } else {
                          formik.setFieldValue("billingGroupId", e);
                        }
                      }}
                      isDisabled={movementEdit.isDeleted === 1 ? true : false}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={props.showModalSetting}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                    style={{
                      cursor:
                        movementEdit.isDeleted === 1 ? "no-drop" : "pointer",
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Invoice#:</p>
                </div>
                <div className="detail_field-data">
                  <Input
                    name="invoiceCode"
                    size="large"
                    value={formik.values.invoiceCode}
                    onChange={formik.handleChange}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Bill#:</p>
                </div>
                <div className="detail_field-data">
                  <Input
                    name="billCode"
                    size="large"
                    value={formik.values.billCode}
                    onChange={formik.handleChange}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Shipping#:</p>
                </div>
                <div className="detail_field-data">
                  <Input
                    name="shippingCode"
                    size="large"
                    value={formik.values.shippingCode}
                    onChange={formik.handleChange}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>

            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Note:</p>
                </div>
                <div className="detail_field-data">
                  <Input.TextArea
                    name="notes"
                    size="medium"
                    className="notes"
                    onChange={formik.handleChange}
                    value={formik.values.notes}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Comment:</p>
                </div>
                <div className="detail_field-data">
                  <Input.TextArea
                    name="comment"
                    size="medium"
                    className="notes"
                    onChange={formik.handleChange}
                    value={formik.values.comment}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>
          </ul>
          <div className="detail_action">
            <button
              type="submit"
              disabled={movementEdit.isDeleted === 1 ? true : false}
              style={{
                cursor: movementEdit.isDeleted === 1 ? "no-drop" : "pointer",
              }}
            >
              Save Change
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
