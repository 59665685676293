import React, { useState } from "react";
import { Input, Table, Space, Modal, Popconfirm } from "antd";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/scss/Component/Platform/_platformStatusManage.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import BillingGroupDetail from "./BillingGroupDetail";
import {
  addNewBillingGroup,
  deleteBillingGroup,
  editBillingGroup,
} from "../../Redux/billingGroupReducer/billingGroupReducer";

export default function BillingGroupManagement(props) {
  const { arrayBillingGroup } = useSelector(
    (state) => state.billingGroupReducer
  );
  const [openDetail, setOpenDetail] = useState(false);
  const showModalSetting = () => {
    setOpenDetail(true);
  };

  const handleCancelStatus = () => {
    setOpenDetail(false);
  };
  const dispatch = useDispatch();
  const data = arrayBillingGroup;
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "30%",
      render: (text, record) => (
        <a
          onClick={() => {
            showModalSetting();
            const action = editBillingGroup(record);
            dispatch(action);
          }}
        >
          {text}
        </a>
      ),
      sorter: (beforeItem, afterItem) =>
        beforeItem.name.toLowerCase() > afterItem.name.toLowerCase()
          ? 1
          : beforeItem.name.toLowerCase() === afterItem.name.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "50%",
      sorter: (beforeItem, afterItem) =>
        beforeItem.description.toLowerCase() >
        afterItem.description.toLowerCase()
          ? 1
          : beforeItem.description.toLowerCase() ===
            afterItem.description.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "15%",
      render: (_, record) => (
        <Space size="middle" className="user_action">
          <Popconfirm
            title="Delete Billing group"
            placement="leftBottom"
            description="Are you sure to delete this billing group?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              const action = deleteBillingGroup(record.id);
              dispatch(action);
            }}
          >
            <button>
              <DeleteOutlined className="user_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const billingGroupInformation = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      const action = addNewBillingGroup(values);
      dispatch(action);
      billingGroupInformation.resetForm();
    },
  });
  return (
    <div className="status_management">
      <div className="status_box">
        <form
          className="status_create"
          onSubmit={billingGroupInformation.handleSubmit}
        >
          <p className="status_title">Create new billing group:</p>
          <div className="status_form">
            <div className="status_item">
              <p>Name*</p>
              <Input
                name="name"
                onChange={billingGroupInformation.handleChange}
                onBlur={billingGroupInformation.handleBlur}
                value={billingGroupInformation.values.name}
                size="large"
                className={
                  billingGroupInformation.errors.name ? "error_input" : ""
                }
              />
              {billingGroupInformation.errors.name ? (
                <div className="error_message">
                  <i className="fa fa-exclamation-circle"></i>
                  <span className="ms_errors">
                    {billingGroupInformation.errors.name}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="status_item">
              <p>Description</p>
              <Input.TextArea
                name="description"
                onChange={billingGroupInformation.handleChange}
                onBlur={billingGroupInformation.handleBlur}
                value={billingGroupInformation.values.description}
                size="small"
              />
            </div>
            <div className="status_submit">
              <button type="submit">Create</button>
            </div>
          </div>
        </form>
        <div className="status_table">
          <p className="status_title">Billing group list</p>
          <div className="status_show">
            <Table
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                defaultCurrent: 1,
                showSizeChanger: true,
                showTotal: (total, range) => (
                  <p>
                    Showing{" "}
                    <strong>
                      {range[0]}-{range[1]}
                    </strong>{" "}
                    of <strong>{total}</strong> items
                  </p>
                ),
              }}
              bordered={true}
              size="small"
            />
          </div>
        </div>
      </div>
      <div className="status_action">
        {" "}
        <button type="button" onClick={props.handleCancelSetting}>
          Close
        </button>
      </div>
      <Modal
        title="Billing group detail"
        open={openDetail}
        onCancel={handleCancelStatus}
        footer={null}
        width={500}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <BillingGroupDetail
          handleCancelStatus={handleCancelStatus}
        />
      </Modal>
    </div>
  );
}
