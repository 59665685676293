import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { DatePicker, Input } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import { message } from "antd";
import {
  getAllCompanyMovement,
  updateMovement,
} from "../../Redux/movementReducer/movementReducer";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { compareObject } from "../../Utils/compareObject";
import dayjs from "dayjs";
import { onChangeDatePicker } from "../../Utils/onChangeDatePicker";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";
import { getTotalStocker } from "../../Redux/companyReducer/companyReducer";

export default function DetailToBeReturn() {
  const [checkAccount, setCheckAccount] = useState(null);
  const [arrCurrentCompany, setArrCurrentCompany] = useState([]);
  const [arrCurrentStock, setArrCurrentStock] = useState([]);
  const dispatch = useDispatch();
  const { movementEdit, arrMovementCompany } = useSelector(
    (state) => state.movementReducer
  );
  const { arrayStocker } = useSelector((state) => state.companyReducer);

  // Call API
  useEffect(() => {
    dispatch(getAllCompanyMovement());
    dispatch(getTotalStocker());
  }, [dispatch]);

  // Handle form
  const formik = useFormik({
    initialValues: {
      fromAccountId:
        movementEdit.fromAccountId !== null ? movementEdit.fromAccountId : "",
      toAccountId:
        movementEdit.toAccountId !== null ? movementEdit.toAccountId : "",
      movementDate: movementEdit.movementDateShowDetail,
      startBillDate: movementEdit.startBillDateShowDetail,
      notes: movementEdit.notes,
      comment: movementEdit.comment,
    },
    validationSchema: yup.object({
      movementDate: yup.string().required("Movement date cannot be empty"),
      startBillDate: yup
        .string()
        .required("Start bill date date cannot be empty"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      let newValue = { ...values };
      if (checkAccount !== null) {
        formik.setFieldError(
          "fromAccountId",
          "From account and To stock cannot be the same"
        );
        formik.setFieldError(
          "toAccountId",
          "From account and To stock cannot be the same"
        );
        return;
      }
      if (compareObject(newValue, movementEdit)) {
        message.warning("Nothing has changed with this movement");
      } else {
        const newObject = {};
        for (let item in newValue) {
          if (
            item === "movementDate" &&
            newValue.movementDate !== movementEdit.movementDateShowDetail
          ) {
            newObject.movementDate = newValue.movementDate;
          }
          if (
            item === "startBillDate" &&
            newValue.startBillDate !== movementEdit.startBillDateShowDetail
          ) {
            newObject.startBillDate = newValue.startBillDate;
          }
          if (
            item !== "movementDate" &&
            item !== "startBillDate" &&
            newValue[item] !== movementEdit[item]
          ) {
            newObject[item] = newValue[item];
          }
        }
        newObject.deviceId = movementEdit.deviceId;
        const dataSend = {
          id: movementEdit.id,
          data: newObject,
        };
        dispatch(updateMovement(dataSend));
        message.loading("Loading", 0.5);
      }
    },
  });
  useEffect(() => {
    formik.setFieldValue("movementDate", movementEdit.movementDateShowDetail);
    formik.setFieldValue("startBillDate", movementEdit.startBillDateShowDetail);
  }, [movementEdit]);

  useEffect(() => {
    CreateDataForSelect(arrMovementCompany, setArrCurrentCompany);
  }, [arrMovementCompany]);

  useEffect(() => {
    CreateDataForSelect(arrayStocker, setArrCurrentStock);
  }, [arrayStocker]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        className="detail_content"
        style={{ cursor: movementEdit.isDeleted === 1 ? "no-drop" : "" }}
      >
        <div className="detail_form">
          <ul>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>ESN:</p>
                </div>
                <div className="detail_field-data">
                  <p>{movementEdit.shortESN}</p>
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Type:</p>
                </div>
                <div className="detail_field-data">
                  {movementEdit.type !== null ? <p>{movementEdit.type}</p> : ""}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>FROM account:</p>
                </div>
                <div className="detail_field-data">
                  <Select
                    name="fromAccountId"
                    options={arrCurrentCompany}
                    defaultValue={
                      formik.values.fromAccountId !== ""
                        ? {
                            value: formik.values.fromAccountId,
                            label: movementEdit.fromAccount?.name,
                          }
                        : ""
                    }
                    onChange={(e) => {
                      if (e.value === formik.values.toAccountId) {
                        formik.setFieldError(
                          "fromAccountId",
                          "From stock and To supplier cannot be the same"
                        );
                        setCheckAccount(e.value);
                      } else {
                        formik.setFieldValue("fromAccountId", e.value);
                        setCheckAccount(null);
                      }
                    }}
                    isDisabled={movementEdit.isDeleted === 1 ? true : false}
                    className={
                      formik.errors.fromAccountId ? "error_select" : ""
                    }
                  />
                  {formik.errors.fromAccountId ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.fromAccountId}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>TO stock:</p>
                </div>
                <div className="detail_field-data">
                  <Select
                    name="toAccountId"
                    options={arrCurrentStock}
                    defaultValue={
                      formik.values.toAccountId !== ""
                        ? {
                            value: formik.values.toAccountId,
                            label: movementEdit.toAccount?.name,
                          }
                        : ""
                    }
                    onChange={(e) => {
                      if (e.value === formik.values.fromAccountId) {
                        formik.setFieldError(
                          "toAccountId",
                          "From stock and To supplier cannot be the same"
                        );
                        setCheckAccount(e.value);
                      } else {
                        formik.setFieldValue("toAccountId", e.value);
                        setCheckAccount(null);
                      }
                    }}
                    isDisabled={movementEdit.isDeleted === 1 ? true : false}
                    className={formik.errors.toAccountId ? "error_select" : ""}
                  />
                  {formik.errors.toAccountId ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.toAccountId}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Movement date:</p>
                </div>
                <div className="detail_field-data">
                  <DatePicker
                    name="movementDate"
                    showTime={true}
                    format="DD/MM/YYYY hh:mm A"
                    placeholder="Select..."
                    allowClear={false}
                    defaultValue={dayjs(movementEdit.movementDateShowDetail)}
                    onChange={(date) => {
                      onChangeDatePicker(date, "movementDate", formik);
                    }}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                    className={
                      formik.touched.movementDate && formik.errors.movementDate
                        ? "error_input"
                        : ""
                    }
                  />
                  {formik.touched.movementDate && formik.errors.movementDate ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.movementDate}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Start bill date:</p>
                </div>
                <div className="detail_field-data">
                  <DatePicker
                    name="startBillDate"
                    showTime={true}
                    format="DD/MM/YYYY hh:mm A"
                    placeholder="Select..."
                    allowClear={false}
                    defaultValue={dayjs(movementEdit.startBillDateShowDetail)}
                    onChange={(date) => {
                      onChangeDatePicker(date, "startBillDate", formik);
                    }}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                    className={
                      formik.touched.startBillDate &&
                      formik.errors.startBillDate
                        ? "error_input"
                        : ""
                    }
                  />
                  {formik.touched.startBillDate &&
                  formik.errors.startBillDate ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span>{formik.errors.startBillDate}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Notes:</p>
                </div>
                <div className="detail_field-data">
                  <Input.TextArea
                    name="notes"
                    size="medium"
                    className="notes"
                    onChange={formik.handleChange}
                    value={formik.values.notes}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="detail_field">
                <div className="detail_field-name">
                  <p>Comment:</p>
                </div>
                <div className="detail_field-data">
                  <Input.TextArea
                    name="comment"
                    size="medium"
                    className="comment"
                    onChange={formik.handleChange}
                    value={formik.values.comment}
                    disabled={movementEdit.isDeleted === 1 ? true : false}
                  />
                </div>
              </div>
            </li>
          </ul>
          <div className="detail_action">
            <button
              type="submit"
              disabled={movementEdit.isDeleted === 1 ? true : false}
              style={{
                cursor: movementEdit.isDeleted === 1 ? "no-drop" : "pointer",
              }}
            >
              Save Change
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
