import React, { useEffect } from "react";
import MovementColumnAvailable from "./MovementColumnAvailable";
import MovementColumnSelect from "./MovementColumnSelect";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

export default function MovementColumnRows({
  column,
  setColumn,
  selected,
  setSelected,
}) {
  const handleDragOver = (e) => {
    const { active, over } = e;
    if (!active) return;
    const idActive = active.id;
    const statusActive = active.data.current.status;
    const statusOver = over?.data?.current?.status;
    if (statusActive !== statusOver) {
      if (statusActive === "available") {
        const moveCard = column.map((item, index) => {
          if (item.id === idActive) {
            return { ...item, status: "selected" };
          } else {
            return item;
          }
        });
        setColumn(moveCard);
      }
      if (statusActive === "selected") {
        const moveCard = column.map((item, index) => {
          if (item.id === idActive) {
            return { ...item, status: "available" };
          } else {
            return item;
          }
        });
        setColumn(moveCard);
      }
    }
  };
  const handleDragEnd = (e) => {
    const { active, over } = e;
    if (over !== null && active.id !== over.id) {
      setColumn((prevColumn) => {
        const oldIndex = prevColumn.findIndex((todo) => todo.id === active.id);
        const newIndex = prevColumn.findIndex((todo) => todo.id === over.id);
        setColumn(arrayMove(prevColumn, oldIndex, newIndex));
        return arrayMove(prevColumn, oldIndex, newIndex);
      });
    }
  };
  useEffect(() => {
    getSelectedArray();
  }, [column]);
  const getSelectedArray = () => {
    const arraySelect = [];
    column.map((item, index) => {
      if (item.status === "selected") {
        arraySelect.push(item.id);
      }
    });
    if (arraySelect.length !== 0) {
      setSelected(arraySelect);
    } else {
      setSelected(null);
    }
  };
  return (
    <DndContext onDragEnd={handleDragEnd} onDragOver={handleDragOver}>
      <SortableContext
        items={column?.map((item) => {
          return item.id;
        })}
        strategy={verticalListSortingStrategy}
      >
        <MovementColumnAvailable
          column={column}
          status="available"
          setColumn={setColumn}
        />
        <MovementColumnSelect
          column={column}
          status="selected"
          setColumn={setColumn}
          selected={selected}
          setSelected={setSelected}
        />
      </SortableContext>
    </DndContext>
  );
}
