import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import movementApi from "../../Services/movementApi";
import { convertLongToShortESN } from "../../Utils/convertESN";
import companyApi from "../../Services/companyApi";
import deviceApi from "../../Services/deviceApi";
import deviceStateApi from "../../Services/deviceStateApi";
import { convertTime } from "../../Utils/convertTime";
import { message } from "antd";
import { convertTimeShowDetail } from "../../Utils/convertTimeShowDetail";

export const getAllMovement = createAsyncThunk(
  "movement/getAll",
  async (params, { rejectWithValue }) => {
    try {
      const response = await movementApi.getAllMovement(params);
      return response;
    } catch (error) {
      message.error(error.response.data.message);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllDeviceStateMovement = createAsyncThunk(
  "movement/getAllState",
  async (_, { rejectWithValue }) => {
    try {
      const response = await deviceStateApi.getAllDeviceState();
      return response;
    } catch (error) {
      message.error(error.response.data.message);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllCompanyMovement = createAsyncThunk(
  "movement/getAllCompany",
  async (_, { rejectWithValue }) => {
    try {
      const response = await companyApi.companyGetAll();
      return response;
    } catch (error) {
      message.error(error.response.data.message);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllDeviceMovement = createAsyncThunk(
  "movement/getAllDevice",
  async (_, { rejectWithValue }) => {
    try {
      const response = await deviceApi.getAllDevice();
      return response;
    } catch (error) {
      message.error(error.response.data.message);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findMovementByDeviceId = createAsyncThunk(
  "movement/findByDeviceId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await movementApi.findMovementByDeviceId(id);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findMovementByESN = createAsyncThunk(
  "movement/findByESN",
  async ({ esn, params }, { rejectWithValue }) => {
    try {
      const response = await movementApi.findMovementByESN(esn, params);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findLastMovementByID = createAsyncThunk(
  "movement/findLastMovement",
  async (id, { rejectWithValue }) => {
    try {
      const response = await movementApi.latestMovement(id);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createMovement = createAsyncThunk(
  "movement/create",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await movementApi.createMovement(data);
      setTimeout(() => {
        message.success("Create movement success!");
      }, 1000);
      dispatch(getAllMovement());
      dispatch(getAllDeviceMovement());
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        setTimeout(() => {
          message.error(error.response.data.message);
        }, 1000);
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const createBulkMovement = createAsyncThunk(
  "movement/createBulk",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await movementApi.createBulkMovement(data);
      dispatch(getAllMovement());
      dispatch(getAllDeviceMovement());
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        setTimeout(() => {
          message.error(error.response.data.message);
        }, 1000);
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const createNewTransactionMovement = createAsyncThunk(
  "movement/createNewTransaction",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await movementApi.newTransactionMovement(data);
      dispatch(getAllMovement());
      dispatch(getAllDeviceMovement());
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        setTimeout(() => {
          message.error(error.response.data.message);
        }, 1000);

        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const returnStockMovement = createAsyncThunk(
  "movement/returnStock",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await movementApi.returnStockMovement(data);
      dispatch(getAllMovement());
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const createStateMovement = createAsyncThunk(
  "movement/createState",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await deviceStateApi.addDeviceState(data);
      setTimeout(() => {
        message.success("Create state success!");
      }, 1000);
      dispatch(getAllDeviceStateMovement());
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        setTimeout(() => {
          message.error(error.response.data.message);
        }, 1000);
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateMovement = createAsyncThunk(
  "movement/update",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await movementApi.updateMovement(params);
      dispatch(getAllMovement());
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteMovement = createAsyncThunk(
  "movement/delete",
  async (id, { dispatch }) => {
    try {
      const response = await movementApi.deleteMovement(id);
      setTimeout(() => {
        message.success("Delete movement success!");
      }, 1000);
      dispatch(getAllMovement());
      return response;
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
);

export const getTotalMovement = createAsyncThunk(
  "movement/findAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await movementApi.findTotal();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getTotalMovementByYear = createAsyncThunk(
  "movement/findTotalByYear",
  async (year, { rejectWithValue }) => {
    try {
      const response = await movementApi.findTotalMovementByYear(year);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getTotalMovementToday = createAsyncThunk(
  "movement/findTotalByDay",
  async (_, { rejectWithValue }) => {
    try {
      const response = await movementApi.findAllMovementToday();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const findOneMovement = createAsyncThunk(
  "movement/findOneMovement",
  async (id, { rejectWithValue }) => {
    try {
      const response = await movementApi.findOneMovement(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const warrantyMovement = createAsyncThunk(
  "movement/warranty",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await movementApi.warrantyMovement(data);
      dispatch(getAllMovement());
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const returnAfterWarrantyMovement = createAsyncThunk(
  "movement/returnAfterWarranty",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await movementApi.returnAfterWarrantyMovement(data);
      dispatch(getAllMovement());
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const toBeReturnMovement = createAsyncThunk(
  "movement/toBeReturn",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await movementApi.toBeReturn(data);
      dispatch(getAllMovement());
      return response;
    } catch (error) {
      const data = error.response.status;
      if (data === 401) {
        window.location.href = "/error";
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

const movementSlice = createSlice({
  name: "movementReducer",
  initialState: {
    arrMovement: [],
    arrMovementCompany: [],
    arrMovementDevice: [],
    arrTransaction: [],
    arrMovementOneDevice: [],
    movementEdit: {},
    latestMovementData: {},
    openNotice: false,
    objectNoticeBulkCreate: {},
    noticeNewStock: false,
    objectNoticeNewStock: {},
    notifyReturnStock: false,
    objectNoticeReturnStock: {},
    totalMovement: 0,
    totalMovementByYear: [],
    totalMovementToday: [],
    errorUpdate: null,
    errorReturnStock: null,
    errorFindOne: null,
    pagination: {},
    objectWarranty: {},
    objectReturnAfterWarranty: {},
    objectToBeReturn: {},
    noticeWarranty: false,
    noticeReturnAfterWarranty: false,
    noticeToBeReturn: false,
    errorWarranty: null,
  },
  reducers: {
    resetLatestMovement: (state, action) => {
      state.latestMovementData = action.payload;
    },
    resetNotice: (state) => {
      state.openNotice = false;
    },
    resetNoticeNewStock: (state) => {
      state.noticeNewStock = false;
    },
    resetNoticeReturnStock: (state) => {
      state.notifyReturnStock = false;
    },
    resetMovementDevice: (state) => {
      state.arrMovementOneDevice = [];
    },
    resetNoticeWarranty: (state) => {
      state.noticeWarranty = false;
    },
    resetNoticeReturnAfterWarranty: (state) => {
      state.noticeReturnAfterWarranty = false;
    },
    resetLatestMovementData: (state, action) => {
      state.latestMovementData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMovement.fulfilled, (state, action) => {
        let data = action.payload.data;
        state.pagination = action.payload.pagination;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            if (data[index].device !== null) {
              let item = data[index];
              item.key = item.id;
              item.toAccountName =
                item.toAccount !== null ? item.toAccount.name : "";
              item.fromAccountName =
                item.fromAccount !== null ? item.fromAccount.name : "";

              item.shortESN =
                item.device !== null
                  ? convertLongToShortESN(item.device.serialNumber)
                  : "";
              item.typeShow = item.type !== null ? item.type : "";

              item.purchaseName =
                item.device?.purchaseProduct !== null
                  ? item.device.purchaseProduct.name
                  : "";
              item.purchaseShortName =
                item.device?.purchaseProduct !== null
                  ? item.device.purchaseProduct.shortName
                  : "";
              item.saleName =
                item.salesProduct !== null ? item.salesProduct.name : "";

              item.saleShortName =
                item.salesProduct !== null ? item.salesProduct.shortName : "";
              item.movementAt =
                item.movementDate !== null
                  ? convertTime(item.movementDate)
                  : "";
              item.startBillAt =
                item.startBillDate !== null
                  ? convertTime(item.startBillDate)
                  : "";
              item.billingGroupShow =
                item.billingGroup !== null ? item.billingGroup.name : "";
              item.haas = item.haasPrice !== null ? item.haasPrice : "";
              item.invoiceShow =
                item.invoiceCode !== null ? item.invoiceCode : "";
              item.billCodeShow = item.billCode !== null ? item.billCode : "";
              item.shippingCodeShow =
                item.shippingCode !== null ? item.shippingCode : "";
              item.noteShow = item.notes !== null ? item.notes : "";
              item.commentShow = item.comment !== null ? item.comment : "";
              item.replaceDeviceShow =
                item.replaceDevice !== null
                  ? convertLongToShortESN(item.replaceDevice.serialNumber)
                  : "";
              item.movementDateShowDetail =
                item.movementDate !== null
                  ? convertTimeShowDetail(item.movementDate)
                  : null;
              item.deliverDateShowDetail =
                item.deliveredAt !== null
                  ? convertTimeShowDetail(item.deliveredAt)
                  : null;
              item.receiveDateShowDetail =
                item.receivedAt !== null
                  ? convertTimeShowDetail(item.receivedAt)
                  : null;
              item.startBillDateShowDetail =
                item.startBillDate !== null
                  ? convertTimeShowDetail(item.startBillDate)
                  : null;
              newData.push(item);
            }
          }
        }
        state.arrMovement = newData;
      })
      .addCase(getAllCompanyMovement.fulfilled, (state, action) => {
        state.arrMovementCompany = action.payload.data;
      })
      .addCase(getAllDeviceMovement.fulfilled, (state, action) => {
        state.arrMovementDevice = action.payload.data;
      })
      .addCase(getAllDeviceStateMovement.fulfilled, (state, action) => {
        state.arrTransaction = action.payload.data;
      })
      .addCase(createMovement.fulfilled, (state, action) => {
        let data = action.payload.data;
        data.key = data.id;
        state.arrMovement.unshift(data);
      })
      .addCase(findLastMovementByID.fulfilled, (state, action) => {
        state.latestMovementData = action.payload.data;
      })
      .addCase(updateMovement.fulfilled, (state, action) => {
        let data = { ...action.payload.data };
        data.key = data.id;
        data.shortESN =
          data.device !== null
            ? convertLongToShortESN(data.device.serialNumber)
            : "";
        data.toAccountName = data.toAccount !== null ? data.toAccount.name : "";
        data.fromAccountName =
          data.fromAccount !== null ? data.fromAccount.name : "";
        data.movementDateShowDetail =
          data.movementDate !== null
            ? convertTimeShowDetail(data.movementDate)
            : null;
        data.deliverDateShowDetail =
          data.deliveredAt !== null
            ? convertTimeShowDetail(data.deliveredAt)
            : null;
        data.receiveDateShowDetail =
          data.receivedAt !== null
            ? convertTimeShowDetail(data.receivedAt)
            : null;
        data.startBillDateShowDetail =
          data.startBillDate !== null
            ? convertTimeShowDetail(data.startBillDate)
            : null;
        state.movementEdit = data;
        state.errorUpdate = null;
        message.success("Update movement success", 3);
      })
      .addCase(updateMovement.pending, (state) => {
        state.errorUpdate = null;
      })
      .addCase(updateMovement.rejected, (state, action) => {
        state.errorUpdate = action.payload;
        message.error(`${state.errorUpdate}`, 3);
      })
      .addCase(createBulkMovement.fulfilled, (state, action) => {
        state.openNotice = true;
        state.objectNoticeBulkCreate = action.payload.data;
      })
      .addCase(createBulkMovement.pending, (state, action) => {
        state.openNotice = false;
        state.objectNoticeBulkCreate = {};
      })
      .addCase(createBulkMovement.rejected, (state, action) => {
        state.openNotice = false;
        state.objectNoticeBulkCreate = {};
      })
      .addCase(createNewTransactionMovement.fulfilled, (state, action) => {
        state.noticeNewStock = true;
        state.objectNoticeNewStock = action.payload.data;
      })
      .addCase(createNewTransactionMovement.pending, (state, action) => {
        state.noticeNewStock = false;
        state.objectNoticeNewStock = {};
      })
      .addCase(createNewTransactionMovement.rejected, (state, action) => {
        state.noticeNewStock = false;
        state.objectNoticeNewStock = {};
      })
      .addCase(returnStockMovement.fulfilled, (state, action) => {
        state.notifyReturnStock = true;
        state.objectNoticeReturnStock = action.payload.data;
        state.errorReturnStock = null;
      })
      .addCase(returnStockMovement.pending, (state, action) => {
        state.notifyReturnStock = false;
        state.objectNoticeReturnStock = {};
        state.errorReturnStock = null;
      })
      .addCase(returnStockMovement.rejected, (state, action) => {
        state.notifyReturnStock = false;
        state.objectNoticeReturnStock = {};
        state.errorReturnStock = action.payload;
        message.error(`${state.errorReturnStock}`, 3);
      })
      .addCase(getTotalMovement.fulfilled, (state, action) => {
        state.totalMovement = action.payload.data.total;
      })
      .addCase(getTotalMovementByYear.fulfilled, (state, action) => {
        state.totalMovementByYear = action.payload.data;
      })
      .addCase(getTotalMovementToday.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        for (let index = 0; index < data.length; index++) {
          let item = data[index];
          item.key = item.id;
          item.shortESN =
            item.device !== null
              ? convertLongToShortESN(item.device.serialNumber)
              : "";
          item.typeShow = item.type !== null ? item.type : "";
          item.movementAt =
            item.movementDate !== null ? convertTime(item.movementDate) : "";
          item.startBillAt =
            item.startBillDate !== null ? convertTime(item.startBillDate) : "";
          item.toAccountName =
            item.toAccount !== null ? item.toAccount.name : "";
          item.fromAccountName =
            item.fromAccount !== null ? item.fromAccount.name : "";
          newData.push(item);
        }
        state.totalMovementToday = newData;
      })
      .addCase(getTotalMovementToday.rejected, (state, action) => {
        state.totalMovementToday = [];
      })
      .addCase(findOneMovement.fulfilled, (state, action) => {
        let data = { ...action.payload.data };
        data.key = data.id;
        data.shortESN =
          data.device !== null
            ? convertLongToShortESN(data.device.serialNumber)
            : "";
        data.toAccountName = data.toAccount !== null ? data.toAccount.name : "";
        data.fromAccountName =
          data.fromAccount !== null ? data.fromAccount.name : "";
        data.movementDateShowDetail =
          data.movementDate !== null
            ? convertTimeShowDetail(data.movementDate)
            : null;
        data.deliverDateShowDetail =
          data.deliveredAt !== null
            ? convertTimeShowDetail(data.deliveredAt)
            : null;
        data.receiveDateShowDetail =
          data.receivedAt !== null
            ? convertTimeShowDetail(data.receivedAt)
            : null;
        data.startBillDateShowDetail =
          data.startBillDate !== null
            ? convertTimeShowDetail(data.startBillDate)
            : null;
        state.movementEdit = data;
        state.errorFindOne = null;
      })
      .addCase(findOneMovement.pending, (state) => {
        state.errorFindOne = null;
      })
      .addCase(findOneMovement.rejected, (state, action) => {
        state.errorFindOne = action.payload;
        message.error(`${state.errorFindOne}`, 3);
      })
      .addCase(findMovementByDeviceId.fulfilled, (state, action) => {
        let data = action.payload.data;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            let item = data[index];
            item.key = item.id;
            item.fromAccount =
              item.fromAccount !== null ? item.fromAccount.name : "";
            item.toAccount = item.toAccount !== null ? item.toAccount.name : "";
            item.movementType = item.type !== null ? item.type : "";
            item.movementDateShow =
              item.movementDate !== null
                ? convertTime(item.movementDate)
                : null;
            item.startBillDateShow =
              item.startBillDate !== null
                ? convertTime(item.startBillDate)
                : null;
            newData.push(item);
          }
        }
        state.arrMovementOneDevice = newData;
      })
      .addCase(findMovementByESN.fulfilled, (state, action) => {
        let data = action.payload.data;
        state.pagination = action.payload.pagination;
        let newData = [];
        if (data.length !== 0) {
          for (let index = 0; index < data.length; index++) {
            if (data[index].device !== null) {
              let item = data[index];
              item.key = item.id;
              item.toAccountName =
                item.toAccount !== null ? item.toAccount.name : "";
              item.fromAccountName =
                item.fromAccount !== null ? item.fromAccount.name : "";

              item.shortESN =
                item.device !== null
                  ? convertLongToShortESN(item.device.serialNumber)
                  : "";
              item.typeShow = item.type !== null ? item.type : "";

              item.purchaseName =
                item.purchaseProduct !== null ? item.purchaseProduct.name : "";
              item.purchaseShortName =
                item.purchaseProduct !== null
                  ? item.purchaseProduct.shortName
                  : "";
              item.saleName =
                item.salesProduct !== null ? item.salesProduct.name : "";

              item.saleShortName =
                item.salesProduct !== null ? item.salesProduct.shortName : "";
              item.movementAt =
                item.movementDate !== null
                  ? convertTime(item.movementDate)
                  : "";
              item.startBillAt =
                item.startBillDate !== null
                  ? convertTime(item.startBillDate)
                  : "";
              item.billingGroupShow =
                item.billingGroup !== null ? item.billingGroup.name : "";
              item.haas = item.haasPrice !== null ? item.haasPrice : "";
              item.invoiceShow =
                item.invoiceCode !== null ? item.invoiceCode : "";
              item.billCodeShow = item.billCode !== null ? item.billCode : "";
              item.shippingCodeShow =
                item.shippingCode !== null ? item.shippingCode : "";
              item.noteShow = item.notes !== null ? item.notes : "";
              item.commentShow = item.comment !== null ? item.comment : "";
              item.replaceDeviceShow =
                item.replaceDevice !== null
                  ? convertLongToShortESN(item.replaceDevice.serialNumber)
                  : "";
              item.movementDateShowDetail =
                item.movementDate !== null
                  ? convertTimeShowDetail(item.movementDate)
                  : null;
              item.deliverDateShowDetail =
                item.deliveredAt !== null
                  ? convertTimeShowDetail(item.deliveredAt)
                  : null;
              item.receiveDateShowDetail =
                item.receivedAt !== null
                  ? convertTimeShowDetail(item.receivedAt)
                  : null;
              item.startBillDateShowDetail =
                item.startBillDate !== null
                  ? convertTimeShowDetail(item.startBillDate)
                  : null;
              newData.push(item);
            }
          }
        }
        state.arrMovement = newData;
      })
      .addCase(warrantyMovement.fulfilled, (state, action) => {
        state.noticeWarranty = true;
        state.objectWarranty = action.payload.data;
        state.errorWarranty = null;
      })
      .addCase(warrantyMovement.pending, (state, action) => {
        state.noticeWarranty = false;
        state.objectWarranty = {};
        state.errorWarranty = null;
      })
      .addCase(warrantyMovement.rejected, (state, action) => {
        state.noticeWarranty = false;
        state.objectWarranty = {};
        state.errorWarranty = action.payload;
        message.error(`${state.errorWarranty}`, 3);
      })
      .addCase(returnAfterWarrantyMovement.fulfilled, (state, action) => {
        state.noticeReturnAfterWarranty = true;
        state.objectReturnAfterWarranty = action.payload.data;
        state.errorWarranty = null;
      })
      .addCase(returnAfterWarrantyMovement.pending, (state, action) => {
        state.noticeReturnAfterWarranty = false;
        state.objectReturnAfterWarranty = {};
        state.errorWarranty = null;
      })
      .addCase(returnAfterWarrantyMovement.rejected, (state, action) => {
        state.noticeReturnAfterWarranty = false;
        state.objectReturnAfterWarranty = {};
        state.errorWarranty = action.payload;
        message.error(`${state.errorWarranty}`, 3);
      })
      .addCase(toBeReturnMovement.fulfilled, (state, action) => {
        message.success("Create movement success", 3);
        state.errorWarranty = null;
      })
      .addCase(toBeReturnMovement.pending, (state, action) => {
        state.errorWarranty = null;
      })
      .addCase(toBeReturnMovement.rejected, (state, action) => {
        state.errorWarranty = action.payload;
        message.error(`${state.errorWarranty}`, 3);
      });
  },
});

export const {
  resetLatestMovement,
  resetNotice,
  resetNoticeNewStock,
  resetNoticeReturnStock,
  resetMovementDevice,
  resetNoticeWarranty,
  resetNoticeReturnAfterWarranty,
  resetLatestMovementData,
} = movementSlice.actions;
export default movementSlice.reducer;
