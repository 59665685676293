export default function dayCount(pastDay, userMonth, userYear) {
  if (pastDay !== null) {
    const today = new Date();
    const pastDate = new Date(pastDay)
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    let endDate;

    if (userMonth === currentMonth && userYear === currentYear) {
      endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
    } else {
      endDate = new Date(userYear, userMonth, 0);
    }

    const diffTime = Math.abs(endDate - pastDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  } else {
    return "";
  }
}
