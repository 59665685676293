import React, { useState } from "react";
import { Input, Table, Space, Modal, Popconfirm } from "antd";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  addDeviceStateApi,
  deleteDeviceStateApi,
  editDeviceState,
} from "../../Redux/deviceReducer/deviceStateReducer";
import DeviceStatusEdit from "./DeviceStatusEdit";
import "../../Assets/scss/Component/Platform/_platformStatusManage.scss";

export default function PlatformStatusManagement(props) {
  const { arrDeviceState } = useSelector((state) => state.deviceStateReducer);
  const [openDetail, setOpenDetail] = useState(false);
  const showModalSetting = () => {
    setOpenDetail(true);
  };

  const handleCancelStatus = () => {
    setOpenDetail(false);
  };

  const dispatch = useDispatch();

  const data = arrDeviceState;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "30%",
      render: (text, record) => (
        <a
          onClick={() => {
            showModalSetting();
            dispatch(editDeviceState(record));
          }}
        >
          {text}
        </a>
      ),
      sorter: (a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : a.name.toLowerCase() === b.name.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "50%",
      sorter: (a, b) =>
        a.description.toLowerCase() > b.description.toLowerCase()
          ? 1
          : a.description.toLowerCase() === b.description.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "15%",
      render: (_, record) => (
        <Space size="middle" className="user_action">
          <Popconfirm
            title="Delete Device state"
            placement="leftBottom"
            description="Are you sure to delete this device state?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              const action = deleteDeviceStateApi(record.id);
              dispatch(action);
            }}
          >
            <button type="button">
              <DeleteOutlined className="user_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const deviceStateInformation = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      const action = addDeviceStateApi(values);
      dispatch(action);
      deviceStateInformation.resetForm();
    },
  });

  return (
    <div className="status_management">
      <div className="status_box">
        <form
          className="status_create"
          onSubmit={deviceStateInformation.handleSubmit}
        >
          <p className="status_title">Create new device state:</p>
          <div className="status_form">
            <div className="status_item">
              <p>Name *</p>
              <Input
                className={
                  deviceStateInformation.touched.name &&
                  deviceStateInformation.errors.name
                    ? "status_errors"
                    : ""
                }
                name="name"
                onChange={deviceStateInformation.handleChange}
                onBlur={deviceStateInformation.handleBlur}
                value={deviceStateInformation.values.name}
                size="large"
              />
              {deviceStateInformation.touched.name &&
              deviceStateInformation.errors.name ? (
                <div className="status_validation">
                  <i className="fas fa-exclamation-circle"></i>
                  <span>{deviceStateInformation.errors.name}</span>
                </div>
              ) : null}
            </div>
            <div className="status_item">
              <p>Description</p>
              <Input.TextArea
                name="description"
                onChange={deviceStateInformation.handleChange}
                onBlur={deviceStateInformation.handleBlur}
                value={deviceStateInformation.values.description}
                size="small"
              />
            </div>
            <div className="status_submit">
              <button type="submit">Create</button>
            </div>
          </div>
        </form>

        <div className="status_table">
          <p className="status_title">Device state list</p>
          <div className="status_show">
            <Table
              columns={columns}
              dataSource={data}
              rowClassName={(record) =>
                record.isDeleted === 1 ? "deleted" : ""
              }
              pagination={{
                defaultPageSize: 5,
                defaultCurrent: 1,
                showSizeChanger: true,
                showTotal: (total, range) => (
                  <p>
                    Showing{" "}
                    <strong>
                      {range[0]}-{range[1]}
                    </strong>{" "}
                    of <strong>{total}</strong> items
                  </p>
                ),
              }}
              bordered={true}
              size="small"
            />
          </div>
        </div>
      </div>

      <div className="status_action">
        {" "}
        <button type="button" onClick={props.handleCancelStatus}>
          Close
        </button>
      </div>
      <Modal
        title="Device state detail"
        open={openDetail}
        onCancel={handleCancelStatus}
        footer={null}
        width={500}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <DeviceStatusEdit handleCancelStatus={handleCancelStatus} />
      </Modal>
    </div>
  );
}
