import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import { useSelector } from "react-redux";
import { Skeleton, Tooltip } from "antd";
import { FileOutlined } from "@ant-design/icons";
import "../../Assets/scss/Component/Device/_deviceHistory.scss";

dayjs.extend(relativeTime);

const DiscountHistory = () => {
  const { discountHistory, isLoadingHistory } = useSelector(
    (state) => state.companyReducer
  );
  const { arrWirelessTech } = useSelector((state) => state.wirelessTechReducer);
  const showHistory = () => {
    if (isLoadingHistory === true) {
      return <Skeleton active avatar />;
    } else {
      if (discountHistory.length !== 0) {
        return discountHistory.map((item, index) => {
          if (item.action === "Created") {
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    created {dayjs(new Date(item.createdAt)).fromNow()}
                  </p>
                </div>
              </div>
            );
          } else if (item.action === "Deleted") {
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    deleted {dayjs(new Date(item.createdAt)).fromNow()}
                  </p>
                </div>
              </div>
            );
          } else if (item.action === "Restore") {
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    restored {dayjs(new Date(item.createdAt)).fromNow()}
                  </p>
                </div>
              </div>
            );
          } else {
            const arrShow = [];
            let newItem = { ...item };
            newItem.arrHistoryShow = arrShow;

            if ("price" in item.beforeData) {
              const price = {
                name: "Price",
                old: item.beforeData.price,
                new: item.afterData.price,
              };
              arrShow.push(price);
            }

            if ("afterMonth" in item.beforeData) {
              const afterMonth = {
                name: "After month",
                old: item.beforeData.afterMonth,
                new: item.afterData.afterMonth,
              };
              arrShow.push(afterMonth);
            }

            if ("wirelessTechnologyId" in item.beforeData) {
              let beforeWirelessTech = "None";
              let afterWirelessTech = "None";
              if (item.beforeData.wirelessTechnologyId !== null) {
                const result = arrWirelessTech.find(
                  (state) => state.id === item.beforeData.wirelessTechnologyId
                );
                beforeWirelessTech = result?.name;
              }
              if (item.afterData.wirelessTechnologyId !== null) {
                const result = arrWirelessTech.find(
                  (state) => state.id === item.afterData.wirelessTechnologyId
                );
                afterWirelessTech = result?.name;
              }
              const type = {
                name: "Wireless technology",
                old: beforeWirelessTech,
                new: afterWirelessTech,
              };
              arrShow.push(type);
            }
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {" "}
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    updated {dayjs(new Date(newItem.createdAt)).fromNow()}
                  </p>
                </div>
                {newItem.arrHistoryShow.map((item, index) => {
                  return (
                    <div key={index} className="description">
                      <strong>{item.name}</strong>: <span>{item.old}</span>
                      <i className="fa fa-long-arrow-alt-right"></i>
                      <span>{item.new}</span>
                    </div>
                  );
                })}
              </div>
            );
          }
        });
      } else {
        return (
          <div className="history_error">
            <div className="icon">
              <FileOutlined />
            </div>
            <p className="title">No data</p>
          </div>
        );
      }
    }
  };

  return (
    <div className="history">
      <div>{showHistory()}</div>
    </div>
  );
};

export default DiscountHistory;
