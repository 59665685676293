import React from "react";
import HomeTemplate from "./Templates/HomeTemplate/HomeTemplate";
import HomeTemplateMobile from "./Templates/HomeTemplateMobile/HomeTemplateMobile.jsx";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import User from "./Pages/User/User";
import Device from "./Pages/Device/Device";
import Platform from "./Pages/Platform/Platform";
import Account from "./Pages/Account/Account";
import SignIn from "./Pages/SignIn/SignIn";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import Company from "./Pages/Company/Company";
import Movement from "./Pages/Movement/Movement";
import BillingPolicy from "./Pages/BillingPolicy/BillingPolicy";
import Currency from "./Pages/Currency/Currency";
import CheckOtpPage from "./Pages/ForgotPassword/CheckOtpPage";
import ResetPassword from "./Pages/ForgotPassword/ResetPassword";
import Notice from "./Pages/ForgotPassword/Notice";
import ResponseItem from "./ResponsiveItem/ResponsiveItem";
import PurchaseProduct from "./Pages/Product/PurchaseProduct";
import SaleProduct from "./Pages/Product/SaleProduct";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import BillingSummary from "./Pages/BillingSummary/BillingSummary.jsx";
import WirelessTechnology from "./Pages/Product/WirelessTechnology.jsx";
import Event from "./Pages/EventPage/Event.jsx";

const App = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ResponseItem
            component={HomeTemplate}
            mobileComponent={HomeTemplateMobile}
          />
        }
      >
        <Route index element={<Dashboard />}></Route>
        <Route path="user" element={<User />}></Route>
        <Route path="device" element={<Device />}></Route>
        <Route path="purchase-product" element={<PurchaseProduct />}></Route>
        <Route path="sale-product" element={<SaleProduct />}></Route>
        <Route path="wireless-tech" element={<WirelessTechnology />}></Route>
        <Route path="platform" element={<Platform />}></Route>
        <Route path="account" element={<Account />}></Route>
        <Route path="company" element={<Company />}></Route>
        <Route path="event" element={<Event />}></Route>
        <Route path="movement" element={<Movement />}></Route>
        <Route path="billingPolicy" element={<BillingPolicy />}></Route>
        <Route path="currency" element={<Currency />}></Route>
        <Route path="billing-summary" element={<BillingSummary />}></Route>
      </Route>
      <Route path="sign-in" element={<SignIn />}></Route>
      <Route path="forgot-password" element={<ForgotPassword />}></Route>
      <Route path="check-otp" element={<CheckOtpPage />}></Route>
      <Route path="reset-password" element={<ResetPassword />}></Route>
      <Route path="notice" element={<Notice />}></Route>
      <Route path="error" element={<ErrorPage />}></Route>
      <Route path="*" element={<Navigate to={""} />}></Route>
    </Routes>
  );
};

export default App;
