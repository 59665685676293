import React, { useEffect, useState } from "react";
import { Input, Tooltip, message, Modal, DatePicker } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompany } from "../../Redux/companyReducer/companyReducer";
import {
  createBulkMovement,
  getAllDeviceMovement,
  getAllDeviceStateMovement,
} from "../../Redux/movementReducer/movementReducer";
import { convertTimeToUtc } from "../../Utils/convertToUtcTime";
import "../../Assets/scss/Component/Movement/_movementNewTransaction.scss";
import { getAllSaleProductApi } from "../../Redux/productReducer/saleProductReducer";
import CreateBillingGroup from "./CreateBillingGroup";
import CreateSaleProduct from "../Product/CreateSaleProduct";
import DeviceInforBulkMovement from "./DeviceInforBulkMovement";
import { convertLongToShortESN } from "../../Utils/convertESN";
import { getAllBillingGroup } from "../../Redux/billingGroupReducer/billingGroupReducer";
import CreateStateMovement from "./CreateStateMovement";
import { onChangeDatePicker } from "../../Utils/onChangeDatePicker";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";

const CreateMovementBulk = ({
  cancelBulk,
  resetBulk,
  closeModalBulk,
  setValueEnter,
}) => {
  const [serialList, setSerialList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [deviceShow, setDeviceShow] = useState({});
  const [saleProductShow, setSaleProductShow] = useState({});
  const [replaceDeviceShow, setReplaceDeviceShow] = useState({});
  const [arrPreviousCompany, setArrPreviousCompany] = useState([]);
  const [arrDevice, setArrDevice] = useState([]);
  const [showBillingGroup, setShowBillingGroup] = useState(false);
  const [openCreateSale, setOpenCreateSale] = useState(false);
  const [deviceInforShow, setDeviceInforShow] = useState(false);
  const [arrayBillingGroupsShow, setArrayBillingGroupShow] = useState([]);
  const [arraySaleProductShow, setArraySaleProductShow] = useState([]);
  const [arrayValue, setArrayValue] = useState([]);
  const [arrayDevice, setArrayDevice] = useState([]);
  const [inputMethod, setInputMethod] = useState(false);
  const [listClassName, setListClassName] = useState(
    "transaction_number-list-movement"
  );
  const [addMoreValueReset, setAddMoreValueReset] = useState("");
  const [checkValues, setCheckValues] = useState({});
  const [arrState, setArrState] = useState([]);
  const [showState, setShowState] = useState(false);
  const [resetState, setResetState] = useState(false);
  const [value, setValue] = useState(false);
  const dispatch = useDispatch();
  const { arrCompany } = useSelector((state) => state.companyReducer);
  const { arrMovementDevice, arrTransaction } = useSelector(
    (state) => state.movementReducer
  );
  const { arrayBillingGroup } = useSelector(
    (state) => state.billingGroupReducer
  );
  const { arrSaleProduct } = useSelector((state) => state.saleProductReducer);

  // Handle form
  const formik = useFormik({
    initialValues: {
      toAccountId: "",
      movementDate: "",
      startBillDate: "",
      billingGroupId: null,
      salesProductId: "",
      haasPrice: null,
      invoiceCode: null,
      shippingCode: null,
      billCode: null,
      deviceStateId: null,
      comment: null,
      notes: null,
    },
    validationSchema: yup.object().shape({
      toAccountId: yup.mixed().required(" TO account is required"),
      movementDate: yup.mixed().required("Movement date is required"),
      startBillDate: yup.mixed().required("Start bill date is required"),
      salesProductId: yup.mixed().required("Sales product is required"),
      deviceStateId: yup.mixed().required("Device state is required"),
    }),
    onSubmit: (values) => {
      if (serialList.length === 0) {
        setSerialList(null);
      } else {
        convertEmptyToNull(values);
        const newData = { ...values };
        newData.movementDate = convertTimeToUtc(newData.movementDate);
        newData.startBillDate = convertTimeToUtc(newData.startBillDate);
        newData.devices = deviceList;
        dispatch(createBulkMovement(newData));
        message.loading("Loading", 1);
        closeModalBulk();
      }
    },
  });

  // Change value enter
  useEffect(() => {
    const data = {};
    convertEmptyToNull(formik.values);
    for (let item in formik.values) {
      if (formik.values[item] !== formik.initialValues[item]) {
        data[item] = formik.values[item];
      }
    }
    setCheckValues(data);
  }, [formik.values]);

  useEffect(() => {
    if (Object.keys(checkValues).length !== 0) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [checkValues]);

  useEffect(() => {
    if (serialList?.length !== 0) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [serialList]);

  useEffect(() => {
    setValueEnter(value);
  }, [value]);

  const showBillingGroupModal = () => {
    setShowBillingGroup(true);
  };
  const cancelBillingGroupModal = () => {
    setShowBillingGroup(false);
  };
  const showModalCreateSale = () => {
    setOpenCreateSale(true);
  };

  const handleOkCreateSale = () => {
    message.loading("loading", 1);
    setOpenCreateSale(false);
  };
  const handleCancelCreateSale = () => {
    setOpenCreateSale(false);
  };

  const showDeviceInforModal = (id) => {
    setDeviceInforShow(true);
    const itemFind = deviceList.find((item) => item.deviceId === id);
    setDeviceShow(itemFind);
  };
  const cancelDeviceInforModal = () => {
    setDeviceInforShow(false);
  };
  // Show modal add new state
  const showAddState = () => {
    setShowState(true);
  };
  const cancelAddState = () => {
    setResetState(!resetState);
    setShowState(false);
  };
  useEffect(() => {
    formik.resetForm();
  }, [resetBulk]);

  // Call api
  useEffect(() => {
    dispatch(getAllCompany());
    dispatch(getAllDeviceMovement());
    dispatch(getAllSaleProductApi());
    dispatch(getAllBillingGroup());
    dispatch(getAllDeviceStateMovement());
  }, [dispatch]);

  useEffect(() => {
    CreateDataForSelect(arrCompany, setArrPreviousCompany);
  }, [arrCompany]);

  useEffect(() => {
    let arrSelect = [];
    arrMovementDevice.map((item) => {
      if (item.isDeleted === 0) {
        let obj = {
          value: item.id,
          label: convertLongToShortESN(item.serialNumber),
        };
        arrSelect.push(obj);
      }
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setArrDevice(arrSelect);
  }, [arrMovementDevice]);

  useEffect(() => {
    let arrSelect = [];
    arrayBillingGroup.map((item) => {
      let obj = { value: item.id, label: item.name };
      arrSelect.push(obj);
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setArrayBillingGroupShow(arrSelect);
  }, [arrayBillingGroup]);

  useEffect(() => {
    CreateDataForSelect(arrSaleProduct, setArraySaleProductShow);
  }, [arrSaleProduct]);

  useEffect(() => {
    CreateDataForSelect(arrTransaction, setArrState);
  }, [arrTransaction]);

  const renderSerialList = (e) => {
    setArrayValue(e);
    const arrayShow = [];
    const arraySend = [];
    for (let i = 0; i < e.length; i++) {
      const item = e[i].value;
      const itemFind = arrMovementDevice.find((object) => object.id === item);
      if (itemFind !== undefined) {
        const itemSend = {
          deviceId: item,
          salesProductId: null,
          replaceDeviceId: null,
          comment: null,
        };
        arrayShow.push(itemFind);
        arraySend.push(itemSend);
      }
    }
    for (let i = 0; i < deviceList.length; i++) {
      const item = deviceList[i];
      const itemFind = arraySend.findIndex(
        (obj) => obj.deviceId === item.deviceId
      );
      if (itemFind !== -1) {
        arraySend[itemFind] = item;
      }
    }
    setSerialList(arrayShow);
    setDeviceList(arraySend);
  };

  useEffect(() => {
    if (deviceShow.salesProductId !== null) {
      const itemFind = arraySaleProductShow.find(
        (item) => item.value === deviceShow.salesProductId
      );
      setSaleProductShow(itemFind);
    }
    if (deviceShow.replaceDeviceId !== null) {
      const itemFind = arrDevice.find(
        (item) => item.value === deviceShow.replaceDeviceId
      );
      setReplaceDeviceShow(itemFind);
    }
  }, [deviceShow]);

  const removeItem = (data) => {
    setArrayValue(arrayValue.filter((item) => item.value !== data.id));
    setSerialList(serialList.filter((item) => item.id !== data.id));
    setDeviceList(deviceList.filter((item) => item.deviceId !== data.id));
    setArrayDevice(arrayDevice.filter((item) => item !== data.serialNumber));
  };
  const removeAllItem = () => {
    setArrayValue([]);
    setSerialList([]);
    setDeviceList([]);
    setArrayDevice([]);
  };

  // Handle modal multi choose
  const onChangeAddMoreDevices = (e) => {
    const data = e.target.value.split("\n");
    const dataNew = data.filter((item) => item !== "");
    const arrayDuplicate = [];
    setAddMoreValueReset(e.target.value);
    if (arrayDevice.length === 0) {
      setArrayDevice((item) => [...item.concat(dataNew)]);
    } else {
      for (let i = 0; i < dataNew.length; i++) {
        const item = dataNew[i];
        const itemFind = arrayDevice.find((items) => items === item);
        if (itemFind === undefined) {
          setArrayDevice((arrayDevice) => [...arrayDevice, item]);
        } else {
          arrayDuplicate.push(itemFind);
        }
      }
      if (arrayDuplicate.length !== 0) {
        message.warning(
          `Devices: ${arrayDuplicate.toString()} already exist in the list`,
          5
        );
      }
    }
  };
  const changeInputMethod = () => {
    setSerialList([]);
    setInputMethod(!inputMethod);
    setArrayDevice([]);
    setArrayValue([]);
  };
  const renderSerialListByAddMore = () => {
    setAddMoreValueReset("");
    if (arrayDevice.length === 0) {
      setSerialList(null);
    } else {
      const arrayShow = [];
      const arraySend = [];
      for (let i = 0; i < arrayDevice.length; i++) {
        const item = arrayDevice[i];
        const itemFind = arrMovementDevice.find(
          (object) => object.serialNumber === item
        );
        if (itemFind !== undefined) {
          const itemSend = {
            deviceId: itemFind.id,
            salesProductId: null,
            replaceDeviceId: null,
            comment: null,
          };
          arrayShow.push(itemFind);
          arraySend.push(itemSend);
        }
      }
      for (let i = 0; i < deviceList.length; i++) {
        const item = deviceList[i];
        const itemFind = arraySend.findIndex(
          (obj) => obj.deviceId === item.deviceId
        );
        if (itemFind !== -1) {
          arraySend[itemFind] = item;
        }
      }
      setSerialList(arrayShow);
      setDeviceList(arraySend);
    }
  };

  return (
    <div className="transaction">
      <form onSubmit={formik.handleSubmit}>
        <div className="transaction_form">
          <div className="transaction_top">
            <div className="transaction_left">
              <div className="transaction_item">
                <div className="transaction_serial-name">
                  {inputMethod === false ? (
                    <>
                      <div className="transaction_title">
                        <div className="transaction_title-left">
                          <p>Choose device*</p>{" "}
                        </div>
                        <Tooltip title="Change input method" color="#2962ff">
                          <i
                            className="fa fa-sync-alt"
                            onClick={() => {
                              changeInputMethod();
                              setListClassName(
                                "transaction_number-list-movement-change"
                              );
                            }}
                          ></i>
                        </Tooltip>
                      </div>
                      <Select
                        options={arrDevice}
                        isMulti
                        onChange={renderSerialList}
                        className={serialList === null ? "error_select" : ""}
                        controlShouldRenderValue={false}
                        value={arrayValue}
                      ></Select>
                      {serialList === null ? (
                        <div className="error_message">
                          <i className="fa fa-exclamation-circle"></i>
                          <span>Device is required</span>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div className="add_more-devices">
                      <div className="add_more-field">
                        <div className="add_more-title">
                          <div className="add_more-title-left">
                            <p>Enter device ESN*</p>{" "}
                            <Tooltip
                              title=" Copy devices ESN data from a column in an Excel file and past it
                              here."
                              color="#2962ff"
                            >
                              <i
                                className="fa fa-exclamation-circle"
                                onClick={() => {
                                  changeInputMethod();
                                  setListClassName(
                                    "transaction_number-list-movement"
                                  );
                                }}
                              ></i>
                            </Tooltip>
                          </div>
                          <Tooltip
                            title="Change input method"
                            color="#2962ff"
                            className="change-input"
                          >
                            <i
                              className="fa fa-sync-alt"
                              onClick={() => {
                                changeInputMethod();
                                setListClassName(
                                  "transaction_number-list-movement"
                                );
                              }}
                            ></i>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="add_more-action">
                        <Input.TextArea
                          placeholder="Paste here..."
                          name="addMulti"
                          value={addMoreValueReset}
                          onChange={onChangeAddMoreDevices}
                          className={serialList === null ? "error_input" : ""}
                        />
                        {serialList === null ? (
                          <div className="error_message">
                            <i className="fa fa-exclamation-circle"></i>
                            <span>Device ESN is required</span>
                          </div>
                        ) : null}
                        <button
                          className="add_more-add"
                          type="button"
                          onClick={renderSerialListByAddMore}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="transaction_number">
                <div className="transaction_number-name">
                  <div className="transaction_number-title">
                    <div className="transaction_number-title-left">
                      <p>Serial number list</p>
                      {serialList?.length > 0 ? (
                        <span>
                          (Total: <strong>{serialList.length}</strong>)
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="transaction_number-title-right">
                      {serialList?.length > 0 ? (
                        <p
                          onClick={() => {
                            removeAllItem();
                          }}
                        >
                          Clear all
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className={listClassName}>
                  {serialList?.length > 0
                    ? serialList.map((item, key) => {
                        return (
                          <div className="transaction_number-tag" key={key}>
                            <button
                              type="button"
                              onClick={() => {
                                removeItem(item);
                              }}
                              className="remove"
                            >
                              <i className="fa fa-times"></i>
                            </button>
                            <div className="transaction_number-item">
                              <div className="transaction_number-text">
                                <span>
                                  {convertLongToShortESN(item.serialNumber)}
                                </span>
                                {item.currentAccount !== null ? (
                                  <p>
                                    Current Account:{" "}
                                    <strong>{item.currentAccount.name}</strong>
                                  </p>
                                ) : (
                                  <p>
                                    Current Account: <strong>None</strong>
                                  </p>
                                )}
                                {item.purchaseProduct !== null ? (
                                  <p>
                                    Purchase Product:{" "}
                                    <strong>{item.purchaseProduct.name}</strong>
                                  </p>
                                ) : (
                                  <p>
                                    Purchase Product: <strong>None</strong>
                                  </p>
                                )}
                              </div>
                              <button
                                type="button"
                                onClick={() => {
                                  showDeviceInforModal(item.id);
                                }}
                              >
                                <i className="fa fa-pencil-alt"></i>
                              </button>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
              <div className="transaction_item">
                <p>Sales product*</p>
                <div className="transaction_item-state">
                  <div className="transaction_state-label">
                    <Select
                      name="salesProductId"
                      options={arraySaleProductShow}
                      onChange={(e) => {
                        formik.setFieldValue("salesProductId", e.value);
                      }}
                      className={
                        formik.touched.salesProductId &&
                        formik.errors.salesProductId
                          ? "error_select"
                          : ""
                      }
                    ></Select>
                    {formik.touched.salesProductId &&
                    formik.errors.salesProductId ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span className="cm_errors">
                          {formik.errors.salesProductId}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <button type="button" onClick={showModalCreateSale}>
                    Add
                  </button>
                </div>
              </div>
              <div className="transaction_item">
                <p>Billing group</p>
                <div className="transaction_item-state">
                  <div className="transaction_state-label">
                    <Select
                      name="billingGroupId"
                      options={arrayBillingGroupsShow}
                      onChange={(e) => {
                        if (e !== null) {
                          formik.setFieldValue("billingGroupId", e.value);
                        } else {
                          formik.setFieldValue("billingGroupId", e);
                        }
                      }}
                    ></Select>
                  </div>
                  <button type="button" onClick={showBillingGroupModal}>
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="transaction_right">
              <div className="transaction_item">
                <p>Device state*</p>
                <div className="transaction_item-state">
                  <div className="transaction_state-label">
                    <Select
                      name="deviceStateId"
                      options={arrState}
                      onChange={(e) => {
                        formik.setFieldValue("deviceStateId", e.value);
                      }}
                      className={
                        formik.touched.deviceStateId &&
                        formik.errors.deviceStateId
                          ? "error_select"
                          : ""
                      }
                    ></Select>
                    {formik.touched.deviceStateId &&
                    formik.errors.deviceStateId ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span className="cm_errors">
                          {formik.errors.deviceStateId}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <button type="button" onClick={showAddState}>
                    Add
                  </button>
                </div>
              </div>
              <div className="transaction_item">
                <p>TO account*</p>
                <Select
                  name="toAccountId"
                  options={arrPreviousCompany}
                  onChange={(e) => {
                    formik.setFieldValue("toAccountId", e.value);
                  }}
                  className={
                    formik.touched.toAccountId && formik.errors.toAccountId
                      ? "error_select"
                      : ""
                  }
                />
                {formik.touched.toAccountId && formik.errors.toAccountId ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.toAccountId}</span>
                  </div>
                ) : null}
              </div>
              <div className="transaction_item">
                <p>Movement date*</p>
                <DatePicker
                  name="movementDate"
                  showTime={true}
                  format="DD/MM/YYYY hh:mm A"
                  placeholder="Select..."
                  onChange={(date) => {
                    onChangeDatePicker(date, "movementDate", formik);
                  }}
                  className={
                    formik.touched.movementDate && formik.errors.movementDate
                      ? "error_input"
                      : ""
                  }
                />
                {formik.touched.movementDate && formik.errors.movementDate ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.movementDate}</span>
                  </div>
                ) : null}
              </div>
              <div className="transaction_item">
                <p>Start bill date*</p>
                <DatePicker
                  name="startBillDate"
                  showTime={true}
                  format="DD/MM/YYYY hh:mm A"
                  placeholder="Select..."
                  onChange={(date) => {
                    onChangeDatePicker(date, "startBillDate", formik);
                  }}
                  className={
                    formik.touched.startBillDate && formik.errors.startBillDate
                      ? "error_input"
                      : ""
                  }
                />
                {formik.touched.startBillDate && formik.errors.startBillDate ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.startBillDate}</span>
                  </div>
                ) : null}
              </div>
              <div className="transaction_item">
                <p>Invoice#</p>
                <Input
                  name="invoiceCode"
                  size="large"
                  onChange={formik.handleChange}
                  value={formik.values.invoiceCode}
                  className="input_ant"
                />
              </div>
              <div className="transaction_item">
                <p>Bill#</p>
                <Input
                  name="billCode"
                  size="large"
                  onChange={formik.handleChange}
                  value={formik.values.billCode}
                  className="input_ant"
                />
              </div>
              <div className="transaction_item">
                <p>Shipping#</p>
                <Input
                  name="shippingCode"
                  size="large"
                  onChange={formik.handleChange}
                  value={formik.values.shippingCode}
                  className="input_ant"
                />
              </div>
            </div>
          </div>
          <div className="transaction_bottom">
            <div className="transaction_item">
              <p>Haas price</p>
              <Input
                name="haasPrice"
                size="large"
                type="number"
                min={0}
                step={0.01}
                onChange={formik.handleChange}
                value={formik.values.haasPrice}
                className="input_ant"
              />
            </div>
            <div className="transaction_comment">
              <p>Note</p>
              <Input.TextArea
                name="notes"
                size="large"
                onChange={formik.handleChange}
                value={formik.values.notes}
              />
            </div>
            <div className="transaction_comment">
              <p>
                Comment{" "}
                <Tooltip title="This applies to all devices" color="#2962ff">
                  <i className="fa fa-exclamation-circle"></i>
                </Tooltip>
              </p>
              <Input.TextArea
                name="comment"
                size="large"
                onChange={formik.handleChange}
                value={formik.values.comment}
              />
            </div>
          </div>
        </div>

        <div className="transaction_action">
          <button
            type="button"
            className="transaction_action-cancel"
            onClick={cancelBulk}
          >
            Cancel
          </button>
          <button type="submit" className="transaction_action-create">
            Create
          </button>
        </div>
      </form>
      <Modal
        title="Create New Billing group"
        open={showBillingGroup}
        onCancel={cancelBillingGroupModal}
        footer={null}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateBillingGroup cancelBillingGroupModal={cancelBillingGroupModal} />
      </Modal>
      <Modal
        title="Create Sales Product"
        open={openCreateSale}
        onCancel={handleCancelCreateSale}
        footer={null}
        width={600}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateSaleProduct
          handleCancelCreateSale={handleCancelCreateSale}
          handleOkCreateSale={handleOkCreateSale}
        />
      </Modal>
      <Modal
        title={"Device ID: " + deviceShow.deviceId}
        open={deviceInforShow}
        onCancel={cancelDeviceInforModal}
        footer={null}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <DeviceInforBulkMovement
          showModalCreateSale={showModalCreateSale}
          arraySaleProductShow={arraySaleProductShow}
          arrDevice={arrDevice}
          cancelDeviceInforModal={cancelDeviceInforModal}
          setDeviceList={setDeviceList}
          deviceList={deviceList}
          deviceShow={deviceShow}
          setDeviceShow={setDeviceShow}
          saleProductShow={saleProductShow}
          replaceDeviceShow={replaceDeviceShow}
        />
      </Modal>
      <Modal
        title="Add New State"
        open={showState}
        onCancel={cancelAddState}
        footer={null}
        destroyOnClose
      >
        <CreateStateMovement
          cancelAddState={cancelAddState}
          resetState={resetState}
        />
      </Modal>
    </div>
  );
};

export default CreateMovementBulk;
