import React, { useEffect, useState } from "react";
import { message } from "antd";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTimeZone,
  updateAccountTimeZone,
} from "../../Redux/accountReducer/accountReducer";
import "../../Assets/scss/Component/Account/_accountTimeZone.scss";

const AccountTimeZone = () => {
  const [showIconTimezone, setShowIconTimezone] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const [arrayTimezone, setArrayTimezone] = useState([]);

  const dispatch = useDispatch();
  const { arrTimeZone, arrUserProfile } = useSelector(
    (state) => state.accountReducer
  );

  const [selectTimezone, setSelectTimezone] = useState({
    value: "",
    label: "",
  });

  const handleShowTimezone = () => {
    setShowIconTimezone(!showIconTimezone);
    setShowDropdown(!showDropdown);
  };

  const handleUpdateTimezone = (item) => {
    const values = {
      id: arrUserProfile?.id,
      timezoneId: item,
    };
    dispatch(updateAccountTimeZone(values));
    message.loading("Loading", 1);
  };

  useEffect(() => {
    if (arrUserProfile.timezone && arrUserProfile.timezone !== null) {
      setSelectTimezone({
        value: arrUserProfile.timezone.id,
        label: `${arrUserProfile.timezone.shortName}) ${arrUserProfile.timezone.name} (${arrUserProfile.timezone.offset}`,
      });
    }
  }, [arrUserProfile]);

  useEffect(() => {
    let arrSelect = [];
    arrTimeZone.map((item) => {
      let obj = {
        value: item.id,
        label: `(${item.shortName}) ${item.name} (${item.offset})`,
      };
      arrSelect.push(obj);
    });
    arrSelect.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setArrayTimezone(arrSelect);
  }, [arrTimeZone]);

  useEffect(() => {
    dispatch(getAllTimeZone());
  }, []);

  return (
    <div className="account_timezone">
      <div className="account_timezone-change">
        <button onClick={() => handleShowTimezone()}>
          <div className="account_timezone-show">
            <p>Change Timezone</p>
            {showIconTimezone === false ? (
              <i className="fas fa-caret-down"></i>
            ) : (
              <i className="fas fa-caret-up"></i>
            )}
          </div>
        </button>
      </div>

      {showDropdown === true ? (
        <div className="account_timezone-select">
          <Select
            options={arrayTimezone}
            value={{
              value: selectTimezone.value !== "" ? selectTimezone.value : "",
              label:
                selectTimezone.label !== ""
                  ? selectTimezone.label
                  : Intl.DateTimeFormat().resolvedOptions().timeZone,
            }}
            onChange={(e) => {
              handleUpdateTimezone(e.value);
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AccountTimeZone;
