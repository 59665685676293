import React, { useEffect, useState } from "react";
import { LaptopOutlined, UserOutlined, CarOutlined } from "@ant-design/icons";
import { defaults } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getTotalUserApi } from "../../Redux/userReducer/userReducer";
import { getTotalDeviceApi } from "../../Redux/deviceReducer/deviceReducer";
import { getTotalCompanyApi } from "../../Redux/companyReducer/companyReducer";
import {
  getTotalMovement,
  getTotalMovementByYear,
} from "../../Redux/movementReducer/movementReducer";
import CountUp from "react-countup";
import DashboardDatePicker from "../DatePickerMonthYear/DashboardDatePicker";
defaults.maintainAspectRatio = false;
defaults.responsive = true;

export default function DashboardMiddle() {
  const dispatch = useDispatch();
  const yearLocal = new Date().getFullYear();
  const [valueShowInput, setValueShowInput] = useState(yearLocal);
  const { totalMovement, totalMovementByYear } = useSelector(
    (state) => state.movementReducer
  );
  const { totalUser } = useSelector((state) => state.userReducer);
  const { totalDevice } = useSelector((state) => state.deviceReducer);
  const { totalCompany } = useSelector((state) => state.companyReducer);

  // Call api
  useEffect(() => {
    dispatch(getTotalDeviceApi());
    dispatch(getTotalUserApi());
    dispatch(getTotalCompanyApi());
    dispatch(getTotalMovement());
    dispatch(getTotalMovementByYear(yearLocal));
  }, [dispatch]);

  return (
    <div className="dashboard_mid">
      <div className="dashboard_mid-count">
        <div className="dashboard_body">
          <div className="dashboard_row">
            <div className="dashboard_col">
              <div className="col_icon">
                <LaptopOutlined className="dashboard_icon1" />
              </div>
              <div className="col_text">
                <p>Total Devices</p>
                <CountUp end={totalDevice} />
              </div>
            </div>
            <div className="dashboard_col1">
              <div className="col_icon">
                <CarOutlined className="dashboard_icon2" />
              </div>
              <div className="col_text">
                <p>Total Movements</p>
                <CountUp end={totalMovement} />
              </div>
            </div>
            <div className="dashboard_col2">
              <div className="col_icon">
                <i className="fa fa-address-book dashboard_icon3"></i>
              </div>
              <div className="col_text">
                <p>Total Accounts</p>
                <CountUp end={totalCompany} />
              </div>
            </div>
            <div className="dashboard_col3">
              <div className="col_icon">
                <UserOutlined className="dashboard_icon4" />
              </div>
              <div className="col_text">
                <p>Total Users</p>
                <CountUp end={totalUser} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard_mid-chart">
        <div className="chart_title">
          <p>Total Movement each month</p>
          <div className="chart_title-input">
            <span className="label">Select year:</span>
            <DashboardDatePicker
              valueShowInput={valueShowInput}
              setValueShowInput={setValueShowInput}
              yearLocal={yearLocal}
            />
          </div>
        </div>
        <div className="chart_body">
          <Line
            data={{
              labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
              datasets: [
                {
                  data: totalMovementByYear.map((item) => item.total),
                  tension: 0.3,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
