import axiosClient from "./axiosClient";

const billingSummaryApi = {
  postBillingSummary: async (data) => {
    const response = await axiosClient.post("/billing-summary", data);
    return response;
  },
};

export default billingSummaryApi;
