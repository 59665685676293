import axiosClient from "./axiosClient";

const userApi = {
  signin: async (data) => {
    const response = await axiosClient.post("/auth/signin", data);
    return response;
  },
  signout: async (token) => {
    const response = await axiosClient.delete("/auth/signout", token);
    return response;
  },
  getAll: async () => {
    const response = await axiosClient.get("/user");
    return response;
  },
  addUser: async (data) => {
    const response = await axiosClient.post("/user", data);
    return response;
  },
  delUser: async (id) => {
    const response = await axiosClient.delete("/user/" + id);
    return response;
  },
  restoreUser: async (id) => {
    const response = await axiosClient.put("/user/restore/" + id);
    return response;
  },
  updateUser: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/user/" + id, data);
    return response;
  },
  forgotPassword: async (data) => {
    const response = await axiosClient.post("/auth/forgot-password", data);
    return response;
  },
  verifyOtp: async (data) => {
    const response = await axiosClient.post("/auth/verify-otp", data);
    return response;
  },
  resetPassword: async (data) => {
    const response = await axiosClient.post("/auth/reset-password", data);
    return response;
  },
  getTotalUser: async () => {
    const response = await axiosClient.get("/user/total");
    return response;
  },
  changePassword: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/user/update-password/" + id, data);
    return response;
  },
  changeLog: async (id) => {
    const response = await axiosClient.get("/user/history/" + id);
    return response;
  },
  getAllRole: async () => {
    const response = await axiosClient.get("/role");
    return response;
  },
  findOne: async (id) => {
    const response = await axiosClient.get("/user/" + id);
    return response;
  },
};

export default userApi;
