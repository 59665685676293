import { storage } from "./storage";

export const showHideDeletedItem = (trigger, data, action, name) => {
  if (trigger === false) {
    const arrayData = data.filter((item) => item.isDeleted !== 1);
    action(arrayData);
    storage.setStorage(name, trigger);
  } else {
    action(data);
    storage.setStorage(name, trigger);
  }
};
