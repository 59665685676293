import axiosClient from "./axiosClient";

const eventApi = {
  getAll: async (params) => {
    let url = "/event";
    if (params) {
      const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");
      url = `${url}?${queryString}`;
    }
    const response = await axiosClient.get(url);
    return response;
  },
  createNew: async (data) => {
    const response = await axiosClient.post("/event", data);
    return response;
  },
  findTotal: async () => {
    const response = await axiosClient.get("/event/total");
    return response;
  },
  updateOne: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/event/" + id, data);
    return response;
  },
  deleteOne: async (id) => {
    const response = await axiosClient.delete("/event/" + id);
    return response;
  },
  restoreOne: async (id) => {
    const response = await axiosClient.put("/event/restore/" + id);
    return response;
  },
  findOne: async (id) => {
    const response = await axiosClient.get("/event/" + id);
    return response;
  },
  findHistory: async (id) => {
    const response = await axiosClient.get("/event/history/" + id);
    return response;
  },
 
};

export default eventApi;
