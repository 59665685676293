import { TimePicker } from "antd";
import React from "react";
import Select from "react-select";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import { storage } from "../../Utils/storage";
dayjs.extend(utc);
const format = "H:mm";

export default function FormWeekly(props) {
  const arrayDay = [
    { value: "1", label: "Mon" },
    { value: "2", label: "Tue" },
    { value: "3", label: "Wed" },
    { value: "4", label: "Thu" },
    { value: "5", label: "Fri" },
    { value: "6", label: "Sat" },
    { value: "0", label: "Sun" },
  ];
  const handleChangeSelect = (e) => {
    const arrDaySelect = [];
    if (e !== null) {
      for (let index = 0; index < e.length; index++) {
        arrDaySelect.push(e[index].value);
      }
      props.reportDetail.setFieldValue("daysOfWeek", arrDaySelect.toString());
    }
    if (e.length === 0) {
      props.reportDetail.setFieldValue("daysOfWeek", null);
    }
  };
  const onChange = (time) => {
    let user = storage.getStorageJson("user");
    if (user.timezone && user.timezone === null) {
      const utcHour = dayjs(time.$d).utc().hour();
      let utcMinute = dayjs(time.$d).utc().minute();
      if (utcMinute < 10) {
        utcMinute = "0" + utcMinute;
      }
      props.setTime(`${utcHour}:${utcMinute}`);
    } else {
      let newDay = new Date(
        `${dayjs(time.$d).format("M/DD/YYYY HH:mm:ss")}  UTC ${
          user.timezone.offset
        }`
      );
      const utcHour = newDay.getUTCHours();
      let utcMinute = newDay.getUTCMinutes();
      if (utcMinute < 10) {
        utcMinute = "0" + utcMinute;
      }
      props.setTime(`${utcHour}:${utcMinute}`);
    }
  };
  const renderDefaultSelected = () => {
    const arrayDefault = [];
    if (props.reportDetail.values.daysOfWeek !== null) {
      const arrDaySelected = props.reportDetail.values.daysOfWeek.split(",");
      for (let index = 0; index < arrDaySelected.length; index++) {
        const item = arrDaySelected[index];
        const itemFind = arrayDay.find((obj) => obj.value === item);
        if (itemFind !== undefined) {
          arrayDefault.push(itemFind);
        }
      }
      return arrayDefault;
    } else {
      return null;
    }
  };
  return (
    <>
      <div className="movement_report-row">
        <div className="row_left">
          <p>Set days:</p>
        </div>
        <div className="row_right">
          <Select
            options={arrayDay}
            onChange={handleChangeSelect}
            isMulti
            defaultValue={renderDefaultSelected}
          />
        </div>
      </div>
      <div className="movement_report-row">
        <div className="row_left">
          <p>Time send:</p>
        </div>
        <div className="row_right">
          <TimePicker
            format={format}
            placeholder="Select..."
            defaultValue={dayjs(props.reportDetail.values.reportSendAt, format)}
            onChange={onChange}
            allowClear={false}
          />
        </div>
      </div>
    </>
  );
}
