import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../Redux/authReducer/authReducer";
import Loading from "../../Components/Loading/Loading";
import "../../Assets/scss/Pages/SignIn/_signin.scss";

const SignIn = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.auth.account);
  const error = useSelector((state) => state.auth.isError);
  const isLoading = useSelector((state) => state.auth.isLoading);

  // Toggle Show Password
  const handleToggle = () => {
    setOpen(!open);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string("Enter your username")
        .required("Please enter your email"),
      password: yup
        .string("Enter your password")
        .min(6, "Password should be of minimum 6 characters length")
        .required("Please enter your password"),
    }),
    onSubmit: (values) => {
      const data = {
        email: values.email,
        password: values.password,
      };
      dispatch(signin(data));
    },
  });

  useEffect(() => {
    document.title = "Tag Register - Sign in";
  }, []);
  
  useEffect(() => {
    if (account && account.isSuccess) {
      navigate("/");
    }
  }, [account, navigate]);
  
  return (
    <div className="login">
      <div className="login_body">
        <div className="login_content">
          <div className="login_avatar">
            <i className="fas fa-user"></i>
          </div>
          <div className="login_text">
            <p>Sign In</p>
          </div>
          <div className="login_form">
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="login_field">
                <input
                  className={
                    formik.touched.email && formik.errors.email
                      ? "login_errors-input"
                      : "login_input"
                  }
                  id="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="username"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="login_errors">
                    <i className="fas fa-exclamation-circle"></i>
                    <span>{formik.errors.email}</span>
                  </div>
                ) : null}
              </div>

              <div className="login_field">
                <input
                  className={
                    formik.touched.password && formik.errors.password
                      ? "login_errors-input"
                      : "login_input"
                  }
                  type={open === false ? "password" : "text"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="login_errors">
                    <i className="fas fa-exclamation-circle"></i>
                    <span>{formik.errors.password}</span>
                  </div>
                ) : null}
                <div className="login_show-password">
                  {open === false ? (
                    <i className="fas fa-eye" onClick={handleToggle}></i>
                  ) : (
                    <i className="fas fa-eye-slash" onClick={handleToggle}></i>
                  )}
                </div>
              </div>

              <div className="login_action">
                <button type="submit">Sign In</button>
              </div>

              {error && isLoading === false ? (
                <div className="login_errors-server">
                  <span>{error}</span>
                </div>
              ) : null}

              <div className="login_forgot">
                <a href="/forgot-password">Forgot Password</a>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isLoading === true ? <Loading /> : null}
    </div>
  );
};

export default SignIn;
