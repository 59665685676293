import React, { useEffect, useState } from "react";
import { Input, message, Modal, DatePicker, Checkbox } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompany } from "../../Redux/companyReducer/companyReducer";
import { getAllDeviceMovement } from "../../Redux/movementReducer/movementReducer";
import "../../Assets/scss/Component/Movement/_movementNewTransaction.scss";
import { convertLongToShortESN } from "../../Utils/convertESN";
import { onChangeDatePicker } from "../../Utils/onChangeDatePicker";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";
import { getAllUserApi } from "../../Redux/userReducer/userReducer";
import { getAllEventType } from "../../Redux/eventReducer/eventTypeReducer";
import CreateEventType from "./CreateEventType";
import { createNewEventApi } from "../../Redux/eventReducer/eventReducer";

const CreateEvent = ({ handleCancel, setValueEnter, closeModal }) => {
  const [serialList, setSerialList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [arrPreviousCompany, setArrPreviousCompany] = useState([]);
  const [arrDevice, setArrDevice] = useState([]);
  const [arrayEventTypeShow, setArrayEventTypeShow] = useState([]);
  const [arrayValue, setArrayValue] = useState([]);
  const [charge, setCharge] = useState(false);
  const [checkValues, setCheckValues] = useState({});
  const [arrSupporter, setArrSupporter] = useState([]);
  const [showType, setShowType] = useState(false);
  const [value, setValue] = useState(false);
  const dispatch = useDispatch();
  const { arrCompany } = useSelector((state) => state.companyReducer);
  const { arrMovementDevice } = useSelector((state) => state.movementReducer);
  const { arrayEventType } = useSelector((state) => state.eventTypeReducer);
  const { arrUser } = useSelector((state) => state.userReducer);

  // Handle form
  const formik = useFormik({
    initialValues: {
      typeId: "",
      supporterId: "",
      supportedAt: "",
      isCharged: 0,
      cost: null,
      accountId: "",
      address: null,
      siteName: null,
      notes: null,
    },
    validationSchema: yup.object().shape({
      typeId: yup.mixed().required(" Event type is required"),
      supportedAt: yup.mixed().required("Support date is required"),
      supporterId: yup.mixed().required("Supporter is required"),
      accountId: yup.mixed().required("Account is required"),
      cost: yup.mixed().when("isCharged", {
        is: 1,
        then: yup.mixed().required("Cost is required"),
        otherwise: yup.mixed(),
      }),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      let newData = { ...values };
      if (newData.isCharged === 0) {
        delete newData.cost;
      }
      newData.devices = deviceList;
      dispatch(createNewEventApi(newData));
      message.loading("Loading", 1);
      closeModal();
    },
  });

  // Change value enter
  useEffect(() => {
    const data = {};
    convertEmptyToNull(formik.values);
    for (let item in formik.values) {
      if (formik.values[item] !== formik.initialValues[item]) {
        data[item] = formik.values[item];
      }
    }
    setCheckValues(data);
  }, [formik.values]);

  useEffect(() => {
    if (Object.keys(checkValues).length !== 0) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [checkValues]);

  useEffect(() => {
    if (serialList?.length !== 0) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [serialList]);

  useEffect(() => {
    setValueEnter(value);
  }, [value]);

  // Show modal add new state
  const showAddType = () => {
    setShowType(true);
  };
  const cancelAddType = () => {
    setShowType(false);
  };

  // Call api
  useEffect(() => {
    dispatch(getAllCompany());
    dispatch(getAllDeviceMovement());
    dispatch(getAllUserApi());
    dispatch(getAllEventType());
  }, []);

  useEffect(() => {
    let arrSelect = [];
    arrCompany.map((item) => {
      if (item.typeId === 1) {
        let obj = {
          value: item.id,
          label: item.name,
        };
        arrSelect.push(obj);
      }
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setArrPreviousCompany(arrSelect);
  }, [arrCompany]);

  useEffect(() => {
    let arrSelect = [];
    arrMovementDevice.map((item) => {
      if (item.isDeleted === 0) {
        let obj = {
          value: item.id,
          label: convertLongToShortESN(item.serialNumber),
        };
        arrSelect.push(obj);
      }
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setArrDevice(arrSelect);
  }, [arrMovementDevice]);

  useEffect(() => {
    CreateDataForSelect(arrayEventType, setArrayEventTypeShow);
  }, [arrayEventType]);

  useEffect(() => {
    let arrSelect = [];
    arrUser.map((item) => {
      if (item.isDeleted === 0) {
        let obj = {
          value: item.id,
          label: `${item.lastName} ${item.firstName}`,
        };
        arrSelect.push(obj);
      }
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setArrSupporter(arrSelect);
  }, [arrUser]);

  const renderSerialList = (e) => {
    setArrayValue(e);
    const arrayShow = [];
    const arraySend = [];
    for (let i = 0; i < e.length; i++) {
      const item = e[i].value;
      const itemFind = arrMovementDevice.find((object) => object.id === item);
      if (itemFind !== undefined) {
        const itemSend = itemFind.id;
        arrayShow.push(itemFind);
        arraySend.push(itemSend);
      }
    }
    setSerialList(arrayShow);
    setDeviceList(arraySend);
  };

  const removeItem = (data) => {
    setArrayValue(arrayValue.filter((item) => item.value !== data.id));
    setSerialList(serialList.filter((item) => item.id !== data.id));
    setDeviceList(deviceList.filter((item) => item !== data.id));
  };
  const removeAllItem = () => {
    setArrayValue([]);
    setSerialList([]);
    setDeviceList([]);
  };

  const onChangeCheckbox = (e) => {
    if (e.target.checked === true) {
      formik.setFieldValue("isCharged", 1);
    } else {
      formik.setFieldValue("isCharged", 0);
    }
    setCharge(e.target.checked);
  };

  return (
    <div className="transaction">
      <form onSubmit={formik.handleSubmit}>
        <div className="transaction_form">
          <div className="transaction_top">
            <div className="transaction_right">
              <div className="transaction_item">
                <p>Account*</p>
                <Select
                  name="accountId"
                  options={arrPreviousCompany}
                  onChange={(e) => {
                    formik.setFieldValue("accountId", e.value);
                  }}
                  className={
                    formik.touched.accountId && formik.errors.accountId
                      ? "error_select"
                      : ""
                  }
                />
                {formik.touched.accountId && formik.errors.accountId ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.accountId}</span>
                  </div>
                ) : null}
              </div>
              <div className="transaction_item">
                <p>Event type*</p>
                <div className="transaction_item-state">
                  <div className="transaction_state-label">
                    <Select
                      name="typeId"
                      options={arrayEventTypeShow}
                      onChange={(e) => {
                        formik.setFieldValue("typeId", e.value);
                      }}
                      className={
                        formik.touched.typeId && formik.errors.typeId
                          ? "error_select"
                          : ""
                      }
                    ></Select>
                    {formik.touched.typeId && formik.errors.typeId ? (
                      <div className="error_message">
                        <i className="fa fa-exclamation-circle"></i>
                        <span className="cm_errors">
                          {formik.errors.typeId}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <button type="button" onClick={showAddType}>
                    Add
                  </button>
                </div>
              </div>
              <div className="transaction_item">
                <p>Supporter*</p>
                <Select
                  name="supporterId"
                  options={arrSupporter}
                  onChange={(e) => {
                    formik.setFieldValue("supporterId", e.value);
                  }}
                  className={
                    formik.touched.supporterId && formik.errors.supporterId
                      ? "error_select"
                      : ""
                  }
                />
                {formik.touched.supporterId && formik.errors.supporterId ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.supporterId}</span>
                  </div>
                ) : null}
              </div>
              <div className="transaction_item">
                <p>Support date*</p>
                <DatePicker
                  name="supportedAt"
                  showTime={true}
                  format="DD/MM/YYYY hh:mm A"
                  placeholder="Select..."
                  onChange={(date) => {
                    onChangeDatePicker(date, "supportedAt", formik);
                  }}
                  className={
                    formik.touched.supportedAt && formik.errors.supportedAt
                      ? "error_input"
                      : ""
                  }
                />
                {formik.touched.supportedAt && formik.errors.supportedAt ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span>{formik.errors.supportedAt}</span>
                  </div>
                ) : null}
              </div>
              <div className="transaction_item">
                <div className="transaction_itemChild">
                  <div className="transaction_itemLeft">
                    <p>Charge</p>
                    <Checkbox onChange={onChangeCheckbox}>Yes/No</Checkbox>
                  </div>
                  <div className="transaction_itemRight">
                    {charge === true ? (
                      <div>
                        <p>Cost*</p>
                        <Input
                          name="cost"
                          size="large"
                          type="number"
                          min={0}
                          step={0.1}
                          onChange={formik.handleChange}
                          value={formik.values.cost}
                          className={
                            formik.touched.cost && formik.errors.cost
                              ? "error_input"
                              : ""
                          }
                        />
                        {formik.touched.cost && formik.errors.cost ? (
                          <div className="error_message">
                            <i className="fa fa-exclamation-circle"></i>
                            <span>{formik.errors.cost}</span>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="transaction_left">
              <div className="transaction_item">
                <div className="transaction_serial-name">
                  <>
                    <div className="transaction_title">
                      <div className="transaction_title-left">
                        <p>Choose device</p>{" "}
                      </div>
                    </div>
                    <Select
                      options={arrDevice}
                      isMulti
                      onChange={renderSerialList}
                      controlShouldRenderValue={false}
                      value={arrayValue}
                    ></Select>
                  </>
                </div>
              </div>
              <div className="transaction_number">
                <div className="transaction_number-name">
                  <div className="transaction_number-title">
                    <div className="transaction_number-title-left">
                      <p>Serial number list</p>
                      {serialList?.length > 0 ? (
                        <span>
                          (Total: <strong>{serialList.length}</strong>)
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="transaction_number-title-right">
                      {serialList?.length > 0 ? (
                        <p
                          onClick={() => {
                            removeAllItem();
                          }}
                        >
                          Clear all
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="transaction_number-list-movement">
                  {serialList?.length > 0
                    ? serialList.map((item, key) => {
                        return (
                          <div className="transaction_number-tag" key={key}>
                            <button
                              type="button"
                              onClick={() => {
                                removeItem(item);
                              }}
                              className="remove"
                            >
                              <i className="fa fa-times"></i>
                            </button>
                            <div className="transaction_number-item">
                              <div className="transaction_number-text">
                                <span>
                                  {convertLongToShortESN(item.serialNumber)}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="transaction_bottom">
            <div className="transaction_item">
              <p>Site name</p>
              <Input
                name="siteName"
                size="large"
                onChange={formik.handleChange}
                value={formik.values.siteName}
              />
            </div>
            <div className="transaction_item">
              <p>Address</p>
              <Input
                name="address"
                size="large"
                onChange={formik.handleChange}
                value={formik.values.address}
                className="input_ant"
              />
            </div>
            <div className="transaction_comment">
              <p>Notes</p>
              <Input.TextArea
                name="notes"
                size="large"
                onChange={formik.handleChange}
                value={formik.values.notes}
              />
            </div>
          </div>
        </div>

        <div className="transaction_action">
          <button
            type="button"
            className="transaction_action-cancel"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button type="submit" className="transaction_action-create">
            Create
          </button>
        </div>
      </form>

      <Modal
        title="Add New Type"
        open={showType}
        onCancel={cancelAddType}
        footer={null}
        destroyOnClose
      >
        <CreateEventType cancelAddType={cancelAddType} />
      </Modal>
    </div>
  );
};

export default CreateEvent;
