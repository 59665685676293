import React from "react";
import { Input } from "antd";
import "../../Assets/scss/Component/Platform/_createPlatformStatus.scss";

export default function CreatePlatformStatus(props) {
  return (
    <form
      className="status_create"
      onSubmit={props.platformStatusInformation.handleSubmit}
    >
      <div className="status_row">
        <div className="status_item">
          <p>Name*</p>
          <Input
            name="name"
            onChange={props.platformStatusInformation.handleChange}
            onBlur={props.platformStatusInformation.handleBlur}
            value={props.platformStatusInformation.values.name}
            size="large"
            className={
              props.platformStatusInformation.errors.name ? "error_input" : ""
            }
          />
          {props.platformStatusInformation.errors.name ? (
            <div className="error_message">
              <i className="fa fa-exclamation-circle"></i>
              <span>{props.platformStatusInformation.errors.name}</span>
            </div>
          ) : null}
        </div>
        <div className="status_item">
          <p>Description</p>
          <Input.TextArea
            name="description"
            onChange={props.platformStatusInformation.handleChange}
            onBlur={props.platformStatusInformation.handleBlur}
            value={props.platformStatusInformation.values.description}
            size="large"
          />
        </div>
      </div>
      <div className="form_action">
        <button
          type="button"
          className="cancel"
          onClick={() => {
            props.handleCancelStatus();
          }}
        >
          Cancel
        </button>
        <button type="submit" className="create">
          Create
        </button>
      </div>
    </form>
  );
}
