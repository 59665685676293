import React, { useEffect, useState } from "react";
import { Input, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePurchaseProductApi,
  restorePurchaseProductApi,
  updatePurchaseProduct,
} from "../../Redux/productReducer/purchaseProductReducer";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { compareObject } from "../../Utils/compareObject";
import { getAllWirelessTechApi } from "../../Redux/wirelessTechReducer/wirelessTechReducer";
import CreateWirelessTech from "./CreateWirelessTech";
import { renderUpdater } from "../../Utils/renderUpdater";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";

export default function PurchaseProductDetail(props) {
  const { productEdit } = useSelector((state) => state.purchaseProductReducer);
  const { arrPlatform } = useSelector((state) => state.platformReducer);
  const { arrWirelessTech } = useSelector((state) => state.wirelessTechReducer);
  const [arrSelectPlatform, setArrSelectPlatform] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showRestore, setShowRestore] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [arrWirelessTechSelect, setArrWirelessTechSelect] = useState([]);
  const dispatch = useDispatch();

  // Call api
  useEffect(() => {
    dispatch(getAllWirelessTechApi());
  }, []);

  // Handle modal
  const showModalCreate = () => {
    setOpenCreate(true);
  };

  const handleCancelCreate = () => {
    setOpenCreate(false);
  };

  const showModalDelete = () => {
    setShowDelete(true);
  };

  const handleOkModalDelete = () => {
    message.loading("loading", 1);
    const action = deletePurchaseProductApi(productEdit.id);
    dispatch(action);
    setShowDelete(false);
    props.handleCancelDetail();
  };

  const handleCancelModalDelete = () => {
    setShowDelete(false);
  };

  const showModalRestore = () => {
    setShowRestore(true);
  };

  const handleOkModalRestore = () => {
    message.loading("loading", 1);
    const action = restorePurchaseProductApi(productEdit.id);
    dispatch(action);
    setShowRestore(false);
  };
  const handleCancelModalRestore = () => {
    setShowRestore(false);
  };

  // Handle form
  const purchaseProductInformation = useFormik({
    initialValues: {
      name: productEdit.name,
      shortName: productEdit.shortName,
      wirelessTechnologyId: productEdit.wirelessTechnologyId,
      platformId: productEdit.platformId,
      unitCost: productEdit.unitCost,
      activationCost: productEdit.activationCost,
      batteriesCost: productEdit.batteriesCost,
      otherCost: productEdit.otherCost,
      description: productEdit.description,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name cannot be empty"),
      shortName: yup.string().required("Short name cannot be empty"),
      wirelessTechnologyId: yup
        .mixed()
        .required("Wireless technology is required"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      let newValues = { ...values };
      if (compareObject(newValues, productEdit)) {
        message.warning("Nothing has changed with this purchase product");
      } else {
        const newObject = {};
        for (let item in newValues) {
          if (newValues[item] !== productEdit[item]) {
            newObject[item] = newValues[item];
          }
        }
        const dataSend = {
          id: productEdit.id,
          data: newObject,
        };
        const action = updatePurchaseProduct(dataSend);
        dispatch(action);
        message.loading("Loading", 1);
      }
    },
  });

  useEffect(() => {
    CreateDataForSelect(arrPlatform, setArrSelectPlatform)
  }, [arrPlatform]);

  useEffect(() => {
    CreateDataForSelect(arrWirelessTech, setArrWirelessTechSelect)
  }, [arrWirelessTech]);
  return (
    <div className="user_detail">
      <form onSubmit={purchaseProductInformation.handleSubmit}>
        <div
          className="user_current"
          style={{ cursor: productEdit.isDeleted === 1 ? "no-drop" : "" }}
        >
          <div className="current_detail">
            <ul>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Name:</p>
                    </div>
                    <div className="col_2">
                      <Input
                        name="name"
                        onChange={purchaseProductInformation.handleChange}
                        onBlur={purchaseProductInformation.handleBlur}
                        value={purchaseProductInformation.values.name}
                        size="large"
                        className={
                          purchaseProductInformation.errors.name
                            ? "error_input"
                            : ""
                        }
                        disabled={productEdit.isDeleted === 1 ? true : false}
                      />
                      {purchaseProductInformation.errors.name ? (
                        <div className="error_message">
                          <i className="fa fa-exclamation-circle"></i>
                          <span>{purchaseProductInformation.errors.name}</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Short name:</p>
                    </div>
                    <div className="col_2">
                      <Input
                        name="shortName"
                        onChange={purchaseProductInformation.handleChange}
                        onBlur={purchaseProductInformation.handleBlur}
                        value={purchaseProductInformation.values.shortName}
                        size="large"
                        className={
                          purchaseProductInformation.errors.shortName
                            ? "error_input"
                            : ""
                        }
                        disabled={productEdit.isDeleted === 1 ? true : false}
                      />
                      {purchaseProductInformation.errors.shortName ? (
                        <div className="error_message">
                          <i className="fa fa-exclamation-circle"></i>
                          <span>
                            {purchaseProductInformation.errors.shortName}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Wireless technology:</p>
                    </div>
                    <div className="col_2">
                      <div className="wireless_row">
                        <div className="wireless_item-left">
                          <Select
                            name="wirelessTechnologyId"
                            options={arrWirelessTechSelect}
                            defaultValue={
                              purchaseProductInformation.values
                                .wirelessTechnologyId !== null
                                ? {
                                    value:
                                      purchaseProductInformation.wirelessTechnologyId,
                                    label: productEdit.wirelessTechnology?.name,
                                  }
                                : ""
                            }
                            onChange={(e) => {
                              purchaseProductInformation.setFieldValue(
                                "wirelessTechnologyId",
                                e.value
                              );
                            }}
                            className={
                              purchaseProductInformation.errors
                                .wirelessTechnologyId
                                ? "error_select"
                                : ""
                            }
                          />
                          {purchaseProductInformation.errors
                            .wirelessTechnologyId ? (
                            <div className="error_message">
                              <i className="fa fa-exclamation-circle"></i>
                              <span className="cm_errors">
                                {
                                  purchaseProductInformation.errors
                                    .wirelessTechnologyId
                                }
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="wireless_item-right">
                          <button type="button" onClick={showModalCreate}>
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Source platform:</p>
                    </div>
                    <div className="col_2">
                      <Select
                        options={arrSelectPlatform}
                        name="platformId"
                        defaultValue={
                          purchaseProductInformation.values.platformId !== null
                            ? {
                                value:
                                  purchaseProductInformation.values.platformId,
                                label:
                                  productEdit.platform !== null
                                    ? productEdit.platform.name
                                    : "",
                              }
                            : ""
                        }
                        onChange={(e) => {
                          if (e !== null) {
                            purchaseProductInformation.setFieldValue(
                              "platformId",
                              e.value
                            );
                          } else {
                            purchaseProductInformation.setFieldValue(
                              "platformId",
                              e
                            );
                          }
                        }}
                        isDisabled={productEdit.isDeleted === 1 ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Unit cost:</p>
                    </div>
                    <div className="col_2">
                      <Input
                        name="unitCost"
                        type="number"
                        step={0.01}
                        min={0}
                        onChange={purchaseProductInformation.handleChange}
                        onBlur={purchaseProductInformation.handleBlur}
                        value={purchaseProductInformation.values.unitCost}
                        size="large"
                        disabled={productEdit.isDeleted === 1 ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Activation cost:</p>
                    </div>
                    <div className="col_2">
                      <Input
                        name="activationCost"
                        type="number"
                        step={0.01}
                        min={0}
                        onChange={purchaseProductInformation.handleChange}
                        onBlur={purchaseProductInformation.handleBlur}
                        value={purchaseProductInformation.values.activationCost}
                        size="large"
                        disabled={productEdit.isDeleted === 1 ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Batteries cost:</p>
                    </div>
                    <div className="col_2">
                      <Input
                        name="batteriesCost"
                        type="number"
                        step={0.01}
                        min={0}
                        onChange={purchaseProductInformation.handleChange}
                        onBlur={purchaseProductInformation.handleBlur}
                        value={purchaseProductInformation.values.batteriesCost}
                        size="large"
                        disabled={productEdit.isDeleted === 1 ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Other cost:</p>
                    </div>
                    <div className="col_2">
                      <Input
                        name="otherCost"
                        type="number"
                        step={0.01}
                        min={0}
                        onChange={purchaseProductInformation.handleChange}
                        onBlur={purchaseProductInformation.handleBlur}
                        value={purchaseProductInformation.values.otherCost}
                        size="large"
                        disabled={productEdit.isDeleted === 1 ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_row">
                  <div className="row_1">
                    <div className="col_1">
                      <p>Description:</p>
                    </div>
                    <div className="col_2">
                      <Input.TextArea
                        name="description"
                        onChange={purchaseProductInformation.handleChange}
                        onBlur={purchaseProductInformation.handleBlur}
                        value={purchaseProductInformation.values.description}
                        disabled={productEdit.isDeleted === 1 ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="add_action">
              <button
                type="submit"
                disabled={productEdit.isDeleted === 1 ? true : false}
                style={{
                  cursor: productEdit.isDeleted === 1 ? "no-drop" : "pointer",
                }}
              >
                Save Change
              </button>
            </div>
          </div>
        </div>
        {productEdit.isDeleted === 0 ? (
          <>
            <div className="user_addition">
              <div className="add_detail">
                <div className="user_delete">
                  <h3>
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>{" "}
                    Delete purchase product
                  </h3>
                  <div className="user_password_row">
                    <p>This action can't be undone!</p>
                    <button type="button" onClick={showModalDelete}>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <div className="user_footer">{renderUpdater(productEdit,"product")}</div>
            </div>
          </>
        ) : (
          <div className="user_addition">
            <div className="add_detail">
              <div className="user_restore">
                <h3>
                  <span>
                    <i className="fa fa-history"></i>
                  </span>{" "}
                  Restore purchase product
                </h3>
                <div className="user_password_row">
                  <p>
                    This purchase product has been deleted. If you want to
                    restore it, please click on the Restore button.
                  </p>
                  <button type="button" onClick={showModalRestore}>
                    Restore
                  </button>
                </div>
              </div>
            </div>
            <div className="user_footer">{renderUpdater(productEdit,"product")}</div>
          </div>
        )}
      </form>
      <Modal
        open={showDelete}
        onCancel={handleCancelModalDelete}
        footer={null}
        width={450}
      >
        <div className="delete_user">
          <p>
            <i className="fa fa-question"></i> Delete purchase product
          </p>
          <span>Are you sure to delete this purchase product?</span>
          <div className="delete_action">
            <button className="cancel" onClick={handleCancelModalDelete}>
              Cancel
            </button>
            <button className="delete" onClick={handleOkModalDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showRestore}
        onCancel={handleCancelModalRestore}
        footer={null}
        width={450}
      >
        <div className="restore_user">
          <p>
            <i className="fa fa-question"></i> Restore purchase product
          </p>
          <span>Are you sure to restore this purchase product?</span>
          <div className="restore_action">
            <button className="cancel" onClick={handleCancelModalRestore}>
              Cancel
            </button>
            <button className="restore" onClick={handleOkModalRestore}>
              Restore
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        title="Create Wireless Technology"
        open={openCreate}
        onCancel={handleCancelCreate}
        footer={null}
        width={500}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateWirelessTech handleCancelCreate={handleCancelCreate} />
      </Modal>
    </div>
  );
}
