import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, saveEmail } from "../../Redux/authReducer/authReducer";
import Loading from "../../Components/Loading/Loading";
import "../../Assets/scss/Pages/ForgotPassword/_forgotpassword.scss";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Please enter your email !"),
});

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(null);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const error = useSelector((state) => state.auth.isErrorForgotPassword);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(saveEmail(values));
      dispatch(forgotPassword(values));
      setLoading(true);
    },
  });

  useEffect(()=>{
    document.title = "Tag Register - Forgot password";
  },[])
  
  useEffect(() => {
    if (isLoading === true) {
      const timeoutTrue = setTimeout(() => {
        setLoading(false);
      }, 2000);
      return () => {
        clearTimeout(timeoutTrue);
      };
    }
    if (isLoading === false) {
      const timeoutFalse = setTimeout(() => {
        setLoading(false);
      }, 2000);
      return () => {
        clearTimeout(timeoutFalse);
      };
    }
  }, [isLoading]);

  return (
    <div className="forgot">
      <div className="forgot_body">
        <div className="forgot_content">
          <div className="forgot_avatar">
            <i className="fas fa-user"></i>
          </div>
          <div className="forgot_text">
            <p>Forgot Password</p>
          </div>
          <div className="forgot_note">
            <p>
              Enter your email and we'll send you an OTP code to reset your
              password{" "}
            </p>
          </div>
          <div className="forgot_form">
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="forgot_field">
                <input
                  className={
                    formik.touched.email && formik.errors.email
                      ? "forgot_errors-input"
                      : "forgot_input"
                  }
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  autoComplete="email"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="forgot_errors">
                    <i className="fas fa-exclamation-circle"></i>
                    <span>{formik.errors.email}</span>
                  </div>
                ) : null}
              </div>

              <div className="forgot_action">
                <button type="submit">Send</button>
              </div>

              {error && loading === false ? (
                <div className="forgot_errors-server">
                  <span>{error}</span>
                </div>
              ) : null}
            </form>
          </div>

          <div className="forgot_to-login">
            <Link to="/sign-in">
              <span className="forgot_icon">
                <i className="fas fa-chevron-left"></i>
              </span>
              <span>Back to Sign in</span>
            </Link>
          </div>
        </div>
      </div>
      {loading === true ? <Loading /> : null}
    </div>
  );
};

export default ForgotPassword;
