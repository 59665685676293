import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDragListView from "react-drag-listview";
import {
  Input,
  Modal,
  Popconfirm,
  Popover,
  Space,
  Table,
  Tabs,
  FloatButton,
} from "antd";
import {
  DeleteOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  deleteCompany,
  findALLDiscountByAccountId,
  findOneAccount,
  getAllCompany,
  getChangeLogAccount,
} from "../../Redux/companyReducer/companyReducer";
import DetailCompany from "../../Components/Company/DetailCompany";
import CreateCompany from "../../Components/Company/CreateCompany";
import CompanyHistory from "../../Components/Company/CompanyHistory";
import { storage } from "../../Utils/storage";
import "../../Assets/scss/Pages/Company/_company.scss";
import { showHideDeletedItem } from "../../Utils/showHideDeletedItem";
import { getAllCompanyTypes } from "../../Redux/companyReducer/companyTypesReducer";
import Discount from "../../Components/Company/Discount";
import { getAllWirelessTechApi } from "../../Redux/wirelessTechReducer/wirelessTechReducer";
import {
  CURRENT_PAGE,
  DEFAULT_PAGESIZE,
} from "../../Utils/ConstCommon/constCommon";
import { renderShowColumn } from "../../Utils/renderShowColumn";
import ContentShowColumn from "../../Utils/ComponentCommon/ContentShowColumn";
import ContentShowHideDeleted from "../../Utils/ComponentCommon/ContentShowHideDeleted";

const Company = () => {
  const [showAddNew, setShowAddNew] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [arrSelect, setArrSelect] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [resetForm, setResetForm] = useState(false);
  const [showDeleted, setShowDeleted] = useState();
  const [data, setData] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(CURRENT_PAGE);
  const pageSizeRef = useRef(DEFAULT_PAGESIZE);
  const dispatch = useDispatch();
  const { arrCompany, companyEdit } = useSelector(
    (state) => state.companyReducer
  );

  // Handle modal
  const showModalNew = () => {
    setShowAddNew(true);
  };

  const cancelModalCreateNew = () => {
    setResetForm(!resetForm);
    setShowAddNew(false);
  };

  const cancelModalNew = () => {
    setShowAddNew(false);
  };

  const showModalDetail = () => {
    setShowDetail(true);
  };

  const cancelModalDetail = () => {
    setShowDetail(false);
  };

  //antd data
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName?.toLowerCase().includes(value.toLowerCase()) ||
          record.parentAccount?.toLowerCase().includes(value.toLowerCase()) ||
          record.typeNameShow?.toLowerCase().includes(value.toLowerCase()) ||
          record.primaryPlatformName
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          record.billingPolicy?.toLowerCase().includes(value.toLowerCase()) ||
          record.currency?.toLowerCase().includes(value.toLowerCase()) ||
          String(record.defaultPrice)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.deviceCount)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          record.country?.toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (text, record) => (
        <span
          className="movement_action-modal"
          onClick={async () => {
            await dispatch(findOneAccount(record.id));
            showModalDetail();
          }}
        >
          {text}
        </span>
      ),
      sorter: (beforeItem, afterItem) =>
        beforeItem.name.toLowerCase() > afterItem.name.toLowerCase()
          ? 1
          : beforeItem.name.toLowerCase() === afterItem.name.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Short name",
      dataIndex: "shortNameShow",
      key: "shortNameShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.shortNameShow.toLowerCase() >
        afterItem.shortNameShow.toLowerCase()
          ? 1
          : beforeItem.shortNameShow.toLowerCase() ===
            afterItem.shortNameShow.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Parent account",
      dataIndex: "parentAccount",
      key: "parentAccount",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.parentAccount.toLowerCase() >
        afterItem.parentAccount.toLowerCase()
          ? 1
          : beforeItem.parentAccount.toLowerCase() ===
            afterItem.parentAccount.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Account type",
      dataIndex: "typeNameShow",
      key: "typeNameShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.typeNameShow.toLowerCase() >
        afterItem.typeNameShow.toLowerCase()
          ? 1
          : beforeItem.typeNameShow.toLowerCase() ===
            afterItem.typeNameShow.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Primary platform",
      dataIndex: "primaryPlatformName",
      key: "primaryPlatformName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.primaryPlatformName.toLowerCase() >
        afterItem.primaryPlatformName.toLowerCase()
          ? 1
          : beforeItem.primaryPlatformName.toLowerCase() ===
            afterItem.primaryPlatformName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Billing policy",
      dataIndex: "billingPolicy",
      key: "billingPolicy",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.billingPolicy.toLowerCase() >
        afterItem.billingPolicy.toLowerCase()
          ? 1
          : beforeItem.billingPolicy.toLowerCase() ===
            afterItem.billingPolicy.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.currency.toLowerCase() > afterItem.currency.toLowerCase()
          ? 1
          : beforeItem.currency.toLowerCase() ===
            afterItem.currency.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Default price",
      dataIndex: "defaultPrice",
      key: "defaultPrice",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.defaultPrice - afterItem.defaultPrice,
    },
    {
      title: "Device count",
      dataIndex: "deviceCount",
      key: "deviceCount",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.deviceCount - afterItem.deviceCount,
    },
    {
      title: "Country",
      dataIndex: "countryShow",
      key: "countryShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.countryShow.toLowerCase() >
        afterItem.countryShow.toLowerCase()
          ? 1
          : beforeItem.countryShow.toLowerCase() ===
            afterItem.countryShow.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "80px",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Delete Type"
            placement="leftBottom"
            description="Are you sure to delete this type?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              dispatch(deleteCompany(record.id));
              setTimeout(() => {
                dispatch(getAllCompany());
              }, 1000);
            }}
          >
            <button className="company_delete">
              <DeleteOutlined className="company_delete-icon" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  let [columnSelected, setColumnSelected] = useState(columns);
  for (let index = 0; index < columnSelected.length; index++) {
    let item = columnSelected[index];
    item.filteredValue = [searchValue];
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setArrSelect(selectedRows);
      return (
        `selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows
      );
    },
  };

  // Use for detail
  const items = [
    {
      key: "1",
      label: `Account details`,
      children: (
        <DetailCompany
          cancelModalDetail={cancelModalDetail}
          companyEdit={companyEdit}
        />
      ),
    },
    {
      key: "2",
      label: `Discount`,
      children: <Discount />,
    },
    {
      key: "3",
      label: `History`,
      children: <CompanyHistory />,
    },
  ];

  const tabCallback = (key) => {
    if (key === "3") {
      dispatch(getChangeLogAccount(companyEdit.id));
    }
    if (key === "2") {
      dispatch(findALLDiscountByAccountId(companyEdit.id));
      dispatch(getAllWirelessTechApi());
    }
  };

  // Show hide column
  useEffect(() => {
    renderShowColumn(
      columns,
      "arrCompanyShowColumn",
      "arrCompanyRelocateTitleColumn",
      "arrCompanyRelocateTitleColumnNew",
      setColumnSelected
    );
    document.title = "Tag Register - Accounts";
  }, []);

  const resetAll = () => {
    storage.removeStorage("arrCompanyShowColumn");
    storage.removeStorage("arrCompanyRelocateTitleColumn");
    storage.removeStorage("arrCompanyRelocateTitleColumnNew");
    setColumnSelected(columns);
  };

  // Drag and drop
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newFromIndex = fromIndex - 1;
      const newToIndex = toIndex - 1;
      const newColumns = [...columnSelected];
      const item = newColumns.splice(newFromIndex, 1)[0];
      newColumns.splice(newToIndex, 0, item);
      setColumnSelected(newColumns);
      let arrRelocate = newColumns.map((item) => {
        return item.key;
      });
      storage.setStorageJson("arrCompanyRelocateTitleColumn", arrRelocate);
    },
    nodeSelector: "th",
  };

  // Content of popover
  const content = (
    <ContentShowColumn
      columns={columns}
      storageShow="arrCompanyShowColumn"
      storageRelocate="arrCompanyRelocateTitleColumn"
      storageRelocateNew="arrCompanyRelocateTitleColumnNew"
      action={setColumnSelected}
      resetAll={resetAll}
    />
  );

  // Call api
  useEffect(() => {
    dispatch(getAllCompany());
    dispatch(getAllCompanyTypes());
  }, [dispatch]);

  // Recall api
  useEffect(() => {
    const reCallApi = setInterval(() => {
      dispatch(getAllCompany());
    }, 10000);
    return () => clearInterval(reCallApi);
  }, []);

  // Show/hide deleted item
  const onChangeShowDeleted = (checked) => {
    showHideDeletedItem(checked, arrCompany, setData, "showDeletedAccount");
    setShowDeleted(checked);
  };

  useEffect(() => {
    const isShow = storage.getStore("showDeletedAccount");
    if (isShow === undefined || isShow === "true") {
      setData(arrCompany);
      setShowDeleted(true);
    } else {
      const arrayData = arrCompany.filter((item) => item.isDeleted !== 1);
      setData(arrayData);
      setShowDeleted(false);
    }
  }, [arrCompany]);

  const contentAction = (
    <ContentShowHideDeleted
      onChangeShowDeleted={onChangeShowDeleted}
      showDeleted={showDeleted}
      arrayData={arrCompany}
      columnSelected={columnSelected}
      pageName="Account"
      content={content}
    />
  );
  return (
    <div className="company">
      <div className="company_header">
        <p>Account</p>
      </div>
      <div className="company_body">
        <div className="company_action">
          <div className="company_action-left">
            <div className="company_action-selected">
              <p>
                Selected: <strong>{arrSelect.length}</strong>{" "}
              </p>
            </div>
            <button
              onClick={() => {
                showModalNew();
              }}
              className="company_button-create"
            >
              + New
            </button>
            <Popover content={contentAction} trigger="click" placement="bottom">
              <button className="button-second">
                Action <i className="fa fa-caret-down"></i>
              </button>
            </Popover>
          </div>
          <div className="company_action-right">
            <div className="company_action-search">
              <SearchOutlined className="search_icon" />
              <Input
                allowClear
                placeholder="Search..."
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <ReactDragListView.DragColumn {...dragProps}>
          <Table
            dataSource={data}
            columns={[...columnSelected]}
            rowSelection={rowSelection}
            rowClassName={(record) => (record.isDeleted === 1 ? "deleted" : "")}
            pagination={{
              defaultPageSize: 10,
              current: currentPagination,
              showSizeChanger: true,
              showTotal: (total, range) => (
                <p>
                  Showing{" "}
                  <strong>
                    {range[0]}-{range[1]}
                  </strong>{" "}
                  of <strong>{total}</strong> items
                </p>
              ),
              onChange: (newCurrent, newPageSize) => {
                const pageSizeChange = pageSizeRef.current !== newPageSize;
                if (pageSizeChange) {
                  setCurrentPagination(1);
                } else {
                  setCurrentPagination(newCurrent);
                }
                pageSizeRef.current = newPageSize;
              },
            }}
            bordered={true}
            scroll={{
              x: 2000,
            }}
            size="middle"
            sticky
          />
        </ReactDragListView.DragColumn>
      </div>

      <Modal
        title="Create New Account"
        open={showAddNew}
        onCancel={cancelModalCreateNew}
        footer={null}
        width={800}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateCompany
          cancelModalNew={cancelModalNew}
          setShowAddNew={setShowAddNew}
          resetForm={resetForm}
        />
      </Modal>
      <Modal
        title={"Account Id : " + companyEdit.id}
        open={showDetail}
        onCancel={cancelModalDetail}
        footer={null}
        width={800}
        style={{ top: "30px" }}
        destroyOnClose
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          size="small"
          className="user_tab"
          onChange={tabCallback}
          destroyInactiveTabPane={true}
        />
      </Modal>
      <FloatButton.BackTop />
    </div>
  );
};

export default Company;
