export const convertEmptyToNull = (value) => {
  if (value) {
    for (let key of Object.keys(value)) {
      if (
        (key === "baseSubsPrice" && value[key] === "") ||
        (key === "haasPrice" && value[key] === "") ||
        (key === "hwPrice" && value[key] === "") ||
        (key === "otherCost" && value[key] === "") ||
        (key === "unitCost" && value[key] === "") ||
        (key === "activationCost" && value[key] === "") ||
        (key === "batteriesCost" && value[key] === "") ||
        (key === "currentHaasPrice" && value[key] === "")
      ) {
        value[key] = 0;
      } else if (key === "defaultPrice" && value[key] === "") {
        value[key] = 1.5;
      } else if (value[key] === "") {
        value[key] = null;
      }
    }
  }
};
