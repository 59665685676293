import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import { useSelector } from "react-redux";
import { Skeleton, Tooltip } from "antd";
import { FileOutlined } from "@ant-design/icons";
import "../../Assets/scss/Component/Device/_deviceHistory.scss";
dayjs.extend(relativeTime);

export default function PurchaseProductHistory() {
  const { arrChangeLog, isLoading } = useSelector(
    (state) => state.purchaseProductReducer
  );
  const { arrPlatform } = useSelector((state) => state.platformReducer);
  const { arrWirelessTech } = useSelector((state) => state.wirelessTechReducer);
  const showHistory = () => {
    if (isLoading === true) {
      return <Skeleton active avatar />;
    } else {
      if (arrChangeLog.length !== 0) {
        return arrChangeLog.map((item, index) => {
          if (item.action !== "Updated") {
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    {item.action.toLowerCase()}{" "}
                    {dayjs(new Date(item.createdAt)).fromNow()}
                  </p>
                </div>
              </div>
            );
          } else {
            const arrShow = [];
            let newItem = { ...item };
            newItem.arrHistoryShow = arrShow;
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "name" in item.beforeData
            ) {
              const nameBefore = item.beforeData.name;
              const nameAfter = item.afterData.name;
              const name = {
                name: "Name",
                old: nameBefore,
                new: nameAfter,
              };
              arrShow.push(name);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "shortName" in item.beforeData
            ) {
              const shortNameBefore = item.beforeData.shortName;
              const shortNameAfter = item.afterData.shortName;
              const shortName = {
                name: "Short name",
                old: shortNameBefore,
                new: shortNameAfter,
              };
              arrShow.push(shortName);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "wirelessTechnologyId" in item.beforeData
            ) {
              let beforeWireless = "";
              let afterWireless = "";
              if (item.beforeData.wirelessTechnologyId === null) {
                beforeWireless = "None";
              } else {
                const wirelessTechFind = arrWirelessTech.find(
                  (wirelessTech) =>
                    wirelessTech.id === item.beforeData.wirelessTechnologyId
                );
                beforeWireless = wirelessTechFind.name;
              }
              if (item.afterData.wirelessTechnologyId === null) {
                afterWireless = "None";
              } else {
                const wirelessTechFind = arrWirelessTech.find(
                  (wirelessTech) =>
                    wirelessTech.id === item.afterData.wirelessTechnologyId
                );
                afterWireless = wirelessTechFind.name;
              }
              const wirelessTech = {
                name: "Wireless technology",
                old: beforeWireless,
                new: afterWireless,
              };
              arrShow.push(wirelessTech);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "platformId" in item.beforeData
            ) {
              let beforePlatform = "";
              let afterPlatform = "";
              if (item.beforeData.platformId === null) {
                beforePlatform = "None";
              } else {
                const platformFind = arrPlatform.find(
                  (platform) => platform.id === item.beforeData.platformId
                );
                beforePlatform = platformFind.name;
              }
              if (item.afterData.platformId === null) {
                afterPlatform = "None";
              } else {
                const platformFind = arrPlatform.find(
                  (platform) => platform.id === item.afterData.platformId
                );
                afterPlatform = platformFind.name;
              }
              const platform = {
                name: "Source platform",
                old: beforePlatform,
                new: afterPlatform,
              };
              arrShow.push(platform);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "unitCost" in item.beforeData
            ) {
              const unitCostBefore = item.beforeData.unitCost;
              const unitCostAfter = item.afterData.unitCost;
              const unitCost = {
                name: "Unit cost",
                old: unitCostBefore,
                new: unitCostAfter,
              };
              arrShow.push(unitCost);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "activationCost" in item.beforeData
            ) {
              const activationCostBefore = item.beforeData.activationCost;
              const activationCostAfter = item.afterData.activationCost;
              const activationCost = {
                name: "Activation cost",
                old: activationCostBefore,
                new: activationCostAfter,
              };
              arrShow.push(activationCost);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "batteriesCost" in item.beforeData
            ) {
              const batteriesCostBefore = item.beforeData.batteriesCost;
              const batteriesCostAfter = item.afterData.batteriesCost;
              const batteriesCost = {
                name: "Batteries cost",
                old: batteriesCostBefore,
                new: batteriesCostAfter,
              };
              arrShow.push(batteriesCost);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "otherCost" in item.beforeData
            ) {
              const otherCostBefore = item.beforeData.otherCost;
              const otherCostAfter = item.afterData.otherCost;
              const otherCost = {
                name: "Other cost",
                old: otherCostBefore,
                new: otherCostAfter,
              };
              arrShow.push(otherCost);
            }
            if (
              item.beforeData !== null &&
              item.afterData !== null &&
              "description" in item.beforeData
            ) {
              let descriptionBefore =
                item.beforeData.description === null
                  ? "None"
                  : item.beforeData.description;
              let descriptionAfter =
                item.afterData.description === null
                  ? "None"
                  : item.afterData.description;
              const description = {
                name: "Description",
                old: descriptionBefore,
                new: descriptionAfter,
              };
              arrShow.push(description);
            }
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {" "}
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    updated {dayjs(new Date(newItem.createdAt)).fromNow()}
                  </p>
                </div>
                {newItem.arrHistoryShow.map((item, index) => {
                  return (
                    <div key={index} className="description">
                      <strong>{item.name}</strong>: <span>{item.old}</span>
                      <i className="fa fa-long-arrow-alt-right"></i>
                      <span>{item.new}</span>
                    </div>
                  );
                })}
              </div>
            );
          }
        });
      } else {
        return (
          <div className="history_error">
            <div className="icon">
              <FileOutlined />
            </div>
            <p className="title">No data</p>
          </div>
        );
      }
    }
  };
  return <div className="history">{showHistory()}</div>;
}
