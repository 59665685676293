import React from "react";
import { Input } from "antd";
import "../../Assets/scss/Component/ChangePassword/_changePassword.scss";

export default function ChangePassword(props) {
  return (
    <>
      <form onSubmit={props.changePassWordInformation.handleSubmit}>
        <div className="change_password">
          <div className="change_password_item">
            <p>New password*</p>
            <Input.Password
              name="newPassword"
              placeholder="Enter new password"
              size="large"
              onChange={props.changePassWordInformation.handleChange}
              value={props.changePassWordInformation.values.newPassword}
              className={
                props.changePassWordInformation.errors.newPassword
                  ? "error_input"
                  : ""
              }
            />
            {props.changePassWordInformation.errors.newPassword ? (
              <div className="error_message">
                <i className="fa fa-exclamation-circle"></i>
                <span>
                  {props.changePassWordInformation.errors.newPassword}
                </span>
              </div>
            ) : null}
          </div>
          <div className="change_password_item">
            <p>Confirm password*</p>
            <Input.Password
              name="confirmPassword"
              placeholder="Enter confirm password"
              size="large"
              onChange={props.changePassWordInformation.handleChange}
              value={props.changePassWordInformation.values.confirmPassword}
              className={
                props.changePassWordInformation.errors.confirmPassword
                  ? "error_input"
                  : ""
              }
            />
            {props.changePassWordInformation.errors.confirmPassword ? (
              <div className="error_message">
                <i className="fa fa-exclamation-circle"></i>
                <span>
                  {props.changePassWordInformation.errors.confirmPassword}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="modal_action">
          <button
            className="modal_cancel"
            onClick={props.handleCancelModalChangePassword}
            type="button"
          >
            Cancel
          </button>
          <button className="modal_change" type="submit">
            Change
          </button>
        </div>
      </form>
    </>
  );
}
