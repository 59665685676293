import React, { useEffect, useState, useRef } from "react";
import {
  Input,
  Modal,
  Popconfirm,
  Popover,
  Space,
  Table,
  Tabs,
  Tooltip,
  FloatButton,
  Tag,
  notification,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  deleteMovement,
  findMovementByESN,
  findOneMovement,
  getAllDeviceMovement,
  getAllMovement,
  resetLatestMovementData,
  resetNotice,
  resetNoticeNewStock,
  resetNoticeReturnAfterWarranty,
  resetNoticeReturnStock,
  resetNoticeWarranty,
} from "../../Redux/movementReducer/movementReducer";
import CreateMovement from "../../Components/Movement/CreateMovement";
import CreateMovementBulk from "../../Components/Movement/CreateMovementBulk";
import DetailMovement from "../../Components/Movement/DetailMovement";
import MovementNewTransaction from "../../Components/Movement/MovementNewTransaction";
import { storage } from "../../Utils/storage";
import "../../Assets/scss/Pages/Movement/_movement.scss";
import ReactDragListView from "react-drag-listview";
import { convertLongToShortESN } from "../../Utils/convertESN";
import ReturnStock from "../../Components/Movement/ReturnStock";
import { showHideDeletedItem } from "../../Utils/showHideDeletedItem";
import { renderShowColumn } from "../../Utils/renderShowColumn";
import ContentShowColumn from "../../Utils/ComponentCommon/ContentShowColumn";
import {
  CURRENT_PAGE,
  DEFAULT_PAGESIZE,
} from "../../Utils/ConstCommon/constCommon";
import ContentShowHideDeleted from "../../Utils/ComponentCommon/ContentShowHideDeleted";
import ReturnAfterWarranty from "../../Components/Movement/ReturnAfterWarranty";
import Warranty from "../../Components/Movement/Warranty";
import ToBeReturn from "../../Components/Movement/ToBeReturn";

export default function Movement() {
  const [showAddNew, setShowAddNew] = useState(false);
  const [showBulkCreate, setShowBulkCreate] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showReturnStock, setShowReturnStock] = useState(false);
  const [showNewTransaction, setShowTransaction] = useState(false);
  const [showWarranty, setShowWarranty] = useState(false);
  const [showReturnWarranty, setShowReturnWarranty] = useState(false);
  const [showToBeReturn, setShowToBeReturn] = useState(false);
  const [arrSelect, setArrSelect] = useState([]);
  const [resetForm, setResetForm] = useState(false);
  const [resetNewTransaction, setResetNewTransaction] = useState(false);
  const [resetBulk, setResetBulk] = useState(false);
  const [warning, setWarning] = useState(false);
  const [valueEnter, setValueEnter] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(CURRENT_PAGE);
  const [showDeleted, setShowDeleted] = useState();
  const [data, setData] = useState([]);
  const pageSizeRef = useRef(DEFAULT_PAGESIZE);
  const currentPage = useRef(CURRENT_PAGE);
  const searchValue = useRef("");
  const dispatch = useDispatch();
  const {
    arrMovement,
    movementEdit,
    openNotice,
    objectNoticeBulkCreate,
    arrMovementDevice,
    noticeNewStock,
    objectNoticeNewStock,
    notifyReturnStock,
    objectNoticeReturnStock,
    pagination,
    objectWarranty,
    objectReturnAfterWarranty,
    noticeWarranty,
    noticeReturnAfterWarranty,
  } = useSelector((state) => state.movementReducer);

  // Use for table
  const columns = [
    {
      title: "ESN",
      dataIndex: "shortESN",
      key: "shortESN",
      sorter: (beforeItem, afterItem) =>
        beforeItem.shortESN?.toLowerCase() > afterItem.shortESN?.toLowerCase()
          ? 1
          : beforeItem.shortESN?.toLowerCase() ===
            afterItem.shortESN?.toLowerCase()
          ? 0
          : -1,
      render: (text, record) => (
        <span
          className="movement_action-modal"
          onClick={async () => {
            await dispatch(findOneMovement(record.id));
            showModalDetail();
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Movement date",
      dataIndex: "movementAt",
      key: "movementAt",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.movementDate?.toLowerCase() >
        afterItem.movementDate?.toLowerCase()
          ? 1
          : beforeItem.movementDate?.toLowerCase() ===
            afterItem.movementDate?.toLowerCase()
          ? 0
          : -1,
      render: (record) => <strong>{record}</strong>,
    },
    {
      title: "Type",
      dataIndex: "typeShow",
      key: "typeShow",
      align: "center",
      render: (record) => {
        if (record === "new-stock") {
          return (
            <Tag color="#4cbc9a" style={{ margin: "0" }}>
              {record}
            </Tag>
          );
        } else if (record === "movement") {
          return (
            <Tag color="#ffa500" style={{ margin: "0" }}>
              {record}
            </Tag>
          );
        } else if (record === "return-stock") {
          return (
            <Tag color="#ff0f0f" style={{ margin: "0" }}>
              {record}
            </Tag>
          );
        } else if (record === "warranty") {
          return (
            <Tag color="#ad82d9" style={{ margin: "0" }}>
              {record}
            </Tag>
          );
        } else if (record === "return-after-warranty") {
          return (
            <Tag color="#60a4bf" style={{ margin: "0" }}>
              {record}
            </Tag>
          );
        } else {
          return (
            <Tag color="#BC85A3" style={{ margin: "0" }}>
              {record}
            </Tag>
          );
        }
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.typeShow?.toLowerCase() > afterItem.typeShow?.toLowerCase()
          ? 1
          : beforeItem.typeShow?.toLowerCase() ===
            afterItem.typeShow?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Purchase product",
      dataIndex: "purchaseName",
      key: "purchaseName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.purchaseName?.toLowerCase() >
        afterItem.purchaseName?.toLowerCase()
          ? 1
          : beforeItem.purchaseName?.toLowerCase() ===
            afterItem.purchaseName?.toLowerCase()
          ? 0
          : -1,
      render: (text) => <span style={{ color: "#2962ff" }}>{text}</span>,
    },
    {
      title: "Purchase product code",
      dataIndex: "purchaseShortName",
      key: "purchaseShortName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.purchaseShortName?.toLowerCase() >
        afterItem.purchaseShortName?.toLowerCase()
          ? 1
          : beforeItem.purchaseShortName?.toLowerCase() ===
            afterItem.purchaseShortName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Sales product",
      dataIndex: "saleName",
      key: "saleName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.saleName?.toLowerCase() > afterItem.saleName?.toLowerCase()
          ? 1
          : beforeItem.saleName?.toLowerCase() ===
            afterItem.saleName?.toLowerCase()
          ? 0
          : -1,
      render: (text) => <span style={{ color: "#2962ff" }}>{text}</span>,
    },
    {
      title: "Sales product code",
      dataIndex: "saleShortName",
      key: "saleShortName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.saleShortName?.toLowerCase() >
        afterItem.saleShortName?.toLowerCase()
          ? 1
          : beforeItem.saleShortName?.toLowerCase() ===
            afterItem.saleShortName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "FROM account",
      dataIndex: "fromAccountName",
      key: "fromAccountName",
      align: "center",
      render: (text) => <span style={{ color: "#2962ff" }}>{text}</span>,
      sorter: (beforeItem, afterItem) =>
        beforeItem.fromAccountName?.toLowerCase() >
        afterItem.fromAccountName?.toLowerCase()
          ? 1
          : beforeItem.fromAccountName?.toLowerCase() ===
            afterItem.fromAccountName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "TO account",
      dataIndex: "toAccountName",
      key: "toAccountName",
      align: "center",
      render: (text) => <span style={{ color: "#2962ff" }}>{text}</span>,
      sorter: (beforeItem, afterItem) =>
        beforeItem.toAccountName?.toLowerCase() >
        afterItem.toAccountName?.toLowerCase()
          ? 1
          : beforeItem.toAccountName?.toLowerCase() ===
            afterItem.toAccountName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Start bill date",
      dataIndex: "startBillAt",
      key: "startBillAt",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.startBillDate?.toLowerCase() >
        afterItem.startBillDate?.toLowerCase()
          ? 1
          : beforeItem.startBillDate?.toLowerCase() ===
            afterItem.startBillDate?.toLowerCase()
          ? 0
          : -1,
      render: (record) => <strong>{record}</strong>,
    },
    {
      title: "Haas price",
      dataIndex: "haas",
      key: "haas",
      align: "center",
      sorter: (beforeItem, afterItem) => beforeItem.haas - afterItem.haas,
    },
    {
      title: "Replace device",
      dataIndex: "replaceDeviceShow",
      key: "replaceDeviceShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.replaceDeviceShow?.toLowerCase() >
        afterItem.replaceDeviceShow?.toLowerCase()
          ? 1
          : beforeItem.replaceDeviceShow?.toLowerCase() ===
            afterItem.replaceDeviceShow?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Billing group",
      dataIndex: "billingGroupShow",
      key: "billingGroupShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.billingGroupShow?.toLowerCase() >
        afterItem.billingGroupShow?.toLowerCase()
          ? 1
          : beforeItem.billingGroupShow?.toLowerCase() ===
            afterItem.billingGroupShow?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Invoice#",
      dataIndex: "invoiceShow",
      key: "invoiceShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.invoiceShow?.toLowerCase() >
        afterItem.invoiceShow?.toLowerCase()
          ? 1
          : beforeItem.invoiceShow?.toLowerCase() ===
            afterItem.invoiceShow?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Bill#",
      dataIndex: "billCodeShow",
      key: "billCodeShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.billCodeShow?.toLowerCase() >
        afterItem.billCodeShow?.toLowerCase()
          ? 1
          : beforeItem.billCodeShow?.toLowerCase() ===
            afterItem.billCodeShow?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Shipping#",
      dataIndex: "shippingCodeShow",
      key: "shippingCodeShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.shippingCodeShow?.toLowerCase() >
        afterItem.shippingCodeShow?.toLowerCase()
          ? 1
          : beforeItem.shippingCodeShow?.toLowerCase() ===
            afterItem.shippingCodeShow?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Note",
      dataIndex: "noteShow",
      key: "noteShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.noteShow?.toLowerCase() > afterItem.noteShow?.toLowerCase()
          ? 1
          : beforeItem.noteShow?.toLowerCase() ===
            afterItem.noteShow?.toLowerCase()
          ? 0
          : -1,
      render: (text) => (
        <Tooltip title={text} color="#2962ff">
          <Typography.Paragraph ellipsis={{ rows: 1 }}>
            {text}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Comment",
      dataIndex: "commentShow",
      key: "commentShow",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.commentShow?.toLowerCase() >
        afterItem.commentShow?.toLowerCase()
          ? 1
          : beforeItem.commentShow?.toLowerCase() ===
            afterItem.commentShow?.toLowerCase()
          ? 0
          : -1,
      render: (text) => (
        <Tooltip title={text} color="#2962ff">
          <Typography.Paragraph ellipsis={{ rows: 1 }}>
            {text}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "80px",
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="Delete Movement"
            placement="leftBottom"
            description="Are you sure to delete this movement?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              dispatch(deleteMovement(record.id));
              setTimeout(() => {
                dispatch(getAllMovement());
              }, 1000);
            }}
          >
            <button className="movement_action-delete">
              <DeleteOutlined className="movement_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [columnSelected, setColumnSelected] = useState(columns);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setArrSelect(selectedRows);
      return (
        `selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows
      );
    },
  };

  //Handle popover create
  const hidePopoverCreate = () => {
    setOpenCreate(false);
  };
  const handlePopoverCreate = (newOpen) => {
    setOpenCreate(newOpen);
  };

  // Show modal warning
  const showModalWarning = () => {
    setWarning(true);
  };
  const cancelModalWarning = () => {
    setWarning(false);
  };

  // Show and cancel modal create new movement
  const showModalNew = () => {
    setShowAddNew(true);
  };

  const cancelModalNew = () => {
    if (valueEnter === true) {
      showModalWarning();
    } else {
      setShowAddNew(false);
    }
  };
  // Show and cancel detail modal movement
  const showModalDetail = () => {
    setShowDetail(true);
  };
  const cancelModalDetail = () => {
    setShowDetail(false);
  };

  // Show and cancel modal create bulk movement
  const showBulk = () => {
    setShowBulkCreate(true);
  };
  const closeModalBulk = () => {
    setResetBulk(!resetBulk);
    setShowBulkCreate(false);
  };
  const cancelBulk = () => {
    if (valueEnter === true) {
      showModalWarning();
    } else {
      setResetBulk(!resetBulk);
      setShowBulkCreate(false);
    }
  };

  // Show and cancel New Transaction
  const showModalNewTransaction = () => {
    setShowTransaction(true);
  };

  const closeModalTransaction = () => {
    setShowTransaction(false);
    setResetNewTransaction(!resetNewTransaction);
  };
  const cancelNewTransaction = () => {
    if (valueEnter === true) {
      showModalWarning();
    } else {
      setShowTransaction(false);
      setResetNewTransaction(!resetNewTransaction);
    }
  };

  // Show and cancel return stock
  const showModalReturnStock = () => {
    setShowReturnStock(true);
  };

  const cancelModalReturnStock = () => {
    if (valueEnter === true) {
      showModalWarning();
    } else {
      setShowReturnStock(false);
    }
  };

  // Show and cancel warranty
  const showModalWarranty = () => {
    setShowWarranty(true);
  };

  const cancelModalWarranty = () => {
    if (valueEnter === true) {
      showModalWarning();
    } else {
      setShowWarranty(false);
    }
  };

  // Show and cancel return after warranty
  const showModalReturnWarranty = () => {
    setShowReturnWarranty(true);
  };

  const cancelModalReturnWarranty = () => {
    if (valueEnter === true) {
      showModalWarning();
    } else {
      setShowReturnWarranty(false);
    }
  };

  // Show and cancel to be return
  const showModalToBeReturn = () => {
    setShowToBeReturn(true);
  };

  const cancelModalToBeReturn = () => {
    if (valueEnter === true) {
      showModalWarning();
    } else {
      setShowToBeReturn(false);
      dispatch(resetLatestMovementData({}));
    }
  };

  // Detail
  const items = [
    {
      key: "1",
      label: `Movement details`,
      children: <DetailMovement cancelModalDetail={cancelModalDetail} />,
    },
    {
      key: "2",
      label: `History`,
      children: `Coming soon`,
    },
  ];

  // Drag and drop
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newFromIndex = fromIndex - 1;
      const newToIndex = toIndex - 1;
      const newColumns = [...columnSelected];
      const item = newColumns.splice(newFromIndex, 1)[0];
      newColumns.splice(newToIndex, 0, item);
      setColumnSelected(newColumns);
      let arrRelocate = newColumns.map((item) => {
        return item.key;
      });
      storage.setStorageJson("arrayMovementRelocateTitleColumn", arrRelocate);
    },
    nodeSelector: "th",
  };

  // show/hide columns
  useEffect(() => {
    renderShowColumn(
      columns,
      "arrayMovementShowColumn",
      "arrayMovementRelocateTitleColumn",
      "arrayMovementRelocateTitleColumnNew",
      setColumnSelected
    );
    document.title = "Tag Register - Movements";
  }, []);
  const resetAll = () => {
    storage.removeStorage("arrayMovementShowColumn");
    storage.removeStorage("arrayMovementRelocateTitleColumn");
    storage.removeStorage("arrayMovementRelocateTitleColumnNew");
    setColumnSelected(columns);
  };

  // Call api
  useEffect(() => {
    dispatch(getAllMovement());
    dispatch(getAllDeviceMovement());
  }, []);

  // Recall api
  useEffect(() => {
    const reCallApi = setInterval(() => {
      if (searchValue.current !== "") {
        dispatch(
          findMovementByESN({
            esn: searchValue.current,
            params: { limit: 10, page: 1 },
          })
        );
      } else {
        dispatch(
          getAllMovement({
            limit: pageSizeRef.current,
            page: currentPage.current,
          })
        );
      }
    }, 10000);
    return () => clearInterval(reCallApi);
  }, []);

  // Content of popover
  const content = (
    <ContentShowColumn
      columns={columns}
      storageShow="arrayMovementShowColumn"
      storageRelocate="arrayMovementRelocateTitleColumn"
      storageRelocateNew="arrayMovementRelocateTitleColumnNew"
      action={setColumnSelected}
      resetAll={resetAll}
    />
  );

  const contentNew = (
    <div className="movement_dropdown-item">
      <div
        onClick={() => {
          showModalNewTransaction();
          hidePopoverCreate();
        }}
      >
        Add New Stock
      </div>
      <div
        onClick={() => {
          showModalNew();
          hidePopoverCreate();
        }}
      >
        New Movement
      </div>
      <div
        onClick={() => {
          showBulk();
          hidePopoverCreate();
        }}
      >
        Bulk Movement
      </div>
      <div
        onClick={() => {
          showModalToBeReturn();
          hidePopoverCreate();
        }}
      >
        To Be Returned
      </div>
      <div
        onClick={() => {
          showModalReturnStock();
          hidePopoverCreate();
        }}
      >
        Return Stock
      </div>
      <div
        onClick={() => {
          showModalWarranty();
          hidePopoverCreate();
        }}
      >
        Warranty
      </div>
      <div
        onClick={() => {
          showModalReturnWarranty();
          hidePopoverCreate();
        }}
      >
        Return After Warranty
      </div>
    </div>
  );

  // Notice bulk movement
  const [api, contextHolder] = notification.useNotification();
  const renderAddNotice = () => {
    let itemCreated = [];
    let noticeItemCreated = "";
    if (
      Object.keys(objectNoticeBulkCreate).length !== 0 &&
      objectNoticeBulkCreate.added.length !== 0
    ) {
      for (
        let index = 0;
        index < objectNoticeBulkCreate.added.length;
        index++
      ) {
        const item = objectNoticeBulkCreate.added[index];
        const itemFind = arrMovementDevice.find((obj) => obj.id === item);
        if (itemFind !== undefined) {
          itemCreated.push(convertLongToShortESN(itemFind.serialNumber));
        }
      }
      const data = itemCreated.join(", ");
      noticeItemCreated = `Movement of device: ${data} were created`;
    } else {
      noticeItemCreated = "No new movements were created";
    }
    return noticeItemCreated;
  };
  const renderDuplicateNotice = () => {
    let itemDuplicated = [];
    let noticeItemDuplicate = "";
    if (
      Object.keys(objectNoticeBulkCreate).length !== 0 &&
      objectNoticeBulkCreate.duplicated.length !== 0
    ) {
      for (
        let index = 0;
        index < objectNoticeBulkCreate.duplicated.length;
        index++
      ) {
        const item = objectNoticeBulkCreate.duplicated[index];
        const itemFind = arrMovementDevice.find((obj) => obj.id === item);
        if (itemFind !== undefined) {
          itemDuplicated.push(convertLongToShortESN(itemFind.serialNumber));
        }
      }
      const data = itemDuplicated.join(", ");
      noticeItemDuplicate = `Movement of device: ${data} were duplicated`;
    } else {
      noticeItemDuplicate = "No new movements were duplicated";
    }
    return noticeItemDuplicate;
  };
  const openNotificationWithIcon = () => {
    api["success"]({
      message: "Bulk movement notification:",
      description: (
        <div className="notice_bulk">
          <div className="notice_row-add">
            <p>{renderAddNotice()}</p>
          </div>
          <div className="notice_row-duplicate">
            <p>{renderDuplicateNotice()}</p>
          </div>
        </div>
      ),
      duration: 5,
    });
  };

  // Notice return stock
  const renderAddReturnStock = () => {
    let itemCreated = [];
    let noticeItemCreated = "";
    if (
      Object.keys(objectNoticeReturnStock).length !== 0 &&
      objectNoticeReturnStock.added.length !== 0
    ) {
      for (
        let index = 0;
        index < objectNoticeReturnStock.added.length;
        index++
      ) {
        const item = objectNoticeReturnStock.added[index];
        const itemFind = arrMovementDevice.find((obj) => obj.id === item);
        if (itemFind !== undefined) {
          itemCreated.push(convertLongToShortESN(itemFind.serialNumber));
        }
      }
      const data = itemCreated.join(", ");
      noticeItemCreated = `Return stock of device: ${data} were created`;
    } else {
      noticeItemCreated = "No new return were created";
    }
    return noticeItemCreated;
  };
  const renderDuplicateReturnStock = () => {
    let itemDuplicated = [];
    let noticeItemDuplicate = "";
    if (
      Object.keys(objectNoticeReturnStock).length !== 0 &&
      objectNoticeReturnStock.duplicated.length !== 0
    ) {
      for (
        let index = 0;
        index < objectNoticeReturnStock.duplicated.length;
        index++
      ) {
        const item = objectNoticeReturnStock.duplicated[index];
        const itemFind = arrMovementDevice.find((obj) => obj.id === item);
        if (itemFind !== undefined) {
          itemDuplicated.push(convertLongToShortESN(itemFind.serialNumber));
        }
      }
      const data = itemDuplicated.join(", ");
      noticeItemDuplicate = `Return stock of device: ${data} were duplicated`;
    } else {
      noticeItemDuplicate = "No new return were duplicated";
    }
    return noticeItemDuplicate;
  };
  const openNotifyReturnStock = () => {
    api["success"]({
      message: "Return stock notification:",
      description: (
        <div className="notice_bulk">
          <div className="notice_row-add">
            <p>{renderAddReturnStock()}</p>
          </div>
          <div className="notice_row-duplicate">
            <p>{renderDuplicateReturnStock()}</p>
          </div>
        </div>
      ),
      duration: 5,
    });
  };
  // Notice warranty
  const renderAddWarranty = () => {
    let itemCreated = [];
    let noticeItemCreated = "";
    if (
      Object.keys(objectWarranty).length !== 0 &&
      objectWarranty.added !== null
    ) {
      for (let index = 0; index < objectWarranty.added.length; index++) {
        const item = objectWarranty.added[index];
        const itemFind = arrMovementDevice.find((obj) => obj.id === item);
        if (itemFind !== undefined) {
          itemCreated.push(convertLongToShortESN(itemFind.serialNumber));
        }
      }
      const data = itemCreated.join(", ");
      noticeItemCreated = `Warranty movement of device: ${data} were created`;
    } else {
      noticeItemCreated = "No new warranty movement were created";
    }
    return noticeItemCreated;
  };
  const renderDuplicateWarranty = () => {
    let itemDuplicated = [];
    let noticeItemDuplicate = "";
    if (
      Object.keys(objectWarranty).length !== 0 &&
      objectWarranty.denied !== null
    ) {
      for (let index = 0; index < objectWarranty.denied.length; index++) {
        const item = objectWarranty.denied[index];
        const itemFind = arrMovementDevice.find((obj) => obj.id === item);
        if (itemFind !== undefined) {
          itemDuplicated.push(convertLongToShortESN(itemFind.serialNumber));
        }
      }
      const data = itemDuplicated.join(", ");
      noticeItemDuplicate = `Warranty movement of device: ${data} were denied`;
    } else {
      noticeItemDuplicate = "No new warranty movement were denied";
    }
    return noticeItemDuplicate;
  };
  const openNotifyWarranty = () => {
    api["success"]({
      message: "Warranty notification:",
      description: (
        <div className="notice_bulk">
          <div className="notice_row-add">
            <p>{renderAddWarranty()}</p>
          </div>
          <div className="notice_row-duplicate">
            <p>{renderDuplicateWarranty()}</p>
          </div>
        </div>
      ),
      duration: 5,
    });
  };
  // Notice return after warranty
  const renderAddReturnWarranty = () => {
    let itemCreated = [];
    let noticeItemCreated = "";
    if (
      Object.keys(objectReturnAfterWarranty).length !== 0 &&
      objectReturnAfterWarranty.added !== null
    ) {
      for (
        let index = 0;
        index < objectReturnAfterWarranty.added.length;
        index++
      ) {
        const item = objectReturnAfterWarranty.added[index];
        const itemFind = arrMovementDevice.find((obj) => obj.id === item);
        if (itemFind !== undefined) {
          itemCreated.push(convertLongToShortESN(itemFind.serialNumber));
        }
      }
      const data = itemCreated.join(", ");
      noticeItemCreated = `Return after warranty movement of device: ${data} were created`;
    } else {
      noticeItemCreated = "No new return after warranty movement were created";
    }
    return noticeItemCreated;
  };
  const renderDuplicateReturnWarranty = () => {
    let itemDuplicated = [];
    let noticeItemDuplicate = "";
    if (
      Object.keys(objectReturnAfterWarranty).length !== 0 &&
      objectReturnAfterWarranty.denied !== null
    ) {
      for (
        let index = 0;
        index < objectReturnAfterWarranty.denied.length;
        index++
      ) {
        const item = objectReturnAfterWarranty.denied[index];
        const itemFind = arrMovementDevice.find((obj) => obj.id === item);
        if (itemFind !== undefined) {
          itemDuplicated.push(convertLongToShortESN(itemFind.serialNumber));
        }
      }
      const data = itemDuplicated.join(", ");
      noticeItemDuplicate = `Return after warranty movement of device: ${data} were denied`;
    } else {
      noticeItemDuplicate = "No new return after warranty movement were denied";
    }
    return noticeItemDuplicate;
  };
  const openNotifyReturnWarranty = () => {
    api["success"]({
      message: "Return after warranty notification:",
      description: (
        <div className="notice_bulk">
          <div className="notice_row-add">
            <p>{renderAddReturnWarranty()}</p>
          </div>
          <div className="notice_row-duplicate">
            <p>{renderDuplicateReturnWarranty()}</p>
          </div>
        </div>
      ),
      duration: 5,
    });
  };

  useEffect(() => {
    if (openNotice === true) {
      setTimeout(() => {
        openNotificationWithIcon();
      }, 1000);
      dispatch(resetNotice());
    }
  }, [openNotice]);

  // Notice add new stock
  const renderAddNewStockNotice = () => {
    let noticeItemCreated = "";
    if (
      Object.keys(objectNoticeNewStock).length !== 0 &&
      objectNoticeNewStock.added !== null
    ) {
      const data = objectNoticeNewStock.added.join(", ");
      noticeItemCreated = `Devices: ${data} were created`;
    } else {
      noticeItemCreated = "No new devices were created";
    }
    return noticeItemCreated;
  };
  const renderDuplicateNewStockNotice = () => {
    let noticeItemDuplicate = "";
    if (
      Object.keys(objectNoticeNewStock).length !== 0 &&
      objectNoticeNewStock.exist !== null
    ) {
      const data = objectNoticeNewStock.exist.join(", ");
      noticeItemDuplicate = `Devices: ${data} were duplicated`;
    } else {
      noticeItemDuplicate = "No new devices were duplicated";
    }
    return noticeItemDuplicate;
  };
  const openNotificationNewStock = () => {
    api["success"]({
      message: "Add new stock notification:",
      description: (
        <div className="notice_bulk">
          <div className="notice_row-add">
            <p>{renderAddNewStockNotice()}</p>
          </div>
          <div className="notice_row-duplicate">
            <p>{renderDuplicateNewStockNotice()}</p>
          </div>
        </div>
      ),
      duration: 5,
    });
  };
  useEffect(() => {
    if (noticeNewStock === true) {
      setTimeout(() => {
        openNotificationNewStock();
      }, 1000);
      dispatch(resetNoticeNewStock());
    }
  }, [noticeNewStock]);
  useEffect(() => {
    if (notifyReturnStock === true) {
      setTimeout(() => {
        openNotifyReturnStock();
      }, 1000);
    }
    dispatch(resetNoticeReturnStock());
  }, [notifyReturnStock]);
  useEffect(() => {
    if (noticeWarranty === true) {
      setTimeout(() => {
        openNotifyWarranty();
      }, 1000);
    }
    dispatch(resetNoticeWarranty());
  }, [noticeWarranty]);
  useEffect(() => {
    if (noticeReturnAfterWarranty === true) {
      setTimeout(() => {
        openNotifyReturnWarranty();
      }, 1000);
    }
    dispatch(resetNoticeReturnAfterWarranty());
  }, [noticeReturnAfterWarranty]);

  // Show/hide deleted item
  const onChangeShowDeleted = (checked) => {
    showHideDeletedItem(checked, arrMovement, setData, "showDeletedMovement");
    setShowDeleted(checked);
  };

  useEffect(() => {
    const isShow = storage.getStore("showDeletedMovement");
    if (isShow === undefined || isShow === "true") {
      setData(arrMovement);
      setShowDeleted(true);
    } else {
      const arrayData = arrMovement.filter((item) => item.isDeleted !== 1);
      setData(arrayData);
      setShowDeleted(false);
    }
  }, [arrMovement]);

  const contentAction = (
    <ContentShowHideDeleted
      onChangeShowDeleted={onChangeShowDeleted}
      showDeleted={showDeleted}
      arrayData={arrMovement}
      columnSelected={columnSelected}
      pageName="Movement"
      content={content}
    />
  );

  // Handle search
  const handleSearch = () => {
    if (searchValue.current !== "") {
      dispatch(findMovementByESN({ esn: searchValue.current, params: {} }));
    }
  };

  return (
    <div className="movement">
      {contextHolder}
      <div className="movement_header">
        <div className="movement_left">
          <p>Movements</p>
        </div>
      </div>
      <div className="movement_body">
        <div className="movement_show">
          <div className="movement_showChild">
            <div className="movement_showLeft">
              <p>
                Selected: <strong>{arrSelect.length}</strong>
              </p>
              <Popover
                content={contentNew}
                open={openCreate}
                onOpenChange={handlePopoverCreate}
                trigger="click"
                placement="bottom"
              >
                <button className="button-new">+ New</button>
              </Popover>
              <Popover
                content={contentAction}
                trigger="click"
                placement="bottom"
              >
                <button className="button-second">
                  Action <i className="fa fa-caret-down"></i>
                </button>
              </Popover>
            </div>
            <div className="movement_showRight">
              <div className="search_area">
                <div className="search_area-input">
                  <SearchOutlined className="search_icon" />
                  <Input
                    className="search_box"
                    placeholder="Search by ESN"
                    allowClear
                    onChange={(e) => {
                      searchValue.current = e.target.value.replace(/\s+/g, "");
                      if (e.target.value === "") {
                        dispatch(getAllMovement());
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }
                    }}
                  />
                </div>
                <button onClick={handleSearch}>Search</button>
              </div>
            </div>
          </div>
        </div>
        <div className="movement_table">
          <ReactDragListView.DragColumn {...dragProps}>
            <Table
              dataSource={data}
              columns={[...columnSelected]}
              rowSelection={rowSelection}
              rowClassName={(record) =>
                record.isDeleted === 1 ? "deleted" : ""
              }
              pagination={{
                defaultPageSize: 10,
                current: currentPagination,
                showSizeChanger: true,
                total: pagination?.totalItems,
                showTotal: (total, range) => (
                  <p>
                    Showing{" "}
                    <strong>
                      {range[0]}-{range[1]}
                    </strong>{" "}
                    of <strong>{total}</strong> items
                  </p>
                ),
                onChange: (newCurrent, newPageSize) => {
                  setCurrentPagination(newCurrent);
                  pageSizeRef.current = newPageSize;
                  currentPage.current = newCurrent;
                  if (searchValue.current !== "") {
                    dispatch(
                      findMovementByESN({
                        esn: searchValue.current,
                        params: { limit: newPageSize, page: newCurrent },
                      })
                    );
                  } else {
                    dispatch(
                      getAllMovement({ limit: newPageSize, page: newCurrent })
                    );
                  }

                  window.scrollTo({ top: 0, behavior: "smooth" });
                },
              }}
              bordered={true}
              scroll={{
                x: 3000,
              }}
              size="middle"
              sticky
            />
          </ReactDragListView.DragColumn>
        </div>
      </div>

      <Modal
        title={"Movement Id: " + movementEdit.id}
        open={showDetail}
        onCancel={cancelModalDetail}
        footer={null}
        width={800}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          size="small"
          className="user_tab"
          destroyInactiveTabPane
        />
      </Modal>
      <Modal
        title="Create New Movement"
        open={showAddNew}
        onCancel={cancelModalNew}
        footer={null}
        style={{ top: "50px" }}
        width={800}
        destroyOnClose
      >
        <CreateMovement
          cancelModalNew={cancelModalNew}
          setShowAddNew={setShowAddNew}
          resetForm={resetForm}
          setValueEnter={setValueEnter}
        />
      </Modal>
      <Modal
        title="Create New Bulk Movement"
        open={showBulkCreate}
        onCancel={cancelBulk}
        footer={null}
        style={{ top: "50px" }}
        width={800}
        destroyOnClose
      >
        <CreateMovementBulk
          cancelBulk={cancelBulk}
          resetBulk={resetBulk}
          closeModalBulk={closeModalBulk}
          setValueEnter={setValueEnter}
        />
      </Modal>
      <Modal
        title="Add New Stock"
        open={showNewTransaction}
        onCancel={cancelNewTransaction}
        footer={null}
        style={{ top: "50px" }}
        width={800}
        destroyOnClose
      >
        <MovementNewTransaction
          cancelNewTransaction={cancelNewTransaction}
          resetNewTransaction={resetNewTransaction}
          closeModalTransaction={closeModalTransaction}
          setValueEnter={setValueEnter}
        />
      </Modal>
      <Modal
        title="Return Stock"
        open={showReturnStock}
        onCancel={cancelModalReturnStock}
        width={800}
        style={{ top: "50px" }}
        footer={null}
        destroyOnClose
      >
        <ReturnStock
          cancelModalReturnStock={cancelModalReturnStock}
          setShowReturnStock={setShowReturnStock}
          setValueEnter={setValueEnter}
        />
      </Modal>
      <Modal
        title="To Be Returned"
        open={showToBeReturn}
        onCancel={cancelModalToBeReturn}
        width={850}
        style={{ top: "50px" }}
        footer={null}
        destroyOnClose
      >
        <ToBeReturn
          cancelModalToBeReturn={cancelModalToBeReturn}
          setShowToBeReturn={setShowToBeReturn}
          setValueEnter={setValueEnter}
        />
      </Modal>
      <Modal
        title="Warranty"
        open={showWarranty}
        onCancel={cancelModalWarranty}
        width={800}
        style={{ top: "50px" }}
        footer={null}
        destroyOnClose
      >
        <Warranty
          cancelModalWarranty={cancelModalWarranty}
          setShowWarranty={setShowWarranty}
          setValueEnter={setValueEnter}
        />
      </Modal>
      <Modal
        title="Return After Warranty"
        open={showReturnWarranty}
        onCancel={cancelModalReturnWarranty}
        width={800}
        style={{ top: "50px" }}
        footer={null}
        destroyOnClose
      >
        <ReturnAfterWarranty
          cancelModalReturnWarranty={cancelModalReturnWarranty}
          setShowReturnWarranty={setShowReturnWarranty}
          setValueEnter={setValueEnter}
        />
      </Modal>
      <Modal
        open={warning}
        onCancel={cancelModalWarning}
        footer={null}
        width={250}
        destroyOnClose
      >
        <div className="movement_warning">
          <h4 className="warning_title">
            {" "}
            <i className="fa fa-exclamation-circle"></i> Warning
          </h4>
          <p className="warning_text">Are you sure to leave?</p>
          <div className="warning_action">
            <button
              type="button"
              className="warning_no"
              onClick={cancelModalWarning}
            >
              No
            </button>
            <button
              type="button"
              className="warning_yes"
              onClick={() => {
                setShowAddNew(false);
                setResetForm(!resetForm);
                setResetBulk(!resetBulk);
                setShowBulkCreate(false);
                setShowTransaction(false);
                setResetNewTransaction(!resetNewTransaction);
                setShowReturnStock(false);
                setShowWarranty(false);
                setShowReturnWarranty(false);
                setShowToBeReturn(false);
                dispatch(resetLatestMovementData({}));
                cancelModalWarning();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
      <FloatButton.BackTop />
    </div>
  );
}
