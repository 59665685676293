export const timeExcelFile = () => {
  const day = new Date();
  let month = day.getMonth() + 1;
  let date = day.getDate();
  const year = day.getFullYear();
  let hours = day.getHours();
  let minutes = day.getMinutes();
  let seconds = day.getSeconds();
  if (date < 10) {
    date = "0" + date;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return `${month}${date}${year}_${hours}${minutes}${seconds}`;
};
