import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Input, message } from "antd";
import { bulkUpdateApi } from "../../Redux/deviceReducer/deviceReducer";
import { getAllDeviceStateApi } from "../../Redux/deviceReducer/deviceStateReducer";
import { getAllPurchaseProductApi } from "../../Redux/productReducer/purchaseProductReducer";
import { getAllSaleProductApi } from "../../Redux/productReducer/saleProductReducer";
import "../../Assets/scss/Component/Device/_bulkUpdate.scss";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";

export default function BulkUpDate({
  hideModalBulkUpdate,
  dataBulkUpdate,
}) {
  const dispatch = useDispatch();

  const { arrPurchaseProduct } = useSelector(
    (state) => state.purchaseProductReducer
  );
  const { arrSaleProduct } = useSelector((state) => state.saleProductReducer);
  const { arrDeviceState } = useSelector((state) => state.deviceStateReducer);

  const [arrDeviceId, setArrDeviceId] = useState([]);
  const [showPurchaseProduct, setShowPurchaseProduct] = useState(false);
  const [showSalesProduct, setShowSalesProduct] = useState(false);
  const [showInvoiceCode, setShowInvoiceCode] = useState(false);
  const [showState, setShowState] = useState(false);
  const [arrPurchase, setArrPurchase] = useState([]);
  const [arrSales, setArrSales] = useState([]);
  const [arrState, setArrState] = useState([]);
  const [valuePurchase, setValuePurchase] = useState("");
  const [valueSales, setValueSales] = useState("");
  const [valueState, setValueState] = useState("");
  const [valueInvoiceCode, setValueInvoiceCode] = useState("");

  const handleShowPurchase = () => {
    setShowPurchaseProduct(true);
    setShowSalesProduct(false);
    setShowInvoiceCode(false);
    setShowState(false);
    setValueSales("");
    setValueState("");
    setValueInvoiceCode("");
  };

  const handleShowSales = () => {
    setShowPurchaseProduct(false);
    setShowSalesProduct(true);
    setShowInvoiceCode(false);
    setShowState(false);
    setValuePurchase("");
    setValueState("");
    setValueInvoiceCode("");
  };

  const handleShowState = () => {
    setShowPurchaseProduct(false);
    setShowSalesProduct(false);
    setShowInvoiceCode(false);
    setShowState(true);
    setValuePurchase("");
    setValueSales("");
    setValueInvoiceCode("");
  };

  const handleShowInvoice = () => {
    setShowPurchaseProduct(false);
    setShowSalesProduct(false);
    setShowInvoiceCode(true);
    setShowState(false);
    setValuePurchase("");
    setValueSales("");
    setValueState("");
  };

  const handleUpdate = () => {
    let dataSend = {
      devices: arrDeviceId,
    };
    if (valuePurchase !== "") {
      dataSend.purchaseProductId = valuePurchase;
      message.loading("Loading", 1);
      dispatch(bulkUpdateApi(dataSend));
    } else if (valueSales !== "") {
      dataSend.currentSaleProductId = valueSales;
      message.loading("Loading", 1);
      dispatch(bulkUpdateApi(dataSend));
    } else if (valueState !== "") {
      dataSend.stateId = valueState;
      message.loading("Loading", 1);
      dispatch(bulkUpdateApi(dataSend));
    } else if (valueInvoiceCode !== "") {
      dataSend.invoiceCode = valueInvoiceCode;
      message.loading("Loading", 1);
      dispatch(bulkUpdateApi(dataSend));
    } else {
      message.warning("Nothing has changed with this device");
    }
  };

  const handleCancel = () => {
    hideModalBulkUpdate();
  };

  useEffect(() => {
    let arrUpdate = dataBulkUpdate;
    let arrId = [];
    for (let i = 0; i < arrUpdate.length; i++) {
      let id = arrUpdate[i].id;
      arrId.unshift(id);
    }
    setArrDeviceId(arrId);
  }, [dataBulkUpdate]);

  useEffect(() => {
    CreateDataForSelect(arrPurchaseProduct, setArrPurchase)
  }, [arrPurchaseProduct]);

  useEffect(() => {
    CreateDataForSelect(arrSaleProduct, setArrSales)
  }, [arrSaleProduct]);

  useEffect(() => {
    CreateDataForSelect(arrDeviceState, setArrState)
  }, [arrDeviceState]);

  useEffect(() => {
    dispatch(getAllDeviceStateApi());
    dispatch(getAllPurchaseProductApi());
    dispatch(getAllSaleProductApi());
  }, []);

  return (
    <div className="bulk_update">
      <div></div>
      <p className="bulk_title">
        Update for {dataBulkUpdate.length} selected devices
      </p>
      <div className="bulk_box">
        <div className="bulk_choose">
          <div className="bulk_merge">
            <div className="bulk_button-left">
              <button type="button" onClick={handleShowPurchase}>
                Update purchase product
              </button>
            </div>
            <div className="bulk_button-right">
              <button type="button" onClick={handleShowSales}>
                Update sales product
              </button>
            </div>
          </div>
          <div className="bulk_merge">
            <div className="bulk_button-left">
              <button type="button" onClick={handleShowInvoice}>
                Update invoice code
              </button>
            </div>
            <div className="bulk_button-right">
              <button type="button" onClick={handleShowState}>
                Update state
              </button>
            </div>
          </div>
        </div>
        <div className="bulk_notification">
          <p>
            Corresponding fields will be displayed below after selecting the
            above fields.
          </p>
        </div>

        {showPurchaseProduct === true ? (
          <div className="bulk_field">
            <div className="bulk_field-name">
              <p>Purchase Product</p>
            </div>
            <div className="bulk_field-choose">
              <Select
                name="purchaseProductId"
                options={arrPurchase}
                onChange={(e) => {
                  setValuePurchase(e.value);
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        {showSalesProduct === true ? (
          <div className="bulk_field">
            <div className="bulk_field-name">
              <p>Sales Product</p>
            </div>
            <div className="bulk_field-choose">
              <Select
                name="currentSaleProductId"
                options={arrSales}
                onChange={(e) => {
                  setValueSales(e.value);
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        {showState === true ? (
          <div className="bulk_field">
            <div className="bulk_field-name">
              <p>State</p>
            </div>
            <div className="bulk_field-choose">
              <Select
                name="stateId"
                options={arrState}
                onChange={(e) => {
                  setValueState(e.value);
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        {showInvoiceCode === true ? (
          <div className="bulk_field">
            <div className="bulk_field-name">
              <p>Invoice Code</p>
            </div>
            <div className="bulk_field-choose">
              <Input
                size="large"
                name="invoiceCode"
                onChange={(e) => setValueInvoiceCode(e.target.value)}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="bulk_action">
        <div className="bulk_cancel">
          <button type="button" onClick={handleCancel}>
            Cancel
          </button>
        </div>
        <div className="bulk_update">
          <button type="button" onClick={handleUpdate}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
}
