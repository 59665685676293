import React from "react";
import HeaderMobile from "../../Components/Header/HeaderMobile";

export default function HomeTemplate() {
  return (
    <div>
      <HeaderMobile />
    </div>
  );
}
