import axiosClient from "./axiosClient";

const eventTypeApi = {
  addNew: async (data) => {
    const response = await axiosClient.post("/event-type", data);
    return response;
  },
  getAll: async () => {
    const response = await axiosClient.get("/event-type");
    return response;
  },
  delete: async (id) => {
    const response = await axiosClient.delete("/event-type/" + id);
    return response;
  },
  update: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/event-type/" + id, data);
    return response;
  },
  restore: async (id) => {
    const response = await axiosClient.put("/event-type/restore/" + id);
    return response;
  },
  findOne: async (id) => {
    const response = await axiosClient.get("/event-type/" + id);
    return response;
  },
  findHistory: async (id) => {
    const response = await axiosClient.get("/event-type/history/" + id);
    return response;
  },
};
export default eventTypeApi;
