import React from "react";
import "../../Assets/scss/Component/User/_createUser.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { Input, message } from "antd";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { addWirelessTechApi } from "../../Redux/wirelessTechReducer/wirelessTechReducer";
import { useDispatch } from "react-redux";

export default function CreateWirelessTech({ handleCancelCreate }) {
  const dispatch = useDispatch();

  // Handle form
  const wirelessTechInformation = useFormik({
    initialValues: {
      name: "",
      shortName: "",
      description: null,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      shortName: yup.string().required("Short name is required"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      const action = addWirelessTechApi(values);
      dispatch(action);
      message.loading("loading", 1);
      handleCancelCreate();
    },
  });
  return (
    <form onSubmit={wirelessTechInformation.handleSubmit}>
      <div className="create_user">
        <div className="user_field">
          <p>Name*</p>
          <Input
            name="name"
            onChange={wirelessTechInformation.handleChange}
            onBlur={wirelessTechInformation.handleBlur}
            value={wirelessTechInformation.values.name}
            size="large"
            className={wirelessTechInformation.errors.name ? "error_input" : ""}
          />

          {wirelessTechInformation.errors.name ? (
            <div className="error_message">
              <i className="fa fa-exclamation-circle"></i>
              <span className="cm_errors">
                {wirelessTechInformation.errors.name}
              </span>
            </div>
          ) : null}
        </div>
        <div className="user_field">
          <p>Short name*</p>
          <Input
            name="shortName"
            className={
              wirelessTechInformation.errors.shortName ? "error_input" : ""
            }
            onChange={wirelessTechInformation.handleChange}
            onBlur={wirelessTechInformation.handleBlur}
            value={wirelessTechInformation.values.shortName}
            size="large"
          />
          {wirelessTechInformation.errors.shortName ? (
            <div className="error_message">
              <i className="fa fa-exclamation-circle"></i>
              <span className="cm_errors">
                {wirelessTechInformation.errors.shortName}
              </span>
            </div>
          ) : null}
        </div>
        <div className="user_field">
          <p>Description</p>
          <Input.TextArea
            name="description"
            onChange={wirelessTechInformation.handleChange}
            onBlur={wirelessTechInformation.handleBlur}
            value={wirelessTechInformation.values.description}
            size="large"
          />
        </div>
      </div>
      <div className="form_action">
        <button
          type="button"
          className="cancel"
          onClick={() => {
            handleCancelCreate();
          }}
        >
          Cancel
        </button>
        <button type="submit" className="create">
          Create
        </button>
      </div>
    </form>
  );
}
