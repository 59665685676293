import * as XLSX from "xlsx-js-style";
export const exportExcel = (data, column, pageName) => {
  // Convert data
  const objectTable = [];
  const arrayData = [];
  for (let i = 0; i < column.length; i++) {
    if (column[i].title !== "Action") {
      const objectItem = {
        title: column[i].title,
        index: column[i].dataIndex,
      };
      objectTable.push(objectItem);
    }
  }

  for (let j = 0; j < data.length; j++) {
    let itemMovement = data[j];
    let item = objectTable.map((item) => {
      let index = item.index;
      let title = item.title;
      let indexParse = itemMovement[index];
      if (index === "devices") {
        indexParse = indexParse
          .map((item) => {
            return item.device.serialNumber;
          })
          .join(", ");
      }
      let obj = { [title]: indexParse };
      return obj;
    });
    const arrayMerge = Object.assign({ "#": j + 1 }, ...item);
    arrayData.push(arrayMerge);
  }
  const day = new Date();
  let month = day.getMonth() + 1;
  let date = day.getDate();
  const year = day.getFullYear();
  let hours = day.getHours();
  let minutes = day.getMinutes();
  let seconds = day.getSeconds();
  if (date < 10) {
    date = "0" + date;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  const timeShow = `${month}${date}${year}_${hours}${minutes}${seconds}`;
  
  // Export data
  const workBook = XLSX.utils.book_new();
  const workSheet = XLSX.utils.json_to_sheet(arrayData);

  // Style column title
  let columnCount = XLSX.utils.decode_range(workSheet["!ref"]).e.c + 1;
  for (let index = 0; index < columnCount; index++) {
    let indexTitle = XLSX.utils.encode_cell({ r: 0, c: index });
    workSheet[indexTitle].s = {
      font: { bold: true },
      fill: { fgColor: { rgb: "D9E1F2" } },
      alignment: { vertical: "center", horizontal: "center" },
      border: {
        right: {
          style: "thin",
          color: "000000",
        },
        left: {
          style: "thin",
          color: "000000",
        },
        top: {
          style: "thin",
          color: "000000",
        },
        bottom: {
          style: "thin",
          color: "000000",
        },
      },
    };
  }
  XLSX.utils.book_append_sheet(workBook, workSheet, "Data");
  XLSX.writeFile(workBook, `${pageName}_${timeShow}.xlsx`);
};
