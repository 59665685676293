import axiosClient from "./axiosClient";

const discountApi = {
  getAll: async () => {
    const response = await axiosClient.get("/discount");
    return response;
  },
  addNew: async (data) => {
    const response = await axiosClient.post("/discount", data);
    return response;
  },
  getAllByAccountId: async (id) => {
    const response = await axiosClient.get("/discount/account/" + id);
    return response;
  },
  getHistory: async (id) => {
    const response = await axiosClient.get("/discount/history/" + id);
    return response;
  },
  getOne: async (id) => {
    const response = await axiosClient.get("/discount/" + id);
    return response;
  },
  updateOne: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/discount/" + id, data);
    return response;
  },
  deleteOne: async (data) => {
    const response = await axiosClient.delete("/discount/" + data.id);
    return response;
  },
  restoreOne: async (data) => {
    const response = await axiosClient.put("/discount/restore/" + data.id);
    return response;
  },
};

export default discountApi;
