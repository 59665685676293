import { storage } from "./storage";
export const convertTimeShowDetail = (time) => {
  let user = storage.getStorageJson("user");
  let timeConvert = "";
  if (user.timezone && user.timezone !== null) {
    let arrOffset = user.timezone.offset.split(":");
    if (arrOffset[1] === "30") {
      arrOffset[1] = "50";
    }
    let offset = arrOffset[0] + "." + arrOffset[1];
    let day = new Date(time);
    let utc = day.getTime() + day.getTimezoneOffset() * 60000;
    timeConvert = new Date(utc + 3600000 * offset);
  } else {
    timeConvert = new Date(time);
  }
  let month = timeConvert.getMonth() + 1;
  let date = timeConvert.getDate();
  const year = timeConvert.getFullYear();
  let hours = timeConvert.getHours();
  let minutes = timeConvert.getMinutes();
  let seconds = timeConvert.getSeconds();
  if (date < 10) {
    date = "0" + date;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds === 0) {
    seconds = "0" + seconds;
  }
  const timeShow = `${year}-${month}-${date}T${hours}:${minutes}:${seconds}`;
  return timeShow;
};
