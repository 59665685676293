import React, { useState } from "react";
import { Input, Table, Space, Modal, Popconfirm } from "antd";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewPlatformStatusApi,
  deletePlatformStatusApi,
  editPlatformStatus,
} from "../../Redux/platformReducer/platformStatusReducer";
import "../../Assets/scss/Component/Platform/_platformStatusManage.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import PlatformStatusDetail from "./PlatformStatusDetail";

export default function PlatformStatusManagement(props) {
  const dispatch = useDispatch();
  const { arrPlatformStatus } = useSelector(
    (state) => state.platformStatusReducer
  );
  const [openDetail, setOpenDetail] = useState(false);

  // Handle modal
  const showModalSetting = () => {
    setOpenDetail(true);
  };
  const handleOkStatus = () => {
    setOpenDetail(false);
  };

  const handleCancelStatus = () => {
    setOpenDetail(false);
  };

  // Use for table
  const data = arrPlatformStatus;
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "30%",
      render: (text, record) => (
        <a
          onClick={() => {
            showModalSetting();
            const action = editPlatformStatus(record);
            dispatch(action);
          }}
        >
          {text}
        </a>
      ),
      sorter: (a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : a.name.toLowerCase() === b.name.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "50%",
      sorter: (a, b) =>
        a.description.toLowerCase() > b.description.toLowerCase()
          ? 1
          : a.description.toLowerCase() === b.description.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "15%",
      render: (_, record) => (
        <Space size="middle" className="user_action">
          <Popconfirm
            title="Delete Platform status"
            placement="leftBottom"
            description="Are you sure to delete this platform status?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              const action = deletePlatformStatusApi(record.id);
              dispatch(action);
            }}
          >
            <button>
              <DeleteOutlined className="user_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Handle form
  const platformStatusInformation = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      const action = addNewPlatformStatusApi(values);
      dispatch(action);
      platformStatusInformation.resetForm();
    },
  });
  return (
    <div className="status_management">
      <div className="status_box">
        <form
          className="status_create"
          onSubmit={platformStatusInformation.handleSubmit}
        >
          <p className="status_title">Create new platform status:</p>
          <div className="status_form">
            <div className="status_item">
              <p>Name*</p>
              <Input
                name="name"
                onChange={platformStatusInformation.handleChange}
                onBlur={platformStatusInformation.handleBlur}
                value={platformStatusInformation.values.name}
                size="large"
                className={
                  platformStatusInformation.errors.name ? "error_input" : ""
                }
              />
              {platformStatusInformation.errors.name ? (
                <div className="error_message">
                  <i className="fa fa-exclamation-circle"></i>
                  <span className="cm_errors">
                    {platformStatusInformation.errors.name}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="status_item">
              <p>Description</p>
              <Input.TextArea
                name="description"
                onChange={platformStatusInformation.handleChange}
                onBlur={platformStatusInformation.handleBlur}
                value={platformStatusInformation.values.description}
                size="small"
              />
            </div>
            <div className="status_submit">
              <button type="submit">Create</button>
            </div>
          </div>
        </form>
        <div className="status_table">
          <p className="status_title">Platform status list</p>
          <div className="status_show">
            <Table
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                defaultCurrent: 1,
                showSizeChanger: true,
                showTotal: (total, range) => (
                  <p>
                    Showing{" "}
                    <strong>
                      {range[0]}-{range[1]}
                    </strong>{" "}
                    of <strong>{total}</strong> items
                  </p>
                ),
              }}
              bordered={true}
              scroll={{
                y: 200,
              }}
              size="small"
            />
          </div>
        </div>
      </div>
      <div className="status_action">
        {" "}
        <button type="button" onClick={props.handleCancelSetting}>
          Close
        </button>
      </div>

      <Modal
        title="Platform status detail"
        open={openDetail}
        onCancel={handleCancelStatus}
        footer={null}
        width={500}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <PlatformStatusDetail
          handleCancelStatus={handleCancelStatus}
          handleOkStatus={handleOkStatus}
        />
      </Modal>
    </div>
  );
}
