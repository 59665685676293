import axiosClient from "./axiosClient";

const platformStatusApi = {
  addPlatformStatus: async (data) => {
    const response = await axiosClient.post("/platform-status", data);
    return response;
  },
  getAllPlatformStatus: async () => {
    const response = await axiosClient.get("/platform-status");
    return response;
  },
  deletePlatformStatus: async (id) => {
    const response = await axiosClient.delete("/platform-status/" + id);
    return response;
  },
  updatePlatformStatus: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/platform-status/" + id, data);
    return response;
  },
};

export default platformStatusApi;
