import { Checkbox, message, Modal, Popconfirm, Space, Table, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import "../../Assets/scss/Component/Company/_discount.scss";
import {
  deleteDiscount,
  findDiscountHistory,
  findOneDiscount,
  updateCompany,
} from "../../Redux/companyReducer/companyReducer";
import CreateDiscount from "./CreateDiscount";
import DetailDiscount from "./DetailDiscount";
import DiscountHistory from "./DiscountHistory";
import { CURRENT_PAGE } from "../../Utils/ConstCommon/constCommon";

export default function Discount() {
  const dispatch = useDispatch();
  const { companyEdit, arrayDiscountByAccount, discountEdit } = useSelector(
    (state) => state.companyReducer
  );
  const [discountCheck, setDiscountCheck] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(CURRENT_PAGE);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const pageSizeRef = useRef(5);

  // Handle modal
  const showModalCreate = () => {
    setOpenCreate(true);
  };

  const showModalDetail = () => {
    setOpenDetail(true);
  };

  const handleCancelCreate = () => {
    setOpenCreate(false);
  };

  const handleCancelDetail = () => {
    setOpenDetail(false);
  };

  const onChangeDiscount = (e) => {
    const checked = e.target.checked;
    if (checked === true) {
      dispatch(updateCompany({ id: companyEdit.id, data: { hasDiscount: 1 } }));
    } else {
      dispatch(updateCompany({ id: companyEdit.id, data: { hasDiscount: 0 } }));
    }
    setDiscountCheck(checked);
  };

  useEffect(() => {
    if (companyEdit.hasDiscount === 0) {
      setDiscountCheck(false);
    } else {
      setDiscountCheck(true);
    }
  }, [companyEdit]);
  
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: 50,
      key: "id",
      sorter: (beforeItem, afterItem) =>
        beforeItem.id.toLowerCase() > afterItem.id.toLowerCase()
          ? 1
          : beforeItem.id.toLowerCase() === afterItem.id.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Wireless technology",
      dataIndex: "wirelessTechName",
      key: "wirelessTechName",
      align: "center",
      render: (text, record) => (
        <span
          className="open_detail"
          onClick={async () => {
            await dispatch(findOneDiscount(record.id));
            showModalDetail();
          }}
        >
          {text}
        </span>
      ),
      sorter: (beforeItem, afterItem) =>
        beforeItem.wirelessTechName.toLowerCase() >
        afterItem.wirelessTechName.toLowerCase()
          ? 1
          : beforeItem.wirelessTechName.toLowerCase() ===
            afterItem.wirelessTechName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "After month",
      dataIndex: "afterMonth",
      key: "afterMonth",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.afterMonth.toLowerCase() > afterItem.afterMonth.toLowerCase()
          ? 1
          : beforeItem.afterMonth.toLowerCase() ===
            afterItem.afterMonth.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.price.toLowerCase() > afterItem.price.toLowerCase()
          ? 1
          : beforeItem.price.toLowerCase() === afterItem.price.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "80px",
      render: (_, record) => (
        <Space size="middle" className="user_action">
          <Popconfirm
            title="Delete Discount"
            placement="leftBottom"
            description="Are you sure to delete this discount?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              const action = deleteDiscount({
                id: record.id,
                accountId: record.accountId,
              });
              dispatch(action);
              message.loading("Loading", 1);
            }}
          >
            <button>
              <DeleteOutlined className="user_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const items = [
    {
      key: "1",
      label: `Discount details`,
      children: <DetailDiscount handleCancelDetail={handleCancelDetail} />,
    },
    {
      key: "2",
      label: `History`,
      children: <DiscountHistory />,
    },
  ];

  const tabCallback = (key) => {
    if (key === "2") {
      dispatch(findDiscountHistory(discountEdit.id));
    }
  };

  return (
    <div className="discount">
      <div className="discount__checked">
        <h4>Apply discount</h4>
        <Checkbox checked={discountCheck} onChange={onChangeDiscount}>
          {discountCheck === true
            ? "This account is currently discounted."
            : "By clicking the checkbox, you will apply the discount to this account."}
        </Checkbox>
      </div>
      {companyEdit.hasDiscount === 1 ? (
        <div className="discount__table">
          <div className="discount__table-action">
            <h4>Discount detail</h4>
            <button type="button" onClick={showModalCreate}>
              + New
            </button>
          </div>
          <Table
            columns={columns}
            dataSource={arrayDiscountByAccount}
            rowClassName={(record) => (record.isDeleted === 1 ? "deleted" : "")}
            pagination={{
              defaultPageSize: 10,
              current: currentPagination,
              showSizeChanger: true,
              showTotal: (total, range) => (
                <p>
                  Showing{" "}
                  <strong>
                    {range[0]}-{range[1]}
                  </strong>{" "}
                  of <strong>{total}</strong> items
                </p>
              ),
              onChange: (newCurrent, newPageSize) => {
                const pageSizeChange = pageSizeRef.current !== newPageSize;
                if (pageSizeChange) {
                  setCurrentPagination(1);
                } else {
                  setCurrentPagination(newCurrent);
                }
                pageSizeRef.current = newPageSize;
              },
            }}
            bordered={true}
            scroll={{
              y: 300,
            }}
          />
        </div>
      ) : (
        ""
      )}
      <Modal
        title="Create Discount"
        open={openCreate}
        onCancel={handleCancelCreate}
        footer={null}
        width={500}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateDiscount handleCancelCreate={handleCancelCreate} />
      </Modal>
      <Modal
        title={"Discount Id : " + discountEdit.id}
        open={openDetail}
        onCancel={handleCancelDetail}
        footer={null}
        width={600}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          size="small"
          className="user_tab"
          onChange={tabCallback}
          destroyInactiveTabPane={true}
        />
      </Modal>
    </div>
  );
}
