import React, { useEffect } from "react";
import "../../Assets/scss/Pages/ErrorPage/ErrorPage.scss";
export default function ErrorPage() {
  useEffect(()=>{
    document.title = "Tag Register - Error page";
  },[])
  return (
    <div className="error">
      <img src="./img/401-error.jpg" alt="error" className="error_img" />
      <h4 className="error_title">
        You don't have permission to access this system
      </h4>
      <p className="error_information">
        Your account was disabled or blocked. Contact to Super Admin to get more
        information
      </p>
      <button
        onClick={() => {
          window.location.href = "/sign-in";
        }}
      >
        <i className="fa fa-angle-left"></i>Back to Sign in
      </button>
    </div>
  );
}
