import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Table,
  Space,
  Modal,
  message,
  Popconfirm,
  Tabs,
  Popover,
  Tooltip,
  FloatButton,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/scss/Pages/User/_user.scss";
import {
  SearchOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { storage } from "../../Utils/storage";
import SaleProductDetail from "../../Components/Product/SaleProductDetail";
import CreateSaleProduct from "../../Components/Product/CreateSaleProduct";
import {
  deleteSaleProductApi,
  findOneSaleProductApi,
  getAllSaleProductApi,
  getChangeLog,
} from "../../Redux/productReducer/saleProductReducer";
import SaleProductHistory from "../../Components/Product/SaleProductHistory";
import ReactDragListView from "react-drag-listview";
import { showHideDeletedItem } from "../../Utils/showHideDeletedItem";
import {
  CURRENT_PAGE,
  DEFAULT_PAGESIZE,
} from "../../Utils/ConstCommon/constCommon";
import { renderShowColumn } from "../../Utils/renderShowColumn";
import ContentShowColumn from "../../Utils/ComponentCommon/ContentShowColumn";
import ContentShowHideDeleted from "../../Utils/ComponentCommon/ContentShowHideDeleted";

export default function PurchaseProduct() {
  const [arrSelect, setArrSelect] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [openCreateSale, setOpenCreateSale] = useState(false);
  const [openSaleDetail, setOpenSaleDetail] = useState(false);
  const { arrSaleProduct, productEdit } = useSelector(
    (state) => state.saleProductReducer
  );

  const [currentPagination, setCurrentPagination] = useState(CURRENT_PAGE);
  const pageSizeRef = useRef(DEFAULT_PAGESIZE);
  const [showDeleted, setShowDeleted] = useState();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          String(record.haasPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.baseSubsPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.hwPrice).toLowerCase().includes(value.toLowerCase()) ||
          String(record.otherCost)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.total).toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (text, record) => (
        <span
          className="open_detail"
          onClick={async () => {
            await dispatch(findOneSaleProductApi(record.id));
            showModalSaleDetail();
          }}
        >
          {text}
        </span>
      ),
      sorter: (beforeItem, afterItem) =>
        beforeItem.name.toLowerCase() > afterItem.name.toLowerCase()
          ? 1
          : beforeItem.name.toLowerCase() === afterItem.name.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Short name",
      dataIndex: "shortName",
      key: "shortName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.shortName.toLowerCase() > afterItem.shortName.toLowerCase()
          ? 1
          : beforeItem.shortName.toLowerCase() ===
            afterItem.shortName.toLowerCase()
          ? 0
          : -1,
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          String(record.haasPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.baseSubsPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.hwPrice).toLowerCase().includes(value.toLowerCase()) ||
          String(record.otherCost)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.total).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Base subs price",
      dataIndex: "baseSubsPrice",
      key: "baseSubsPrice",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          String(record.haasPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.baseSubsPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.hwPrice).toLowerCase().includes(value.toLowerCase()) ||
          String(record.otherCost)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.total).toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.baseSubsPrice - afterItem.baseSubsPrice,
    },
    {
      title: "Haas price",
      dataIndex: "haasPrice",
      key: "haasPrice",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          String(record.haasPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.baseSubsPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.hwPrice).toLowerCase().includes(value.toLowerCase()) ||
          String(record.otherCost)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.total).toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.haasPrice - afterItem.haasPrice,
    },
    {
      title: "Hw price",
      dataIndex: "hwPrice",
      key: "hwPrice",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          String(record.haasPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.baseSubsPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.hwPrice).toLowerCase().includes(value.toLowerCase()) ||
          String(record.otherCost)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.total).toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) => beforeItem.hwPrice - afterItem.hwPrice,
    },
    {
      title: "Other cost",
      dataIndex: "otherCost",
      key: "otherCost",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          String(record.haasPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.baseSubsPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.hwPrice).toLowerCase().includes(value.toLowerCase()) ||
          String(record.otherCost)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.total).toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.otherCost - afterItem.otherCost,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          String(record.haasPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.baseSubsPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.hwPrice).toLowerCase().includes(value.toLowerCase()) ||
          String(record.otherCost)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.total).toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) => beforeItem.total - afterItem.total,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          String(record.haasPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.baseSubsPrice)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.hwPrice).toLowerCase().includes(value.toLowerCase()) ||
          String(record.otherCost)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.total).toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (beforeItem, afterItem) =>
        beforeItem.description?.toLowerCase() >
        afterItem.description?.toLowerCase()
          ? 1
          : beforeItem.description?.toLowerCase() ===
            afterItem.description?.toLowerCase()
          ? 0
          : -1,
      render: (text) => (
        <Tooltip title={text} color="#2962ff">
          <Typography.Paragraph ellipsis={{ rows: 1 }}>
            {text}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "80px",
      render: (_, record) => (
        <Space size="middle" className="user_action">
          <Popconfirm
            title="Delete Sales Product"
            placement="leftBottom"
            description="Are you sure to delete this sales product?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              const action = deleteSaleProductApi(record.id);
              dispatch(action);
            }}
          >
            <button>
              <DeleteOutlined className="user_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  let [columnSelected, setColumnSelected] = useState(columns);
  for (let index = 0; index < columnSelected.length; index++) {
    let item = columnSelected[index];
    item.filteredValue = [searchValue];
  }
  // Show/ Hide column
  useEffect(() => {
    renderShowColumn(
      columns,
      "arrSaleProductShowColumn",
      "arraySaleProductRelocateTitleColumn",
      "arraySaleProductRelocateTitleColumnNew",
      setColumnSelected
    );
    document.title = "Tag Register - Sales Product";
  }, []);
  const resetAll = () => {
    storage.removeStorage("arrSaleProductShowColumn");
    storage.removeStorage("arraySaleProductRelocateTitleColumn");
    storage.removeStorage("arraySaleProductRelocateTitleColumnNew");
    setColumnSelected(columns);
  };

  // Drag and drop column
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newFromIndex = fromIndex - 1;
      const newToIndex = toIndex - 1;
      const newColumns = [...columnSelected];
      const item = newColumns.splice(newFromIndex, 1)[0];
      newColumns.splice(newToIndex, 0, item);
      setColumnSelected(newColumns);
      let arrRelocate = newColumns.map((item) => {
        return item.key;
      });
      storage.setStorageJson(
        "arraySaleProductRelocateTitleColumn",
        arrRelocate
      );
    },
    nodeSelector: "th",
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setArrSelect(selectedRows);
      return (
        `selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows
      );
    },
  };

  // Handle modal
  const showModalCreateSale = () => {
    setOpenCreateSale(true);
  };
  const showModalSaleDetail = () => {
    setOpenSaleDetail(true);
  };
  const handleOkCreateSale = () => {
    message.loading("loading", 1);
    setOpenCreateSale(false);
  };

  const handleCancelCreateSale = () => {
    setOpenCreateSale(false);
  };
  const handleCancelSaleDetail = () => {
    setOpenSaleDetail(false);
  };
  const tabCallback = (key) => {
    if (key === "2") {
      const action = getChangeLog(productEdit.id);
      dispatch(action);
    }
  };

  const items = [
    {
      key: "1",
      label: `Sales product details`,
      children: (
        <SaleProductDetail handleCancelSaleDetail={handleCancelSaleDetail} />
      ),
    },
    {
      key: "2",
      label: `History`,
      children: <SaleProductHistory />,
    },
  ];

  // Call api
  useEffect(() => {
    const action = getAllSaleProductApi();
    dispatch(action);
  }, [dispatch]);

  //Recall api
  useEffect(() => {
    const reCallApi = setInterval(() => {
      dispatch(getAllSaleProductApi());
    }, 10000);
    return () => clearInterval(reCallApi);
  }, []);

  const content = (
    <ContentShowColumn
      columns={columns}
      storageShow="arrSaleProductShowColumn"
      storageRelocate="arraySaleProductRelocateTitleColumn"
      storageRelocateNew="arraySaleProductRelocateTitleColumnNew"
      action={setColumnSelected}
      resetAll={resetAll}
    />
  );

  // Show/hide selected
  const onChangeShowDeleted = (checked) => {
    showHideDeletedItem(checked, arrSaleProduct, setData, "showDeletedSale");
    setShowDeleted(checked);
  };
  useEffect(() => {
    const isShow = storage.getStore("showDeletedSale");
    if (isShow === undefined || isShow === "true") {
      setData(arrSaleProduct);
      setShowDeleted(true);
    } else {
      const arrayData = arrSaleProduct.filter((item) => item.isDeleted !== 1);
      setData(arrayData);
      setShowDeleted(false);
    }
  }, [arrSaleProduct]);

  const contentAction = (
    <ContentShowHideDeleted
      onChangeShowDeleted={onChangeShowDeleted}
      showDeleted={showDeleted}
      arrayData={arrSaleProduct}
      columnSelected={columnSelected}
      pageName="SaleProduct"
      content={content}
    />
  );
  return (
    <div className="user">
      <div className="user_top">
        <div className="user_left">
          <p>Sales Products</p>
        </div>
      </div>
      <div className="user_body">
        <div className="user_show">
          <div className="user_showChild">
            <div className="user_showLeft">
              <p>
                Selected: <strong>{arrSelect.length}</strong>
              </p>
              <button
                onClick={() => {
                  showModalCreateSale();
                }}
                className="user_button-create"
              >
                + New
              </button>
              <Popover
                content={contentAction}
                trigger="click"
                placement="right"
              >
                <button className="button-second">
                  Action <i className="fa fa-caret-down"></i>
                </button>
              </Popover>
            </div>
            <div className="user_showRight">
              <div className="search_area">
                <SearchOutlined className="search_icon" />
                <Input
                  className="search_box"
                  placeholder="Search..."
                  allowClear
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="user_table">
          <ReactDragListView.DragColumn {...dragProps}>
            <Table
              rowSelection={rowSelection}
              columns={[...columnSelected]}
              dataSource={data}
              rowClassName={(record) =>
                record.isDeleted === 1 ? "deleted" : ""
              }
              pagination={{
                defaultPageSize: 10,
                current: currentPagination,
                showSizeChanger: true,
                showTotal: (total, range) => (
                  <p>
                    Showing{" "}
                    <strong>
                      {range[0]}-{range[1]}
                    </strong>{" "}
                    of <strong>{total}</strong> items
                  </p>
                ),
                onChange: (newCurrent, newPageSize) => {
                  const pageSizeChange = pageSizeRef.current !== newPageSize;
                  if (pageSizeChange) {
                    setCurrentPagination(1);
                  } else {
                    setCurrentPagination(newCurrent);
                  }
                  pageSizeRef.current = newPageSize;
                },
              }}
              bordered={true}
              scroll={{
                x: 1500,
              }}
              size="middle"
              sticky
            />
          </ReactDragListView.DragColumn>
        </div>
      </div>
      <Modal
        title="Create Sales Product"
        open={openCreateSale}
        onCancel={handleCancelCreateSale}
        footer={null}
        width={600}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateSaleProduct
          handleCancelCreateSale={handleCancelCreateSale}
          handleOkCreateSale={handleOkCreateSale}
        />
      </Modal>
      <Modal
        title={"Sales product ID: " + productEdit.id}
        open={openSaleDetail}
        onCancel={handleCancelSaleDetail}
        footer={null}
        width={600}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          size="small"
          className="user_tab"
          onChange={tabCallback}
          destroyInactiveTabPane={true}
        />
      </Modal>
      <FloatButton.BackTop />
    </div>
  );
}
