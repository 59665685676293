import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { DatePicker, Input, message, Modal } from "antd";
import Select from "react-select";
import {
  createMovement,
  findLastMovementByID,
  getAllCompanyMovement,
  getAllDeviceMovement,
  getAllDeviceStateMovement,
  resetLatestMovement,
} from "../../Redux/movementReducer/movementReducer";
import "../../Assets/scss/Component/Movement/_createMovement.scss";
import CreateBillingGroup from "./CreateBillingGroup";
import { getAllSaleProductApi } from "../../Redux/productReducer/saleProductReducer";
import CreateSaleProduct from "../Product/CreateSaleProduct";
import { convertLongToShortESN } from "../../Utils/convertESN";
import { getAllBillingGroup } from "../../Redux/billingGroupReducer/billingGroupReducer";
import CreateStateMovement from "./CreateStateMovement";
import { convertEmptyToNull } from "../../Utils/convertEmptyToNull";
import { onChangeDatePicker } from "../../Utils/onChangeDatePicker";
import { CreateDataForSelect } from "../../Utils/createDataForSelect";

const CreateMovement = ({
  setShowAddNew,
  cancelModalNew,
  resetForm,
  setValueEnter,
}) => {
  const [arrDevice, setArrDevice] = useState([]);
  const [arrPreviousCompany, setArrPreviousCompany] = useState([]);
  const [showBillingGroup, setShowBillingGroup] = useState(false);
  const [arrayBillingGroupsShow, setArrayBillingGroupShow] = useState([]);
  const [arraySaleProductShow, setArraySaleProductShow] = useState([]);
  const [arrState, setArrState] = useState([]);
  const [showState, setShowState] = useState(false);
  const [resetState, setResetState] = useState(false);
  const dispatch = useDispatch();
  const [openCreateSale, setOpenCreateSale] = useState(false);
  const [checkValues, setCheckValues] = useState({});
  const [value, setValue] = useState(false);
  const {
    arrMovementCompany,
    arrMovementDevice,
    latestMovementData,
    arrTransaction,
  } = useSelector((state) => state.movementReducer);
  const { arrayBillingGroup } = useSelector(
    (state) => state.billingGroupReducer
  );
  const { arrSaleProduct } = useSelector((state) => state.saleProductReducer);

  // Handle modal
  const showBillingGroupModal = () => {
    setShowBillingGroup(true);
  };

  const cancelBillingGroupModal = () => {
    setShowBillingGroup(false);
  };

  const handleOk = () => {
    message.loading("loading", 1);
  };

  const showModalCreateSale = () => {
    setOpenCreateSale(true);
  };

  const handleOkCreateSale = () => {
    message.loading("loading", 1);
    setOpenCreateSale(false);
  };

  const handleCancelCreateSale = () => {
    setOpenCreateSale(false);
  };

  const showAddState = () => {
    setShowState(true);
  };
  const cancelAddState = () => {
    setResetState(!resetState);
    setShowState(false);
  };

  //Handle form
  const formik = useFormik({
    initialValues: {
      deviceId: null,
      toAccountId: null,
      movementDate: null,
      startBillDate: null,
      billingGroupId: null,
      salesProductId: null,
      invoiceCode: null,
      shippingCode: null,
      billCode: null,
      replaceDeviceId: null,
      haasPrice: null,
      deviceStateId: null,
      comment: null,
      notes: null,
    },
    validationSchema: yup.object({
      deviceId: yup.mixed().required("Serial number is required"),
      toAccountId: yup.mixed().required(" TO account is required"),
      movementDate: yup.mixed().required("Movement date is required"),
      startBillDate: yup.mixed().required("Start bill date is required"),
      salesProductId: yup.mixed().required("Sales product is required"),
      deviceStateId: yup.mixed().required("Device state is required"),
    }),
    onSubmit: (values) => {
      convertEmptyToNull(values);
      dispatch(createMovement(values));
      handleOk();
      setShowAddNew(false);
    },
  });

  // Change value enter
  useEffect(() => {
    const data = {};
    convertEmptyToNull(formik.values);
    for (let item in formik.values) {
      if (formik.values[item] !== formik.initialValues[item]) {
        data[item] = formik.values[item];
      }
    }
    setCheckValues(data);
  }, [formik.values]);
  useEffect(() => {
    if (Object.keys(checkValues).length !== 0) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [checkValues]);
  useEffect(() => {
    setValueEnter(value);
  }, [value]);

  //Call API
  useEffect(() => {
    dispatch(getAllSaleProductApi());
    dispatch(getAllCompanyMovement());
    dispatch(getAllDeviceMovement());
    dispatch(getAllBillingGroup());
    dispatch(getAllDeviceStateMovement());
  }, [dispatch]);

  //------
  useEffect(() => {
    dispatch(resetLatestMovement({}));
    formik.resetForm();
  }, [resetForm]);

  useEffect(() => {
    let arrSelect = [];
    arrMovementDevice.map((item) => {
      if (item.isDeleted === 0) {
        let obj = {
          value: item.id,
          label: convertLongToShortESN(item.serialNumber),
        };
        arrSelect.push(obj);
      }
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setArrDevice(arrSelect);
  }, [arrMovementDevice]);

  useEffect(() => {
    CreateDataForSelect(arrMovementCompany, setArrPreviousCompany);
  }, [arrMovementCompany]);

  useEffect(() => {
    let arrSelect = [];
    arrayBillingGroup.map((item) => {
      let obj = { value: item.id, label: item.name };
      arrSelect.push(obj);
    });
    arrSelect.sort(function (beforeItem, afterItem) {
      if (beforeItem.label.toLowerCase() < afterItem.label.toLowerCase()) {
        return -1;
      }
      if (beforeItem.label.toLowerCase() > afterItem.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setArrayBillingGroupShow(arrSelect);
  }, [arrayBillingGroup]);

  useEffect(() => {
    CreateDataForSelect(arrSaleProduct, setArraySaleProductShow);
  }, [arrSaleProduct]);

  useEffect(() => {
    CreateDataForSelect(arrTransaction, setArrState);
  }, [arrTransaction]);

  return (
    <div className="create_movement">
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <div className="create_movement-form">
          <div className="create_movement-formRows">
            <div className="create_movement-formLeft">
              <div className="create_movement-field">
                <label>Serial number*</label>
                <Select
                  name="deviceId"
                  options={arrDevice}
                  onChange={(e) => {
                    formik.setFieldValue("deviceId", e.value);
                    dispatch(findLastMovementByID(e.value));
                  }}
                  className={
                    formik.touched.deviceId && formik.errors.deviceId
                      ? "error_select"
                      : ""
                  }
                />
                {formik.touched.deviceId && formik.errors.deviceId ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span className="create_movement-errors">
                      {formik.errors.deviceId}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="create_movement-field">
                <label>FROM account</label>
                <Input
                  name="fromAccountId"
                  readOnly
                  value={
                    latestMovementData !== null
                      ? latestMovementData.toAccount?.name
                      : "None"
                  }
                />
              </div>
              <div className="create_movement-field">
                <label>TO account*</label>
                <Select
                  name="toAccountId"
                  options={arrPreviousCompany}
                  onChange={(e) => {
                    formik.setFieldValue("toAccountId", e.value);
                  }}
                  className={
                    formik.touched.toAccountId && formik.errors.toAccountId
                      ? "error_select"
                      : ""
                  }
                />
                {formik.touched.toAccountId && formik.errors.toAccountId ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span className="create_movement-errors">
                      {formik.errors.toAccountId}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="create_movement-field">
                <label>Purchase product</label>
                <Input
                  name="purchaseProduct"
                  readOnly
                  value={
                    latestMovementData !== null &&
                    latestMovementData?.device?.purchaseProduct !== null
                      ? latestMovementData?.device?.purchaseProduct?.name
                      : "None"
                  }
                />
              </div>
              <div className="create_movement-field">
                <label>Sales product*</label>
                <div className="create_movement-fieldAdd">
                  <Select
                    name="salesProductId"
                    options={arraySaleProductShow}
                    className={
                      formik.touched.salesProductId &&
                      formik.errors.salesProductId
                        ? "error_select"
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("salesProductId", e.value);
                    }}
                  />
                  {formik.touched.salesProductId &&
                  formik.errors.salesProductId ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span className="create_movement-errors">
                        {formik.errors.salesProductId}
                      </span>
                    </div>
                  ) : null}
                  <button type="button" onClick={showModalCreateSale}>
                    Add
                  </button>
                </div>
              </div>
              <div className="create_movement-field">
                <label>Billing group</label>
                <div className="create_movement-fieldAdd">
                  <Select
                    name="billingGroupId"
                    options={arrayBillingGroupsShow}
                    onChange={(e) => {
                      if (e !== null) {
                        formik.setFieldValue("billingGroupId", e.value);
                      } else {
                        formik.setFieldValue("billingGroupId", e);
                      }
                    }}
                  />
                  <button type="button" onClick={showBillingGroupModal}>
                    Add
                  </button>
                </div>
              </div>
              <div className="create_movement-field">
                <label>Replace device</label>
                <Select
                  name="replaceDeviceId"
                  options={arrDevice}
                  onChange={(e) => {
                    if (e !== null) {
                      formik.setFieldValue("replaceDeviceId", e.value);
                    } else {
                      formik.setFieldValue("replaceDeviceId", e);
                    }
                  }}
                />
              </div>
            </div>
            <div className="create_movement-formRight">
              <div className="create_movement-field">
                <label>Device state*</label>
                <div className="create_movement-fieldAdd">
                  <Select
                    name="deviceStateId"
                    options={arrState}
                    className={
                      formik.touched.deviceStateId &&
                      formik.errors.deviceStateId
                        ? "error_select"
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("deviceStateId", e.value);
                    }}
                  />
                  {formik.touched.deviceStateId &&
                  formik.errors.deviceStateId ? (
                    <div className="error_message">
                      <i className="fa fa-exclamation-circle"></i>
                      <span className="create_movement-errors">
                        {formik.errors.deviceStateId}
                      </span>
                    </div>
                  ) : null}
                  <button type="button" onClick={showAddState}>
                    Add
                  </button>
                </div>
              </div>
              <div className="create_movement-field">
                <label>Movement date*</label>
                <DatePicker
                  className={
                    formik.touched.movementDate && formik.errors.movementDate
                      ? "error_input"
                      : ""
                  }
                  name="movementDate"
                  showTime={true}
                  format="DD/MM/YYYY hh:mm A"
                  placeholder="Select..."
                  onChange={(date) => {
                    onChangeDatePicker(date, "movementDate", formik);
                  }}
                />
                {formik.touched.movementDate && formik.errors.movementDate ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span className="create_movement-errors">
                      {formik.errors.movementDate}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="create_movement-field">
                <label>Start bill date*</label>
                <DatePicker
                  className={
                    formik.touched.startBillDate && formik.errors.startBillDate
                      ? "error_input"
                      : ""
                  }
                  name="startBillDate"
                  showTime={true}
                  format="DD/MM/YYYY hh:mm A"
                  placeholder="Select..."
                  onChange={(date) => {
                    onChangeDatePicker(date, "startBillDate", formik);
                  }}
                />
                {formik.touched.startBillDate && formik.errors.startBillDate ? (
                  <div className="error_message">
                    <i className="fa fa-exclamation-circle"></i>
                    <span className="create_movement-errors">
                      {formik.errors.startBillDate}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="create_movement-field">
                <label>Invoice#</label>
                <Input
                  className="create_movement-input"
                  name="invoiceCode"
                  size="large"
                  value={
                    formik.values.invoiceCode !== null
                      ? formik.values.invoiceCode
                      : ""
                  }
                  onChange={formik.handleChange}
                />
              </div>
              <div className="create_movement-field">
                <label>Bill#</label>
                <Input
                  className="create_movement-input"
                  name="billCode"
                  size="large"
                  value={
                    formik.values.billCode !== null
                      ? formik.values.billCode
                      : ""
                  }
                  onChange={formik.handleChange}
                />
              </div>
              <div className="create_movement-field">
                <label>Shipping#</label>
                <Input
                  className="create_movement-input"
                  name="shippingCode"
                  size="large"
                  value={
                    formik.values.shippingCode !== null
                      ? formik.values.shippingCode
                      : ""
                  }
                  onChange={formik.handleChange}
                />
              </div>
              <div className="create_movement-field">
                <label>Haas price</label>
                <Input
                  className="create_movement-input"
                  name="haasPrice"
                  size="large"
                  type="number"
                  min={0}
                  step={0.01}
                  value={
                    formik.values.haasPrice !== null
                      ? formik.values.haasPrice
                      : ""
                  }
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="create_movement-formBottom">
            <div className="create_movement-field">
              <label>Note</label>
              <Input.TextArea
                name="notes"
                size="medium"
                className="text_area"
                onChange={formik.handleChange}
                value={formik.values.notes}
              />
            </div>
            <div className="create_movement-field">
              <label>Comment</label>
              <Input.TextArea
                name="comment"
                size="medium"
                className="text_area"
                onChange={formik.handleChange}
                value={formik.values.comment}
              />
            </div>
          </div>
        </div>
        <div className="create_movement-submit">
          <button
            className="create_movement-cancel"
            type="button"
            onClick={cancelModalNew}
          >
            Cancel
          </button>
          <button className="create_movement-create" type="submit">
            Create
          </button>
        </div>
      </form>
      <Modal
        title="Create New Billing group"
        open={showBillingGroup}
        onCancel={cancelBillingGroupModal}
        footer={null}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateBillingGroup cancelBillingGroupModal={cancelBillingGroupModal} />
      </Modal>
      <Modal
        title="Create Sales Product"
        open={openCreateSale}
        onCancel={handleCancelCreateSale}
        footer={null}
        width={600}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreateSaleProduct
          handleCancelCreateSale={handleCancelCreateSale}
          handleOkCreateSale={handleOkCreateSale}
        />
      </Modal>
      <Modal
        title="Add New State"
        open={showState}
        onCancel={cancelAddState}
        footer={null}
      >
        <CreateStateMovement
          cancelAddState={cancelAddState}
          resetState={resetState}
        />
      </Modal>
    </div>
  );
};

export default CreateMovement;
