import axios from "axios";
import { storage } from "../Utils/storage";
import { isTokenExpired } from "../Utils/isTokenExpried";

const getToken = () => {
  return storage.getStore("token");
};

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: Number(process.env.REACT_APP_BASE_TIMEOUT),
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    if (
      config.url.includes("/auth/signin") ||
      config.url.includes("auth/forgot-password") ||
      config.url.includes("/auth/verify-otp") ||
      config.url.includes("/auth/reset-password")
    ) {
      return config;
    } else {
      const token = getToken();
      if (!token || isTokenExpired(token)) {
        storage.removeStorage("token");
        window.location.href = "/sign-in";
      } else {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response.data ? response.data : { statusCode: response.statusCode };
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const data = error.response.status;
    const token = getToken();
    if (token && data === 401) {
      window.location.href = "/error";
      storage.removeStorage("token");
      storage.removeStorage("user");
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
