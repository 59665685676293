import React from "react";
import { storage } from "../storage";
import { toggleButtonClass } from "../toggleButtonClass";

export default function ContentShowColumn({
  columns,
  storageShow,
  storageRelocate,
  storageRelocateNew,
  action,
  resetAll,
}) {
  const dataShow = storage.getStorageJson(storageShow);
  return (
    <div className="user_content-show">
      {columns.map((item, index) => {
        if (dataShow && dataShow.includes(item.key) === true) {
          return (
            <button
              className="active"
              key={index}
              value={item.key}
              onClick={(e) => {
                toggleButtonClass(
                  e,
                  columns,
                  storageShow,
                  storageRelocate,
                  storageRelocateNew,
                  action
                );
              }}
            >
              {item.title}
            </button>
          );
        }
        if (dataShow && dataShow.includes(item.key) === false) {
          return (
            <button
              className="inActive"
              key={index}
              value={item.key}
              onClick={(e) => {
                toggleButtonClass(
                  e,
                  columns,
                  storageShow,
                  storageRelocate,
                  storageRelocateNew,
                  action
                );
              }}
            >
              {item.title}
            </button>
          );
        }
        if (dataShow === undefined) {
          return (
            <button
              className="active"
              key={index}
              value={item.key}
              onClick={(e) => {
                toggleButtonClass(
                  e,
                  columns,
                  storageShow,
                  storageRelocate,
                  storageRelocateNew,
                  action
                );
              }}
            >
              {item.title}
            </button>
          );
        }
      })}
      <button className="restore" onClick={resetAll}>
        Restore visibility
      </button>
    </div>
  );
}
