import { storage } from "./storage";
import dayjs from "dayjs";

export const convertTime = (time) => {
  let user = storage.getStorageJson("user");
  if (user.timezone && user.timezone !== null) {
    let arrOffset = user.timezone.offset.split(":");
    if (arrOffset[1] === "30") {
      arrOffset[1] = "50";
    }
    let offset = arrOffset[0] + "." + arrOffset[1];
    let day = new Date(time);
    let utc = day.getTime() + day.getTimezoneOffset() * 60000;
    let newDay = new Date(utc + 3600000 * offset);
    return dayjs(newDay).format("DD-MMM-YYYY");
  } else {
    let day = new Date(time);
    return dayjs(day).format("DD-MMM-YYYY");
  }
};
