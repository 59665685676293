import axiosClient from "./axiosClient";

const companyApi = {
  companyGetAll: async () => {
    const response = await axiosClient.get("/account");
    return response;
  },
  companyAdd: async (data) => {
    const response = await axiosClient.post("/account", data);
    return response;
  },
  companyUpdate: async (params) => {
    const { id, data } = params;
    const response = await axiosClient.put("/account/" + id, data);
    return response;
  },
  companyDelete: async (id) => {
    const response = await axiosClient.delete("/account/" + id);
    return response;
  },
  companyRestore: async (id) => {
    const response = await axiosClient.put("/account/restore/" + id);
    return response;
  },
  companyTotal: async () => {
    const response = await axiosClient.get("/account/total");
    return response;
  },
  companySupplier: async () => {
    const response = await axiosClient.get("/account/supplier");
    return response;
  },
  companyStock: async () => {
    const response = await axiosClient.get("/account/stocker");
    return response;
  },
  changeLog: async (id) => {
    const response = await axiosClient.get("/account/history/" + id);
    return response;
  },
  findOne: async (id) => {
    const response = await axiosClient.get("/account/" + id);
    return response;
  },
};

export default companyApi;
