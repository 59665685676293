import axiosClient from "./axiosClient";

const accountApi = {
  getUserProfile: async () => {
    const response = await axiosClient.get("/user/profile");
    return response;
  },
  getAccountId: async (id) => {
    const response = await axiosClient.get("/user/" + id);
    return response;
  },
  updateProfile: async (data) => {
    const response = await axiosClient.put("/user/profile", data);
    return response;
  },
  updateTimeZone: async (params) => {
    const { id, timezoneId } = params;
    const response = await axiosClient.put("/user/" + id, {
      timezoneId,
    });
    return response;
  },
  updatePassword: async (params) => {
    const { currentPassword, newPassword, confirmPassword } = params;
    const response = await axiosClient.put("/user/change-password", {
      currentPassword,
      newPassword,
      confirmPassword,
    });
    return response;
  },
};

export default accountApi;
