import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import { useSelector } from "react-redux";
import { Skeleton, Tooltip } from "antd";
import { FileOutlined } from "@ant-design/icons";
import "../../Assets/scss/Component/Device/_deviceHistory.scss";
import { convertTime } from "../../Utils/convertTime";
dayjs.extend(relativeTime);

export default function DeviceHistory() {
  const { arrChangeLog, isLoading } = useSelector(
    (state) => state.deviceReducer
  );
  const { arrUser } = useSelector((state) => state.userReducer);
  const { arrSaleProduct } = useSelector((state) => state.saleProductReducer);
  const { arrPurchaseProduct } = useSelector(
    (state) => state.purchaseProductReducer
  );
  const { arrDeviceState } = useSelector((state) => state.deviceStateReducer);
  const { arrCompany } = useSelector((state) => state.companyReducer);

  const showHistory = () => {
    if (isLoading === true) {
      return <Skeleton active avatar />;
    } else {
      if (arrChangeLog.length !== 0) {
        return arrChangeLog.map((item, index) => {
          if (item.action !== "Updated") {
            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    {item.action.toLowerCase()}{" "}
                    {dayjs(new Date(item.createdAt)).fromNow()}
                  </p>
                </div>
              </div>
            );
          } else {
            const arrShow = [];
            let newItem = { ...item };
            newItem.arrHistoryShow = arrShow;

            if ("serialNumber" in item.beforeData) {
              const serial = {
                name: "Serial number",
                old: item.beforeData.serialNumber,
                new: item.afterData.serialNumber,
              };
              arrShow.push(serial);
            }

            if ("currentMovementDate" in item.beforeData) {
              let before =
                item.beforeData.currentMovementDate === null ||
                item.beforeData.currentMovementDate === ""
                  ? "None"
                  : `${convertTime(item.beforeData.currentMovementDate)}`;
              let after =
                item.afterData.currentMovementDate === null ||
                item.afterData.currentMovementDate === ""
                  ? "None"
                  : `${convertTime(item.afterData.currentMovementDate)}`;
              const currentMovementDate = {
                name: "Current movement date",
                old: before,
                new: after,
              };
              arrShow.push(currentMovementDate);
            }
            if ("currentStartBillDate" in item.beforeData) {
              let before =
                item.beforeData.currentStartBillDate === null ||
                item.beforeData.currentStartBillDate === ""
                  ? "None"
                  : `${convertTime(item.beforeData.currentStartBillDate)}`;
              let after =
                item.afterData.currentStartBillDate === null ||
                item.afterData.currentStartBillDate === ""
                  ? "None"
                  : `${convertTime(item.afterData.currentStartBillDate)}`;
              const currentStartBillDate = {
                name: "Current start bill date",
                old: before,
                new: after,
              };
              arrShow.push(currentStartBillDate);
            }

            if ("purchaseDate" in item.beforeData) {
              let before =
                item.beforeData.purchaseDate === null ||
                item.beforeData.purchaseDate === ""
                  ? "None"
                  : `${convertTime(item.beforeData.purchaseDate)}`;
              let after =
                item.afterData.purchaseDate === null ||
                item.afterData.purchaseDate === ""
                  ? "None"
                  : `${convertTime(item.afterData.purchaseDate)}`;
              const purchaseDate = {
                name: "Purchase date",
                old: before,
                new: after,
              };
              arrShow.push(purchaseDate);
            }

            if ("purchaserId" in item.beforeData) {
              let before = "None";
              let after = "None";
              if (item.beforeData.purchaserId !== null) {
                const result = arrUser.find(
                  (state) => state.id === item.beforeData.purchaserId
                );
                before = `${result.firstName} ${result.lastName}`;
              }
              if (item.afterData.purchaserId !== null) {
                const result = arrUser.find(
                  (state) => state.id === item.afterData.purchaserId
                );
                after = `${result.firstName} ${result.lastName}`;
              }
              const purchaser = {
                name: "Purchaser",
                old: before,
                new: after,
              };
              arrShow.push(purchaser);
            }

            if ("receiverId" in item.beforeData) {
              let before = "None";
              let after = "None";
              if (item.beforeData.receiverId !== null) {
                const result = arrUser.find(
                  (state) => state.id === item.beforeData.receiverId
                );
                before = `${result.firstName} ${result.lastName}`;
              }
              if (item.afterData.receiverId !== null) {
                const result = arrUser.find(
                  (state) => state.id === item.afterData.receiverId
                );
                after = `${result.firstName} ${result.lastName}`;
              }
              const receiverId = {
                name: "Receiver",
                old: before,
                new: after,
              };
              arrShow.push(receiverId);
            }

            if ("purchaseProductId" in item.beforeData) {
              let before = "None";
              let after = "None";

              if (item.beforeData.purchaseProductId !== null) {
                const result = arrPurchaseProduct.find(
                  (state) => state.id === item.beforeData.purchaseProductId
                );
                before = result.name;
              }
              if (item.afterData.purchaseProductId !== null) {
                const result = arrPurchaseProduct.find(
                  (state) => state.id === item.afterData.purchaseProductId
                );
                after = result.name;
              }
              const purchaseProductId = {
                name: "Purchase product",
                old: before,
                new: after,
              };
              arrShow.push(purchaseProductId);
            }

            if ("currentSaleProductId" in item.beforeData) {
              let before = "None";
              let after = "None";

              if (item.beforeData.currentSaleProductId !== null) {
                const result = arrSaleProduct.find(
                  (state) => state.id === item.beforeData.currentSaleProductId
                );
                before = result?.name;
              }
              if (item.afterData.currentSaleProductId !== null) {
                const result = arrSaleProduct.find(
                  (state) => state.id === item.afterData.currentSaleProductId
                );
                after = result?.name;
              }
              const currentSaleProductId = {
                name: "Current sales product",
                old: before,
                new: after,
              };
              arrShow.push(currentSaleProductId);
            }

            if ("invoiceCode" in item.beforeData) {
              let beforeInvoiceCode =
                item.beforeData.invoiceCode === null ||
                item.beforeData.invoiceCode === ""
                  ? "None"
                  : item.beforeData.invoiceCode;
              let afterInvoiceCode =
                item.afterData.invoiceCode === null ||
                item.afterData.invoiceCode === ""
                  ? "None"
                  : item.afterData.invoiceCode;
              const invoiceCode = {
                name: "Invoice code",
                old: beforeInvoiceCode,
                new: afterInvoiceCode,
              };
              arrShow.push(invoiceCode);
            }

            if ("stateId" in item.beforeData) {
              let before = "None";
              let after = "None";

              if (item.beforeData.stateId !== null) {
                const result = arrDeviceState.find(
                  (state) => state.id === item.beforeData.stateId
                );
                before = result.name;
              }
              if (item.afterData.stateId !== null) {
                const result = arrDeviceState.find(
                  (state) => state.id === item.afterData.stateId
                );
                after = result.name;
              }
              const stateId = {
                name: "State",
                old: before,
                new: after,
              };
              arrShow.push(stateId);
            }
            if ("supplierId" in item.beforeData) {
              let before = "None";
              let after = "None";

              if (item.beforeData.supplierId !== null) {
                const result = arrCompany.find(
                  (state) => state.id === item.beforeData.supplierId
                );
                before = result.name;
              }
              if (item.afterData.supplierId !== null) {
                const result = arrCompany.find(
                  (state) => state.id === item.afterData.supplierId
                );
                after = result.name;
              }
              const supplierId = {
                name: "Supplier",
                old: before,
                new: after,
              };
              arrShow.push(supplierId);
            }
            if ("currentAccountId" in item.beforeData) {
              let before = "None";
              let after = "None";

              if (item.beforeData.currentAccountId !== null) {
                const result = arrCompany.find(
                  (state) => state.id === item.beforeData.currentAccountId
                );
                before = result.name;
              }
              if (item.afterData.currentAccountId !== null) {
                const result = arrCompany.find(
                  (state) => state.id === item.afterData.currentAccountId
                );
                after = result.name;
              }
              const currentAccountId = {
                name: "Current account",
                old: before,
                new: after,
              };
              arrShow.push(currentAccountId);
            }

            if ("note" in item.beforeData) {
              let before =
                item.beforeData.note === null || item.beforeData.note === ""
                  ? "None"
                  : item.beforeData.note;
              let after =
                item.afterData.note === null || item.afterData.note === ""
                  ? "None"
                  : item.afterData.note;
              const note = {
                name: "Note",
                old: before,
                new: after,
              };
              arrShow.push(note);
            }

            if ("description" in item.beforeData) {
              let before =
                item.beforeData.description === null ||
                item.beforeData.description === ""
                  ? "None"
                  : item.beforeData.description;
              let after =
                item.afterData.description === null ||
                item.afterData.description === ""
                  ? "None"
                  : item.afterData.description;
              const description = {
                name: "Description",
                old: before,
                new: after,
              };
              arrShow.push(description);
            }

            return (
              <div className="history_item" key={index}>
                {item.author !== null && item.author.isDeleted === 1 ? (
                  <Tooltip
                    className="warning"
                    title="User had been deleted!"
                    color="#2962FF"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-exclamation-circle"></i>
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <div className="history_body">
                  <span className="avatar">
                    {item.author !== null
                      ? item.author.firstName.charAt(0)
                      : "S"}
                  </span>
                  <p className="title">
                    <strong>
                      {" "}
                      {item.author !== null
                        ? item.author.firstName + " " + item.author.lastName
                        : "System"}
                    </strong>{" "}
                    updated {dayjs(new Date(newItem.createdAt)).fromNow()}
                  </p>
                </div>
                {newItem.arrHistoryShow.map((item, index) => {
                  return (
                    <div key={index} className="description">
                      <strong>{item.name}</strong>: <span>{item.old}</span>
                      <i className="fa fa-long-arrow-alt-right"></i>
                      <span>{item.new}</span>
                    </div>
                  );
                })}
              </div>
            );
          }
        });
      } else {
        return (
          <div className="history_error">
            <div className="icon">
              <FileOutlined />
            </div>
            <p className="title">No data</p>
          </div>
        );
      }
    }
  };

  return <div className="history">{showHistory()}</div>;
}
