import { convertTime } from "./convertTime";

export  const renderUpdater = (data,name) => {
    if (data.updater !== null) {
      return (
        <i>
          The last user updated this {name} was{" "}
          <strong>
            {data.updater.firstName +
              " " +
              data.updater.lastName}
          </strong>{" "}
          at <strong>{convertTime(data.updatedAt)}</strong>{" "}
        </i>
      );
    } else {
      return (
        <i>
          The last user updated this {name} was <strong>Empty</strong>{" "}
        </i>
      );
    }
  };