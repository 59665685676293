import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Table,
  Space,
  Modal,
  message,
  Popconfirm,
  Tabs,
  Popover,
  Tooltip,
  FloatButton,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/scss/Pages/User/_user.scss";
import {
  SearchOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import CreatePurchaseProduct from "../../Components/Product/CreatePurchaseProduct";

import { getAllPlatformApi } from "../../Redux/platformReducer/platformReducer";
import {
  deletePurchaseProductApi,
  findOnePurchaseProductApi,
  getAllPurchaseProductApi,
  getChangeLog,
} from "../../Redux/productReducer/purchaseProductReducer";
import { storage } from "../../Utils/storage";
import ReactDragListView from "react-drag-listview";
import PurchaseProductDetail from "../../Components/Product/PurchaseProductDetail";
import PurchaseProductHistory from "../../Components/Product/PurchaseProductHistory";
import { showHideDeletedItem } from "../../Utils/showHideDeletedItem";
import { renderShowColumn } from "../../Utils/renderShowColumn";
import ContentShowColumn from "../../Utils/ComponentCommon/ContentShowColumn";
import {
  CURRENT_PAGE,
  DEFAULT_PAGESIZE,
} from "../../Utils/ConstCommon/constCommon";
import ContentShowHideDeleted from "../../Utils/ComponentCommon/ContentShowHideDeleted";

export default function PurchaseProduct() {
  const [arrSelect, setArrSelect] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const { arrPurchaseProduct, productEdit } = useSelector(
    (state) => state.purchaseProductReducer
  );
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);

  const [currentPagination, setCurrentPagination] = useState(CURRENT_PAGE);
  const pageSizeRef = useRef(DEFAULT_PAGESIZE);
  const [showDeleted, setShowDeleted] = useState();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  // Use for table
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase()) ||
          record.shortName.toLowerCase().includes(value.toLowerCase()) ||
          record.wirelessTechName.toLowerCase().includes(value.toLowerCase()) ||
          record.description?.toLowerCase().includes(value.toLowerCase()) ||
          record.platformName?.toLowerCase().includes(value.toLowerCase()) ||
          record.supplierName?.toLowerCase().includes(value.toLowerCase()) ||
          String(record.unitCost).toLowerCase().includes(value.toLowerCase()) ||
          String(record.activationCost)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.batteriesCost)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.otherCost)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.totalPurchase)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      render: (text, record) => (
        <span
          className="open_detail"
          onClick={async () => {
            await dispatch(findOnePurchaseProductApi(record.id));
            showModalDetail();
          }}
        >
          {text}
        </span>
      ),
      sorter: (beforeItem, afterItem) =>
        beforeItem.name.toLowerCase() > afterItem.name.toLowerCase()
          ? 1
          : beforeItem.name.toLowerCase() === afterItem.name.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Short name",
      dataIndex: "shortName",
      key: "shortName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.shortName.toLowerCase() > afterItem.shortName.toLowerCase()
          ? 1
          : beforeItem.shortName.toLowerCase() ===
            afterItem.shortName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Wireless technology",
      dataIndex: "wirelessTechName",
      key: "wirelessTechName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.wirelessTechName.toLowerCase() >
        afterItem.wirelessTechName.toLowerCase()
          ? 1
          : beforeItem.wirelessTechName.toLowerCase() ===
            afterItem.wirelessTechName.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Source platform",
      dataIndex: "platformName",
      key: "platformName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.platformName?.toLowerCase() >
        afterItem.platformName?.toLowerCase()
          ? 1
          : beforeItem.platformName?.toLowerCase() ===
            afterItem.platformName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Purchase supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.supplierName?.toLowerCase() >
        afterItem.supplierName?.toLowerCase()
          ? 1
          : beforeItem.supplierName?.toLowerCase() ===
            afterItem.supplierName?.toLowerCase()
          ? 0
          : -1,
    },
    {
      title: "Unit cost",
      dataIndex: "unitCost",
      key: "unitCost",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.unitCost - afterItem.unitCost,
    },
    {
      title: "Activation cost",
      dataIndex: "activationCost",
      key: "activationCost",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.activationCost - afterItem.activationCost,
    },
    {
      title: "Battery cost",
      dataIndex: "batteriesCost",
      key: "batteriesCost",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.batteriesCost - afterItem.batteriesCost,
    },
    {
      title: "Other cost",
      dataIndex: "otherCost",
      key: "otherCost",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.otherCost - afterItem.otherCost,
    },
    {
      title: "Total",
      dataIndex: "totalPurchase",
      key: "totalPurchase",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.totalPurchase - afterItem.totalPurchase,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      sorter: (beforeItem, afterItem) =>
        beforeItem.description?.toLowerCase() >
        afterItem.description?.toLowerCase()
          ? 1
          : beforeItem.description?.toLowerCase() ===
            afterItem.description?.toLowerCase()
          ? 0
          : -1,
      render: (text) => (
        <Tooltip title={text} color="#2962ff">
          <Typography.Paragraph ellipsis={{ rows: 1 }}>
            {text}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "80px",
      render: (_, record) => (
        <Space size="middle" className="user_action">
          <Popconfirm
            title="Delete Purchase Product"
            placement="leftBottom"
            description="Are you sure to delete this purchase product?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={() => {
              const action = deletePurchaseProductApi(record.id);
              dispatch(action);
            }}
          >
            <button>
              <DeleteOutlined className="user_delete" />
            </button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  let [columnSelected, setColumnSelected] = useState(columns);
  for (let index = 0; index < columnSelected.length; index++) {
    let item = columnSelected[index];
    item.filteredValue = [searchValue];
  }

  // Drag and drop column
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newFromIndex = fromIndex - 1;
      const newToIndex = toIndex - 1;
      const newColumns = [...columnSelected];
      const item = newColumns.splice(newFromIndex, 1)[0];
      newColumns.splice(newToIndex, 0, item);
      setColumnSelected(newColumns);
      let arrRelocate = newColumns.map((item) => {
        return item.key;
      });
      storage.setStorageJson(
        "arrayPurchaseProductRelocateTitleColumn",
        arrRelocate
      );
    },
    nodeSelector: "th",
  };

  // Show hide column
  useEffect(() => {
    renderShowColumn(
      columns,
      "arrPurchaseProductShowColumn",
      "arrayPurchaseProductRelocateTitleColumn",
      "arrayPurchaseProductRelocateTitleColumnNew",
      setColumnSelected
    );
    document.title = "Tag Register - Purchase Products";
  }, []);

  const resetAll = () => {
    storage.removeStorage("arrPurchaseProductShowColumn");
    storage.removeStorage("arrayPurchaseProductRelocateTitleColumn");
    storage.removeStorage("arrayPurchaseProductRelocateTitleColumnNew");
    setColumnSelected(columns);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setArrSelect(selectedRows);
      return (
        `selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows
      );
    },
  };

  // Handle modal
  const showModal = () => {
    setOpen(true);
  };
  const showModalDetail = () => {
    setOpenDetail(true);
  };
  const handleOk = () => {
    message.loading("loading", 1);
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleCancelDetail = () => {
    setOpenDetail(false);
  };

  const tabCallback = (key) => {
    if (key === "2") {
      const action = getChangeLog(productEdit.id);
      dispatch(action);
    }
  };

  const items = [
    {
      key: "1",
      label: `Purchase product details`,
      children: (
        <PurchaseProductDetail handleCancelDetail={handleCancelDetail} />
      ),
    },
    {
      key: "2",
      label: `History`,
      children: <PurchaseProductHistory />,
    },
  ];

  // Call api
  useEffect(() => {
    dispatch(getAllPurchaseProductApi());
    dispatch(getAllPlatformApi());
  }, [dispatch]);

  // Recall api
  useEffect(() => {
    const reCallApi = setInterval(() => {
      dispatch(getAllPurchaseProductApi());
    }, 10000);
    return () => clearInterval(reCallApi);
  }, []);

  // Content for popover
  const content = (
    <ContentShowColumn
      columns={columns}
      storageShow="arrPurchaseProductShowColumn"
      storageRelocate="arrayPurchaseProductRelocateTitleColumn"
      storageRelocateNew="arrayPurchaseProductRelocateTitleColumnNew"
      action={setColumnSelected}
      resetAll={resetAll}
    />
  );

  // Show/hide selected item
  const onChangeShowDeleted = (checked) => {
    showHideDeletedItem(
      checked,
      arrPurchaseProduct,
      setData,
      "showDeletedPurchase"
    );
    setShowDeleted(checked);
  };

  useEffect(() => {
    const isShow = storage.getStore("showDeletedPurchase");
    if (isShow === undefined || isShow === "true") {
      setData(arrPurchaseProduct);
      setShowDeleted(true);
    } else {
      const arrayData = arrPurchaseProduct.filter(
        (item) => item.isDeleted !== 1
      );
      setData(arrayData);
      setShowDeleted(false);
    }
  }, [arrPurchaseProduct]);

  const contentAction = (
    <ContentShowHideDeleted
      onChangeShowDeleted={onChangeShowDeleted}
      showDeleted={showDeleted}
      arrayData={arrPurchaseProduct}
      columnSelected={columnSelected}
      pageName="PurchaseProduct"
      content={content}
    />
  );
  return (
    <div className="user">
      <div className="user_top">
        <div className="user_left">
          <p>Purchase Products</p>
        </div>
      </div>
      <div className="user_body">
        <div className="user_show">
          <div className="user_showChild">
            <div className="user_showLeft">
              <p>
                Selected: <strong>{arrSelect.length}</strong>
              </p>
              <button
                onClick={() => {
                  showModal();
                }}
                className="user_button-create"
              >
                + New
              </button>
              <Popover
                content={contentAction}
                trigger="click"
                placement="right"
              >
                <button className="button-second">
                  Action <i className="fa fa-caret-down"></i>
                </button>
              </Popover>
            </div>
            <div className="user_showRight">
              <div className="search_area">
                <SearchOutlined className="search_icon" />
                <Input
                  className="search_box"
                  placeholder="Search..."
                  allowClear
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="user_table">
          <ReactDragListView.DragColumn {...dragProps}>
            <Table
              rowSelection={rowSelection}
              columns={[...columnSelected]}
              dataSource={data}
              rowClassName={(record) =>
                record.isDeleted === 1 ? "deleted" : ""
              }
              pagination={{
                defaultPageSize: 10,
                current: currentPagination,
                showSizeChanger: true,
                showTotal: (total, range) => (
                  <p>
                    Showing{" "}
                    <strong>
                      {range[0]}-{range[1]}
                    </strong>{" "}
                    of <strong>{total}</strong> items
                  </p>
                ),
                onChange: (newCurrent, newPageSize) => {
                  const pageSizeChange = pageSizeRef.current !== newPageSize;
                  if (pageSizeChange) {
                    setCurrentPagination(1);
                  } else {
                    setCurrentPagination(newCurrent);
                  }
                  pageSizeRef.current = newPageSize;
                },
              }}
              bordered={true}
              scroll={{
                x: 2000,
              }}
              size="middle"
              sticky
            />
          </ReactDragListView.DragColumn>
        </div>
      </div>
      <Modal
        title="Create Purchase Product"
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
        style={{ top: "50px" }}
        destroyOnClose
      >
        <CreatePurchaseProduct
          handleCancel={handleCancel}
          handleOk={handleOk}
        />
      </Modal>
      <Modal
        title={"Purchase product ID: " + productEdit.id}
        open={openDetail}
        onCancel={handleCancelDetail}
        footer={null}
        width={600}
        destroyOnClose
        style={{ top: "50px" }}
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          size="small"
          className="user_tab"
          onChange={tabCallback}
          destroyInactiveTabPane
        />
      </Modal>
      <FloatButton.BackTop />
    </div>
  );
}
